import { ExternalUser } from '@/models/User';

export enum MostAppreciatedType {
  Talks = 'TALKGROUP_TOP_CONTRIBUTORS',
  Projects = 'PROJECT_TOP_CONTRIBUTORS',
}

export interface MostAppreciatedEntry {
  externalUser: ExternalUser;
  listPosition: number;
  listType: MostAppreciatedType;
  listPoints: number;
}
