
import IconCheckmark from '@/components/svg/checkmark.svg?inline';
import IconExclamationMark from '@/components/svg/exclamation-mark.svg?inline';
import { SelectItem } from '@/models/SelectItem';
import scrollIntoView from '@/utils/scrollIntoView';
import { getItemByText, getItemByValue } from '@/utils/selectItemTools';
import {
  computed, defineComponent, nextTick, PropType, ref, toRefs, watch
} from 'vue';
import {
  debouncedRef, onClickOutside, onKeyStroke, templateRef
} from '@vueuse/core';
import { clamp } from 'lodash';

interface ListItem extends SelectItem {
  lcText?: string; // lowercase cached
}

export default defineComponent({
  name: 'BCXSearchSelect',
  components: {
    IconCheckmark,
    IconExclamationMark
  },
  props: {
    // v-model value. Will always return the selected value.
    // except if "AllowAnything" is set, then it will return exactly what's been written there.
    value: {
      type: [Number, String],
      default: ''
    },
    // "select" mode will behave like a select. REALLY.
    // "input" mode will behave like an input field combined with a select.
    mode: {
      type: String as PropType<'select' | 'input'>,
      default: 'select'
    },
    // the list of items, in the format [{ value: string, text: string }, ...]
    list: {
      type: Array as PropType<ListItem[]>,
      default: () => []
    },
    // If set, you can type anything in the field and it will be returned on Enter. (mostly useful when value === text in the list)
    allowAnything: {
      type: Boolean
    },
    // Placeholder to show when nothing is selected
    placeholder: {
      type: String,
      default: ''
    },
    // Will clear the input field on click if this is set.
    clearOnClick: {
      type: Boolean
    },
    showValidityIndicator: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    selectedText: {
      type: String as () => string | null,
      default: null
    },
    invalid: {
      type: Boolean
    },
    label: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [String, Number, Boolean],
      default: false
    },
    type: {
      type: String as PropType<'default' | 'in-panel'>,
      default: 'default'
    },
  },
  emits: ['actively-select', 'search', 'update:search', 'update:selected-text', 'input'],
  setup(props, { emit }) {
    const {
      value, list, mode, allowAnything, clearOnClick, selectedText
    } = toRefs(props);

    const getItemFromValue = (value: string | number) => getItemByValue(list.value, value);

    let waitForNextTextChange = false;
    const inputText = ref<string | number>('');
    const isInputFocussed = ref<boolean>(false);
    const listElement = templateRef<HTMLElement>('list');
    const el = templateRef<HTMLElement>('el');
    const elRoot = templateRef<HTMLElement>('elRoot');
    const cursorPosition = ref<number>(-1);
    const lcList = computed(() => (list.value?.length
      ? list.value.map((listItem) => ({
        ...listItem,
        lcText: listItem.text.toLowerCase()
      }))
      : []));
    const inputTextDebounced = debouncedRef(inputText, 250);

    watch(inputTextDebounced, (text) => {
      emit('update:search', text);
    });

    const initialText = computed(() => {
      const itemFromValue = getItemFromValue(value.value);
      if (itemFromValue) {
        return itemFromValue?.selectedText ?? itemFromValue?.text;
      }
      return selectedText.value ?? value.value;
    });
    watch(initialText, (initialText) => {
      waitForNextTextChange = true;
      inputText.value = initialText;
    });
    inputText.value = initialText.value;

    watch(isInputFocussed, (is) => {
      if (is) cursorPosition.value = -1;
    });

    const lcInputText = computed(() => (inputText.value as string).toLowerCase());

    const shownList = computed(() => {
      if (!inputText.value || mode.value === 'select') return list.value;
      return lcList.value.filter(({ text }) => text.toLowerCase().includes(lcInputText.value));
    });
    const isListShown = computed(() => isInputFocussed.value);

    const selectItem = (value: string) => {
      waitForNextTextChange = true;
      const item = getItemFromValue(value);
      if (item?.selectedText) inputText.value = item.selectedText;
      emit('update:selected-text', item?.selectedText ?? item?.text);
      emit('input', value);
      isInputFocussed.value = false;
    };

    const selectAnything = () => {
      // emit('input', inputText.value);
      // console.log('Update ', inputText.value);
      emit('input', getItemByText(list.value, inputText.value as string)?.value ?? '');
      emit('update:selected-text', inputText.value);
      isInputFocussed.value = false;
    };

    watch(inputText, (txt: string | number) => {
      if (txt === '') {
        emit('input', '');
      }
      if (waitForNextTextChange) {
        waitForNextTextChange = false;
        return;
      }
      cursorPosition.value = -1;
      isInputFocussed.value = true;
    });

    const isValid = computed(() => lcList.value.some((listItem) => listItem.lcText === lcInputText.value || listItem.selectedText === inputText.value));

    const leaveField = () => {
      cursorPosition.value = -1;
      isInputFocussed.value = false;
      if (allowAnything.value) {
        // selectAnything();
      } else if (!isValid.value) inputText.value = initialText.value;
    };

    const selectCurrentItem = () => {
      if (cursorPosition.value < 0 && allowAnything.value) {
        selectAnything();
      } else {
        const itemValue = shownList.value?.[cursorPosition.value]?.value;
        if (itemValue) selectItem(itemValue);
      }
    };

    onClickOutside(el, () => {
      selectCurrentItem();
      leaveField();
    });

    const addToCursorPosition = (amount: number) => {
      cursorPosition.value = clamp(cursorPosition.value + amount, 0, shownList.value.length - 1);
      nextTick(() => {
        const cursorElement = listElement.value?.querySelector('.c-list--cursor');
        if (cursorElement) {
          scrollIntoView(cursorElement as HTMLElement, el.value as HTMLElement);
        }
      });
    };

    onKeyStroke(['Tab', 'ArrowUp', 'ArrowDown', 'Enter'], (evt) => {
      switch (evt.key) {
        case 'Tab':
          selectCurrentItem();
          leaveField();
          break;
        case 'ArrowUp':
          addToCursorPosition(-1);
          break;
        case 'ArrowDown':
          addToCursorPosition(1);
          break;
        case 'Enter':
          selectCurrentItem();
          emit('actively-select');
          break;
        default:
      }
      evt.preventDefault();
    }, {
      target: elRoot,
    });

    const onInputMouseDown = () => {
      if (clearOnClick.value) inputText.value = '';
    };

    const toggleList = () => {
      isInputFocussed.value = !isInputFocussed.value;
    };

    const classNames = computed(() => {
      const names = [];
      if (props.type !== 'default') names.push(`bc-search-select--${props.type}`);
      if (props.disabled) names.push('bc-search-select--disabled');
      return names;
    });

    return {
      isValid,
      isInputFocussed,
      inputText,
      isListShown,
      shownList,
      cursorPosition,
      selectItem,
      onInputMouseDown,
      toggleList,
      classNames
    };
  }
});
