

import { computed, defineComponent } from 'vue';
import useProfileState from '@/state/profile/profileState';
import { ProfileStoreKey } from '@/models/Profile';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';

export default defineComponent({
  name: 'ProfileAboutusView',
  components: { BCXMarkdown },
  setup() {
    const { profile } = useProfileState(ProfileStoreKey.PROFILE);

    const company = computed(() => profile.value?.company);

    return {
      company
    };
  }
});

