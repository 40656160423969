
import SidebarContent from '@/components/SidebarContent.vue';
import useI18n from '@/mixins/useI18n';
import { InfoBoxType } from '@/models/InfoBox';
import useUsersInPub from '@/views/virtual-pub/composable/virtual-pub/useUsersInPub';
import { computed, defineComponent, ref } from 'vue';
import AvailableUserItem from './AvailableUserItem.vue';
import AvailableUsersCounter from './AvailableUsersCounter.vue';

export default defineComponent({
  components: {
    SidebarContent,
    AvailableUserItem,
    AvailableUsersCounter
  },
  props: {
    inBox: {
      type: Boolean
    }
  },
  setup() {
    const isOpened = ref(true);
    const { t } = useI18n();

    const { users } = useUsersInPub();
    const contentClass = computed(() => (isOpened.value ? 'c-content--expanded' : 'c-content--compact'));

    return {
      availableUsers: users,
      contentClass,
      isOpened,
      t,
      InfoBoxType
    };
  }
});
