
import SidebarContent from '@/components/SidebarContent.vue';
import { InfoBoxType } from '@/models/InfoBox';
import useMessengerState from '@/state/messenger/messengerState';
import {
  computed, defineComponent, ref, watch
} from 'vue';
import BCXButton from '@/components/molecules/BCXButton.vue';
import RecommendProjectToUsers from '@/views/recommendations/components/recommend-project-to-users/RecommendProjectToUsers.vue';
import ProjectActionsButtons from '@/views/project-forum/components/sidebar-actions/ProjectActionsButtons.vue';
import ProjectActionApply from '@/views/project-forum/components/sidebar-actions/ProjectActionApply.vue';
import { useStore } from 'vue2-helpers/vuex';
import useUser from '@/mixins/useUser';
import ProjectOffersListBox from '@/views/project-forum/components/sidebar-actions/ProjectOffersListBox.vue';

export default defineComponent({
  name: 'ProjectActionsContainer',
  components: {
    ProjectActionApply,
    ProjectActionsButtons,
    RecommendProjectToUsers,
    BCXButton,
    SidebarContent,
    ProjectOffersListBox
  },
  props: {
    inBox: {
      type: Boolean
    }
  },
  setup() {
    const { tUserFallback: t, userId } = useUser('self');
    const isOpened = ref(true);
    const { isOpened: isMessengerOpened } = useMessengerState();
    const mode = ref<'actions' | 'recommend' | 'apply'>('actions');
    const store = useStore();
    const offerCount = ref(0);

    const isOwnProject = computed(() => store.state.projectForum?.project?.creator?.userId === userId.value);
    const projectId = computed(() => store.state.projectForum?.project?.id);

    const infoBoxType = computed(() => {
      if (mode.value === 'recommend') return InfoBoxType.INFOBOX_RECOMMENDATION;
      if (mode.value === 'apply' || isOwnProject.value) return InfoBoxType.INFOBOX_APPLY;
      return InfoBoxType.INFOBOX_PROJECT_ACTION_BOX;
    });

    const title = computed(() => {
      if (isOwnProject.value) {
        return offerCount.value ? t('project-forum_USERTYPE.offers-box.title-with-offers', { offers: offerCount.value }) : t('project-forum_USERTYPE.offers-box.title');
      }
      if (mode.value === 'recommend') return t('recommendations.recommend-to-user.title');
      if (mode.value === 'apply') return t('project-forum_USERTYPE.apply-box.title');
      return t('project-forum_USERTYPE.action-box.title');
    });

    const onSelected = () => {
      isMessengerOpened.value = false;
    };

    watch(mode, (mode) => {
      if (mode === 'apply') isMessengerOpened.value = false;
    });

    const isRecommendationTriggered = computed(() => store.state.projectForum.recommendTrigger);
    const isApplianceTriggered = computed(() => store.state.projectForum.applyTrigger);

    watch(isRecommendationTriggered, () => {
      mode.value = 'recommend';
    });
    watch(isApplianceTriggered, () => {
      mode.value = 'apply';
    });

    const onOffers = (offers:number) => {
      offerCount.value = offers;
      console.log('Received offers:', offers);
    };

    return {
      isOpened,
      infoBoxType,
      onSelected,
      mode,
      title,
      isRecommendationTriggered,
      isApplianceTriggered,
      isOwnProject,
      projectId,
      onOffers
    };
  }
});
