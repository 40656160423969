
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import { Invitee } from '@/models/Invitation';
import InviteeStatus from '@/views/invites/InviteeStatus.vue';
import {
  computed,
  defineComponent, PropType, toRefs
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'InviteesTable',
  components: {
    InviteeStatus,
    BCXUserBanner,
  },
  props: {
    invitees: {
      type: Array as PropType<Invitee[]>,
      default: () => []
    }
  },
});
