
import useI18n from '@/mixins/useI18n';
import { Lang } from '@/models/User';
import useSettings from '@/state/settingsState';
import GeneralSetting from '@/views/settings/components/utils/GeneralSetting.vue';
import { useLocalStorage } from '@vueuse/core';
import { computed, defineComponent, watch } from 'vue';

export default defineComponent({
  name: 'SectionGeneral',
  components: {
    GeneralSetting,
  },
  setup() {
    const { settings } = useSettings();
    const { locale } = useI18n();
    const storedUser = useLocalStorage<any>('user', null);

    const valueRef = computed(() => settings.value?.platformSettings.platformLocale);

    watch(valueRef, (langCode) => {
      if (langCode) {
        const [localeString] = langCode.split('-');
        if (localeString) {
          if (storedUser.value) {
            const user = JSON.parse(storedUser.value);
            user.preferredLocale = localeString as Lang;
            storedUser.value = JSON.stringify(user);
          }
          locale.value = localeString;
        }
      }
    });

    return {
      valueRef
    };
  }
});
