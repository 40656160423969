import { Industry } from '@/models/Tags';
import { User } from '@/models/User';
import { Ref } from 'vue';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';

export const PROFILE_MAX_INDUSTRIES = 6;

export enum ProfileMaxChars {
  HEADER_CITY = 22,
  HEADER_SUMMARY = 260,
  SKILL = 26,
  PLZ = 10,
  RADIUS = 4,
  DIPLOMA = 90,
  INSTITUTION = 90,
  CERTIFICATE = 90,
  ISSUER = 60,
  LINK = 255,
  PROJECT_FILE_TITLE = 60,
  CLIENT = 60,
  EXPERIENCE_TEXT = 5000,
  EXPERIENCE_TEXT_SOURCE = 50000,
  EXPERIENCE_CHARACTERS_LEFT_WARNING = 50,
  COMPANY_TEXT = 1000,
  COMPANY_TEXT_SOURCE= 10000,
  COMPANY_CHARACTERS_LEFT_WARNING = 50
}

export interface BasicDataRequest {
  city?: string;
  countryIsoCode?: string;
  profileSummary?: string;
}

export interface BasicData {
  city?: string;
  country?: string;
  profileSummary?: string;
  memberSince?: string;
  invitedBy?: User;
  employeeOf?: User;
}

export interface ProjectPreferencesRequest {
  countryIsoCode?: string;
  postalCode?: string;
  geoRadius?: number;
  remoteWork?: string;
  microProjects?: boolean;
  companySize?: string;
  placeName?: string;
  industryTagList?: Industry[];
}

export interface Certificate {
  externalId?: string;
  label: string;
  year: number;
  issuer: string;
  link: string;
}

export interface CertificatesRequest {
  certificates: {
    certificatesList: Certificate[];
  };
}

export interface Education {
  externalId?: string;
  label: string;
  year: number;
  institute: string;
  link: string;
}

export interface EducationsRequest {
  educations: {
    educationsList: Education[];
  };
}

export interface Reference {
  externalId?: string;
  externalIdObject?: string;
  clientName: string;
  year: number;
  filename?: string;
  document?: string;
  filetype?: string;
}

export interface References {
  referencesList: Reference[];
}

export interface ReferencesRequest {
  references: References;
}

export interface ProjectPreferences {
  companySize?: string;
  country?: string;
  industrieList?: Industry[];
  microProjects?: boolean;
  placeName?: string;
  postalCode?: string;
  radiusInKm?: number;
  remoteWork?: string;
}

export interface CompanyInformation {
  employeeCount: number;
  industries: Industry[];
  aboutUs: string;
  members: User[];
  publicProjects: {
    externalProjectList: ProjectListProject[];
  };
}

export interface PokListItem {
  pokComment: string;
  pokId: string;
  pokLink: string;
}

export interface SkillListItem {
  skillTagName?: string;
  skillTagId?: string;
  skillRoleTagName?: string;
  skillRoleTagId?: string;
  userSkillId?: string;
  pokList?: PokListItem[];
}

export interface SkillListItemRequest extends SkillListItem {
  skillTagId?: string;
}

export interface Skills {
  skillList: SkillListItem[];
}

export interface SkillListRequest {
  userSkills: {
    userSkillList: SkillListItem[];
  };
}

export interface CertificateEducationListItem {
  externalId?: string;
  link: string;
  year: number;
  label: string;
  position?: number;
}

export interface CertificateListItem extends CertificateEducationListItem {
  externalIdObject: string;
  issuer: string;
}

export interface EducationListItem extends CertificateEducationListItem {
  institute: string;
}

export interface Certificates {
  certificatesList: CertificateListItem[];
}

export interface Educations {
  educationsList: EducationListItem[];
}

export interface Experiences {
  experiencesText: string;
  experiencesDocumentLabel: string;
  experiencesDocument?: string | null;
}

export interface Profile {
  basic: BasicData;
  projectPreferences: ProjectPreferences;
  skills: Skills;
  certificates: Certificates;
  educations: Educations;
  references: References;
  experiences?: Experiences; // accumulated from different endpoint
  company?: CompanyInformation;
}

export interface ProfileStoreBlock {
  profile: Ref<Profile | null>;
  userId: Ref<string>;
  isSelf: Ref<boolean>;
  user: Ref<User | null>;
  isLoading: Ref<boolean>;
}

export enum ProfileStoreKey {
  PROFILE = 'profile',
  QUICK_INFO = 'quickInfo'
}

export type ProfileSectionKey = 'basic' | 'certificates' | 'educations' | 'projectPreferences' | 'skills' | 'references' | 'projects' | 'certificatesEducations' | 'invitedBy' | 'aboutus' | 'members' | 'publicprojects';

export type ProfileStore = Record<ProfileStoreKey, ProfileStoreBlock>;

export interface CompanyInformationRequest {
  aboutUs: string;
  employeeCount: number;
  industries: Industry[];
}
