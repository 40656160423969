import {
  deleteData, getData, postData, putData
} from '@/utils/apiTools';
import {
  BlogEntries, BlogEntriesAdmin, BlogEntry, BlogForm
} from '../../views/blog/models/Blog';

// Blog Getters
export const getBlogEntries = async (): Promise<BlogEntries> => getData('/v1/blog/entries');
export const getBlogEntry = async (blogEntryId: string): Promise<BlogEntry> => getData(`/v1/blog/entries/${blogEntryId}`);

// Blog Put
// eslint-disable-next-line max-len
export const putUpdateBlogEntryView = async (blogEntryId: string): Promise<void> => putData(`/v1/blog/entries/${blogEntryId}/views`);

// Blog Admin Getters
export const getBlogEntriesAdmin = async (): Promise<BlogEntriesAdmin> => getData('/v1/admin/blog/entries');
export const getBlogEntryAdmin = async (blogEntryId: string): Promise<BlogForm> => getData(`/v1/admin/blog/entries/${blogEntryId}`);

// Blog Admin Post
export const postBlogEntryAdmin = async (blogEntry: any): Promise<BlogForm> => postData('/v1/admin/blog/entries', blogEntry);
export const postPublishBlogEntryAdmin = async (blogEntryId: string): Promise<void> => postData(`/v1/admin/blog/entries/${blogEntryId}/publish`);
// eslint-disable-next-line max-len
export const postUnPublishBlogEntryAdmin = async (blogEntryId: string): Promise<void> => postData(`/v1/admin/blog/entries/${blogEntryId}/unpublish`);

// Blog Admin Put
// eslint-disable-next-line max-len
export const putBlogEntryAdmin = async (blogEntryId: string, blogEntry: any): Promise<BlogForm> => putData(`/v1/admin/blog/entries/${blogEntryId}`, blogEntry);

// BLOG ADMIN DELETE
export const deleteBlogEntryAdmin = async (blogEntryId: string): Promise<void> => deleteData(`/v1/admin/blog/entries/${blogEntryId}`);
