import { computed, Ref, unref } from 'vue';
import { MaybeRef } from '@vueuse/core';

const usePaginationHelper = <T>(pageSize:MaybeRef<number>, currentPage:Ref<number>, array:MaybeRef<T[]> = []) => {
  const slicedArray = computed(() => {
    const offset = unref(pageSize) * (unref(currentPage) - 1);
    return unref(array)
      .slice(offset, offset + unref(pageSize));
  });

  const firstItemOnPage = computed(() => ((currentPage.value - 1) * unref(pageSize)) + 1);
  const total = computed(() => unref(array)?.length ?? 0);

  return {
    slicedArray, firstItemOnPage, pageSize, total
  };
};

export default usePaginationHelper;
