import { computedEager, createGlobalState, useMediaQuery } from '@vueuse/core';
import useI18n from '@/mixins/useI18n';
import { computed, inject } from 'vue';
import useMultiLineBreaks from '@/mixins/useMultiLineBreaks';

const useFreelancerLandingPage = createGlobalState(() => {
  const { t } = useI18n();
  const isNarrow = useMediaQuery('(width < 800px)');
  const isTablet = useMediaQuery('(800px <= width < 1020px)');
  const backgroundNr = computedEager(() => {
    if (isNarrow.value) return 2;
    if (isTablet.value) return 1;
    return 0;
  });
  // eslint-disable-next-line no-nested-ternary
  const lineBreak = computed(() => (isNarrow.value ? 'smartphone' : isTablet.value ? 'tablet' : 'desktop'));
  const linebreaks = t('landingpage-freelancer.linebreaks') as any;
  const { tl } = useMultiLineBreaks(linebreaks, lineBreak);
  const toggleInvitePopup = inject<CallableFunction>('toggleInviteCodePopup');

  return {
    t, isNarrow, isTablet, backgroundNr, tl, toggleInvitePopup
  };
});

export default useFreelancerLandingPage;
