
import {
  computed, defineComponent, nextTick, ref, watch
} from 'vue';
import { ForumMaxChars } from '@/views/forum/models/Forum';
import Chevron from '@/components/svg-components/Chevron.vue';
import { Editor } from '@toast-ui/vue-editor';
import ToastEditor from '@toast-ui/editor';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import { buttonSVGs, ButtonType } from '@/mixins/useToastUiEditorTools';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';

export default defineComponent({
  components: {
    Chevron,
    Editor,
  },
  props: {
    editValue: {
      type: String,
      default: '',
    },
    isCreatingProject: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const maxChars = ref(ForumMaxChars.PROJECT_DESCRIPTION);
    const { beforeRenderEditor } = BCXHTMLSanitizer;

    const user = computed(() => store.getters.user);
    const isUserAdmin = computed(() => (user.value.roles as Array<string>).includes('ADMIN'));

    const isEnlarged = ref(false);
    const editorHeight = computed(() => {
      if (isMobileLayout.value) {
        return isEnlarged.value ? '80vh' : '280px';
      }
      return isEnlarged.value ? '680px' : '280px';
    });

    const addImageBlobHook = (blob: File, callback: any) => {
      /* if (blob.size < 1024 * 20) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        let base64FileString = '';
        reader.onload = () => {
          base64FileString = reader.result as string;
        };
        callback(`data:${blob.type};base64,${base64FileString}`, '');
      } */
      callback('', '');
    };

    const toastUiEditor = ref<ToastEditor>({} as ToastEditor);

    const charsLeft = computed(() => {
      if (toastUiEditor.value.getMarkdown) {
        return maxChars.value - toastUiEditor.value.getMarkdown().length;
      }
      return maxChars.value;
    });

    watch(() => charsLeft.value, (newValue) => {
      if (newValue <= 0 && !isUserAdmin.value) {
        const truncatedText = toastUiEditor.value.getMarkdown().substring(0, maxChars.value);
        toastUiEditor.value.setMarkdown(truncatedText);
      }
    });

    const toggleTextAreaSize = () => {
      isEnlarged.value = !isEnlarged.value;
      nextTick(() => {
        toastUiEditor.value.setHeight(editorHeight.value);
      });
    };

    const onLoad = (editor: ToastEditor) => {
      toastUiEditor.value = editor;
      nextTick(() => {
        if (isUserAdmin.value) {
          toastUiEditor.value.insertToolbarItem({ groupIndex: 0, itemIndex: 10 }, 'image');
        } else {
          toastUiEditor.value.addHook('addImageBlobHook', addImageBlobHook);
        }
        toastUiEditor.value.setHeight(editorHeight.value);
      });
    };
    const onKeyDown = (ev: KeyboardEvent | 'wysiwyg') => {
      emit('input', toastUiEditor.value.getHTML());
    };

    const createButton = (which: ButtonType) => {
      const button = document.createElement('button');
      button.style.margin = '0';
      button.innerHTML = buttonSVGs[which];
      button.addEventListener('click', () => {
        if (toastUiEditor.value) toastUiEditor.value.exec(which);
      });
      return button;
    };

    return {
      t,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
        autofocus: false,
        toolbarItems: [
          ['bold', 'italic', 'quote', 'ul', 'ol', 'indent', 'outdent', 'link',
            {
              el: createButton('undo'),
              command: 'undo',
              tooltip: t('buttons.undo')
            },
            {
              el: createButton('redo'),
              command: 'redo',
              tooltip: t('buttons.redo')
            }]
        ],
      },
      isEnlarged,
      isMobileLayout,
      isUserAdmin,
      charsLeft,
      toggleTextAreaSize,
      onLoad,
      onKeyDown,
      beforeRenderEditor,
    };
  }
});
