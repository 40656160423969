import { render, staticRenderFns } from "./BCXSidebarHelpContainer.vue?vue&type=template&id=125d21f5&scoped=true"
import script from "./BCXSidebarHelpContainer.vue?vue&type=script&lang=ts&setup=true"
export * from "./BCXSidebarHelpContainer.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./BCXSidebarHelpContainer.vue?vue&type=style&index=0&id=125d21f5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125d21f5",
  null
  
)

export default component.exports