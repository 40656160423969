
import { computed, defineComponent } from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useStore } from 'vue2-helpers/vuex';
import useResponsiveness from '@/mixins/useResponsiveness';
import { useRouter } from 'vue2-helpers/vue-router';
import { onMounted } from 'vue-demi';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'RegistrationFinished',
  components: { BCXMarkdown },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { isMobileWidth } = useResponsiveness();

    const { tUser, user } = useUser('self');

    const gotoLogin = () => {
      store.commit('clearUserAndToken');
      router.push({ name: 'login', query: { email: (user.value?.email ?? '') } });
    };

    onMounted(() => {
      setTimeout(gotoLogin, 5000);
    });

    const welcomeText = computed(() => tUser('registration_USERTYPE.welcome_title'));

    return {
      isMobileWidth,
      user,
      gotoLogin,
      welcomeText
    };
  },
});
