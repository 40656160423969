
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { ProjectGuest } from '@/models/Project';
import { PropType, defineComponent } from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXLinkedUser from '@/components/molecules/BCXLinkedUser.vue';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProjectGuestsTable',
  components: {
    BCXAvatar,
    BCXLinkedUser,

  },
  props: {
    guests: {
      type: Array as PropType<ProjectGuest[]>,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { tUserFallback: t } = useUser('self');
    const { getFormattedDate } = useFormattedDateTime();

    const convertRegisteredDate = (date: string | undefined) => {
      if (!date) return '';

      return getFormattedDate(date, false);
    };
    const removeGuest = (guest: ProjectGuest) => {
      emit('remove-guest', guest);
    };

    return {
      t,
      removeGuest,
      convertRegisteredDate,
    };
  },
});
