
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import { User } from '@/models/User';
import useQuickInfo from '@/state/quickInfo';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { templateRef } from '@vueuse/core';
import { getDisplayName } from '@/utils/user';

export default defineComponent({
  name: 'MostAppreciatedItem',
  components: { BCXAvatar },
  props: {
    isFirst: {
      type: Boolean
    },
    position: {
      type: Number,
      default: 0
    },
    points: {
      type: Number,
      default: 0
    },
    user: {
      type: Object as PropType<User>,
      default: null
    }
  },
  setup(props) {
    const { user } = toRefs(props);
    const el = templateRef<HTMLElement>('el');
    const { showQuickInfo } = useQuickInfo();

    const onClick = () => {
      showQuickInfo(el, { ...user.value, userId: user.value?.userId });
    };

    const userId = computed(() => user.value?.userId);

    const name = computed(() => getDisplayName(user.value));

    return {
      userId,
      onClick,
      name
    };
  }
});
