
import {
  computed, defineComponent, PropType, toRef
} from 'vue';

export default defineComponent({
  name: 'InnerSection',
  props: {
    title: {
      type: String as PropType<string>,
      default: undefined,
    },
    content: {
      type: [String, Array, Number] as PropType<string | string[] | number>,
      default: undefined,
    },
  },
  setup(props) {
    const content = toRef(props, 'content');
    const contentIsArray = computed(() => Array.isArray(content.value));

    return {
      contentIsArray,
    };
  },
});
