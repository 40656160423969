import _axios from '@/plugins/axios';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/rooms/uniqueRoomName/user';

const useRemoveUserFromRoom = () => {
  function getEndpoint(uniqueRoomName: string) {
    return ENDPOINT.replace('uniqueRoomName', uniqueRoomName);
  }

  const removeUserFromRoomAxios = useAxios(ENDPOINT, { method: 'DELETE' }, _axios, { immediate: false });

  async function removeUserFromRoom(uniqueRoomName: string) {
    await removeUserFromRoomAxios.execute(getEndpoint(uniqueRoomName), { method: 'DELETE' });
  }

  return {
    removeUserFromRoom,
    ...removeUserFromRoomAxios,
  };
};

export default useRemoveUserFromRoom;
