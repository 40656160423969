

import { computed, defineComponent } from 'vue';
import useProfileState from '@/state/profile/profileState';
import { ProfileStoreKey } from '@/models/Profile';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import SettingsIcon from '@/components/svg/settings.svg?inline';
import BCXPaginatedContent from '@/components/molecules/BCXPaginatedContent.vue';

export default defineComponent({
  name: 'ProfileMembersView',
  components: { BCXPaginatedContent, BCXUserBanner, SettingsIcon },
  setup() {
    const { profile, canEdit } = useProfileState(ProfileStoreKey.PROFILE);
    const members = computed(() => profile.value?.company?.members ?? []);

    return {
      members, canEdit
    };
  }
});
