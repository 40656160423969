
import Vue, { computed } from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useRoute } from 'vue2-helpers/vue-router';

export default Vue.extend({
  components: { BCXMarkdown },
  setup() {
    const route = useRoute();

    const legalText = computed(() => (route.name === 'show-privacy' ? 'privacy' : 'terms'));

    return {
      legalText,
    };
  }
});
