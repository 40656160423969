import { asyncComputed, MaybeRef } from '@vueuse/core';
import recommendationService from '@/services/RecommendationService';
import { computed, ref, unref } from 'vue';
import useRecommendationVerifyResult from '@/views/recommendations/composables/useRecommendationVerifyResult';
import ProjectForumService from '@/services/ProjectForumService';

const useRecommendation = (projectId: MaybeRef<string>, userId: MaybeRef<string>) => {
  const hasBeenRecommended = ref(false);
  const message = ref('');

  const projectVerification = asyncComputed(
    async () => {
      const pId = unref(projectId);
      const uId = unref(userId);
      if (pId && uId) {
        return recommendationService.verifyRecommendation(uId, pId);
      }
      return null;
    },
    null,
    {
      lazy: true
    }
  );

  const verifyResult = useRecommendationVerifyResult(projectVerification);

  const recommend = async () => {
    const pId = unref(projectId);
    const uId = unref(userId);

    await recommendationService.createRecommendation(uId, pId, message.value);
    hasBeenRecommended.value = true;
  };

  const cantRecommend = computed(
    () => hasBeenRecommended.value || !verifyResult.canRecommend.value
  );

  const verifiedProject = asyncComputed(async () => {
    const pId = unref(projectId);
    if (!pId) return null;

    if (projectVerification.value?.project) return projectVerification.value.project;

    return ProjectForumService.getProject(unref(pId));
  });

  return {
    ...verifyResult,
    projectVerification,
    recommend,
    message,
    hasBeenRecommended,
    cantRecommend,
    verifiedProject
  };
};

export default useRecommendation;
