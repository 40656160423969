
import Vue, { computed, ref } from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import UserSearchItem from '@/views/user-search/components/UserSearchItem.vue';
import MarvinAnnoyed from '@/components/svg/marvin/marvin_annoyed_1.svg?inline';
import IconChevronDown from '@/components/svg/chevron-down.svg?inline';
import useUserSearch from '@/views/user-search/store/useUserSearch';
import BCXPagination from '@/components/molecules/BCXPagination.vue';
import usePaginationHelper from '@/mixins/usePaginationHelper';
import UserSearchItemSkeleton from '@/views/user-search/components/UserSearchItemSkeleton.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { whenever } from '@vueuse/core';

const PAGE_SIZE = 10;

export default Vue.extend({
  name: 'UserSearchResults',
  components: {
    BCXMarkdown,
    UserSearchItemSkeleton,
    BCXPagination,
    UserSearchItem,
    BCXContentArea,
    MarvinAnnoyed,
    IconChevronDown
  },
  setup() {
    const {
      userList, showTags, isLoading, actualResultSize, isError
    } = useUserSearch();
    const isOpen = ref(true);
    const currentPage = ref(1);

    const toggleContainer = () => { isOpen.value = !isOpen.value; };

    const componentClass = computed(() => {
      if (userList.value?.length) return isOpen.value ? '' : 'container-collapsed';
      return 'no-result-box';
    });

    const { slicedArray: users } = usePaginationHelper(PAGE_SIZE, currentPage, userList);

    whenever(isLoading, () => {
      currentPage.value = 1;
    });

    return {
      isError,
      isOpen,
      userList,
      users,
      toggleContainer,
      componentClass,
      showTags,
      PAGE_SIZE,
      currentPage,
      isLoading,
      actualResultSize
    };
  }
});
