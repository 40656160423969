
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import { defineComponent, PropType } from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'ActionButtons',
  components: { BCXValidatedInput },
  props: {
    disabled: {
      type: Boolean
    },
    okDisabled: {
      type: Boolean
    },
    type: {
      type: String as PropType<'normal' | 'popup' | 'password' | 'delete-account'>,
      default: 'normal'
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const vmValue = useVModel(props);
    return {
      vmValue
    };
  }
});
