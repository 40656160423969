
import useI18n from '@/mixins/useI18n';
import { defineComponent, PropType } from 'vue';
import { OpeningDay } from '../../composable/useBarOpeningHours';

export default defineComponent({
  name: 'OpeningHours',
  props: {
    openingDays: {
      type: Array as PropType<OpeningDay[]>,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  }
});
