import { BusinessObjectType } from '@/models/BusinessObjects';
import { Chat, CHAT_TYPE_DIRECT, NEW_CHAT_MESSAGE_ID } from '@/models/Messenger';
import { User } from '@/models/User';
import { Ref } from 'vue';

export const cleanPreviousNewChats = (chats: Ref<Chat[]>) => {
  chats.value = chats.value.filter((chat: Chat) => chat.id !== NEW_CHAT_MESSAGE_ID);
};

export const findExistingChatForUser = (chats: Ref<Chat[]>, user:User) => chats.value.find(
  (chat) => chat?.privatePartner?.userId === user?.userId && chat.type === 'PRIVATE_MESSAGING',
);

export const createNewChatFromUser = (user: User) => {
  const newChat: Chat = {
    type: CHAT_TYPE_DIRECT,
    id: NEW_CHAT_MESSAGE_ID,
    messages: [],
    privatePartner: user,
  };
  return newChat;
};
