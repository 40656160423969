
import {
  computed, defineComponent, PropType, provide, ref, toRefs
} from 'vue';
import IconSkills from '@/components/svg/profile-skills.svg?inline';
import EmptySection from '@/views/profile/components/EmptySection.vue';
import IconProjects from '@/components/svg/profile-projects.svg?inline';
import IconReferences from '@/components/svg/profile-references.svg?inline';
import IconPreferences from '@/components/svg/profile-preferences.svg?inline';
import useProfileState from '@/state/profile/profileState';
import IconCertificates from '@/components/svg/profile-certificates.svg?inline';
import IconAboutUs from '@/components/svg/profile-aboutus.svg?inline';
import IconMembersGuests from '@/components/svg/profile-members-guests.svg?inline';
import IconPublicProjects from '@/components/svg/profile-public-projects.svg?inline';
import EditIcon from '@/components/molecules/EditIcon.vue';
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import { ProfileSectionKey } from '@/models/Profile';
import useUser from '@/mixins/useUser';

const icons = {
  skills: IconSkills,
  projectPreferences: IconPreferences,
  references: IconReferences,
  certificatesEducations: IconCertificates,
  projects: IconProjects,
  aboutus: IconAboutUs,
  members: IconMembersGuests,
  publicprojects: IconPublicProjects
} as any;

const expandableSections = [
  'aboutus', 'members', 'publicprojects'
];

export default defineComponent({
  name: 'ProfileSection',
  components: {
    BCXCollapsible,
    BCXChevronSwitch,
    EditIcon,
    EmptySection,
    IconSkills,
    IconPreferences,
    IconReferences,
    IconCertificates,
    IconProjects,
  },
  props: {
    section: {
      type: String as PropType<ProfileSectionKey>,
      default: '',
    },
  },
  setup(props, { slots }) {
    const { section } = toRefs(props);
    const isExpanded = ref(true);

    provide('currentEditSection', section);

    const {
      isSelf, editSections, toggleEditSection, isSectionFilled, isQuickInfo, user
    } = useProfileState();

    const { tUserFallback } = useUser(user);

    const edit = () => {
      toggleEditSection(section.value);
    };

    const icon = computed(() => icons[section.value]);

    const headline = computed(() => (section.value ? tUserFallback(`profile.${section.value}.headline`) : ''));
    const emptyText = computed(() => tUserFallback(`profile.${section.value}.emptyText`));

    const hasEditorContent = slots?.editor;

    const isBeingEdited = computed(
      () => !isQuickInfo && editSections.value.includes(section.value),
    );
    const hasData = computed(
      () => isSectionFilled.value[section.value],
    );
    const showEmptySection = computed(
      () => !hasData.value && section.value && !isBeingEdited.value,
    );
    const showEditButton = computed(
      () => hasEditorContent && isSelf.value && headline.value && !isBeingEdited.value && section.value,
    );
    const showSection = computed(
      () => hasData.value || (isSelf.value && !isQuickInfo) || !section.value,
    );

    const hasIconSlot = computed(() => !!slots.icon);

    const hasHeadlineSlot = computed(() => !!slots.headline);

    const isExpandable = computed(() => expandableSections.includes(section.value));

    return {
      hasIconSlot,
      hasHeadlineSlot,
      icon,
      hasData,
      isSelf,
      isBeingEdited,
      showSection,
      showEmptySection,
      showEditButton,
      headline,
      emptyText,
      edit,
      isExpandable,
      isExpanded
    };
  },
});
