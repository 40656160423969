import { ExternalVideoGroupRoom } from '@/__generated__/types';
import _axios from '@/plugins/axios';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/rooms/uniqueRoomName';

const useGetRoom = () => {
  function getEndpoint(uniqueRoomName: string) {
    return ENDPOINT.replace('uniqueRoomName', uniqueRoomName);
  }

  const getRoomAxios = useAxios<ExternalVideoGroupRoom>(ENDPOINT, { method: 'GET' }, _axios, { immediate: false });

  async function createGetRoom(uniqueRoomName: string) {
    if (uniqueRoomName) {
      await getRoomAxios.execute(getEndpoint(uniqueRoomName),
        {
          method: 'GET',
        });
    }
  }

  return {
    createGetRoom,
    ...getRoomAxios,
  };
};

export default useGetRoom;
