import BlogAdmin from '@/views/blog/pages/BlogAdmin.vue';
import BlogArticle from '@/views/blog/pages/BlogArticle.vue';
import BlogHome from '@/views/blog/pages/BlogHome.vue';
import BlogNews from '@/views/blog/pages/BlogNews.vue';
import BlogArticles from '@/views/blog/pages/BlogArticles.vue';
import BlogWiki from '@/views/blog/pages/BlogWiki.vue';
import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    name: 'blog',
    path: '/blog',
    component: BlogHome,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'blog news',
    path: '/blog/news',
    component: BlogHome,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'blog articles',
    path: '/blog/articles',
    component: BlogHome,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'blog-admin',
    path: '/admin-editor',
    component: BlogAdmin,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'blog articles article',
    path: '/blog/articles/:articleId',
    props: true,
    component: BlogArticle,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'blog news article',
    path: '/blog/news/:articleId',
    props: true,
    component: BlogArticle,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'blog wiki article',
    path: '/wiki/help/:articleId',
    props: true,
    component: BlogArticle,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  },
  {
    name: 'wiki help',
    path: '/wiki/help',
    component: BlogWiki,
    beforeEnter(to, from, next) {
      next();
    },
    meta: {
      layout: 'full',
      auth: true
    }
  }
];
export default routes;
