import { ref, Ref } from 'vue';

/**
 * useResponsiveness
 * uses a composable & global state to manage all important responsive breakpoints
 * for the bytecookie page.
 *
 * @author nc
 */

const isSmartphoneWidth = ref(false);
const isMobileWidth = ref(false);
const isMini = ref(false);
const isFlat = ref(false);
const isTabletWidth = ref(false);
const isDesktopWidth = ref(false);
const isLandingPageMobileWidth = ref(false);
const isLandingPageTabletWidth = ref(false);
const isTooFlatForMessenger = ref(false);
const isAvailabilityADot = ref(false);

const config: { [key: string]: Ref<boolean> } = {
  '(max-width:500px)': isAvailabilityADot,
  '(max-width:480px)': isSmartphoneWidth,
  '(max-width:766px)': isMobileWidth,
  '(min-width:767px) and (max-width:1439px)': isMini,
  '(max-height:640px) and (orientation:landscape)': isFlat,
  '(max-height:600px) and (orientation:landscape)': isTooFlatForMessenger,
  '(min-width:767px) and (max-width:1255px)': isTabletWidth,
  '(min-width:1256px)': isDesktopWidth,
  '(max-width:799px)': isLandingPageMobileWidth,
  '(min-width:800px) and (max-width:1019px)': isLandingPageTabletWidth,
};

// const isMobileLayout = computed(() => isFlat.value || isMobileWidth.value);

Object.entries(config)
  .forEach(([mediaQuery, ref]) => {
    const mql: MediaQueryList = window.matchMedia(mediaQuery);
    ref.value = mql.matches;
    mql.onchange = (evt) => {
      ref.value = evt.matches;
    };
  });

const useResponsiveness = () => ({
  isSmartphoneWidth,
  isMobileWidth,
  isMini,
  isFlat,
  isTabletWidth,
  isDesktopWidth,
  isMobileLayout: isMobileWidth,
  isSidebarVisible: isDesktopWidth,
  isLandingPageMobileWidth,
  isLandingPageTabletWidth,
  isTooFlatForMessenger,
  isAvailabilityADot
});

export default useResponsiveness;
