import { nextTick } from 'vue';
import useResponsiveness from '@/mixins/useResponsiveness';

const useBodyScroll = () => {
  let scrollPosition = 0;
  const { isMobileLayout } = useResponsiveness();

  const saveScrollPosition = () => {
    scrollPosition = window.scrollY;
  };
  const restoreScrollPosition = () => {
    window.scrollTo({ top: scrollPosition });
  };

  const hideOverflow = () => {
    document.querySelector('html')?.style.setProperty('overflow', 'hidden');
    document.querySelector('body')?.style.setProperty('overflow', 'hidden');
  };

  const removeOverflow = () => {
    document.querySelector('html')?.style.removeProperty('overflow');
    document.querySelector('body')?.style.removeProperty('overflow');
  };

  const restore = () => {
    removeOverflow();
    if (isMobileLayout.value) restoreScrollPosition();
  };

  const hideOrRemove = (hide = false) => {
    if (!isMobileLayout.value) return;
    saveScrollPosition();
    if (hide) {
      nextTick(() => hideOverflow());
    } else {
      removeOverflow();
    }
  };

  return {
    restore, hideOrRemove
  };
};

export default useBodyScroll;
