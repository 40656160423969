
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import ProjectGuests from '@/views/project-forum/components/form-components/ProjectGuests.vue';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import { logDebug } from '@/utils/logger';
import {
  defineComponent, onMounted, ref
} from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { ProjectGuest } from '@/models/Project';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProjectGuestInvitation',
  components: {
    BCXBreadcrumbs,
    ProjectGuests,
    BCXInfoSection
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const breadcrumbItems = [
      {
        text: 'Projects',
        to: { name: 'projectList' }
      },
      {
        text: 'Guest Invitation'
      }
    ];

    const guestList = ref<ProjectGuest[]>([]);
    const { tUserFallback: t } = useUser('self');

    const goToProjectForum = () => {
      router.push({ name: 'projectForumThread', params: { id: route.params.id } });
    };

    onMounted(() => {
      logDebug('ProjectGuestInvitation', 'mounted');
    });
    return {
      t,
      goToProjectForum,
      breadcrumbItems,
      guestList
    };
  },
});
