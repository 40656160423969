import axios from '@/plugins/axios';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';
import { logError } from '@/utils/logger';

export interface Tag {
  tagId: string;
  tagName?: string;
  tagType: string;
}

export interface SkillTag {
  id: string;
  name?: string;
  type: string;
  status?: string;
}

export enum UserVoted {
  UP = 'UP',
  DOWN = 'DOWN',
  NOTVOTED = 'NOTVOTED'
}

export interface ExternalTagAssignment {
  externalId: string;
  businessObjectId: string;
  businessObjectType: string;
  tagId: string;
  tagName: string;
  tagType: string;
  upvoteCount: number;
  downvoteCount: number;
  voteResult: number;
  userVoted: UserVoted;
}

export default {
  async getTags(filter: string): Promise<Tag[]> {
    return axios.get(`/v1/tags/?type=TAG&filter=${filter}`).then((response) => response.data).catch((error: any) => logError(error));
  },
  async getRoles(filter: string): Promise<Tag[]> {
    return axios.get(`/v1/tags/?type=ROLE&filter=${filter}`).then((response) => response.data).catch((error: any) => { logError(error); });
  },
  async getAllRoles(): Promise<Tag[]> {
    return axios.get('/v1/tags/?type=ROLE').then((response) => response.data).catch((error: any) => { logError(error); });
  },
  async getSkills(filter:string):Promise<SkillTag[]> {
    return axios.get(`/v1/tags/skills/?filter=${filter}`).then((response) => response.data).catch((error: any) => logError(error));
  },
  async getAssignedTags(businessObjectType:string, businessObjectId: string):Promise<ExternalTagAssignment[]> {
    return axios.get(`/v1/tags/assignment/type/${businessObjectType}/id/${businessObjectId}`)
      .then((response) => response.data.tagAssignmentList)
      .catch((error: any) => logError(error));
  },
  async deleteAssignedTag(businessObjectType: string, businessObjectId: string, tagId: string): Promise<ExternalTagAssignment> {
    return axios.delete(`/v1/tags/assignment/type/${businessObjectType}/id/${businessObjectId}/tag/${tagId}`)
      .then(({ data }) => data)
      .catch((error: any) => logError(error));
  },
  async assignTag(tagId: string, businessObjectType:string, businessObjectId: string):Promise<ExternalTagAssignment> {
    return axios.post('/v1/tags/assignment', { tagId, businessObjectId, businessObjectType })
      .then((response) => response.data)
      .catch((error: any) => logError(error));
  },
  async createNewTag(newTagName: string, businessObjectType: string, businessObjectId: string): Promise<ExternalTagAssignment> {
    newTagName = BCXHTMLSanitizer.beforeSave(newTagName);
    return axios.post('/v1/tags/assignment', { newTagName, businessObjectId, businessObjectType })
      .then((response) => response.data)
      .catch((error: any) => logError(error));
  },
};
