import { RouteConfig } from 'vue-router';
import PasswordReset from '../pages/PasswordReset.vue';
import PasswordResetConfirmation from '../pages/PasswordResetConfirmation.vue';
import PasswordResetSetNewPassword from '../pages/PasswordResetSetNewPassword.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/password-reset-confirmation',
    name: 'password-reset-confirmation',
    component: PasswordResetConfirmation,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/set-new-password',
    name: 'set-new-password',
    component: PasswordResetSetNewPassword,
    meta: {
      layout: 'login-register',
    },
  },
];

export default routes;
