
import RegistrationService from '@/services/RegistrationService';
import {
  computed, defineComponent, onMounted, reactive, ref
} from 'vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import useVuelidate from '@vuelidate/core';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import useI18n from '@/mixins/useI18n';
import { Lang, UserType } from '@/models/User';
import useUser from '@/mixins/useUser';
import { asyncComputed } from '@vueuse/core';

export default defineComponent({
  name: 'Verification',
  components: {
    BackendErrors,
    ButtonBlock,
    BCXValidatedInput,
    BCXMarkdown,
    LRPopup
  },
  setup() {
    const resent = ref(false);
    const resending = ref(false);
    const resendError = ref(false);
    const submitting = ref(false);
    const showConfirm = ref(false);
    const emptyCode = ref(true);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const backendErrors = ref([]);

    const pid = computed(() => route?.query?.pid as string ?? store.getters.pid ?? '');

    const user = asyncComputed(async () => {
      if (pid.value) {
        const user = await RegistrationService.getRegisteringUser(pid.value);
        store.commit('setUser', user);
      }
      return store.getters.user;
    }, null);

    const email = computed(() => user.value?.email ?? '');

    const { locale } = useI18n();

    const { tUserFallback: t, type: userType } = useUser(user);

    const userId = computed(() => user.value?.userId ?? '');

    const { passwordValidation, required } = useBCValidators();

    const fields = reactive({
      code: route?.query.code ?? '',
      password: ''
    });

    const rules = {
      code: { required },
      password: passwordValidation
    };

    const v = useVuelidate(rules, fields);

    const resendEmail = async () => {
      resending.value = true;
      await RegistrationService.resendEmail(userId.value, pid.value as string).catch(() => {
        resendError.value = true;
      });
      resent.value = true;
      resending.value = false;
    };

    const submit = async () => {
      if (!submitting.value && (await v.value.$validate())) {
        const params = {
          verificationCode: fields.code,
          password: fields.password,
          registrationProcessId: pid.value,
          userId: userId.value
        };

        submitting.value = true;
        const backendErrorObject = await store.dispatch('submitVerificationData', params);

        backendErrors.value = backendErrorObject.allErrors;

        submitting.value = false;
      }
    };

    const showBackButton = computed(() => userType.value != null && emptyCode.value);

    const goBack = () => {
      if (userType.value === UserType.COMPANY) {
        router.push({ name: 'register-customer' });
        return;
      } if (userType.value === UserType.GUEST) {
        router.push({ name: 'register-guest' });
        return;
      }
      router.push({ name: 'register' });
    };

    const resend = () => {
      showConfirm.value = true;
    };

    const onConfirmed = (is: boolean) => {
      if (is) {
        resendEmail();
      } else {
        goBack();
      }
    };

    onMounted(() => {
      emptyCode.value = !route.query?.code?.length;
      if (route.query.locale) {
        const queryLocale = route.query?.locale as string;
        const [localeValue] = queryLocale.split('-');
        if (Object.keys(Lang).includes(localeValue)) {
          locale.value = localeValue;
        }
      }
    });

    return {
      showConfirm,
      fields,
      v,
      resend,
      goBack,
      onConfirmed,
      submit,
      resent,
      resending,
      resendError,
      backendErrors,
      emptyCode,
      t,
      showBackButton,
      email
    };
  }
});
