import { computed, Ref, ref } from 'vue';
import axios from '@/plugins/axios';
import useI18n from '@/mixins/useI18n';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';

const ENDPOINT = '/v1/users/{userId}/trustlevel/events';

export type TrustLevelEventType = 'VOTED_UP' | 'VOTED_DOWN' | 'INVITATION_DIRECT' | 'INVITATION_INDIRECT' | 'PRIVATE_CHAT' | 'PUBLIC_CHAT' | 'VIDEO_CHAT_GROUP' | 'VIDEO_CHAT_GROUP_UPVOTE' | 'VIDEO_CHAT_BAR' | 'VIDEO_CHAT_BAR_UPVOTE' | 'VIDEO_CHAT_BAR_NEUTRAL' | 'VIDEO_CHAT_BAR_DOWNVOTE ';

export type TrustLeveLEventStatus = 'FINISHED';

interface ExternalUserTrustLevelEvent {
    eventType: TrustLevelEventType;
    timestamp: Date;
}

interface ExternalUserTrustLevelEventsResponse {
    userTrustlevelEventList: ExternalUserTrustLevelEvent[];
}

interface TrustLevelEntry {
    eventType: TrustLevelEventType;
    text: string;
    link: string;
    date: string;
}

// todo: remove user name when api returns values for placeholders
const useTrustLevels = (names:Ref<Record<string, string>>) => {
  const { t } = useI18n();
  const apiData = ref<ExternalUserTrustLevelEvent[]>([]);
  const isLoading = ref(false);
  const error = ref<string | undefined>();

  const { getFormattedDate } = useFormattedDateTime();

  const fetch = async (userId: string) => {
    try {
      isLoading.value = true;
      const response = await axios.get<ExternalUserTrustLevelEventsResponse>(ENDPOINT.replace('{userId}', userId));

      apiData.value = response.data.userTrustlevelEventList.sort((itemA, itemB) => (itemA.timestamp < itemB.timestamp ? 1 : -1));
    } catch (e) {
      apiData.value = [];
      error.value = 'An error occured';
    } finally {
      isLoading.value = false;
    }
  };

  const getLink = (item:ExternalUserTrustLevelEvent) => {
    if (item.eventType === 'PRIVATE_CHAT') {
      return '#chat';
    }
    return '';
  };

  const trustLevelEntries = computed<TrustLevelEntry[]>((): TrustLevelEntry[] => apiData.value.map((item) => ({
    eventType: item.eventType,
    text: t(`trust-level.${item.eventType}`, names.value).toString(),
    // date: moment(item.timestamp).format('DD.MM.YY'),
    date: getFormattedDate(item.timestamp, true),
    link: getLink(item)
  })));

  return {
    trustLevelEntries,
    fetch,
    isLoading,
    isError: computed(() => error.value !== undefined),
    error,
  };
};

export default useTrustLevels;
