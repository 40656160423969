
import Chevron from '@/components/svg-components/Chevron.vue';
import { useVModel } from '@vueuse/core';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';

export default defineComponent({
  name: 'BCXTextArea',
  components: { Chevron },
  inheritAttrs: false,
  props: {
    value: { type: String, default: '' },
    height: { type: Number, default: 0 },
    enlargedHeight: { type: Number, default: 0 },
    isEnlarged: { type: Boolean },
    showEnlargeIcon: { type: Boolean },
    maxlength: { type: Number, default: 0 },
    hideCharsLeft: { type: Boolean },
    type: { type: String as PropType<'default' | 'voting-reporting'>, default: 'default' }
  },
  setup(props, { emit }) {
    const {
      height, isEnlarged, enlargedHeight, maxlength, value,
    } = toRefs(props);
    const text = useVModel(props);
    const style = computed(() => {
      if (height.value) {
        return {
          resize: 'none',
        };
      }
      return null;
    });
    const wrapperStyle = computed(() => (height.value ? {
      height: `${isEnlarged.value ? enlargedHeight.value : height.value}px`,
    } : null));

    const toggleHeight = () => emit('update:is-enlarged', !isEnlarged.value);

    const charsLeft = computed(() => (maxlength.value ? (maxlength.value as number) - (value.value as string).length : 0));

    return {
      text, style, wrapperStyle, toggleHeight, charsLeft,
    };
  },

});
