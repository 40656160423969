
import { defineComponent } from 'vue';
import { onClickOutside, templateRef } from '@vueuse/core';
import BlogButton from './BlogButton.vue';

export default defineComponent({
  components: { BlogButton },
  props: {
    labelYes: {
      type: String,
      default: 'Yes'
    },
    labelNo: {
      type: String,
      default: 'No'
    }
  },
  emits: ['confirm-publish', 'close-confirm-dialog'],
  setup(_, { emit }) {
    const dialogEl = templateRef<HTMLElement>('dialogEl');
    onClickOutside(dialogEl, () => { emit('close-confirm-dialog'); });
    const confirm = () => { emit('confirm-publish'); };
    const decline = () => { emit('close-confirm-dialog'); };
    return {
      confirm,
      decline
    };
  }
});
