var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.userHasAccess)?_c(_vm.value._to ? 'router-link' : 'div',{tag:"component",staticClass:"bc-navigation-item",class:{
    'c-sub': _vm.level > 0,
    'c-active': _vm.value._active,
    'c-opened': _vm.value._opened,
    'c-button': _vm.value._button,
    'c-navigation-item': !_vm.isMobileLayout,
    'c-navigation-item-mobile': _vm.isMobileLayout,
    ..._vm.addedClass
  },attrs:{"to":_vm.value._to},on:{"click":function($event){return _vm.$emit('select', { item: _vm.value, level: _vm.level })}}},[(_vm.value._icon)?_c('BCXIcon',{staticClass:"c-icon",attrs:{"icon":_vm.value._icon,"highlight":_vm.value._active}}):_c('BCXIcon',{staticClass:"c-icon-sub",attrs:{"icon":"SmallBar"}}),_c('div',{staticClass:"c-label"},[_vm._t("default")],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }