
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import useToastUiEditorTools from '@/mixins/useToastUiEditorTools';
import {
  Chat,
  CHAT_TYPE_DIRECT,
  CHAT_TYPE_FORUM,
  CHAT_TYPE_PROJECT,
  ChatMessageType, MESSAGE_EVENT_SET_TEXT,
  MessengerMaxChars,
  SendMessageResult
} from '@/models/Messenger';
import useMessengerState from '@/state/messenger/messengerState';
import stripTags from '@/utils/stripTags';
import { Editor } from '@toast-ui/vue-editor';
import {
  computed, defineComponent, onBeforeUnmount, Ref, ref, toRefs, watch
} from 'vue';

import { vOnClickOutside } from '@vueuse/components';
import { onClickOutside, onKeyDown } from '@vueuse/core';
import removeTrailingEmptyLinesFromHTML from '@/utils/removeTrailingEmptyLinesFromHTML';
import eventBus from '@/state/messenger/EventBus';

export default defineComponent({
  name: 'BCXMessengerChatInput',
  components: { BCXIcon, Editor },
  props: {
    chat: {
      type: Object as () => Chat,
      default: () => null,
    },
  },
  setup(props) {
    const { chat } = toRefs(props);
    const {
      sendMessage, editMessage,
      isInputFocussed, selectedChatText, setSelectedChatText,
      isInputMaximized, editMessageId, getMessageById,
      isWaitingForData, preventInputUnFocusOnce,
    } = useMessengerState();
    const isToolbarVisible = ref(false);
    const editor = ref<Editor>();
    const chatInput = ref(null);
    const { createButton } = useToastUiEditorTools(editor);
    const lastSendMessageResult = ref(SendMessageResult.OK);

    // const input = templateRef('input');

    // onClickOutside(input, () => { isInputFocussed.value = false; });

    const editedText = ref('');

    const text = computed({
      get: () => (editMessageId.value ? editedText.value : selectedChatText.value),
      set: (text) => {
        if (editMessageId.value) editedText.value = text;
        else setSelectedChatText(text);
      },
    });

    watch(chat, () => {
      lastSendMessageResult.value = SendMessageResult.OK;
    });

    watch(editMessageId, (id) => {
      editedText.value = getMessageById(id)?.text ?? ''; // clear edited text on edit select:
      if (!editor.value) return;
      editor.value.invoke('setMarkdown', editedText.value, !!id);
    });

    const getMessageType = () => {
      switch (chat.value?.type) {
        case 'PRIVATE_MESSAGING': return ChatMessageType.PRIVATE_MESSAGING_MESSAGE;
        case 'PROJECT_INTERNAL_MESSAGING': return ChatMessageType.PROJECT_THREAD_MESSAGE;
        case 'FORUM_INTERNAL_MESSAGING': return ChatMessageType.FORUM_THREAD_MESSAGE;
        default: return ChatMessageType.PRIVATE_FEEDBACK_MESSAGING;
      }
    };

    const send = async () => {
      const strippedText = stripTags(text.value);
      if (strippedText.trim()) {
        const textToSend = removeTrailingEmptyLinesFromHTML(text.value);
        if (editMessageId.value) {
          lastSendMessageResult.value = await editMessage(textToSend);
          editMessageId.value = '';
        } else {
          // explicitly don't wait for it to be finished.
          sendMessage(textToSend, getMessageType()).then((result) => {
            lastSendMessageResult.value = result;
          });
          text.value = '';
        }
        if (!editor.value) return;
        editor.value.invoke('setMarkdown', '');
      }
    };

    onKeyDown('Enter', (evt) => {
      if (evt.ctrlKey) {
        send();
        evt.preventDefault();
      }
    }, {
      target: chatInput as unknown as Ref<EventTarget>
    });

    const focus = (state:boolean) => {
      if (preventInputUnFocusOnce.value) {
        preventInputUnFocusOnce.value = false;
        return;
      }
      isInputFocussed.value = state;
    };

    onClickOutside(chatInput, () => focus(false));

    onBeforeUnmount(() => {
      isInputFocussed.value = false;
    });

    const isPrivate = computed(() => chat.value.type === CHAT_TYPE_DIRECT);

    const showAsFocussed = computed(() => isInputFocussed.value && !isInputMaximized.value);

    const maxLength = computed(() => {
      switch (chat.value.type) {
        case CHAT_TYPE_DIRECT: return MessengerMaxChars.CHAT_TYPE_DIRECT;
        case CHAT_TYPE_FORUM: return MessengerMaxChars.CHAT_TYPE_FORUM;
        case CHAT_TYPE_PROJECT: return MessengerMaxChars.CHAT_TYPE_PROJECT;
        default:
      }
      return 0;
    });

    let lastMarkdown = '';
    const onEditorInput = () => {
      if (!editor.value) return;
      const markdown = editor.value.invoke('getMarkdown');
      if (markdown === lastMarkdown) return;
      lastMarkdown = markdown;
      text.value = editor.value.invoke('getHTML');
    };

    const addImageBlobHook = (blob:any, callback:any) => {
      callback('', '');
    };

    const classes = computed(() => ({
      'c-messenger-chat-input--maximized': isInputMaximized.value,
      'c-messenger-chat-input--focussed': showAsFocussed.value,
      'c-messenger-chat-input--edited': !!editMessageId.value,
      'c-messenger-chat-input--waiting': isWaitingForData.value,
    }));

    const toggleToolbar = () => {
      if (!editor.value) return;
      isToolbarVisible.value = !isToolbarVisible.value;
      editor.value.invoke('focus');
    };

    eventBus.on((evt, payload) => {
      if (evt === MESSAGE_EVENT_SET_TEXT) {
        text.value = payload;
        if (!editor.value) return;
        editor.value.invoke('setMarkdown', payload);
      }
    });

    return {
      chatInput,
      classes,
      focus,
      isInputFocussed,
      text,
      isPrivate,
      isToolbarVisible,
      onKeyDown,
      send,
      maxLength,
      vOnClickOutside,
      onEditorInput,
      toggleToolbar,
      lastSendMessageResult,
      SendMessageResult,
      editor,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
        autofocus: false,
        minHeight: '100%',
        hooks: {
          addImageBlobHook
        },
        toolbarItems: [
          ['bold', 'italic', 'quote', 'ul', 'ol', 'indent', 'outdent', 'link',
            {
              el: createButton('undo'),
              command: 'undo',
              tooltip: 'Undo'
            }, {
              el: createButton('redo'),
              command: 'redo',
              tooltip: 'Redo'
            }]
        ]
      }
    };
  },
});
