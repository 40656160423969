import axios from '@/plugins/axios';
import axiosSearch from '@/plugins/axios-search';
import { UserListFromSearch, UserSearchRequest, UserSearchResponse } from '@/views/user-search/models/UserSearch';
import { Profile } from '@/models/Profile';

export default {

  async searchUsers(request: UserSearchRequest): Promise<UserSearchResponse> {
    return (await axiosSearch.post('/v1/users/search', request))?.data;
  },

  async getUserList(searchTerm: string, mode = 'full'): Promise<UserListFromSearch> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Search-Endpoint/get_api_v1_search_users
     */
    return axios.get(`/v1/search/users?nameFilter=${encodeURIComponent(searchTerm)}&mode=${mode}`).then(({ data }) => data);
  },

  async getUserProfile(userId: string): Promise<Profile> {
    /*
    * Open-API Description
    * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/UserProfile-Endpoints/get_api_v1_users__userId__profile_
    */
    return axios.get(`/v1/users/${userId}/profile/`).then((response) => response.data);
  }
};
