
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'LPButton',
  props: {
    type: {
      type: String as PropType<'customer' | 'freelancer' | 'register'>,
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: ''
    }
  },
});
