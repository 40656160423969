
import Smiley, { SmileyTypes, SmileySizes } from '@/components/svg-components/Smiley.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MyRecommendationsBar',
  components: { Smiley },
  props: {
    thisWeek: {
      type: Number,
      default: 0
    },
    allTime: {
      type: Number,
      default: 0
    },
    good: {
      type: Number,
      default: 0
    },
    neutral: {
      type: Number,
      default: 0
    },
    bad: {
      type: Number,
      default: 0
    },
  },
  setup() {
    return {
      SmileyTypes,
      SmileySizes
    };
  }
});
