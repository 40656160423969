
import { defineComponent } from 'vue';
import EditIcon from '@/components/molecules/EditIcon.vue';

export default defineComponent({
  name: 'ViewableItem',
  components: { EditIcon },
  props: {
    disabled: { type: Boolean }
  },
  setup(props, { emit }) {
    const view = () => {
      emit('view');
    };
    return { view };
  }
});
