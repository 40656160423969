
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useI18n from '@/mixins/useI18n';
import {
  computed, defineComponent, toRefs, Ref, ref
} from 'vue';
import ByteCookieIcon from '@/components/svg/logo/logo-circles-only.svg?inline';
import Chevron from '@/components/svg-components/Chevron.vue';
import { RawLocation } from 'vue-router';

import { BlogEntry } from '../models/Blog';

export default defineComponent({
  components: {
    ByteCookieIcon,
    Chevron
  },
  props: {
    entry: { type: Object as () => BlogEntry, required: true },
    type: {
      type: String,
      required: true
    },
    positionOne: {
      type: Boolean,
      default: true
    },
    isDashboard: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { entry } = toRefs(props);
    const { getFormattedTimeByAge } = useFormattedDateTime();
    const lastUpdated = computed(() => getFormattedTimeByAge(props.entry?.lastUpdated));

    const articleType = computed<string>(() => {
      switch (props.type) {
        case 'BC_NEWS':
          return 'news';
        case 'HELP_ARTICLE':
          return 'wiki';
        default:
          return 'articles';
      }
    });
    const linkToBlog: Ref<RawLocation> = ref({ name: 'blog' });
    const featureBlogLink = computed(() => ({
      name: 'blog articles article',
      params: {
        articleId: entry.value.externalId
      }
    }));

    return {
      t,
      linkToBlog,
      featureBlogLink,
      lastUpdated,
      articleType
    };
  }
});
