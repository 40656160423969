const b64toBlob = (b64Data:string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const DOWNLOAD_TAG_CLASS_NAME = 'bc-downloadable-item-trigger';

const createTag = () => {
  const oldTags = document.querySelectorAll(`a.${DOWNLOAD_TAG_CLASS_NAME}`);
  Array.from(oldTags).forEach((tag) => {
    tag.remove();
  });
  const a = document.createElement('a');
  a.className = DOWNLOAD_TAG_CLASS_NAME;
  return a;
};

const downloadFile = (base64Data:string, fileName:string, mimeType:string, target: '_blank'|'_self' = '_self') => {
  const a = createTag();
  const blob = b64toBlob(base64Data, mimeType);
  if (target === '_self') {
    a.download = fileName;
  }
  a.href = window.URL.createObjectURL(blob);
  a.target = target;
  a.click();
};

export default downloadFile;
