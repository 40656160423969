import axios from '@/plugins/axios';
import { CancelTokenSource } from 'axios';
import { logError } from '@/utils/logger';

export const getData = async (url: string, cancelToken?: CancelTokenSource | null) => (
  await axios.get(url, cancelToken ? { cancelToken: cancelToken.token } : {}).catch((err) => {
    logError('get Err:', { err, url });
    return { data: null };
  })
).data;

export const postData = async (url: string, data?:any) => (await axios.post(url, data)
  .catch((err) => {
    logError('post Err:', { err, url, data });
    return ({ data: null });
  })).data;

export const putData = async (url: string, data?:any) => (await axios.put(url, data)
  .catch((err) => {
    logError('put Err:', { err, url, data });
    return ({ data: null });
  })).data;

export const deleteData = async (url: string) => (await axios.delete(url)
  .catch((err) => {
    logError('delete Err:', { err, url });
    return ({ data: null });
  })).data;
