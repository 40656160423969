// eslint-disable-next-line import/prefer-default-export
export const autoEncodeUserId = (userId: string): string => {
  let newId;
  do {
    newId = userId;
    userId = decodeURIComponent(newId);
  } while (newId !== userId);

  return encodeURIComponent(userId);
};
