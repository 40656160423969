
import { defineComponent } from 'vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';

export default defineComponent({
  setup() {
    const { isSearchDisabled, search } = useUserSearch();

    return { isSearchDisabled, search };
  }
});
