
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import BCXDottedBox from '@/components/molecules/BCXDottedBox.vue';
import { defineComponent, PropType } from 'vue';
import X from '@/components/svg-components/X.vue';

export default defineComponent({
  name: 'BCXDottedCollapsible',
  components: {
    BCXDottedBox,
    BCXCollapsible,
    X
  },
  props: {
    isOpened: { type: Boolean },
    addTopPadding: { type: String, default: '0' },
    addBottomPadding: { type: String, default: '0' },
    background: {
      type: String as PropType<'none' | 'white'>,
      default: 'none'
    }
  },
});
