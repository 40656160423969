
import { computed, defineComponent, reactive } from 'vue';
import { logDebug } from '@/utils/logger';
import AddIcon from '@/views/profile/components/AddIcon.vue';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProjectGuestsInviteNew',
  components: {
    AddIcon,
  },
  emits: ['add-new-guest'],
  setup(props, { emit }) {
    const { tUserFallback: t } = useUser('self');
    const newGuest = reactive({
      firstname: '',
      lastname: '',
      email: '',
      message: '',
    });
    const resetNewGuest = () => {
      newGuest.firstname = '';
      newGuest.lastname = '';
      newGuest.email = '';
      newGuest.message = '';
    };
    const addIsActive = computed(() => newGuest.firstname.length > 0 && newGuest.lastname.length > 0 && newGuest.email.length > 0);
    const addNewGuest = () => {
      logDebug('Add new guest', { ...newGuest });
      emit('add-new-guest', { ...newGuest });
      resetNewGuest();
    };
    return {
      t,
      newGuest,
      addIsActive,
      addNewGuest,
    };
  },
});
