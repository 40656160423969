
import BCXShowAllToggle from '@/components/molecules/BCXShowAllToggle.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import LinkIcon from '@/components/svg/link.svg?inline';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { defineComponent } from 'vue';
import useInviteTools from '@/views/invites/utils/useInviteTools';
import EditIcon from '@/components/molecules/EditIcon.vue';

export default defineComponent({
  name: 'InvitesCodesTable',
  components: {
    BCXShowAllToggle,
    EditIcon,
    BCXCheckbox,
    LinkIcon
  },
  setup() {
    const { getFormattedDate } = useFormattedDateTime();
    return { ...useInviteTools(), getFormattedDate };
  }
});
