import { NavigationTree, NavigationTreeItems } from '@/models/Navigation';

type TraverseCallback = (value: NavigationTree, level?:number, key?:string, parent?: NavigationTree) => boolean | void;
type FindCallback = TraverseCallback;

const _traverseNavigationTreeItems = (tree:NavigationTreeItems, callBack:TraverseCallback, level = 0, parent?:NavigationTree):boolean => {
  Object.keys(tree).every((key:string) => {
    if (!key.startsWith('_')) {
      const value:NavigationTree = tree[key];
      if (callBack(value, level, key, parent) === false) return false;
      if (value._items) {
        if (!_traverseNavigationTreeItems(value._items, callBack, level + 1, value)) return false;
      }
    }
    return true;
  });
  return true;
};

/**
 * Find item in navigation tree. returns the found item if the callBack returns TRUE
 * @param tree Navigation Tree structure to traverse (can also be an _items subtree)
 * @param callBack
 */
const findInNavigationTree = (tree:NavigationTreeItems, callBack:FindCallback): NavigationTree | null => {
  let found:NavigationTree | null = null;
  _traverseNavigationTreeItems(tree, (value, level, key, parent) => {
    if (callBack(value, level, key, parent)) {
      found = value;
      return false;
    }
    return true;
  });
  return found;
};

/**
 * Traverses a navigation tree.
 * To stop traversion, return FALSE in the callback function.
 * @param tree
 * @param callBack
 */
const traverseNavigationTreeItems = (tree:NavigationTreeItems, callBack:TraverseCallback):boolean => _traverseNavigationTreeItems(tree, callBack, 0);

export { traverseNavigationTreeItems, findInNavigationTree, TraverseCallback };
