
import { defineComponent } from 'vue';
import useLanguageSwitcher from '@/mixins/useLanguageSwitcher';

export default defineComponent({
  name: 'LPLanguageSwitcher',
  setup() {
    const { otherLanguage, switchLanguage } = useLanguageSwitcher();

    return {
      otherLanguage,
      switchLanguage,
    };
  }
});
