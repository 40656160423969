import { Module } from 'vuex';

interface State {
  isParticipateVisible: boolean;
  isMobileSearchVisible: boolean;
  isFlat: boolean;
  isMini: boolean;
  isSmartphoneWidth: boolean;
  isMobileWidth: boolean;
  isTabletWidth: boolean;
  isDesktopWidth: boolean;
}

const module: Module<State, any> = ({
  namespaced: true,
  state: {
    isSmartphoneWidth: false,
    isParticipateVisible: false,
    isMobileSearchVisible: false,
    isFlat: false,
    isMini: false,
    isMobileWidth: false,
    isTabletWidth: false,
    isDesktopWidth: false,
  },
  getters: {
    isMobileLayout: (state):boolean => state.isMobileWidth,
    isSidebarVisible: (state):boolean => state.isDesktopWidth,
  },
  mutations: {
    toggleIsMobileSearchVisible(state) {
      state.isMobileSearchVisible = !state.isMobileSearchVisible;
    },
    setIsParticipateVisible(state, is) {
      state.isParticipateVisible = is;
    },
    toggleIsParticipateVisible(state) {
      state.isParticipateVisible = !state.isParticipateVisible;
    },
    setIsFlat(state, is) {
      state.isFlat = is;
    },
    setIsMini(state, is) {
      state.isMini = is;
    },
    setIsMobileWidth(state, is) {
      state.isMobileWidth = is;
    },
    setIsTabletWidth(state, is) {
      state.isTabletWidth = is;
    },
    setIsDesktopWidth(state, is) {
      state.isDesktopWidth = is;
    },
    setIsSmartphoneWidth(state, is) {
      state.isSmartphoneWidth = is;
    },
  },
});

export default module;
