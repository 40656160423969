const toggleArrayItem = (array:string[], item:string) => {
  const itemIndex:number = array.indexOf(item);

  if (itemIndex >= 0) array.splice(itemIndex, 1);
  else {
    array.push(item);
  }
};

export default toggleArrayItem;
