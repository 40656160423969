
import { defineComponent } from 'vue';
import { templateRef } from '@vueuse/core';
import useScrollLock from '@/mixins/useScrollLock';
import usePreventScroll from '@/mixins/usePreventScroll';

export default defineComponent({
  name: 'BCXPanelFullscreenMobile',
  setup() {
    const panel = templateRef<HTMLElement>('panel');
    useScrollLock(panel);
    usePreventScroll(panel);
  }
});
