<template>
  <div class="c-dashboard-new-members-skeleton">
    <BCXSkeleton
      class="card"
      height="284px"
      width="260px"
      border-radius="8px"
    />
    <BCXSkeleton
      class="message"
      height="30px"
      border-radius="15px"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import BCXSkeleton from '@/components/molecules/BCXSkeleton.vue';

export default defineComponent({
  components: {
    BCXSkeleton,
  },
  setup() {
    return {

    };
  },
});
</script>
<style lang="scss" scoped>
.c-dashboard-new-members-skeleton{
  display: flex;
  flex-direction: column;
  align-items: center;
  .card{
    margin-top: 16px;
  }
  .message {
    margin-top: 80px;
  }
}
</style>
