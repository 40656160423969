import { render, staticRenderFns } from "./BCXFloatingMenu.vue?vue&type=template&id=1cd2acf9&scoped=true"
import script from "./BCXFloatingMenu.vue?vue&type=script&lang=ts"
export * from "./BCXFloatingMenu.vue?vue&type=script&lang=ts"
import style0 from "./BCXFloatingMenu.vue?vue&type=style&index=0&id=1cd2acf9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cd2acf9",
  null
  
)

export default component.exports