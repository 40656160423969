
import X from '@/components/svg-components/X.vue';
import { InfoBoxType } from '@/models/InfoBox';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SidebarAsMobileLayer',
  components: {
    X
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<InfoBoxType>,
      default: InfoBoxType.INFOBOX_INFO
    }
  },
  setup(_, { emit }) {
    const onClose = () => {
      emit('close');
    };

    const closeWithDelay = () => {
      setTimeout(onClose, 2000);
    };

    return {
      InfoBoxType,
      onClose,
      closeWithDelay
    };
  }
});
