import useDevice from './useDevice';

const useAudioOutput = () => {
  const { current: currentSpeaker, devices: speakers } = useDevice('audiooutput');

  return {
    speakers,
    currentSpeaker
  };
};

export default useAudioOutput;
