
import { ProfileStoreKey } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import ProfileSection from '@/views/profile/components/ProfileSection.vue';
import ProfileBasicEditor from '@/views/profile/components/editors/ProfileBasicEditor.vue';
import ProfileBasicView from '@/views/profile/components/views/ProfileBasicView.vue';
import ProfileInvitedBy from '@/views/profile/components/views/ProfileInvitedBy.vue';
import Vue, { inject } from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import ProfileAboutusView from '@/views/profile/components/views/ProfileAboutusView.vue';
import ProfileMembersView from '@/views/profile/components/views/ProfileMembersView.vue';
import ProfilePublicProjectsView from '@/views/profile/components/views/ProfilePublicProjectsView.vue';
import ProfileAboutusEditor from '@/views/profile/components/editors/ProfileAboutusEditor.vue';
import ProfileCreateProjectLink from '@/views/profile/components/views/ProfileCreateProjectLink.vue';

export default Vue.extend({
  name: 'ProfileCompany',
  components: {
    ProfileCreateProjectLink,
    ProfileAboutusEditor,
    ProfilePublicProjectsView,
    ProfileMembersView,
    ProfileAboutusView,
    BCXBreadcrumbs,
    BCXContentArea,
    ProfileSection,
    ProfileBasicEditor,
    ProfileInvitedBy,
    ProfileBasicView,
  },
  setup() {
    const {
      isSelf, isEditSectionOpen, isSectionFilled, isQuickInfo,
    } = useProfileState(ProfileStoreKey.PROFILE);

    const breadcrumbs = inject('breadcrumbs', []);

    return {
      isEditSectionOpen,
      isSelf,
      isSectionFilled,
      isQuickInfo,
      breadcrumbs
    };
  },
});
