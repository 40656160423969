
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean
    }
  },
  emits: ['input'],

  setup(props, { emit }) {
    const { value } = toRefs(props);

    function toggle() {
      emit('input', !value.value);
    }

    return {
      toggle,
    };
  },
});

