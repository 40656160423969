
import { computed, ComputedRef, defineComponent } from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import Report from '@/components/voting/components/report.vue';
import Upvote from '@/components/voting/components/upvote.vue';
import Downvote from '@/components/voting/components/downvote.vue';
import BCXQuickLayer from '@/components/molecules/BCXQuickLayer.vue';

export default defineComponent({
  components: {
    Report,
    Upvote,
    Downvote,
    BCXQuickLayer
  },

  setup() {
    const store = useStore();

    const storeActiveType = computed(() => store.state.voting.activeType);
    const directUpvoteType = computed(() => store.state.voting.directUpvote);
    const storeUser = computed(() => store.state.voting.user);

    const isUpvoteActive: ComputedRef<boolean> = computed(() => storeActiveType.value === 'upvote' && directUpvoteType.value === undefined);
    const isDownvoteActive: ComputedRef<boolean> = computed(() => storeActiveType.value === 'downvote');
    const isReportingActive: ComputedRef<boolean> = computed(() => storeActiveType.value === 'report');

    const showLayer: ComputedRef<boolean> = computed(() => !!(storeActiveType.value && storeUser.value));

    return {
      showLayer,
      storeUser,
      isUpvoteActive,
      isDownvoteActive,
      isReportingActive,
    };
  },
});
