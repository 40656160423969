
import { EmailNotificationInterval, EmailNotificationType } from '@/models/Settings';
import useSettings from '@/state/settingsState';
import NotificationSetting from '@/views/settings/components/utils/NotificationSetting.vue';
import { computed, defineComponent, ref } from 'vue';
import useBarChatReminder from '@/views/virtual-pub/composable/bar-chat/useBarChatReminder';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  name: 'SectionNotifications',
  components: {
    NotificationSetting,
    BCXSearchSelect
  },
  setup() {
    const { t } = useI18n();
    const { settings } = useSettings();
    const showAnnouncements = ref(false); // currently hidden

    const {
      isEnabled, isLoading: isBarchatReminderStatusLoading, removeReminder, setReminder
    } = useBarChatReminder();

    const isBarchatReminderEnabled = computed({
      get: () => (isEnabled.value ? 1 : 0),
      set: async (value) => {
        if (isBarchatReminderEnabled.value === value) return;
        if (value === 1) {
          await setReminder();
          isEnabled.value = true;
        } else {
          await removeReminder();
          isEnabled.value = false;
        }
      }
    });

    const barChatReminderList = ref([
      {
        value: 1,
        text: t('settings.notifications.interval_types_pub_opening.YES')
      },
      {
        value: 0,
        text: t('settings.notifications.interval_types_pub_opening.NO')
      }
    ]);

    const getIntervalForType = (type:EmailNotificationType) => settings.value?.notificationSettings.find(
      (notification) => notification.emailNotificationType === type
    ) ?? EmailNotificationInterval.Never;

    const valueRefIncomingMessages = computed(() => getIntervalForType(EmailNotificationType.IncomingMessages) as string);

    const valueRefSubscriptions = computed(() => getIntervalForType(EmailNotificationType.Subscriptions) as string);

    const valueRefRecommendations = computed(() => getIntervalForType(EmailNotificationType.Recommendations) as string);

    const valueRefProjectOffers = computed(() => getIntervalForType(EmailNotificationType.ProjectOffer) as string);

    const valueRefAnnouncements = computed(() => getIntervalForType(EmailNotificationType.Announcements) as string);

    const valueRefNewsletter = computed(() => getIntervalForType(EmailNotificationType.Newsletter) as string);

    return {
      showAnnouncements,
      valueRefAnnouncements,
      valueRefNewsletter,
      valueRefSubscriptions,
      valueRefRecommendations,
      valueRefProjectOffers,
      valueRefIncomingMessages,
      EmailNotificationType,
      isBarchatReminderEnabled,
      isBarchatReminderStatusLoading,
      barChatReminderList,
    };
  }
});
