
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BytecookieLogo from '@/components/svg-components/BytecookieLogo.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import SettingsPopup from '@/views/settings/components/utils/SettingsPopup.vue';
import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import X from '@/components/svg-components/X.vue';
import { useStore } from 'vue2-helpers/vuex';
import useLanguageSwitcher from '@/mixins/useLanguageSwitcher';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'LoginRegister',
  components: {
    BCXMarkdown,
    SettingsPopup,
    X,
    BytecookieLogo,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isGoBackPopupVisible = ref(false);

    const { tUserFallback: t } = useUser('self');

    const isSubpage = computed(() => route?.name !== 'index');
    const { isMobileWidth } = useResponsiveness();

    const { otherLanguage, switchLanguage } = useLanguageSwitcher();

    const goBackFinally = async () => {
      isGoBackPopupVisible.value = false;
      store.commit('removeRegistrationData');
      store.commit('clearUserAndToken');
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      router.push({ name: 'index' }).catch(() => {});
    };

    const goBack = () => {
      if (!route?.name?.startsWith('register')) {
        goBackFinally();
        return;
      }
      isGoBackPopupVisible.value = true;
    };

    const closePopup = () => {
      isGoBackPopupVisible.value = false;
    };

    return {
      isGoBackPopupVisible,
      isSubpage,
      isMobileWidth,
      closePopup,
      goBack,
      goBackFinally,
      otherLanguage,
      switchLanguage,
      t
    };
  }
});

