// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const useWebAudioPlayer = () => {
  let context = null;
  let audiobuffer = null;
  let fileBuffer = null;
  let resolveAudioBuffer = null;

  const init = async (audioFileUrl: string) => {
    const buffer = await fetch(audioFileUrl)
      .then((resp) => resp.ok && resp.arrayBuffer());
    if (!buffer) return;
    fileBuffer = buffer;
    resolveAudioBuffer?.();
  };

  const play = async () => {
    if (!context) {
      context = new (
        window.AudioContext || window.webkitAudioContext
      )();
    }

    if (!fileBuffer) {
      await new Promise((resolve) => {
        resolveAudioBuffer = resolve;
      });
    }

    if (!audiobuffer) {
      audiobuffer = await context.decodeAudioData(fileBuffer);
    }

    const buffersource = context.createBufferSource();
    buffersource.buffer = audiobuffer;
    buffersource.connect(context.destination);
    buffersource.start(0);

    buffersource.onended = async () => {
      buffersource.disconnect();
      await context.close();
      context = null;
    };
  };

  return {
    play,
    init,
  };
};

export default useWebAudioPlayer;
