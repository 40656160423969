
import { defineComponent } from 'vue';
import usePushRouteIngoreDuplicate from '@/mixins/useRouterIngoreDuplicate';
import { RawLocation } from 'vue-router';

export default defineComponent({
  name: 'RouterLinkIgnoreDuplicate',
  props: {
    to: {
      type: Object as () => RawLocation,
      default: null
    }
  },
  setup() {
    const { pushRoute } = usePushRouteIngoreDuplicate();

    return {
      pushRoute
    };
  }
});
