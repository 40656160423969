
import RecommendUserForProjectsLayer from '@/views/recommendations/components/recommend-user-for-projects/RecommendUserForProjectsLayer.vue';
import { defineComponent } from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'RecommendUserForProjectsMobileLayer',
  components: { RecommendUserForProjectsLayer },
  props: {
    isOpened: Boolean
  },
  setup(props) {
    const isOpenedVM = useVModel(props, 'isOpened');

    const onClose = () => {
      isOpenedVM.value = false;
    };

    return {
      onClose
    };
  }
});
