
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import { addReferences } from '@/services/Profile/Api';
import EditIcon from '@/components/molecules/EditIcon.vue';
import UploadableItem from '@/components/molecules/UploadableItem.vue';
import {
  computed, defineComponent, ref, watch
} from 'vue';
import { ProfileMaxChars, Reference } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import useListTools from '@/mixins/useListTools';
import AddIcon from '@/views/profile/components/AddIcon.vue';

export default defineComponent({
  name: 'ProfileReferencesEditor',
  components: {
    AddIcon, UploadableItem, EditIcon, ProfileEditActionButtons
  },
  setup() {
    const fileNameToAdd = ref('');
    const fileContentsToAdd = ref('');
    const fileTypeToAdd = ref('');
    const clientNameToAdd = ref('');
    const yearToAdd = ref(`${new Date().getFullYear()}`);
    const hasUploadError = ref(false);
    const hasEdited = ref(false);

    const { profile } = useProfileState();

    const references = ref<Reference[]>([]);
    const { deleteItem, addItem, moveUp } = useListTools(references);

    const canAdd = computed(() => !!(yearToAdd.value && clientNameToAdd.value && fileContentsToAdd.value));

    watch(profile, (profile) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      references.value = (profile?.references?.referencesList ?? []).map(({ externalIdObject, ...rest }) => ({ ...rest }));
    }, { immediate: true });

    const add = async () => {
      if (canAdd.value) {
        addItem({
          clientName: clientNameToAdd.value,
          filename: fileNameToAdd.value,
          year: +yearToAdd.value,
          document: fileContentsToAdd.value,
        });
        clientNameToAdd.value = '';
        fileNameToAdd.value = '';
        yearToAdd.value = '';
        fileContentsToAdd.value = '';
        // await save(true);
        // await loadProfile();
      }
    };

    watch(references, () => {
      hasEdited.value = true;
    });

    const save = async (stayInEditor = false) => {
      if (canAdd.value) await add();
      hasUploadError.value = false;
      const result = await addReferences({
        references: {
          referencesList: references.value,
        },
      });
      if (!result) {
        hasUploadError.value = true;
        return true;
      }
      return stayInEditor;
    };

    const canSave = computed(() => canAdd.value || hasEdited.value);

    return {
      clientNameToAdd,
      yearToAdd,
      fileNameToAdd,
      fileContentsToAdd,
      fileTypeToAdd,
      references,
      canAdd,
      canSave,
      hasUploadError,
      add,
      save,
      moveUp,
      deleteItem,
      maxLength: {
        client: ProfileMaxChars.CLIENT
      }
    };
  },
});
