
import downloadFile from '@/utils/downloadFile';
import { getAccountData } from '@/services/Settings/Api';
import {
  computed, defineComponent, onMounted, ref
} from 'vue';
import useSettings from '@/state/settingsState';
import LegalButton from '@/views/settings/components/utils/LegalButton.vue';
import { useStore } from 'vue2-helpers/vuex';
import { AffiliateContract } from '@/models/Affiliate';
import { getAffiliateContract } from '@/services/AffiliateService';
import { logDebug } from '@/utils/logger';

export default defineComponent({
  name: 'SectionLegal',
  components: { LegalButton },
  setup() {
    const { settings, showDeleteAccountPopup } = useSettings();
    const store = useStore();
    const affiliateDownload = ref<AffiliateContract | null>(null);
    const isUserAffiliate = computed(() => store.state.authentication.user.roles.includes('AFFILIATE_USER'));

    // a function to download a base64 encoded string as pdf file
    const donwloadAfiliateContract = () => {
      if (affiliateDownload.value) {
        downloadFile(affiliateDownload.value.attachment, affiliateDownload.value.filename, affiliateDownload.value.fileType, '_blank');
      }
    };

    const consentSettings = computed(() => settings.value?.consentObjectSettings);

    const deleteAccount = () => {
      showDeleteAccountPopup.value = true;
    };

    const downloadAccountData = async () => {
      const data = await getAccountData();
      if (data) {
        downloadFile(data.attachment, data.filename, data.fileType);
      }
    };

    onMounted(() => {
      if (isUserAffiliate.value) {
        logDebug('Affiliate user detected');
        getAffiliateContract().then((contract) => {
          affiliateDownload.value = contract;
        });
      }
    });

    return {
      deleteAccount,
      downloadAccountData,
      consentSettings,
      donwloadAfiliateContract,
      isUserAffiliate
    };
  }
});
