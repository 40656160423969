import { ref } from 'vue';
import { createGlobalState } from '@vueuse/core';

const useFullPageState = createGlobalState(() => {
  const isOnFullPage = ref(false);

  return {
    isOnFullPage,
  };
});

export default useFullPageState;
