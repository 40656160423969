import { render, staticRenderFns } from "./MyRecommendationsBar.vue?vue&type=template&id=ff0241aa&scoped=true"
import script from "./MyRecommendationsBar.vue?vue&type=script&lang=ts"
export * from "./MyRecommendationsBar.vue?vue&type=script&lang=ts"
import style0 from "./MyRecommendationsBar.vue?vue&type=style&index=0&id=ff0241aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff0241aa",
  null
  
)

export default component.exports