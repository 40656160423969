import { Chat, ChatMessage, Group } from '@/models/Messenger';

type MessageTraversalCallback = (message:ChatMessage, chat?:Chat, group?:Group) => boolean | void;

export const traverseMessagesInChats = (chats:Chat[], callBack:MessageTraversalCallback, group?:Group) => chats.some(
  (chat) => chat.messages.some((message) => callBack(message, chat, group)),
);

export const traverseMessagesInGroups = (groups:Group[], callBack:MessageTraversalCallback) => groups.some(
  (group) => traverseMessagesInChats(group.chats, callBack, group),
);

export const getIsChatRead = (chat: Chat):boolean => !chat.messages.some((message) => !message.read);
