interface StringFromFile {
  string: string;
  mime: string | undefined;
}

export default {
  methods: {
    fileToString(file: File): Promise<StringFromFile> {
      return new Promise((resolve, reject) => {
        const readerArrayBuffer = new FileReader();
        const readerBase64 = new FileReader();
        let arrayBuffer:ArrayBuffer;
        let base64:string;

        const ready = () => {
          const mime = this.getMimeType(
            new Uint8Array(arrayBuffer as ArrayBuffer)
              .slice(0, 4)
              .reduce((prev, cur) => prev + cur.toString(16).padStart(2, '0'), '')
              .toUpperCase(),
          );

          const string = base64.substring(
            base64.indexOf('base64,') + 7,
          );

          resolve({
            string,
            mime,
          });
        };

        const isReady = () => {
          if (arrayBuffer && base64) ready();
        };

        readerArrayBuffer.onload = () => {
          arrayBuffer = readerArrayBuffer.result as ArrayBuffer;
          isReady();
        };
        readerBase64.onload = () => {
          base64 = readerBase64.result as string;
          isReady();
        };

        readerArrayBuffer.onerror = reject;
        readerBase64.onerror = reject;
        readerBase64.readAsDataURL(file);
        readerArrayBuffer.readAsArrayBuffer(file);
      });
    },

    getMimeType(hex: string): string | undefined {
      switch (hex) {
        case '89504E47':
          return 'image/png';
        case '25504446':
          return 'application/pdf';
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return 'image/jpeg';
        // TODO: remove text/plain after testing
        case '74657374':
          return 'text/plain';
        default:
          return undefined;
      }
    },
  },
};
