import { MaybeComputedElementRef, useResizeObserver } from '@vueuse/core';

interface OnSizeChangeCallbackParams {
  hasWidthChanged: boolean;
  hasHeightChanged: boolean;
  width: number;
  height: number;
  oldWidth: number;
  oldHeight: number;
}
type OnSizeChangeCallback = (params: OnSizeChangeCallbackParams) => void

const onSizeChange = (element: MaybeComputedElementRef, callBack: OnSizeChangeCallback, box:ResizeObserverBoxOptions = 'content-box') => {
  let oldHeight = 0;
  let oldWidth = 0;
  useResizeObserver(element, (entries) => {
    const { width = 0, height = 0 } = entries[0].contentRect;
    const hasWidthChanged = width !== oldWidth;
    const hasHeightChanged = height !== oldHeight;

    if (hasWidthChanged || hasHeightChanged) {
      callBack({
        hasWidthChanged,
        hasHeightChanged,
        width,
        height,
        oldWidth,
        oldHeight
      });
    }

    oldWidth = width;
    oldHeight = height;
  }, {
    box
  });
};

export default onSizeChange;
