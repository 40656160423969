import axios, { AxiosRequestConfig } from 'axios';

import {
  axiosBaseConfig, requestInterceptor, responseSuccessInterceptor, responseErrorInterceptor
} from './axios';

const axiosSearchConfig: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_SEARCH_API_URL ?? '',
};

const axiosSearch = axios.create({ ...axiosBaseConfig, ...axiosSearchConfig });

axiosSearch.interceptors.request.use(requestInterceptor);
axiosSearch.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export default axiosSearch;
