import { ProfileStoreKey } from '@/models/Profile';
import { RECOMMENDATIONS_EVENT_REFRESH, RECOMMENDATIONS_EVENT_REFRESH_FEEDBACKS } from '@/models/Recommendations';
import { SaveReportingSourceType } from '@/models/Reporting';
import { User } from '@/models/User';
import {
  businessObjectType, UpdateVoting, UpdateVotingResponse, VoteReasonType
} from '@/models/Voting';
import globalRouter from '@/router/index';
import { invalidateMessage } from '@/state/messenger/Network';
import ReportingService from '@/services/ReportingService';
import VotingService from '@/services/VotingService';
import useMessengerState from '@/state/messenger/messengerState';
import useProfileState from '@/state/profile/profileState';
import useRecommendationState from '@/state/recommendationsState';
import globalStore from '@/store/index';
import { Module } from 'vuex';

type activeType = null | 'upvote' | 'downvote' | 'report';

export interface VotingState {
  activeType: activeType;
  user: User | null;
  businessObjectId: string | null;
  businessObjectType: businessObjectType | null;
  updatedVoting: UpdateVotingResponse | null;
  sourceType?: SaveReportingSourceType | null;
  directUpvote?: VoteReasonType;
}

const module: Module<VotingState, any> = ({
  namespaced: true,
  state: {
    activeType: null,
    user: null,
    businessObjectId: null,
    businessObjectType: null,
    updatedVoting: null,
    sourceType: null,
    directUpvote: undefined,
  },
  mutations: {
    setVotingReporting(state, payload: VotingState) {
      state.activeType = payload.activeType;
      state.user = payload.user;
      state.businessObjectId = payload.businessObjectId;
      state.businessObjectType = payload.businessObjectType;
      state.sourceType = payload.sourceType;
      state.directUpvote = payload.directUpvote;
    },
    updateVoting(state, payload) {
      state.updatedVoting = payload;
    }
  },
  actions: {
    setVotingReporting({ commit, dispatch }, payload: VotingState) {
      commit('setVotingReporting', payload);
      if (payload.directUpvote !== undefined) {
        dispatch('vote', {
          voteType: 'UP', reason: payload.directUpvote, link: globalRouter.currentRoute.fullPath
        });
      }
    },
    resetVotingReporting({ commit }) {
      const payload: VotingState = {
        activeType: null,
        user: null,
        businessObjectId: null,
        businessObjectType: null,
        updatedVoting: null,
        sourceType: null,
      };
      commit('setVotingReporting', payload);
    },
    vote({ commit, state }, {
      voteType, reason, downVoteReason, link
    }) {
      if (state.businessObjectType !== null && state.businessObjectId !== null) {
        const payload: UpdateVoting = {
          businessObjectId: state.businessObjectId,
          businessObjectType: state.businessObjectType,
          type: voteType,
          reason,
          downVoteReason,
          link,
        };
        return VotingService.updateVoting(payload).then((response) => {
          commit('updateVoting', response);
          return response;
        });
      }
      return null;
    },
    report({ commit, state }, { reason, message }) {
      if (state.businessObjectType !== null && state.businessObjectId !== null && state.sourceType !== null) {
        return ReportingService.sendReport({
          type: reason,
          reasonText: message,
          sourceId: state.businessObjectId,
          sourceType: state.sourceType || 'MESSAGE'
        })
          .then((response) => {
            commit('updateVoting', response);
            return response;
          }).finally(() => {
            const { name } = globalRouter.currentRoute;
            const { recommendationsEventBus } = useRecommendationState();
            const { loadProfile } = useProfileState(ProfileStoreKey.PROFILE);
            const { selectedChatId } = useMessengerState();
            switch (state.sourceType || 'MESSAGE') {
              case 'PROJECT':
                globalStore.dispatch('projectForum/fetchProject', state.businessObjectId);
                break;
              case 'PROFILE':
                loadProfile();
                break;
              case 'USER':
                break;
              case 'RECOMMENDATION_FEEDBACKMESSAGE':
                recommendationsEventBus.emit(RECOMMENDATIONS_EVENT_REFRESH_FEEDBACKS);
                break;
              case 'RECOMMENDATION_MESSAGE':
                recommendationsEventBus.emit(RECOMMENDATIONS_EVENT_REFRESH);
                break;
              case 'MESSAGE':
              default:
                if (selectedChatId.value && state.businessObjectId) {
                  invalidateMessage(state.businessObjectId);
                }
                if (name) {
                  if (name === 'thread') {
                    globalStore.dispatch('forum/refreshThread');
                  } else if (name === 'projectForumThread') {
                    globalStore.dispatch('projectForum/refreshThread');
                  }
                }
                break;
            }
          });
      }
      return null;
    }
  }
});

export default module;
