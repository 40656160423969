
import {
  computed, defineComponent, PropType, ref
} from 'vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import { BlogEntry } from '../models/Blog';

export default defineComponent({
  components: {
    BCXIcon,
    TagDisplay,
  },
  props: {
    data: {
      type: Array as PropType<BlogEntry[]>,
      default: () => []
    },
    headline: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    controls: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    const entries = computed(() => props.data);
    const showTags = ref(true);
    const filter = ref('');
    const filtered = computed(() => {
      let data = entries.value;
      if (filter.value !== '' && filter.value.length > 2) {
        data = entries.value?.filter((entry) => entry.summary?.toLowerCase().includes(filter.value.toLowerCase()) || entry.title.includes(filter.value.toLowerCase()));
        return data;
      }
      return data;
    });
    return {
      showTags,
      filter,
      entries,
      filtered
    };
  }
});
