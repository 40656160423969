
import {
  ExternalVideoBarChatMatchingResult,
  ExternalVideoChatParticipant,
  ExternalVideoGroupRoom,
  ExternalVideoSessionCreator
} from '@/__generated__/types';
import BCXMessenger from '@/components/messenger/BCXMessenger.vue';
import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import { User } from '@/models/User';
import useQuickInfo from '@/state/quickInfo';
import usePlaceholderImage from '@/views/virtual-pub/composable/video-chat/usePlaceholderImage';
import {
  computed, defineComponent, nextTick, onMounted, onUnmounted, PropType, ref, toRefs, watch, watchEffect
} from 'vue';
import {
  connect, ConnectOptions, RemoteParticipant, Room
} from 'twilio-video';
import { useStore } from 'vue2-helpers/vuex';
import { logDebug, logError } from '@/utils/logger';
import { whenever } from '@vueuse/core';
import useLeaveBarChat from '../../composable/bar-chat/useLeaveBarChat';

import useJoinRoom from '../../composable/rooms/useJoinRoom';
import useRemoveUserFromRoom from '../../composable/rooms/useRemoveUserFromRoom';
import useRoomParticipants from '../../composable/rooms/useRoomParticipants';
import useChatRoomRouter from '../../composable/useChatRoomRouter';
import useConnectOptions from '../../composable/video-chat/useConnectOptions';
import useVideoChat from '../../composable/video-chat/useVideoChat';
import useVideoChatTimer from '../../composable/video-chat/useVideoChatTimer';

import { DeviceSettingsModel } from '../../models/DeviceSettings';
import OneToOneChat from '../bar-chat/OneToOneChat.vue';
import OneToManyChat from '../table-chat/OneToManyChat.vue';
import VideoBottomBar from './video-chat/VideoBottomBar.vue';
import useGetRoom from '../../composable/rooms/useGetRoom';

export default defineComponent({
  name: 'VideoChat',
  components: {
    BCXMessenger,
    OneToOneChat,
    OneToManyChat,
    VideoBottomBar
  },

  props: {
    room: {
      type: Object as PropType<ExternalVideoBarChatMatchingResult | ExternalVideoGroupRoom>,
      default: null
    },
    creator: {
      type: Object as PropType<ExternalVideoSessionCreator>,
      default: null
    },
    deviceSettings: {
      type: Object as PropType<DeviceSettingsModel>,
      default: undefined
    }
  },

  emits: ['leaveVideoChat', 'openDeviceSettings', 'room-join-failed', 'toggleMessenger'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const { isBarChat } = useChatRoomRouter();
    const { insertPlaceholderImage } = usePlaceholderImage();
    const {
      setBarChatTimer, formattedTimer, isCounterExpired, isCounterLastSeconds, showTimerInTableChat
    } = useVideoChatTimer(props.room, isBarChat.value);
    const showVideoChatTimer = computed(() => isBarChat.value || showTimerInTableChat.value);
    const store = useStore();

    const { deviceSettings } = toRefs(props);
    const { closeQuickInfo } = useQuickInfo();
    const { isMobileWidth } = useResponsiveness();

    const videoChatVariant = computed(() => (isBarChat.value ? OneToOneChat : OneToManyChat));

    const activeRoom = ref<Room>();
    const connectedSound = ref<HTMLAudioElement>();

    const cameraDeviceId = computed(() => deviceSettings.value?.cameraDeviceId ?? '');
    const microphoneDeviceId = computed(() => deviceSettings.value?.microphoneDeviceId ?? '');
    const speakerDeviceId = computed(() => deviceSettings.value?.speakerDeviceId ?? '');
    const uniqueRoomName = computed(() => props.room?.uniqueRoomName ?? '');
    const roomSid = computed(() => props.room?.roomSid ?? '');
    const isAudioInputDisabled = ref(false);
    const isVideoInputDisabled = ref(false);

    const videoInputPreview = ref<HTMLElement>();
    const remoteMedia = ref<HTMLElement>();

    const { createJoinRoom, data, error } = useJoinRoom();
    const { getRoomParticipants, data: roomParticipantsData } = useRoomParticipants();
    const { leaveBarChat } = useLeaveBarChat();
    const { removeUserFromRoom } = useRemoveUserFromRoom();
    const { createConnectionOptions } = useConnectOptions();

    const user = computed<User>(() => store.getters.user);
    const username = computed(() => `${user.value.firstname} ${user.value.lastname}`);

    const isTogglingAudio = ref(false);
    const isTogglingVideo = ref(false);

    const {
      handleParticipantConnected,
      handleParticipantDisconnected,
      applyAudioInputDeviceChange,
      applyAudioOutputDeviceChange,
      applyVideoInputDeviceChange,
      removeAudioInputDevice,
      removeVideoInputDevice,
      stopAllInputDevices,
      createTracks,
      localAudioTrack,
      localVideoTrack
    } = useVideoChat();

    async function leaveRoom() {
      logDebug('leave room');
      closeQuickInfo();

      if (activeRoom.value) {
        stopAllInputDevices(activeRoom.value.localParticipant);

        activeRoom.value.disconnect();
        activeRoom.value = undefined;
      } else {
        logDebug('No active room to leave');
        localVideoTrack.value?.disable();
        localAudioTrack.value?.disable();
        localAudioTrack.value?.stop();
        localVideoTrack.value?.stop();
        localVideoTrack.value?.detach();
        localAudioTrack.value?.detach();
        localAudioTrack.value?.mediaStreamTrack.stop();
        localVideoTrack.value?.mediaStreamTrack.stop();

        localVideoTrack.value = null;
        localAudioTrack.value = null;
      }

      if (uniqueRoomName.value) {
        if (isBarChat.value) {
          await leaveBarChat(uniqueRoomName.value);
        }

        await removeUserFromRoom(uniqueRoomName.value);
      }

      window.removeEventListener('beforeunload', () => undefined);
      window.removeEventListener('pagehide', () => undefined);

      emit('leaveVideoChat');
    }

    watchEffect(async () => {
      if (isCounterExpired.value) {
        await leaveRoom();
      }
    });

    async function videoInputDeviceChange() {
      if (activeRoom.value) {
        await applyVideoInputDeviceChange(activeRoom.value.localParticipant, cameraDeviceId.value, username.value, videoInputPreview.value);
      }
    }

    async function audioInputDeviceChange() {
      if (activeRoom.value) {
        await applyAudioInputDeviceChange(activeRoom.value.localParticipant, microphoneDeviceId.value, videoInputPreview.value);
      }
    }

    watch(cameraDeviceId, (value, oldValue) => {
      if (value !== oldValue && activeRoom.value) {
        if (!isVideoInputDisabled.value) {
          removeVideoInputDevice(activeRoom.value.localParticipant);
          videoInputDeviceChange();
        }
      }
    });

    watch(microphoneDeviceId, (value, oldValue) => {
      if (value !== oldValue && activeRoom.value) {
        if (!isAudioInputDisabled.value) {
          removeAudioInputDevice(activeRoom.value.localParticipant);
          audioInputDeviceChange();
        }
      }
    });

    watch(speakerDeviceId, (value, oldValue) => {
      if (value !== oldValue && activeRoom.value) {
        applyAudioOutputDeviceChange(value);
      }
    });

    const roomParticipants = computed(() => roomParticipantsData.value?.videoRoomParticipantList);

    async function onParticipantConnected(participant: RemoteParticipant) {
      await getRoomParticipants(uniqueRoomName.value);

      // TODO: Hier gibts es noch ein timing problem bei Firefox das Bild wird beim erstenmal nicht geladen
      // Workaround: Der Gesprächspartner muss einmal die Kamera aus und einschalten
      let videoRoomParticipantList: ExternalVideoChatParticipant[] = [];
      if (roomParticipantsData?.value?.videoRoomParticipantList?.length) {
        videoRoomParticipantList = roomParticipantsData?.value?.videoRoomParticipantList;
      }

      if (isBarChat.value) {
        setBarChatTimer();
      }
      handleParticipantConnected(participant, videoRoomParticipantList, speakerDeviceId.value, remoteMedia.value, isBarChat.value);
    }

    async function connectToTwilio(accessToken: string) {
      const connectOptions: ConnectOptions = createConnectionOptions(uniqueRoomName.value, isBarChat.value, isMobileWidth.value);
      videoInputPreview.value = document.querySelector('#videoInputPreview') as HTMLElement;
      remoteMedia.value = isBarChat.value ? (document.querySelector('#remoteMedia') as HTMLElement) : (document.querySelector('#videoContainerCounter') as HTMLElement);

      if (videoInputPreview.value && remoteMedia.value) {
        try {
          let room: Room | undefined;
          await createTracks(cameraDeviceId.value, microphoneDeviceId.value).then(async (tracks) => {
            if (tracks) {
              tracks.forEach((track) => {
                if (track.kind === 'audio') {
                  isAudioInputDisabled.value = false;
                  localAudioTrack.value = track;
                } else if (track.kind === 'video') {
                  isVideoInputDisabled.value = false;
                  localVideoTrack.value = track;
                }
              });
            }
            room = await connect(accessToken, connectOptions);
            Promise.resolve(room).then((room) => {
              if (room) {
                logDebug('twilio -> connect -> room', JSON.parse(JSON.stringify(room)));
                if (!videoInputPreview.value) return;
                videoInputPreview.value.appendChild(room.localParticipant.videoTracks.values().next().value?.track.attach());

                if (isBarChat.value) {
                  insertPlaceholderImage(videoInputPreview);
                  connectedSound.value?.play();
                }
              }
            });

            activeRoom.value = room;
          });

          await getRoomParticipants(uniqueRoomName.value);
          await nextTick();
          // sync the preview with connected tracks.
          if (room) {
            room.participants.forEach(onParticipantConnected);

            if (isBarChat.value) {
              const { createGetRoom, data: roomDataResponse, error } = useGetRoom();
              await createGetRoom(uniqueRoomName.value);
              if (error.value) {
                logError('Error while getting room data', error.value);
                await leaveRoom();
              } else if ((roomDataResponse.value?.numberOfActiveUsers || 0) < 2) {
                logDebug('No active user connected. Leaving room.');
                await leaveRoom();
              }
            }

            let roomLoopCounter = 0;
            const roomCheckIntervall = setInterval(async () => {
              if (isBarChat.value) {
                roomLoopCounter++;
                if (room) {
                  if (roomLoopCounter > 8 && room?.participants.size === 0) {
                    await leaveRoom();
                  }
                }
                if (roomLoopCounter > 8) {
                  clearInterval(roomCheckIntervall);
                }
              } else {
                clearInterval(roomCheckIntervall);
              }
            }, 2500);

            // listen as participants connect/disconnect
            room.on('participantConnected', async (participant) => {
              await nextTick();
              await onParticipantConnected(participant);
            });
            room.on('disconnected', async (room) => {
              logDebug('twilio -> connect -> room -> disconnected', room);
              if (isBarChat.value) {
                await leaveRoom();
              }
            });
            room.on('participantDisconnected', async (participant) => {
              if (isBarChat.value) {
                await leaveRoom();
              }
              await getRoomParticipants(uniqueRoomName.value);
              handleParticipantDisconnected(participant);
            });
          }
        } catch (error) {
          logError('twilio -> connect -> error', error);
          await leaveRoom();
        }
      }
    }

    watchEffect(() => {
      // TODO: dominantSpeaker
      if (activeRoom.value?.dominantSpeaker) {
        logDebug('debug -> watchEffect -> activeRoom.value?.dominantSpeaker', activeRoom.value?.dominantSpeaker);
      }
    });

    whenever(roomSid, async () => {
      await createJoinRoom({
        roomSid: roomSid.value,
        uniqueRoomName: uniqueRoomName.value
      });
      const accessToken = data.value?.accessToken;

      if (error.value) {
        emit('room-join-failed', error.value);
      }

      if (accessToken) {
        await connectToTwilio(accessToken);
      }
    }, {
      immediate: true
    });

    onUnmounted(async () => {
      await leaveRoom();
    });

    onMounted(() => {
      window.addEventListener('beforeunload', async () => {
        await leaveRoom();
      });
      window.addEventListener('pagehide', async () => {
        await leaveRoom();
      });
    });

    async function toggleAudioOrVideoInput(kind: 'video' | 'audio') {
      if (activeRoom.value) {
        const { localParticipant } = activeRoom.value;

        if (kind === 'audio' && !isTogglingAudio.value) {
          isTogglingAudio.value = true;
          isAudioInputDisabled.value = !isAudioInputDisabled.value;

          if (isAudioInputDisabled.value) {
            removeAudioInputDevice(localParticipant);
          } else {
            await audioInputDeviceChange();
          }
          isTogglingAudio.value = false;
        } else if (kind === 'video' && !isTogglingVideo.value) {
          isTogglingVideo.value = true;
          isVideoInputDisabled.value = !isVideoInputDisabled.value;

          if (isVideoInputDisabled.value) {
            removeVideoInputDevice(localParticipant);
          } else {
            await videoInputDeviceChange();
          }
          isTogglingVideo.value = false;
        }
      }
    }

    function openDeviceSettings() {
      emit('openDeviceSettings');
    }

    const roomTopic = computed(() => {
      if (isBarChat.value) {
        return user.value.fullname === roomParticipants.value?.[0]?.fullname ? roomParticipants.value?.[1]?.fullname ?? '' : roomParticipants.value?.[0]?.fullname ?? '';
      }
      return props.room?.roomTopic ?? '';
    });

    const toggleMessenger = () => {
      emit('toggleMessenger');
    };

    return {
      toggleMessenger,
      videoChatVariant,
      roomParticipants,
      t,
      leaveRoom,
      toggleAudioOrVideoInput,
      openDeviceSettings,
      isVideoInputDisabled,
      isAudioInputDisabled,
      roomTopic,
      isBarChat,
      formattedTimer,
      connectedSound,
      isCounterLastSeconds,
      showVideoChatTimer,
      isTogglingVideo,
      isTogglingAudio
    };
  }
});
