import { ref } from 'vue';
import { createGlobalState } from '@vueuse/core';

const useOnlineState = createGlobalState(() => {
  const isOnline = ref(navigator.onLine);

  window.addEventListener('online', () => {
    isOnline.value = true;
  });

  window.addEventListener('offline', () => {
    isOnline.value = false;
  });

  return ({
    isOnline
  });
});

export default useOnlineState;
