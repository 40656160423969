
import { defineComponent } from 'vue';
import BCXSkeleton from '@/components/molecules/BCXSkeleton.vue';
import ThreadMessageSkeleton from '@/views/forum/components/ThreadMessageSkeleton.vue';

export default defineComponent({
  components: {
    BCXSkeleton,
    ThreadMessageSkeleton,
  },
  setup() {
    return {

    };
  },
});
