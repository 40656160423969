
import Vue from 'vue';
import LightBulb from '@/components/svg/profile-lightbulb.svg?inline';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useUser from '@/mixins/useUser';
import Description from './form-components/Description.vue';
import Client from './form-components/Client.vue';
import Conditions from './form-components/Conditions.vue';
import ProjectGuests from './form-components/ProjectGuests.vue';
import ProjectTitle from './form-components/ProjectTitle.vue';

export default Vue.extend({
  name: 'EditProjectForumSection',
  components: {
    LightBulb,
    Description,
    Client,
    Conditions,
    ProjectTitle,
    BCXCheckbox,
    BCXMarkdown,
    ProjectGuests,
  },
  props: {
    formAttributes: {
      type: Object,
      default() {
        return {};
      }
    },
    formType: {
      type: String,
      default: undefined
    },
    explainText: {
      type: String,
      default: ''
    },
    showTestFlag: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { tUserFallback: t } = useUser('self');

    return { t };
  },
  data() {
    return {
      formValue: undefined,
      testFlag: false
    };
  },
  computed: {
    typeLc(): string {
      return this.formType.toLowerCase();
    }
  },
  mounted() {
    this.testFlag = this.formAttributes.testFlag || false;
  },
  methods: {
    save() {
      const returnFormValue: any = this.formValue;
      if (typeof returnFormValue === 'string' && this.formType === 'project-title') {
        this.$emit('edit-save', {
          title: returnFormValue,
          testFlag: this.testFlag,
        });
      } else {
        if (this.showTestFlag && returnFormValue !== undefined) {
          returnFormValue.testFlag = this.testFlag;
        }
        this.$emit('edit-save', returnFormValue);
      }
    }
  }
});
