
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import ReminderSwitch from '@/views/virtual-pub/components/bar-chat/ReminderSwitch.vue';
import { useBarOpeningHours } from '@/views/virtual-pub/composable/useBarOpeningHours';
import {
  computed, defineComponent, ref, watch
} from 'vue';
import { useLocalStorage } from '@vueuse/core';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';
import VirtualPubSection from '../molecules/VirtualPubSection.vue';
import OpeningHours from './OpeningHours.vue';

export default defineComponent({
  components: {
    BCXHelpTextTriggerIcon,
    ReminderSwitch,
    BCXMarkdown,
    BCXInfoCollapsible,
    VirtualPubSection,
    OpeningHours
  },
  setup() {
    const { t } = useI18n();
    const {
      isBarOpen, isBarOpeningSoon, openingDays, formattedTimer
    } = useBarOpeningHours();
    const { isMobileWidth } = useResponsiveness();
    const isEnabled = ref(true);
    const isOpen = useLocalStorage('virtual-pub-header', true);

    const toggleIsOpen = () => {
      if (isMobileWidth.value) return;
      isOpen.value = !isOpen.value;
    };
    const inlineHelpOpened = ref(false);

    function updateIsEnabled() {
      isEnabled.value = !isEnabled.value;
    }

    const openBadge = computed(() => {
      if (isBarOpeningSoon.value) {
        return t('video_chat.bar_header.pub_hours.opens_in', {
          countdown: formattedTimer.value
        });
      }
      if (isBarOpen.value) return t('video_chat.bar_header.pub_hours.open');
      return t('video_chat.bar_header.pub_hours.closed');
    });

    const openBadgeClass = computed(() => {
      if (isBarOpeningSoon.value) return 'chip--opening-soon';
      if (isBarOpen.value) return 'chip--open';
      return 'chip--closed';
    });

    watch(isOpen, (is) => {
      if (!is) inlineHelpOpened.value = false;
    });

    return {
      t,
      isOpen,
      openBadgeClass,
      toggleIsOpen,
      isBarOpen,
      isEnabled,
      updateIsEnabled,
      openingDays,
      formattedTimer,
      inlineHelpOpened,
      openBadge
    };
  }
});
