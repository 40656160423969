
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import SectionIcon from '@/components/svg/settings-big.svg?inline';
import useSettings from '@/state/settingsState';
import DeleteAccountPopup from '@/views/settings/components/popups/DeleteAccountPopup.vue';
import SettingVerificationPopup from '@/views/settings/components/popups/SettingsVerificationPopup.vue';
import SectionGeneral from '@/views/settings/components/sections/SectionGeneral.vue';
import SectionLegal from '@/views/settings/components/sections/SectionLegal.vue';
import SectionNotifications from '@/views/settings/components/sections/SectionNotifications.vue';
import SectionSecurity from '@/views/settings/components/sections/SectionSecurity.vue';
import SectionUserDataEmail from '@/views/settings/components/sections/SectionUserDataEmail.vue';
import SettingsSection from '@/views/settings/components/utils/SettingsSection.vue';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'Settings',
  components: {
    DeleteAccountPopup,
    SettingVerificationPopup,
    SectionLegal,
    SectionGeneral,
    SectionNotifications,
    SectionSecurity,
    SectionUserDataEmail,
    SettingsSection,
    BCXBreadcrumbs,
    SectionIcon
  },
  setup() {
    const { loadSettings, pendingVerification, showDeleteAccountPopup } = useSettings();

    const showVerificationPopup = ref(false);

    watch(pendingVerification, (verification) => {
      if (verification) {
        showVerificationPopup.value = true;
      }
    }, { immediate: true });

    loadSettings();

    const onCloseVerificationPanel = () => {
      pendingVerification.value = null;
      showVerificationPopup.value = false;
    };

    return {
      showVerificationPopup,
      onCloseVerificationPanel,
      showDeleteAccountPopup,
    };
  }
});
