
import useI18n from '@/mixins/useI18n';
import { computed, defineComponent, toRefs } from 'vue';

export default defineComponent({
  name: 'ProfileSidebarTitle',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { name } = toRefs(props);
    const { t } = useI18n();

    const divs = computed(() => {
      const titleString = `${t('recommendations.sidebar.title', {
        username: `|${name.value}|`
      })}`;

      return titleString.split(/\s*\|\s*/)
        .filter((str) => !!str)
        .map((str) => ({
          str,
          isUserName: str === name.value
        }));
    });

    const templateColumns = computed(() => divs.value.map(({ isUserName }, i) => {
      if (isUserName) {
        return 'auto';
      }
      return i === 0 ? 'max-content' : 'minmax(max-content, 1fr)';
    }).join(' '));

    return { divs, templateColumns };
  }
});
