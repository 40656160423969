
import { ProfileStoreKey } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import ProfileSection from '@/views/profile/components/ProfileSection.vue';
import ProfileBasicEditor from '@/views/profile/components/editors/ProfileBasicEditor.vue';
import ProfileSkillsEditor from '@/views/profile/components/editors/ProfileSkillsEditor.vue';
import ProfileBasicView from '@/views/profile/components/views/ProfileBasicView.vue';
import ProfileInvitedBy from '@/views/profile/components/views/ProfileInvitedBy.vue';
import ProfileSkillsView from '@/views/profile/components/views/ProfileSkillsView.vue';
import Vue, { inject } from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';

export default Vue.extend({
  name: 'ProfileGuest',
  components: {
    BCXBreadcrumbs,
    BCXContentArea,
    ProfileSkillsEditor,
    ProfileSkillsView,
    ProfileSection,
    ProfileBasicEditor,
    ProfileInvitedBy,
    ProfileBasicView,
  },
  setup() {
    const {
      isEditSectionOpen, isSectionFilled,
    } = useProfileState(ProfileStoreKey.PROFILE);

    const breadcrumbs = inject('breadcrumbs', []);

    return {
      isEditSectionOpen,
      isSectionFilled,
      breadcrumbs
    };
  },
});
