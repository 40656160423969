
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useI18n from '@/mixins/useI18n';
import { AvailabilityInterface } from '@/models/Availability';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';

export default defineComponent({
  name: 'ProfileSidebarAvailability',
  props: {
    availability: {
      type: Object as PropType<AvailabilityInterface | null>,
      default: null
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { availability } = toRefs(props);
    const { getFormattedDate } = useFormattedDateTime();

    const availabilityMode = computed(() => {
      if (!availability.value || !availability.value.available) {
        return 0;
      }
      if (!availability.value.availableNow || !availability.value.availableFulltime) {
        return 2;
      }
      return 1;
    });

    const availabilityColor = computed(() => {
      switch (availabilityMode.value) {
        case 1:
          return 'green';
        case 2:
          return 'yellow';
        default:
          return 'red';
      }
    });

    const availabilityColorClass = computed(() => `availability-indicator bg-${availabilityColor.value}`);

    const availabilityText = computed(() => {
      if (availabilityMode.value === 0 || !availability.value) {
        return t('availability.unavailable');
      }

      const {
        availableFulltime, availableNow, availableHoursPerWeek, availableFrom
      } = availability.value;

      const fullTimeOrPartTime = availableFulltime
        ? t('availability.fullTime')
        : `${availableHoursPerWeek} ${t('availability.hoursPerWeekShort')}`;

      const nowOrLater = availableNow
        ? `${t('availability.available')}`
        : `<span>${t('availability.from')}</span> ${getFormattedDate(availableFrom)}`;

      return `${nowOrLater} • ${fullTimeOrPartTime}`;
    });

    return {
      availabilityColorClass,
      availabilityText
    };
  }
});
