
import Vue from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useVModel } from '@vueuse/core';

export default Vue.extend({
  components: { BCXMarkdown },
  props: {
    isOpen: { type: Boolean },
    text: { type: String, default: '' },
    tag: { type: String, default: 'span' }
  },
  setup(props) {
    const isOpenVM = useVModel(props, 'isOpen');

    return { isOpenVM };
  }
});
