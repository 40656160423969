
import { defineComponent, ref } from 'vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProjectGuestsAddExisting',
  components: {
    BCXSearchSelect
  },
  setup() {
    const { tUserFallback: t } = useUser('self');

    const searchInput = ref('');

    return {
      t,
      searchInput,
    };
  },
});
