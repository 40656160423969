
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { Security2FaMethodType, Settings, SettingsVerificationType } from '@/models/Settings';
import { set2FAMethod } from '@/services/Settings/Api';
import useSettings from '@/state/settingsState';
import { i18nToItemList } from '@/utils/selectItemTools';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import useUpdateTools from '@/views/settings/utils/useUpdateTools';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'TwoFactorMethodSetting',
  components: {
    BackendErrors,
    ActionButtons,
    BCXSearchSelect
  },
  setup() {
    const twoFactorMethods = i18nToItemList('settings.security.2fa_method_types');
    const twoFactorMethod = ref<Security2FaMethodType>(Security2FaMethodType.Email);
    const { settings } = useSettings();

    const setBySettings = (settings: Settings | null) => {
      twoFactorMethod.value = settings?.securitySettings?.security2FaMethod ?? Security2FaMethodType.Email;
    };

    const fnSave = async () => set2FAMethod({
      newSecurity2FaMethod: twoFactorMethod.value
    });

    const dirty = computed(() => settings.value?.securitySettings?.security2FaMethod !== twoFactorMethod.value);

    const {
      save,
      reset,
      isLoading,
      backendErrors,
      isDirty
    } = useUpdateTools(false, fnSave, setBySettings, SettingsVerificationType.Security2FAMethod, dirty);

    return {
      twoFactorMethods,
      twoFactorMethod,
      isDirty,
      save,
      reset,
      isLoading,
      backendErrors
    };
  }
});
