
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import Close from '@/components/svg-components/X.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { ForumMaxNum } from '@/views/forum/models/Forum';
import {
  computed, defineComponent, nextTick, onMounted, ref, toRefs, watch
} from 'vue';
import useUser from '@/mixins/useUser';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  components: {
    'bc-search-select': BCXSearchSelect,
    BCXCheckbox,
    Close,
  },
  props: {
    locationValue: {
      type: String,
      default: 'REMOTE_ONLY',
    },
    remoteWorkValue: {
      type: String,
      default: 'TWO_DAYS_PER_WEEK',
    },
    teamSizeValue: {
      type: Number,
      default: 1,
    },
    startDateValue: {
      type: String,
      default: (new Date()).toISOString().split('T')[0],
    },
    durationAmountValue: {
      type: Number,
      default: 3,
    },
    durationUnitValue: {
      type: String,
      default: 'MONTH',
    },
    hoursPerWeekValue: {
      type: Number,
      default: 40,
    },
    dependingOnComplexityValue: {
      type: Boolean,
      default: false,
    },
    isCreatingProject: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const isSmartphoneWidth = computed(() => store.getters['page/isSmartphoneWidth']);
    const {
      locationValue, remoteWorkValue, teamSizeValue,
      startDateValue, durationAmountValue, durationUnitValue,
      hoursPerWeekValue, dependingOnComplexityValue, isCreatingProject
    } = toRefs(props);

    const { tUserFallback: tUser } = useUser('self');

    const getDropdownItems = (translationKey: string): Array<any> => {
      const translation = { ...tUser(`project-forum_USERTYPE.form-section.conditions.${translationKey}.dropdown`) as any };
      const keys = Object.keys(translation);
      return keys.map((key) => ({
        text: translation[key],
        value: key
      }));
    };

    const formatDate = (timestamp: string, dateformat: 'short'|'diff' = 'diff'): string => {
      const { getFormattedTimeByAge, getFormattedDate } = useFormattedDateTime();
      if (dateformat === 'diff') return getFormattedTimeByAge(timestamp);
      return getFormattedDate(timestamp);
    };

    const location = ref('REMOTE_ONLY');
    const locationItems = computed(() => getDropdownItems('location'));

    const remoteWork = ref('');
    const remoteWorkItems = computed(() => getDropdownItems('remote-work'));

    const teamSize = ref(3);

    const startDate = ref('');

    const durationAmount = ref(3);

    const durationUnit = ref('MONTH');
    const durationUnitItems = computed(() => getDropdownItems('duration'));

    const hours = ref(40);
    const dependingOnComplexity = ref(false);
    watch(() => dependingOnComplexity.value, (newValue) => {
      if (newValue) {
        hours.value = 0;
      } else {
        hours.value = 40;
      }
    });

    const pushValue = () => {
      const clientData = {
        location: location.value,
        remoteWork: remoteWork.value,
        teamSize: teamSize.value,
        startDate: startDate.value,
        durationAmount: durationAmount.value,
        durationUnit: durationUnit.value,
        hoursPerWeek: hours.value,
        dependingOnComplexity: dependingOnComplexity.value
      };
      emit('input', clientData);
    };

    watch(() => location.value, () => {
      nextTick(() => {
        pushValue();
      });
    });

    watch(() => remoteWork.value, () => {
      nextTick(() => {
        pushValue();
      });
    });

    watch(() => teamSize.value, (value) => {
      if (value > 999) {
        teamSize.value = 999;
      }
      nextTick(() => {
        pushValue();
      });
    });

    watch(() => startDate.value, () => {
      nextTick(() => {
        pushValue();
      });
    });

    watch(() => durationAmount.value, (value) => {
      if (value > 9999) {
        durationAmount.value = 9999;
      }
      nextTick(() => {
        pushValue();
      });
    });

    watch(() => durationUnit.value, () => {
      nextTick(() => {
        pushValue();
      });
    });

    watch(() => hours.value, (value) => {
      if (value > 60) {
        hours.value = 60;
      }
      nextTick(() => {
        pushValue();
      });
    });

    const menuStartDate = ref(false);
    const maxHours = ForumMaxNum.PROJECT_HOURS;
    const maxTeamSize = 999;
    const maxProjectDuration = ForumMaxNum.PROJECT_DURATION;

    const datepickerSettings = {
      locale: 'de-de',
      'first-day-of-week': '1',
      min: (new Date()).toISOString().split('T')[0],
      flat: true,
      'locale-first-day-of-year': 4,
    };

    const startDateFormatted = computed(() => {
      let date: string = startDate.value;
      if (date === '') {
        date = new Date().toString();
      }
      return formatDate(date, 'short');
    });

    const remoteWorkVisible = computed(() => location.value === 'ONSITE_AND_REMOTE');

    onMounted(() => {
      if (locationValue.value) {
        location.value = locationValue.value;
      }
      if (remoteWorkValue.value) {
        remoteWork.value = remoteWorkValue.value;
      }
      if (teamSizeValue.value) {
        teamSize.value = teamSizeValue.value;
      }
      if (startDateValue.value) {
        startDate.value = startDateValue.value;
      }
      if (durationAmountValue.value) {
        durationAmount.value = durationAmountValue.value;
      }
      if (durationUnitValue.value) {
        durationUnit.value = durationUnitValue.value;
      }
      if (hoursPerWeekValue.value) {
        hours.value = hoursPerWeekValue.value;
      }
      if (dependingOnComplexityValue.value) {
        dependingOnComplexity.value = dependingOnComplexityValue.value;
      }
    });

    return {
      location,
      locationItems,
      remoteWork,
      remoteWorkItems,
      teamSize,
      startDate,
      durationAmount,
      durationUnit,
      durationUnitItems,
      hours,
      dependingOnComplexity,
      menuStartDate,
      maxHours,
      maxTeamSize,
      maxProjectDuration,
      datepickerSettings,
      startDateFormatted,
      remoteWorkVisible,
      isSmartphoneWidth,
      tUser,
    };
  }
});
