
import {
  defineComponent
} from 'vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import { useVModel } from '@vueuse/core';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useI18n from '@/mixins/useI18n';
import { UserType } from '@/models/User';

export default defineComponent({
  name: 'InviteCodePopup',
  components: {
    BCXMarkdown,
    LRPopup
  },
  props: {
    value: {
      type: Boolean
    },
  },
  setup(props) {
    const vModel = useVModel(props);
    const { tUserType } = useI18n();
    const t = (key: string, ...args: any) => tUserType(key, UserType.COMPANY, ...args);
    const close = () => {
      vModel.value = false;
    };
    return {
      vModel,
      close,
      t,
      UserType
    };
  }
});
