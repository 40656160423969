
import { defineComponent } from 'vue';
import IconQuestionMark from '@/components/svg/questionmark-circle.svg?inline';
import IconRemove from '@/components/svg/minus-circle.svg?inline';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  components: {
    IconQuestionMark,
    IconRemove,
  },
  props: {
    isOpen: { type: Boolean },
    size: {
      type: Number,
      default: 20
    }
  },
  setup(props) {
    const isOpenVM = useVModel(props, 'isOpen');
    return { isOpenVM };
  }
});
