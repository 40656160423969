
import BCXSuggestionBox from '@/components/molecules/BCXSuggestionBox.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { defineComponent, PropType } from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'ProjectSelector',
  components: { BCXSuggestionBox },
  props: {
    value: {
      type: String,
      default: ''
    },
    projectList: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const { getFormattedTimeByAge } = useFormattedDateTime();
    const projectName = useVModel(props);

    const onClickItem = async (item: any, close: CallableFunction) => {
      emit('select', item);
      close();
    };

    return {
      projectName, getFormattedTimeByAge, onClickItem
    };
  }
});
