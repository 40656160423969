import _axios from '@/plugins/axios';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/bar';

const useDeleteVirtualPubLobby = () => {
  const deleteVirtualPubLobbyAxios = useAxios(ENDPOINT, { method: 'DELETE' }, _axios, { immediate: false });

  async function deleteVirtualPubLobby() {
    await deleteVirtualPubLobbyAxios.execute(ENDPOINT, { method: 'DELETE' });
  }

  return {
    deleteVirtualPubLobby,
    ...deleteVirtualPubLobbyAxios
  };
};

export default useDeleteVirtualPubLobby;
