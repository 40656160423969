import { RouteConfig } from 'vue-router';
import Requester from '@/views/requester/components/Requester.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/requester',
    name: 'requester',
    component: Requester,
    meta: {
      auth: true,
      layout: 'plain',
    },
  }
];
export default routes;
