
import useSuggestionBox from '@/mixins/useSuggestionBox';
import {
  computed,
  defineComponent, nextTick, onMounted, PropType, ref, toRefs, watch
} from 'vue';
import {
  onClickOutside, templateRef, useElementBounding, useRafFn, useVModel
} from '@vueuse/core';
import X from '@/components/svg-components/X.vue';

export default defineComponent({
  name: 'BCXSuggestionBox',
  components: { X },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 2000
    },
    list: {
      type: Array as PropType<any []>,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean
    },
    clearable: {
      type: Boolean
    },
  },
  setup(props, { emit }) {
    const {
      list,
      autofocus
    } = toRefs(props);
    const search = useVModel(props);
    const input = templateRef<HTMLInputElement>('input');
    const box = templateRef<HTMLElement>('suggestion-box');
    const isFocused = ref(false);

    const {
      left,
      width,
      bottom,
      update: updateBoundingBox
    } = useElementBounding(box);

    onMounted(() => {
      if (input.value && autofocus.value) {
        input.value.focus();
      }
    });

    const { cursorPosition } = useSuggestionBox({
      input,
      list,
      select: (index) => {
        emit('select', list.value?.[index], index);
      }
    });

    const clear = () => {
      search.value = '';
      emit('clear');
    };

    const close = async () => {
      await nextTick();
      isFocused.value = false;
    };

    onClickOutside(input, async () => {
      await close();
    }, {
      ignore: ['.c-list-wrapper']
    });

    const isListShown = computed(() => list.value.length && search.value && isFocused.value);

    const { resume, pause } = useRafFn(() => {
      updateBoundingBox();
    }, { immediate: false });

    watch(isListShown, (is) => {
      if (is) resume();
      else pause();
    });

    return {
      search,
      cursorPosition,
      left,
      width,
      bottom,
      clear,
      close,
      isFocused,
      isListShown
    };
  }
});
