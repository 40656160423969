
import Vue, { computed } from 'vue';
import useProfileState from '@/state/profile/profileState';
import EmptySection from '@/views/profile/components/EmptySection.vue';

export default Vue.extend({
  name: 'ProfileSkillsView',
  components: { EmptySection },
  setup() {
    const { profile, user, isSelf } = useProfileState();
    const hasSkills = computed(() => !!profile.value?.skills?.skillList?.length);
    const name = computed(() => user.value?.firstname ?? '');
    return {
      profile, hasSkills, name, isSelf
    };
  },
});
