
import SettingsPopup from '@/views/settings/components/utils/SettingsPopup.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BCXConfirm',
  components: { SettingsPopup },
  props: {
    value: {
      type: Boolean
    },
    yesLabel: {
      type: String,
      default: ''
    },
    noLabel: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<'default' | 'watchlist'>,
      default: 'default'
    }
  },
  setup(props, { emit }) {
    const close = () => {
      emit('input', false);
    };

    const confirm = (which: boolean) => {
      close();
      emit('confirm', which);
    };

    return { confirm, close };
  }
});
