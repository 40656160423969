
import {
  defineComponent, PropType, Ref, ref
} from 'vue';
import { NotificationBarPanelItem } from '@/models/Notification';

export default defineComponent({
  name: 'NotificationBarItem',
  props: {
    item: {
      type: Object as PropType<NotificationBarPanelItem>,
      default: () => null
    },
    active: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const btn = ref() as Ref<HTMLElement>;

    const click = () => {
      const {
        left,
        right,
      } = btn.value.getBoundingClientRect();
      emit('click', (left + right) * 0.5);
    };

    return {
      btn,
      click,
    };
  },
});
