
import { defineComponent } from 'vue';
import BgImage from '@/views/landingpage/components/freelancer/BgImage.vue';
import useFreelancerLandingPage from '@/views/landingpage/components/freelancer/useFreelancerLandingPage';

export default defineComponent({
  name: 'FreelancerLogo',
  components: { BgImage },
  setup() {
    const { tl } = useFreelancerLandingPage();
    return {
      tl
    };
  }
});
