import {
  onBeforeUnmount, onMounted, Ref, watch,
} from 'vue';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useResponsiveness from '@/mixins/useResponsiveness';

const useScrollLock = (targetElement:Ref<HTMLElement | SVGElement | null>, trigger?:Ref<boolean>) => {
  const { isMobileWidth } = useResponsiveness();

  // console.log('useScrollLock v.2');
  let previousScrollPos = 0;

  const setWindowHeight = () => {
    if (isMobileWidth.value) {
      document.body.style.setProperty('--window-height', `${window.innerHeight}px`);
      // console.log('innerHeight:', window.innerHeight);
    }
  };
  // let ivId = 0;

  const allowTouchMove = (el: any) => {
    while (el && el !== document.body) {
      if (
        el.getAttribute('data-body-scroll-lock-ignore') !== null
        || el.classList.contains('toastui-editor-contents')
      ) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  };

  const enable = () => {
    enableBodyScroll(targetElement.value);
    window.scroll(0, previousScrollPos);
  };

  const disable = () => {
    previousScrollPos = window.scrollY;
    disableBodyScroll(targetElement.value, { allowTouchMove });
  };

  setWindowHeight();
  if (trigger !== undefined) {
    watch(trigger, (is) => {
      if (!isMobileWidth.value) return;
      if (is) {
        disable();
      } else {
        enable();
      }
    });
  }
  onMounted(() => {
    if (!isMobileWidth.value) return;
    if (trigger === undefined) {
      disable();
    }
    window.addEventListener('resize', setWindowHeight);
    // ivId = setInterval(() => setWindowHeight(), 1000);
  });
  onBeforeUnmount(() => {
    if (!isMobileWidth.value) return;
    window.removeEventListener('resize', setWindowHeight);
    // clearInterval(ivId);
    if (trigger === undefined) {
      enable();
    }
    clearAllBodyScrollLocks();
  });
};

export default useScrollLock;
