import { useRouter } from 'vue2-helpers/vue-router';

const PATH = '/virtual-pub';

export default () => {
  const router = useRouter();

  const push = async () => {
    await router.push(PATH);
  };

  return {
    push,
  };
};
