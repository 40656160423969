
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import { ExternalRecommendation } from '@/models/Recommendations';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import FeedbackDetails from '@/views/recommendations/components/FeedbackDetails.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'MyRecommendationsTableFeedback',
  components: {
    FeedbackDetails,
    BCXCollapsible,
  },
  props: {
    recommendation: {
      type: Object as PropType<ExternalRecommendation>,
      default: null
    },
    project: {
      type: Object as PropType<ProjectListProject>,
      default: null
    },
    isOpened: { type: Boolean }
  },
});

