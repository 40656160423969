
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import { RouterEntry } from '@/models/Router';
import { getBlogEntries } from '@/services/Blog/Api';
import {
  computed, defineComponent, onMounted, ref
} from 'vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import useI18n from '@/mixins/useI18n';
import { BlogEntry } from '../models/Blog';
import BlogWikiList from '../components/BlogWikiList.vue';

export default defineComponent({
  components: {
    BCXBreadcrumbs,
    BCXContentArea,
    BCXIcon,
    BlogWikiList
  },
  setup() {
    const { t } = useI18n();
    const entries = ref<BlogEntry[]>([] as BlogEntry[]);
    const breadcrumbItems = computed(() => {
      const breadcrumbItems: Array<RouterEntry> = [
        {
          text: t('wiki.wiki') as string,
          disabled: true
        },
        {
          text: t('wiki.help') as string
        }
      ];

      return breadcrumbItems;
    });

    const featured = computed(() => entries.value.filter((entry) => entry.position === 1));
    const all = computed(() => entries.value.filter((entry) => entry.position === 0));

    const loadBlogEntries = async () => {
      const blogEntries = (await getBlogEntries())?.blogEntries.filter((entry) => entry.type === 'HELP_ARTICLE') ?? [];
      const sortedBlogEntries = blogEntries.sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());
      entries.value = sortedBlogEntries;
    };
    onMounted(loadBlogEntries);

    return {
      t,
      entries,
      breadcrumbItems,
      featured,
      all,
    };
  }
});
