
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import { Settings, SettingsVerificationType } from '@/models/Settings';
import { setEmail } from '@/services/Settings/Api';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import useUpdateTools from '@/views/settings/utils/useUpdateTools';
import { defineComponent, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
  name: 'EmailSetting',
  components: { BackendErrors, ActionButtons, BCXValidatedInput },
  setup() {
    const { emailValidation } = useBCValidators();

    const fields = reactive({ newEmail: '' });
    const rules = { newEmail: emailValidation };

    const setBySettings = (settings:Settings | null) => {
      fields.newEmail = settings?.userDataSettings.primaryEmail ?? '';
    };

    const v = useVuelidate(rules, fields);

    const fnSave = async () => setEmail(fields);

    const {
      isLoading, reset, save, backendErrors, isDirty
    } = useUpdateTools(v, fnSave, setBySettings, SettingsVerificationType.Email);

    return {
      reset,
      save,
      v,
      isLoading,
      backendErrors,
      isDirty
    };
  }
});
