export const emojis = [
  [':-)', '🙂'],
  [':-X', '🤐'],
  ['(-:', '🙃'],
  [':-(', '🙁'],
  ['>:-(', '😠'],
  [':\'-(', '😢'],
  [':-/', '😕'],
  [':-o', '😮'],
  [';-)', '😉'],
  [':-P', '😛'],
  [';-P', '😜'],
  ['X-P', '😝'],
  [':-D', '😃'],
  ['X-D', '😆'],
  [/[B8]-\)/g, '😎'],
  ['%-)', '🥴'],
  [':-*', '😘'],
  ['<3', '❤'],
  [':-|', '😐'],
  ['*_*', '🤩'],
  ['^_^', '😊'],
  [/x_x/gi, '😵'],
  [/o_o/gi, '🤨'],
];

export const replaceEmojis = (str:string) => {
  emojis.forEach(([pattern, emoji]) => {
    str = str.replaceAll(pattern, emoji as string);
  });
  return str;
};
