import { TranslatedErrorCode } from '@/models/BackendError';
import { Settings, SettingsVerificationType } from '@/models/Settings';
import useSettings from '@/state/settingsState';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import {
  computed, Ref, ref, watch
} from 'vue';
import { AxiosResponse } from 'axios';

const lastDirty = ref<SettingsVerificationType | null>(null);

const useUpdateTools = (
  v: any | false,
  fnSave: () => Promise<AxiosResponse>,
  fnReset: (settings: Settings | null) => void,
  verificationType: SettingsVerificationType,
  isDirty?: Ref
) => {
  const isLoading = ref(false);
  const backendErrors = ref<(TranslatedErrorCode | string)[] >([]);
  const actualDirty = ref(false);

  if (!isDirty) {
    isDirty = computed(() => v.value.$anyDirty);
  }

  const {
    pendingVerification,
    settings,
    setResendFunction
  } = useSettings();

  const save = async () => {
    if (!v || await v.value.$validate()) {
      isLoading.value = true;
      const response = await fnSave();
      const backendErrorObject = new TranslatedBackendErrors(response);
      backendErrors.value = backendErrorObject.allErrors;
      if (!backendErrors.value.length) {
        pendingVerification.value = verificationType;
      }
      isLoading.value = false;
      actualDirty.value = false;
      setResendFunction(save);
    }
  };

  const reset = (full = false) => {
    fnReset(settings.value);
    if (v) v.value.$reset();
    actualDirty.value = false;
    if (full) lastDirty.value = null;
  };

  watch(settings, (settings) => {
    fnReset(settings);
  }, { immediate: true });

  watch(pendingVerification, (pending) => {
    if (!pending) {
      reset(true);
    } else if (pending !== verificationType) reset();
  });

  watch(isDirty, (is) => {
    if (is) lastDirty.value = verificationType;
    actualDirty.value = is;
  });

  watch(lastDirty, (last) => {
    if (last !== verificationType) reset();
  });

  return {
    isLoading,
    backendErrors,
    save,
    reset: () => reset(true),
    isDirty: actualDirty
  };
};

export default useUpdateTools;
