
import BCXCard from '@/components/molecules/BCXCard.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useInfoBoxState from '@/state/infoBox';
import ByteCookieIcon from '@/components/svg/logo/logo-circles-only.svg?inline';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DashboardInfoBox',
  components: { BCXMarkdown, BCXCard, ByteCookieIcon },
  setup() {
    const { infoBoxState: infoBox, sampledText } = useInfoBoxState();
    return { infoBox, sampledText };
  }
});
