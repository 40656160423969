import {
  asyncComputed, createInjectionState, useAsyncState, useDebounce
} from '@vueuse/core';
import UserSearchService from '@/services/UserSearchService';
import { User } from '@/models/User';
import { ExternalProject } from '@/models/Recommendations';
import recommendationService from '@/services/RecommendationService';
import { ref } from 'vue';
import ProjectForumService from '@/services/ProjectForumService';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';

const [useProvideGuestsEmployees, injectedState] = createInjectionState(() => {
  const { state: guests } = useAsyncState<User[]>(async () => (await UserSearchService.getUserList('Nin')).usersList as User[], []);
  const { state: employees } = useAsyncState<User[]>(async () => (await UserSearchService.getUserList('Str')).usersList as User[], []);

  const projectName = ref('');
  const projectNameDebounced = useDebounce(projectName, 250);
  const projectList = asyncComputed<ExternalProject[]>(
    async () => (await recommendationService.searchProjects(projectNameDebounced.value.toLocaleLowerCase())).externalProjectList,
    []
  );

  // @todo: remove later! Fake projects for employees
  const fakeProjectList = asyncComputed<ProjectListProject[]>(
    async () => ProjectForumService.getProjectList(),
    []
  );

  return {
    guests, employees, projectList, projectName, fakeProjectList
  };
});

export { useProvideGuestsEmployees };

export function useGuestsEmployees() {
  const injected = injectedState();
  if (injected == null) throw new Error('Please call `useProvideGuestsEmployees` on the appropriate parent component');
  return injected;
}
