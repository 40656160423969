import { computed, ref, watch } from 'vue';
import { useDevicesList, useStorage } from '@vueuse/core';

export interface Device {
  value: string;
  text: string;
}

const useDevice = (kind: MediaDeviceKind) => {
  const alldevices = ref<MediaDeviceInfo[]>([]);
  const current = useStorage<undefined | string>(`current-${kind}`, undefined);

  const { devices } = useDevicesList({
    requestPermissions: true,
    onUpdated(devices) {
      alldevices.value = devices.filter((device) => device.kind === kind && device.deviceId.length > 0 && device.deviceId !== 'default');
    }
  });

  watch([alldevices], () => {
    if (!current.value || alldevices.value.some((device) => device.deviceId !== current.value)) {
      if (alldevices.value.length > 0) {
        if (!current.value || current.value?.length === 0 || current.value === 'default') {
          current.value = alldevices.value[0].deviceId;
        }
      } else {
        current.value = undefined;
      }
    }
  });

  return {
    devices: computed(() => alldevices.value.filter((device) => device.kind === kind
      && device.deviceId.length > 0
      && device.deviceId !== 'default')),
    current
  };
};

export default useDevice;
