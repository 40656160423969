import {
  ExternalProjectList,
  MyRecommendationsResponse,
  RecommendationFeedbackRequest,
  RecommendationsForMeResponse,
  ResponseMyProjects,
  ResponseRecommendationVerify
} from '@/models/Recommendations';
import axios from '@/plugins/axios';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';
import { logDebug } from '@/utils/logger';
import { PossibleErrorResponse } from '@/models/BackendError';

const RecommendationService = {

  async searchProjects(searchParam: string): Promise<ExternalProjectList> {
    return (await axios.get('v1/projects/search', {
      params: {
        searchParam
      }
    })).data;
  },

  async verifyRecommendation(recommendedUserId: string, projectId: string): Promise<ResponseRecommendationVerify & PossibleErrorResponse> {
    const res = await axios.post('v1/recommendations/project/verify', {
      recommendedUserId, projectId
    }).catch(({ response }) => response as PossibleErrorResponse);
    return res.data;
  },

  async createRecommendation(recommendedUserId: string, projectId: string, recommendingMessage: string): Promise<void> {
    recommendingMessage = BCXHTMLSanitizer.beforeSave(recommendingMessage);
    await axios.post('v1/recommendations', {
      recommendedUserId,
      projectId,
      recommendingMessage
    });
  },

  async getMyProjects(): Promise<ResponseMyProjects> {
    logDebug('Loading from myProjects');
    const res = await axios.get('v1/users/user/myprojects');
    return res.data;
  },

  async deleteMyProject(userProjectId:string): Promise<void> {
    await axios.delete(`v1/users/user/myprojects/${userProjectId}`);
  },

  async getMyRecommendations(): Promise<MyRecommendationsResponse> {
    const res = await axios.get('v1/recommendations/my');
    return res.data;
  },

  async getRecommendationsForMe(): Promise<RecommendationsForMeResponse> {
    const res = await axios.get('v1/recommendations/me');
    return res.data;
  },

  async acceptRecommendation(recommendationId:string): Promise<void> {
    await axios.post(`v1/recommendations/${recommendationId}/accept`);
  },

  async rejectRecommendation(recommendationId:string): Promise<void> {
    await axios.post(`v1/recommendations/${recommendationId}/reject`);
  },

  async sendRecommendationFeedback(recommendationId: string, feedback: RecommendationFeedbackRequest): Promise<void> {
    if (feedback.userFeedbackMessage) {
      feedback.userFeedbackMessage = BCXHTMLSanitizer.beforeSave(feedback.userFeedbackMessage);
    }
    await axios.post(`v1/recommendations/${recommendationId}/feedback`, feedback);
  }

};

export default RecommendationService;
