import { ResponseVideoChatAvailability } from '@/__generated__/types';
import {
  FAKE_AVAILABILITY_TIMER, fakeTime, weekdays
} from '@/views/virtual-pub/composable/useBarOpeningHours';
import { computed, Ref } from 'vue';
import { useInterval, useNow } from '@vueuse/core';
import dayjs, { Dayjs } from 'dayjs';

export interface UseTimerCountdownParams {
    availability: Ref<ResponseVideoChatAvailability | undefined>;
}

const COUNTDOWN_THRESHOLD = 12 * 60 * 60;
const CLOSING_SOON_THRESHOLD = 5 * 60;
const ADD_TO_LOBBY_THRESHOLD = 1 * 60 * 60;

/**
 * use for testing timer Countdown instead of "useNow"
 */
const useNowFaker = () => {
  const startDate = new Date(fakeTime);
  const addSecond = useInterval(1000);

  return computed(() => new Date(startDate.getTime() + addSecond.value * 1000));
};

const mergeDayWithTime = (day:Dayjs, timeString:string) => {
  const numColons = timeString.split(':').length - 1;
  const time = dayjs(timeString, numColons === 1 ? 'hh:mm' : 'hh:mm:ss');

  return day.second(time.second())
    .minute(time.minute())
    .hour(time.hour());
};

const getWeekdayForDay = (day:number):keyof ResponseVideoChatAvailability => (day > 0 ? weekdays[(day - 1) % 7] : weekdays[6]);

interface StartEndTimes {
  startTime: string;
  endTime: string;
}

export const useTimerCountdown = ({ availability }: UseTimerCountdownParams) => {
  const now = FAKE_AVAILABILITY_TIMER ? useNowFaker() : useNow({ interval: 1000 });
  const nowDayJs = computed(() => dayjs(now.value));

  const getTimesForDay = (day:number):StartEndTimes | null => {
    const dayName = getWeekdayForDay(day);
    if (!availability.value?.[dayName]) return null;
    const startTime = (availability.value?.[`${dayName}StartTime` as keyof ResponseVideoChatAvailability] ?? '') as string;
    const endTime = (availability.value?.[`${dayName}EndTime` as keyof ResponseVideoChatAvailability] ?? '') as string;
    return {
      startTime, endTime
    };
  };

  /**
   * bar is opened or closed: true | false
   * time until bar is opened: number
   */
  const nextOpeningSeconds = computed<number>(() => {
    if (!availability.value) {
      return -1;
    }

    // get current day
    const day = nowDayJs.value.day();
    // get start and ending times for current day
    const times = getTimesForDay(day);

    // if we have an opening for current day:
    if (times) {
      const startTime = mergeDayWithTime(nowDayJs.value, times.startTime);
      const endTime = mergeDayWithTime(nowDayJs.value, times.endTime);
      const secondsUntilStart = startTime.diff(nowDayJs.value, 's');
      const secondsUntilEndRemaining = endTime.diff(nowDayJs.value, 's');

      if (secondsUntilStart > 0) return secondsUntilStart; // next opening still to come
      if (secondsUntilEndRemaining > 0) return 0; // it's open!
    }

    // no opening for current day, look for next day:
    const nextTimes = getTimesForDay(day + 1);
    if (nextTimes) {
      const nextStartTime = mergeDayWithTime(nowDayJs.value.add(1, 'days'), nextTimes.startTime);
      return nextStartTime.diff(nowDayJs.value, 's');
    }
    if (!availability.value.today?.currentlyAvailable) {
      return -1;
    }
    return 0;
  });

  const secondsUntilEndRemaining = computed(() => {
    if (!availability.value) {
      return -1;
    }
    // get current day
    const day = nowDayJs.value.day();
    // get start and ending times for current day
    const times = getTimesForDay(day);

    // if we have an opening for current day:
    if (times) {
      const endTime = mergeDayWithTime(nowDayJs.value, times.endTime);
      return endTime.diff(nowDayJs.value, 's');
    }
    return -1;
  });

  const isBarOpen = computed(() => nextOpeningSeconds.value === 0);
  const isBarClosingSoon = computed(() => secondsUntilEndRemaining.value > 0 && secondsUntilEndRemaining.value <= CLOSING_SOON_THRESHOLD);
  const canJoinRoom = computed(() => secondsUntilEndRemaining.value > CLOSING_SOON_THRESHOLD && nextOpeningSeconds.value === 0);
  const isBarOpeningSoon = computed(() => availability.value && nextOpeningSeconds.value > 0 && nextOpeningSeconds.value <= COUNTDOWN_THRESHOLD);
  const isLobbyOpen = computed(() => availability.value && nextOpeningSeconds.value <= ADD_TO_LOBBY_THRESHOLD);

  const formattedTimer = computed(() => `${Math.floor(nextOpeningSeconds.value / 3600)
    .toString()
    .padStart(2, '0')
  }:${(Math.floor(nextOpeningSeconds.value / 60) % 60).toString()
    .padStart(2, '0')
  }:${(nextOpeningSeconds.value % 60 % 60).toString()
    .padStart(2, '0')}`);

  return {
    formattedTimer,
    isLobbyOpen,
    isBarOpen,
    isBarClosingSoon,
    isBarOpeningSoon,
    canJoinRoom,
    secondsUntilEndRemaining,
  };
};
