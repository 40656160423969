import { computed, onUnmounted, ref } from 'vue';
import {
  Awaitable,
  tryOnScopeDispose, useDebounceFn, useDocumentVisibility, useEventBus, useTimeoutPoll, whenever
} from '@vueuse/core';
import useOnlineState from '@/state/onlineState';
import { TAB_INACTIVE_MULTIPLIER } from '@/models/IntervalTimes';
import { clamp } from 'lodash';
import { logDebug } from '@/utils/logger';

interface RefresherOptions {
  beginningInterval: number;
  endingInterval: number;
  timeSpan: number;
  name: string;
}

export const intervalRefresherEventBus = useEventBus('intervalRefresher');

export const INTERVAL_STOP = 'interval_stop';
export const INTERVAL_RESET = 'interval_reset';

/**
 * useIntervalRefresher
 * calls the refreshFunction in increasing time intervals
 * you can define a start and end interval time and a rate in which the
 * interval increases each time
 * @author nc
 *
 * @param refreshFunction
 * @param options
 */
const useIntervalRefresher = (refreshFunction: CallableFunction, options: RefresherOptions) => {
  if (options.timeSpan <= 0) throw new Error('timeSpan cannot be 0 or lower.');
  if (options.beginningInterval > options.endingInterval) throw new Error('fastInterval must be < slowInterval');

  const beginningInterval = ref(options.beginningInterval ?? 10000);
  const endingInterval = ref(options.endingInterval ?? 10000);
  const timeSpan = ref(options.timeSpan ?? 60000 * 30); // = 1h
  const then = ref(new Date().getTime()); // relative time offset
  const now = ref(then.value);
  const { isOnline } = useOnlineState();

  const easing = (x:number) => 1 - ((1 - x) ** 4);

  const isDocumentVisible = useDocumentVisibility();
  const timeFactor = computed(() => (isDocumentVisible.value === 'visible' ? 1 : TAB_INACTIVE_MULTIPLIER));

  const timeSpanRatio = computed(() => {
    const ratio = (now.value - then.value) / timeSpan.value;
    return easing(clamp(ratio, 0, 1));
  });
  const currentInterval = computed(() => beginningInterval.value + (endingInterval.value - beginningInterval.value) * timeSpanRatio.value * timeFactor.value);

  const refresh = useDebounceFn(() => {
    refreshFunction?.();
    logDebug('IntervalRefresher at ', currentInterval.value, 'ms');
    now.value = new Date().getTime();
  }, 100);

  const intervalFunction = useDebounceFn(refresh, beginningInterval) as any;

  const { isActive, pause: stop, resume } = useTimeoutPoll(intervalFunction, currentInterval, { immediate: true });

  const reset = (reason = '?') => {
    logDebug(`Reset ${options.name} timer to starting timing, because:`, reason);
    then.value = new Date().getTime();
    now.value = new Date().getTime();
    stop();
    resume();
    refresh();
  };

  whenever(isOnline, () => {
    reset();
  });

  onUnmounted(() => {
    if (isActive.value) stop();
  });

  const onRefresherEvent = useDebounceFn(
    (event:any, payload:string) => {
      if (event === INTERVAL_RESET) reset(payload);
      else if (event === INTERVAL_STOP) stop();
    }, 50
  );

  intervalRefresherEventBus.on(onRefresherEvent);

  tryOnScopeDispose(() => {
    intervalRefresherEventBus.off(onRefresherEvent);
  });

  return {
    stop,
    isActive,
    reset,
    resume,
  };
};

export default useIntervalRefresher;
