import { MaybeComputedElementRef, useElementBounding } from '@vueuse/core';
import { Ref } from 'vue';

const useAdaptBounding = (element: MaybeComputedElementRef, x:Ref<number>, y:Ref<number>, pad = 4) => {
  const {
    left, top, right, bottom, width, height
  } = useElementBounding(element);

  function adaptBounding(outsideRatio = 0) {
    if (!width.value && !height.value) return;

    const hOutside = width.value * outsideRatio;
    const vOutside = height.value * outsideRatio;

    const { clientWidth, clientHeight } = document.documentElement;

    const isOutLeft = left.value < pad - hOutside;
    const isOutTop = top.value < pad - vOutside;
    const isOutRight = right.value + pad >= clientWidth + hOutside;
    const isOutBottom = bottom.value + pad >= clientHeight + vOutside;

    if (isOutBottom) {
      y.value -= bottom.value - clientHeight + pad - vOutside;
      if (y.value < pad) y.value = pad;
    } else if (isOutTop && clientHeight > height.value) {
      y.value = pad - vOutside;
    }

    if (clientWidth < width.value + pad * 2) {
      x.value = (clientWidth - width.value) * 0.5;
    } else if (isOutLeft) {
      x.value = pad - hOutside;
    } else if (isOutRight) {
      x.value = clientWidth - width.value - pad + hOutside;
    }
  }

  return {
    adaptBounding
  };
};

export default useAdaptBounding;
