
import { defineComponent } from 'vue';
import BCXPanel from '@/components/molecules/BCXPanel.vue';

export default defineComponent({
  name: 'SettingsPopup',
  components: { BCXPanel },
  props: {
    width: {
      type: String,
      default: '358px'
    }
  }
});
