
import { defineComponent } from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';

export default defineComponent({
  components: { BCXAvatar },
  props: {
    id: {
      type: String,
      required: true
    },
    fullname: {
      type: String,
      required: true
    }
  }
});
