
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import DeviceSettingsContextProvider from '@/views/virtual-pub/components/chat-room/settings/DeviceSettingsContextProvider.vue';
import {
  computed, defineComponent, ref, toRefs, watch
} from 'vue';
import { DeviceSettingsModel } from '../../models/DeviceSettings';
import DeviceSettings from '../chat-room/settings/DeviceSettings.vue';

export default defineComponent({
  name: 'VirtualPubSection',
  components: {
    BCXChevronSwitch,
    BCXCollapsible,
    DeviceSettings,
    DeviceSettingsContextProvider
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true
    },
    displaySettingsButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { isOpen } = toRefs(props);
    const { isMobileWidth } = useResponsiveness();

    const isPanelOpen = computed({
      get(): boolean {
        return isMobileWidth.value ? true : isOpen.value;
      },
      set(isOpen: boolean) {
        emit('update:isOpen', isMobileWidth.value ? true : isOpen);
      }
    });
    watch(isMobileWidth, (is) => {
      if (is) emit('update:isOpen', true);
    });

    const isAddressBarVisible = computed(() => {
      if (!isMobileWidth.value) {
        return false;
      }
      return window.innerHeight < window.screen.availHeight;
    });

    const deviceSettings = ref<DeviceSettingsModel>();
    const showDeviceSettings = ref(false);

    const openDeviceSettings = () => {
      showDeviceSettings.value = true;
    };

    const setDeviceSettings = (newDeviceSettings: DeviceSettingsModel) => {
      deviceSettings.value = newDeviceSettings;
    };

    const changeDeviceSettings = (newDeviceSettings: DeviceSettingsModel) => {
      setDeviceSettings(newDeviceSettings);
      showDeviceSettings.value = false;
    };

    const openChat = () => false;

    const handleClose = () => {
      showDeviceSettings.value = false;
    };

    return {
      isMobileWidth,
      isAddressBarVisible,
      isPanelOpen,
      showDeviceSettings,
      openDeviceSettings,
      changeDeviceSettings,
      openChat,
      handleClose
    };
  }
});
