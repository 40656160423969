
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import asyncWait from '@/utils/asyncWait';

export default defineComponent({
  name: 'Logout',
  components: { BCXMarkdown },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isFinal = route?.query?.final === '1';

    (async () => {
      await store.dispatch('logout');
      await asyncWait(500);

      window.location.href = '/';
    })();

    return { isFinal };
  }
});
