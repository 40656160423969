
import { User } from '@/models/User';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import useMessengerState from '@/state/messenger/messengerState';
import { defineComponent, toRefs } from 'vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';

export default defineComponent({
  name: 'BCXMessengerUserBanner',
  components: { BCXUserBanner, BCXIcon },
  props: {
    user: {
      type: Object as () => User,
      default: () => null,
    },
    canOpenPrivateChat: {
      type: Boolean,
    },
  },
  setup(props) {
    const { user } = toRefs(props);

    const {
      createNewChat,
    } = useMessengerState();

    const startNewChat = () => createNewChat(user.value);

    return { startNewChat };
  },
});
