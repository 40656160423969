
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import { NavigationTree } from '@/models/Navigation';
import { computed, ComputedRef, defineComponent } from 'vue';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'BCXNavigationItem',
  components: { BCXIcon },
  props: {
    value: {
      type: Object as () => NavigationTree,
      default: () => ({} as NavigationTree),
    },
    level: {
      type: Number as () => number,
      default: 0,
    },
  },
  setup(props) {
    const { value } = props as { value: NavigationTree }; // value is already a reactive object
    const { isMobileLayout } = useResponsiveness();
    const store = useStore();
    const user = computed(() => store.getters.user);

    const addedClass: ComputedRef<{ [key: string]: boolean } | null> = computed(() => (value?._class ? { [value._class]: true } : null));

    const userHasAccess = computed(() => {
      let hasAccess = true;
      if (value._permissions) {
        hasAccess = false;
        value._permissions.forEach((permission) => {
          if (user.value.permissions.includes(permission) || user.value.roles.includes(permission)) {
            hasAccess = true;
          }
        });
      }
      return hasAccess;
    });

    return {
      addedClass,
      isMobileLayout,
      userHasAccess,
    };
  },
});
