import {
  createI18nMessage, email, maxLength, minLength, required, requiredIf
} from '@vuelidate/validators';
import { Ref } from 'vue';
import useI18n from '@/mixins/useI18n';
import useConfigState from '@/state/configState';

const useBCValidators = () => {
  const { t } = useI18n();
  const { registrationConfig: config } = useConfigState();
  const getConfig = (key:string) => config.value[`user.registration.${key}`];

  const messagePath = ({ $validator }:any) => `validation.${$validator}`;

  const withI18nMessage = createI18nMessage({ t, messagePath });

  const passwordSpecialCharRegex = getConfig('password.specialCharacters.regex');
  const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
  const passwordNumericRegex = /\d/;

  const phoneRegex = /^\+[1-9][-|\d|\s]{1,28}/;

  const regex = (regex:RegExp) => (value:string) => !!value.match(regex);
  const upperLowerCase = (value:string) => value.toLowerCase() !== value && value.toUpperCase() !== value;

  const i18nRequired = withI18nMessage(required);
  const i18nRequiredIf = (fn:() => boolean) => withI18nMessage(requiredIf(fn));

  const i18nRequiredPolite = withI18nMessage(required);

  const termsValidation = {
    terms: withI18nMessage((v: boolean) => v)
  };

  const wrongCode = (codeRef:Ref<boolean>) => withI18nMessage(() => !codeRef.value);
  const wrongInviteCode = (codeRef:Ref<boolean>) => withI18nMessage(() => !codeRef.value);

  const passwordValidation = {
    required: i18nRequired,
    minLength: withI18nMessage(minLength(getConfig('password.min_length')), { withArguments: true }),
    maxLength: withI18nMessage(maxLength(getConfig('password.max_length')), { withArguments: true }),
    passwordSpecialCharacters: withI18nMessage(regex(passwordSpecialCharRegex)),
    passwordLowerUpperCaseCharacters: withI18nMessage(upperLowerCase),
    passwordNumericCharacters: withI18nMessage(regex(passwordNumericRegex))
  };

  const urlValidation = {
    required: i18nRequired,
    url: withI18nMessage(regex(urlRegex))
  };

  const phoneValidation = {
    required: i18nRequired,
    phoneCharacters: withI18nMessage(regex(phoneRegex))
  };

  const emailValidation = {
    required: i18nRequired,
    email: withI18nMessage(email)
  };

  const emailOptionalValidation = {
    email: withI18nMessage(email)
  };

  return {
    required: i18nRequired,
    requiredPolite: i18nRequiredPolite,
    requiredIf: i18nRequiredIf,
    urlValidation,
    emailValidation,
    emailOptionalValidation,
    passwordValidation,
    termsValidation,
    phoneValidation,
    wrongCode,
    wrongInviteCode
  };
};

export default useBCValidators;
