import {
  computed, ComputedRef, inject, InjectionKey, provide, ref, Ref
} from 'vue';

interface DeviceSettingsContext {
  currentMicrophone: Ref<string | undefined>;
  microphones: ComputedRef<MediaDeviceInfo[]>;
  currentCamera: Ref<string | undefined>;
  cameras: ComputedRef<MediaDeviceInfo[]>;
  currentSpeaker: Ref<string | undefined>;
  speakers: ComputedRef<MediaDeviceInfo[]>;
}

const DeviceSettingsContextKey: InjectionKey<DeviceSettingsContext> = Symbol('DEVICE_SETTINGS_CONTEXT');

const useDeviceSettingsContextProvider = (state: DeviceSettingsContext) => provide(DeviceSettingsContextKey, state);

// provide default value to fix ts undefined error
const useDeviceSettingsContext = () => inject(DeviceSettingsContextKey, {
  currentCamera: ref(),
  currentMicrophone: ref(),
  microphones: computed(() => []),
  cameras: computed(() => []),
  currentSpeaker: ref(),
  speakers: computed(() => [])
});

export { DeviceSettingsContext, useDeviceSettingsContext, useDeviceSettingsContextProvider };
