
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { Invitee, InviteStatus } from '@/models/Invitation';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'InviteeStatus',
  props: {
    invitee: {
      type: Object as PropType<Invitee | null>,
      default: null
    }
  },
  setup(props) {
    const store = useStore();
    const { invitee } = toRefs(props);
    const { getFormattedDate } = useFormattedDateTime();

    const hasSucceeded = computed(() => invitee.value?.status === InviteStatus.SUCCESSFUL);
    const hasFailed = computed(() => invitee.value?.status === InviteStatus.NOT_SUCCESSFUL);

    const memberSince = computed(() => {
      const date = store.getters['userStates/getUserInfo'](invitee.value?.invitedUserId)?.memberSince;
      return date ? getFormattedDate(date) : '?';
    });
    return {
      hasSucceeded,
      hasFailed,
      memberSince
    };
  }
});
