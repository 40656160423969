
import Vue from 'vue';
import BCXRadio from '@/components/molecules/forms/BCXRadio.vue';
import useMessengerState from '@/state/messenger/messengerState';
import BCXPanel from '@/components/molecules/BCXPanel.vue';

export default Vue.extend({
  name: 'BCXMessengerSortPanel',
  components: { BCXPanel, BCXRadio },
  setup() {
    const {
      sortDir, sortBy,
    } = useMessengerState();

    return {
      sortBy,
      sortDir,
    };
  },
});
