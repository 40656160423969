
import { getChatUnreadMessageCount } from '@/state/messenger/useGroupsUnreadMessages';
import useMessengerState from '@/state/messenger/messengerState';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BCXMessengerFilters',
  setup() {
    const { groupFilters: filters, chats } = useMessengerState();

    const toggle = (selectedKey:string) => {
      Object.keys(filters as any).forEach((key) => { (filters as any)[key] = key === selectedKey; });
    };

    const unreadCounter = computed<Record<string, number>>(() => {
      const readCounter: Record<string, number> = {};
      chats.value.forEach((chat) => {
        const key = chat.type;
        readCounter[key] = (readCounter[key] ?? 0) + getChatUnreadMessageCount(chat);
      });
      return readCounter;
    });

    // inclusive toggle:

    // const toggle = (key:string) => {
    //   if (key === 'all') {
    //     Object.keys(filters as any).forEach((key) => { filters[key] = key === 'all'; });
    //   } else {
    //     filters[key] = !filters[key];
    //     filters.all = !Object.values(filters).includes(true);
    //   }
    // };

    return {
      toggle,
      filters,
      unreadCounter
    };
  },
});
