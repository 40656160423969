import { BannerUserState, State } from '@/models/UserState';
import axios from '@/plugins/axios';
import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { REFRESH_INTERVAL_BANNER_FULL_STATUS_TIMES, REFRESH_INTERVAL_BANNER_ONLINE_STATUS } from '@/models/IntervalTimes';
import { logError } from '@/utils/logger';
import store, { RootState } from '..';

export const namespaced = true;

const usersLastRefreshed = new Map<string, number>();
const usersRefreshedTimes = new Map<string, number>();

export const state: State = {
  userStates: {},
};

export const getters: GetterTree<State, RootState> = {
  getUserInfo: (state) => (userId: string): BannerUserState => {
    // store.commit('userStates/addUserOnCurrentPage', userId);
    if (state.userStates[userId]) {
      // We have cached data
      // console.debug(`[userStates]: Returned cached data for user: ${state.userStates[userId].firstname} ${state.userStates[userId].lastname}`);
      // Just dispatch a check, if the data needs an update
      store.dispatch('userStates/checkCacheTime', userId);
    } else {
      // Create cached data
      // console.debug(`[userStates]: Requesting user infos and creating cached data for userId: ${userId}`);
      // Init empty state, so we can return already
      store.commit('userStates/initUserState', userId);
      // Load user data async, the store will updated later
      // We are reactive because this is a computed value
      store.dispatch('userStates/requestUserInfos', userId);
    }
    return state.userStates[userId];
  }
};

export const mutations: MutationTree<State> = {
  initUserState(state, userId) {
    Vue.set(state.userStates, userId, {
      userId: '',
      firstname: '',
      lastname: '',
      fullname: '',
      isOnline: false,
      cookies: 0,
      awards: [],
      lastActive: '',
      memberSince: '',
      reputationPoints: 0,
    });
    usersLastRefreshed.set(userId, new Date().getTime());
    usersRefreshedTimes.set(userId, 1);
  },
  setUserState(state, { userId, userState }) {
    if (userState.firstname && userState.lastname) {
      Object.assign(userState, {
        fullname: `${userState.firstname ?? ''} ${userState.lastname ?? ''}`
      });
    }
    Object.assign(state.userStates[userId], userState, { userId });
  }
};

function getUserState(userId:string, commit: any, includeCache = false, forceAll = false) {
  const onlyOnlineStatus = !includeCache && !forceAll;
  const params = onlyOnlineStatus
    ? 'field=ONLINESTATUS'
    : 'field=ONLINESTATUS&field=COOKIES&field=REPPOINTS&field=AWARDS';
  axios.get(`/v1/users/${userId}/banner?${params}`)
    .then((response) => {
      if (Object.keys(response.data).length > 0) {
        const { data } = response;

        const userStateData = onlyOnlineStatus ? {
          isOnline: data.isOnline
        } : data;

        commit('setUserState', {
          userId,
          userState: {
            ...userStateData,
            ...includeCache && { CACHE_TIME: new Date().getTime() }
          }
        });
      }
    });
}

export const actions: ActionTree<State, RootState> = {
  requestUserInfos({ commit }, userId) {
    // User not yet found in userStates, lets request and save it
    getUserState(userId, commit, true);
  },
  requestOnlineStatus({ commit, state }, userId) {
    // Refresh online status of user
    const user = state.userStates?.[userId];
    if (!user) {
      logError('Oopsie user not found! :-(');
      return;
    }
    const now = new Date().getTime();
    const lastRefreshed = usersLastRefreshed.get(userId) ?? 0;
    if (now < lastRefreshed + REFRESH_INTERVAL_BANNER_ONLINE_STATUS) return;
    usersLastRefreshed.set(userId, now);
    const refreshedTimes = usersRefreshedTimes.get(userId) ?? 0;
    const forceAll = refreshedTimes % REFRESH_INTERVAL_BANNER_FULL_STATUS_TIMES === 0;
    getUserState(userId, commit, false, forceAll);
    usersRefreshedTimes.set(userId, refreshedTimes + 1);
  },
  checkCacheTime({ state, dispatch }, userId) {
    const CACHE_EXPIRE_TIME = 1000 * 60 * 60;
    // If there is no cache time yet, just take now, as it is just getting created
    const cacheTime = state.userStates[userId].CACHE_TIME ?? new Date().getTime();
    const diff = new Date().getTime() - cacheTime;
    if (diff > CACHE_EXPIRE_TIME) {
      dispatch('requestUserInfos', userId);
    }
  },
};
