import { Ref } from 'vue';

const useMultiLineBreaks = (lineBreaks: Record<string, string>, currentLineBreak: Ref<string>) => {
  const tl = (text: string, useBr = false) => {
    if (!text || typeof text !== 'string') return '';
    const br = useBr ? '<br>' : '\n';
    Object.entries(lineBreaks).forEach(([key, lineBreak]) => {
      text = text.replaceAll(lineBreak, key === currentLineBreak.value ? br : '');
    });
    return text;
  };

  return { tl };
};

export default useMultiLineBreaks;
