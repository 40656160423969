import { traverseMessagesInGroups } from '@/state/messenger/traverseHelpers';
import { Group } from '@/models/Messenger';

const getLatestChatFromGroups = (groups:Group[]) => {
  let latestCreated:string | null = null;
  let latestChatId = '';
  let latestGroupId = '';
  traverseMessagesInGroups(groups, (message, chat, group) => {
    if (latestCreated === null) {
      latestCreated = message.created;
    } else if (message.created > latestCreated) {
      latestCreated = message.created;
      latestChatId = chat?.id ?? '';
      latestGroupId = group?.id ?? '';
    }
  });

  return { latestChatId, latestGroupId };
};

export default getLatestChatFromGroups;
