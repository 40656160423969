
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import BCXExtendedDropdown from '@/components/molecules/forms/BCXExtendedDropdown.vue';
import BCXTextArea from '@/components/molecules/forms/BCXTextArea.vue';
import { SmileyTypes } from '@/components/svg-components/Smiley.vue';
import X from '@/components/svg-components/X.vue';
import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import {
  ExternalRecommendation,
  RecommendationAction,
  RecommendationFeedbackRequest,
  RecommendationFeedbackType,
  RecommendationPreference
} from '@/models/Recommendations';
import RecommendationService from '@/services/RecommendationService';
import {
  computed, defineComponent, nextTick, onMounted, PropType, ref, toRefs, watch
} from 'vue';
import { templateRef, useDraggable } from '@vueuse/core';
import useAdaptBounding from '@/mixins/useAdaptBounding';
import SmileyButtons from '@/components/molecules/SmileyButtons.vue';
import useBodyScroll from '@/mixins/useBodyScroll';
import onSizeChange from '@/mixins/onSizeChange';
import { logDebug, logError } from '@/utils/logger';

export default defineComponent({
  name: 'RecommendationFeedbackPopUp',
  components: {
    SmileyButtons,
    BCXAvatar,
    X,
    BCXTextArea,
    BCXCheckbox,
    BCXExtendedDropdown
  },
  props: {
    recommendation: {
      type: Object as PropType<ExternalRecommendation>,
      required: true
    },
    afterAction: {
      type: String as PropType<RecommendationAction>,
      default: ''
    }
  },
  emits: ['close', 'feedback-sent'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isMobileLayout } = useResponsiveness();
    const { restore: restoreBodyScroll, hideOrRemove: hideOrRemoveBodyScroll } = useBodyScroll();

    const node = templateRef<HTMLElement>('node');

    const { recommendation, afterAction } = toRefs(props);

    const { x: dragX, y: dragY } = useDraggable(node, {
      initialValue: { x: 0, y: 0 },
      onEnd() {
        // eslint-disable-next-line no-use-before-define
        adaptBounding(0.75);
      }
    });

    const { adaptBounding } = useAdaptBounding(node, dragX, dragY, 0);

    const headerTitle = t('recommendations.feedback.title');
    const closeModal = () => {
      emit('close');
      restoreBodyScroll();
    };

    const openerQuestion = computed(() => {
      let fnameText = recommendation.value?.recommendingUser?.firstname ?? '[unknown]';

      if (!['s', 'z'].includes(fnameText[fnameText.length - 1])) {
        fnameText += '';
      }

      return t('recommendations.feedback.opener', { fname: fnameText });
    });

    const updatePosition = () => {
      if (node.value?.parentElement) {
        const { width = 0, left = 0 } = node.value?.parentElement.getBoundingClientRect() ?? {};
        const { width: nodeWidth } = node.value.getBoundingClientRect();
        dragX.value = left + (width / 2 - nodeWidth / 2);
      }
    };

    onSizeChange(node, ({ height: nodeHeight }) => {
      if (!dragY.value) {
        const height = window.innerHeight;
        dragY.value = (height - nodeHeight) * 0.33;
      } else {
        adaptBounding(0.75);
      }
    });

    const style = computed(() => (isMobileLayout.value ? {} : {
      // left: `${offsetLeft.value}px`,
      left: `${dragX.value}px`,
      top: `${dragY.value}px`
    }));

    const selectedFeedback = ref<SmileyTypes | ''>('');
    const userFeedback = computed<RecommendationFeedbackType>(() => {
      if (selectedFeedback.value === SmileyTypes.Good) {
        return RecommendationFeedbackType.Positive;
      }
      if (selectedFeedback.value === SmileyTypes.Bad) {
        return RecommendationFeedbackType.Negative;
      }
      return RecommendationFeedbackType.Neutral;
    });

    watch(selectedFeedback, (newValue) => {
      hideOrRemoveBodyScroll(!!newValue);
    });

    const responseMessage = ref('');
    const textAreaHeight = computed(() => {
      if (isMobileLayout.value) {
        return 96;
      }
      return 118;
    });

    const reasonSkills = ref(false);
    const reasonProjectPreferences = ref(false);
    const blockRecommendations = ref(false);

    watch(selectedFeedback, (feedback) => {
      if (feedback !== SmileyTypes.Bad) blockRecommendations.value = false;
    });

    const whichItems = computed(() => {
      const preferences = { ...(t('recommendations.feedback.not-fitting-preferences') as any) };
      return Object.keys(preferences).map((value) => ({
        value,
        label: preferences[value]
      }));
    });
    const whichPlaceholder = t('recommendations.feedback.which-placeholder') as string;
    const whichItemSelected = ref<RecommendationPreference | undefined>(undefined);
    const whichHasNoSelection = ref(false);
    const resetWhichHasNoSelection = () => {
      logDebug('resetWhichHasNoSelection');
      whichHasNoSelection.value = false;
    };

    const sendButtonDisabled = computed(() => !selectedFeedback.value || (
      (selectedFeedback.value === SmileyTypes.Neutral || selectedFeedback.value === SmileyTypes.Bad)
      && !reasonSkills.value
      && !(reasonProjectPreferences.value && whichItemSelected.value)
    ));

    const preferencesOptionselected = computed(() => reasonProjectPreferences.value);

    const sendFeedback = async () => {
      if (selectedFeedback.value) {
        const feedback: RecommendationFeedbackRequest = {
          notFittingMyPreferences: reasonProjectPreferences.value,
          notFittingMySkills: reasonSkills.value,
          userFeedback: userFeedback.value,
          userFeedbackMessage: responseMessage.value,
          userIdToBlock: blockRecommendations.value ? recommendation.value?.recommendingUser?.userId : undefined
        };
        if (reasonProjectPreferences.value && !whichItemSelected.value) {
          whichHasNoSelection.value = true;
          return;
        }
        if (whichItemSelected.value) {
          feedback.notFittingPreferencesList = [whichItemSelected.value];
        }
        await RecommendationService.sendRecommendationFeedback(recommendation.value?.externalId, feedback).catch((ex) => {
          logError(ex);
        });

        switch (afterAction.value) {
          case 'add':
            await RecommendationService.acceptRecommendation(recommendation.value?.externalId);
            break;
          case 'remove':
            await RecommendationService.rejectRecommendation(recommendation.value?.externalId);
            break;
          default:
        }

        emit('feedback-sent', {
          feedback: selectedFeedback.value,
          id: recommendation.value?.externalId
        });
      }
      setTimeout(closeModal, 500);
    };

    onMounted(() => {
      nextTick(() => {
        updatePosition();
      });
      window.addEventListener('resize', updatePosition);
    });

    return {
      t,
      selectedFeedback,
      style,
      headerTitle,
      closeModal,
      SmileyTypes,
      openerQuestion,
      responseMessage,
      textAreaHeight,
      reasonSkills,
      reasonProjectPreferences,
      preferencesOptionselected,
      blockRecommendations,
      whichItems,
      whichPlaceholder,
      whichItemSelected,
      sendButtonDisabled,
      sendFeedback,
      resetWhichHasNoSelection,
      whichHasNoSelection
    };
  }
});
