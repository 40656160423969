import axios from '@/plugins/axios';
import { LatestUsersDashboard, UserProfileStatus } from '@/views/home/models/Dashboard';

export default {
  async getLatestUsers(): Promise<LatestUsersDashboard> {
    /**
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Dashboard-Endpoint/get_api_v1_dashboard_users_latest
     */
    return axios.get('/v1/dashboard/users/latest').then(({ data }) => data);
  },
  async getUserProfileStatus(): Promise<UserProfileStatus> {
    /**
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Dashboard-Endpoint/get_api_v1_dashboard_users_user_profile_status
     */
    return axios.get('/v1/dashboard/users/user/profile-status').then(({ data }) => data);
  }
};
