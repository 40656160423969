
import Vue, {
  computed, nextTick, ref, watch, watchEffect
} from 'vue';
import ThreadTopicSkelleton from '@/views/forum/components/ThreadTopicSkeleton.vue';
import ThreadTopicTitle from '@/views/forum/components/ThreadTopicTitle.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import TagList from '@/components/tagging/TagList.vue';
import ThreadEntry from '@/views/forum/components/ThreadEntry.vue';
import CreateMessage from '@/views/forum/components/CreateMessage.vue';
import IconEdit from '@/components/svg/edit-circle.svg?inline';
import { ForumMaxChars } from '@/views/forum/models/Forum';
import { RouterEntry } from '@/models/Router';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useIsLeavingDirty from '@/utils/isLeavingDirty';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';

export default Vue.extend({
  name: 'ThreadTopic',
  components: {
    ThreadTopicSkelleton,
    ThreadTopicTitle,
    ThreadEntry,
    CreateMessage,
    BCXBreadcrumbs,
    TagList,
    IconEdit,
    BCXMarkdown,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const userScore = ref(800);
    const editTopicMessage = ref(false);
    const editTopicTitle = ref(false);
    const editTitleInput = ref<HTMLElement|null>(null);
    const title = ref('');
    const maxTitleLength = ref(ForumMaxChars.TALK_TITLE);

    const thread = computed(() => store.state.forum.thread);
    const getForumTitle = computed(() => store.getters['forum/getForumTitle']);
    const getForumId = computed(() => store.getters['forum/getForumId']);
    const getGroupId = computed(() => store.getters['forum/getGroupId']);
    const getThreadTopic = computed(() => store.getters['forum/getThreadTopic']);
    const getThreadTitle = computed(() => store.getters['forum/getThreadTitle']);
    const user = computed(() => store.getters['user']);
    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);

    const breadcrumbItems = computed(() => {
      const breadcrumbItems: Array<RouterEntry> = [
        {
          text: t('breadcrumb.community') as string,
        },
        {
          text: t('forum.labels.threads.plural') as string,
          to: { name: 'forums' }
        },
        {
          text: getForumTitle.value,
          to: { name: 'threads', params: { forumId: getForumId.value, groupId: getGroupId.value, threadId: thread.value.threadId } }
        }
      ];
      if (isMobileLayout.value) {
        // display logo as first item
        breadcrumbItems[0].text = 'ByteCookie';
      }
      return breadcrumbItems;
    });

    const userIsCreator = computed(() => getThreadTopic.value && thread.value.creator.userId === user.value.userId);
    const showEditButton = computed(() => (userIsCreator.value
        || user.value.roles.includes('FORUM_MODERATOR')
      || user.value.roles.includes('PROJECT_MODERATOR')) && !editTopicTitle.value);
    const editTitleSaveButtonEnabled = computed(() => title.value.trim().length > 0);
    const backLink = computed(() => ({ name: 'threads', params: { 'forum-id': getForumId.value, threadId: thread.value.threadId } }));
    const charactersLeft = computed(() => {
      const charactersLeft = maxTitleLength.value - title.value.length;
      if (charactersLeft > 0) {
        return charactersLeft;
      }
      return 0;
    });

    const updateThreadMessage = (message: any) => store.dispatch('forum/updateThreadMessage', message);
    const toggleEditMessage = () => {
      editTopicMessage.value = !editTopicMessage.value;
    };
    const postMessage = (postMessage: any) => {
      emit('post-message', postMessage);
    };
    const postMessageEdit = (text: string) => {
      const postMessage = {
        forumId: getForumId.value,
        threadId: thread.value.threadId,
        forumThreadMessageId: getThreadTopic.value.messageId,
        messageContent: text
      };
      updateThreadMessage({ postMessage, messageCount: 'topic' }).then(() => {
        emit('updated-message', title.value);
        toggleEditMessage();
      });
    };

    const toggleEditTitle = () => {
      editTopicTitle.value = !editTopicTitle.value;
    };
    const saveTitle = () => {
      const trimmedTitle = title.value.trim();
      if (trimmedTitle.length > 0) {
        emit('update-topic', trimmedTitle);
        toggleEditTitle();
      }
    };

    watch(() => getThreadTitle.value, (newValue: any) => {
      if (newValue) {
        title.value = newValue;
      }
    });

    watch(() => title.value, (newValue:any) => {
      title.value = newValue.replace(/[^\u00C0-\u017FA-Za-z\d \-.,@$€%=?!()[\]/]+/, '');
    });

    watch(() => editTopicTitle.value, (newValue: any) => {
      if (newValue) {
        nextTick(() => {
          const el = (editTitleInput.value as HTMLInputElement);
          el.focus();
          el.value = getThreadTitle.value;
        });
      }
    });

    const {
      isLeavingDirty, onConfirm, isLeavingMessage, isDirty
    } = useIsLeavingDirty();
    watchEffect(() => {
      isDirty.value = (editTopicMessage.value || editTopicTitle.value);
    });

    return {
      breadcrumbItems,
      userScore,
      editTopicMessage,
      editTopicTitle,
      title,
      thread,
      getForumTitle,
      getForumId,
      getGroupId,
      getThreadTopic,
      getThreadTitle,
      user,
      userIsCreator,
      showEditButton,
      editTitleSaveButtonEnabled,
      backLink,
      charactersLeft,
      isMobileLayout,
      isLeavingDirty,
      isLeavingMessage,
      onConfirm,
      maxTitleLength,
      editTitleInput,
      updateThreadMessage,
      toggleEditMessage,
      postMessage,
      postMessageEdit,
      toggleEditTitle,
      saveTitle
    };
  }
});
