import _axios from '@/plugins/axios';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/bar/uniqueRoomName';

const useLeaveBarChat = () => {
  function getEndpoint(uniqueRoomName: string) {
    return ENDPOINT.replace('uniqueRoomName', uniqueRoomName);
  }

  const useLeaveBarAxios = useAxios(ENDPOINT, { method: 'DELETE' }, _axios, { immediate: false });

  async function leaveBarChat(uniqueRoomName: string) {
    await useLeaveBarAxios.execute(getEndpoint(uniqueRoomName), { method: 'DELETE' });
  }

  return {
    leaveBarChat,
    ...useLeaveBarAxios
  };
};

export default useLeaveBarChat;
