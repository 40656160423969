
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import { Settings, SettingsVerificationType } from '@/models/Settings';
import { setUserData } from '@/services/Settings/Api';
import SalutationSetting from '@/views/settings/components/settings/SalutationSetting.vue';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import useUpdateTools from '@/views/settings/utils/useUpdateTools';
import { reactive } from 'vue';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'UserDataSetting',
  components: {
    BackendErrors,
    SalutationSetting,
    ActionButtons,
    BCXValidatedInput
  },
  setup() {
    const fields = reactive({
      firstname: '',
      lastname: ''
    });

    const setBySettings = (settings: Settings | null) => {
      const userData = settings?.userDataSettings;
      fields.firstname = userData?.firstname ?? '';
      fields.lastname = userData?.lastname ?? '';
    };

    const { required } = useBCValidators();

    const rules = {
      firstname: { required },
      lastname: { required }
    };

    const v = useVuelidate(rules, fields);

    const fnSave = () => setUserData(fields);

    const {
      isLoading,
      save,
      reset,
      backendErrors,
      isDirty,
    } = useUpdateTools(v, fnSave, setBySettings, SettingsVerificationType.FirstAndLastName);

    return {
      save,
      isLoading,
      fields,
      reset,
      backendErrors,
      v,
      isDirty
    };
  }
};
