
import { computed, defineComponent } from 'vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import useI18n from '@/mixins/useI18n';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';
import SearchGraphic from '@/components/svg/search-graphic.svg?inline';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  components: { BCXHelpTextTriggerIcon, BCXBreadcrumbs, SearchGraphic },
  props: {
    isInlineHelpOpen: Boolean
  },
  setup(props) {
    const { t } = useI18n();
    const isInlineHelpOpenVM = useVModel(props, 'isInlineHelpOpen');

    const breadcrumbItems = computed(() => {
      const breadcrumbArray: any = [
        {
          text: 'ByteCookie',
          to: { name: 'home' }
        },
        {
          text: t('user-search.title'),
          to: { name: 'user-search' }
        }
      ];
      return breadcrumbArray;
    });

    return {
      breadcrumbItems,
      isInlineHelpOpenVM
    };
  }

});
