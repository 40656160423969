
import Vue, { computed, PropType } from 'vue';
import LPBgImage from '@/views/landingpage/components/LPBgImage.vue';

import BGLogoDesktop from '@/assets/img/landingpage-freelancer/FreelancerLogoDesktop.webp';
import BGLogoTablet from '@/assets/img/landingpage-freelancer/FreelancerLogoTablet.webp';
import BGLogoSmartphone from '@/assets/img/landingpage-freelancer/FreelancerLogoSmartphone.webp';

import BGCircleDesktop from '@/assets/img/landingpage-freelancer/FreelancerCircleDesktop.webp';
import BGCircleTablet from '@/assets/img/landingpage-freelancer/FreelancerCircleTablet.webp';
import BGCircleSmartphone from '@/assets/img/landingpage-freelancer/FreelancerCircleSmartphone.webp';

import BGFeaturesDesktop from '@/assets/img/landingpage-freelancer/FreelancerFeaturesDesktop.webp';
import BGFeaturesTablet from '@/assets/img/landingpage-freelancer/FreelancerFeaturesTablet.webp';
import BGFeaturesSmartphone from '@/assets/img/landingpage-freelancer/FreelancerFeaturesSmartphone.webp';

import BGPeopleDesktop from '@/assets/img/landingpage-freelancer/FreelancerPeopleDesktop.webp';
import BGPeopleTablet from '@/assets/img/landingpage-freelancer/FreelancerPeopleTablet.webp';
import BGPeopleSmartphone from '@/assets/img/landingpage-freelancer/FreelancerPeopleSmartphone.webp';
import { computedEager } from '@vueuse/core';
import useFreelancerLandingPage from '@/views/landingpage/components/freelancer/useFreelancerLandingPage';

const bgsLogo = [BGLogoDesktop, BGLogoTablet, BGLogoSmartphone];
const bgsCircle = [BGCircleDesktop, BGCircleTablet, BGCircleSmartphone];
const bgsFeatures = [BGFeaturesDesktop, BGFeaturesTablet, BGFeaturesSmartphone];
const bgsPeople = [BGPeopleDesktop, BGPeopleTablet, BGPeopleSmartphone];

export default Vue.extend({
  components: { LPBgImage },
  props: {
    image: {
      type: String as PropType<'logo' | 'circle' | 'features' | 'people'>,
      required: true
    }
  },
  setup(props) {
    const { backgroundNr } = useFreelancerLandingPage();

    const bgLogo = computedEager(() => bgsLogo[backgroundNr.value]);
    const bgCircle = computedEager(() => bgsCircle[backgroundNr.value]);
    const bgFeatures = computedEager(() => bgsFeatures[backgroundNr.value]);
    const bgPeople = computedEager(() => bgsPeople[backgroundNr.value]);

    const src = computed(() => {
      switch (props.image) {
        case 'logo': return bgLogo.value;
        case 'circle': return bgCircle.value;
        case 'features': return bgFeatures.value;
        case 'people': return bgPeople.value;
        default: return '';
      }
    });

    return { src };
  }
});
