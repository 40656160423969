import { TranslatedErrorCode } from '@/models/BackendError';
import router from '@/router';
import RegistrationService, { RegisterBasicDataRequest } from '@/services/RegistrationService';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import moment from 'moment';
import { Module } from 'vuex';
import { UserType } from '@/models/User';
import { getUserType } from '@/utils/user';

type RegistrationStep = 'register-verify' | 'register-mobilephone' | 'register-mobilephone-verify';
type RegistrationStepPlain = 'MOBILEPHONE' | 'MOBILEPHONE_VERIFIED';

interface State {
  registrationStep: RegistrationStep | null;
  registrationStepPlain: RegistrationStepPlain | null;
  registrationProcessId?: string;
  inviteCode: string;
  mobileNumber: string;
  expiresAt: string | null;
  isPasswordAlreadyGiven: boolean;
}

function getExpireTime() {
  return moment()
    .add(process.env.VUE_APP_REGISTRATION_EXPIRES_AFTER, 'minutes')
    .format();
}

const module: Module<State, any> = {
  state: {
    registrationStep: localStorage.getItem('registration_step') as RegistrationStep,
    registrationStepPlain: localStorage.getItem('registration_step_plain') as RegistrationStepPlain,
    registrationProcessId: localStorage.getItem('registration_process_id') ?? undefined,
    inviteCode: localStorage.getItem('invite_code') ?? '',
    mobileNumber: localStorage.getItem('mobile_number') ?? '',
    expiresAt: localStorage.getItem('expires_at'),
    isPasswordAlreadyGiven: false,
  },
  getters: {
    registrationStep: (state) => state.registrationStep,
    registrationStepPlain: (state) => state.registrationStepPlain,
    registrationStepPlainName: (state) => {
      switch (state.registrationStepPlain) {
        case 'MOBILEPHONE_VERIFIED':
        case 'MOBILEPHONE': return 'register-mobilephone';
        default:
      }
      return 'index';
    },
    registrationProcessId: (state) => state.registrationProcessId,
    inviteCode: (state) => state.inviteCode,
    mobileNumber: (state) => state.mobileNumber,
    expiresAt: (state) => state.expiresAt,
  },
  mutations: {
    setPasswordAlreadyGiven(state, is) {
      state.isPasswordAlreadyGiven = is;
    },

    registrationProcessId(state, data) {
      state.registrationProcessId = data.registrationProcessId;
      localStorage.setItem('registration_process_id', data.registrationProcessId);
    },

    registrationStep(state, data) {
      state.registrationStep = data.registrationStep;
      localStorage.setItem('registration_step', data.registrationStep);
    },

    registrationStepPlain(state, data) {
      state.registrationStepPlain = data.registrationStepPlain;
      localStorage.setItem('registration_step_plain', data.registrationStepPlain);
    },

    inviteCode(state, data) {
      state.inviteCode = data.inviteCode;
      localStorage.setItem('invite_code', data.inviteCode);
    },

    mobileNumber(state, data) {
      state.mobileNumber = data.mobileNumber;
      localStorage.setItem('mobile_number', data.mobileNumber);
    },

    expiresAt(state, data) {
      state.expiresAt = data.expiresAt;
      localStorage.setItem('expires_at', data.expiresAt);
    },

    removeRegistrationData(state) {
      ['registration_step',
        'registration_step_plain',
        'registration_process_id',
        'invite_code',
        'mobile_number',
        'expires_at'].forEach((key) => localStorage.removeItem(key));
      Object.assign(state, {
        registrationStep: null,
        registrationStepPlain: null,
        registrationProcessId: undefined,
        inviteCode: '',
        mobileNumber: '',
        isAlreadyRegistered: false,
        expiresAt: null,
      });
    },
  },
  actions: {
    async submitBasicData({
      commit,
      getters
    }, params: RegisterBasicDataRequest): Promise<TranslatedBackendErrors> {
      const response = await RegistrationService.registerBasicData({
        ...params,
        registrationProcessId: getters.registrationProcessId
      }).catch(({ response }) => response);

      const backendErrors = new TranslatedBackendErrors(response);

      const { data, status } = response;

      if (!backendErrors.hasErrors()) {
        const {
          user,
          registrationProcessId,
          user: { userId = '' } = {}
        } = data;

        if (status === 202) {
          if (data.returnCode === 'BC-USR-100') {
            commit('setUser', user);
            commit('registrationProcessId', { registrationProcessId });
            commit('expiresAt', { expiresAt: getExpireTime() });
            await router.push({
              name: 'register-verify',
              query: {
                pid: registrationProcessId,
                uid: userId
              }
            });
          } else {
            backendErrors.setError(TranslatedErrorCode.EMAIL_NOT_UNIQUE);
          }
        } else {
          commit('registrationProcessId', { registrationProcessId });
          commit('registrationStep', { registrationStep: 'register-verify' });
          commit('inviteCode', { inviteCode: params.inviteCode ?? '' });
          commit('setUser', user);
          commit('expiresAt', { expiresAt: getExpireTime() });
          await router.push({
            name: 'register-verify',
            query: {
              pid: registrationProcessId,
              uid: userId
            }
          });
        }
      }

      return backendErrors;
    },

    async submitVerificationData({ commit }, params: {
      verificationCode: string;
      password: string;
      registrationProcessId: string;
      userId: string;
    }):Promise<TranslatedBackendErrors> {
      const response = await RegistrationService.registerSecondStep(
        params.verificationCode,
        params.password,
        params.userId,
        params.registrationProcessId,
      );

      const backendErrors = new TranslatedBackendErrors(response);

      if (!backendErrors.hasErrors()) {
        const {
          data: { user },
          headers: { authorization: token, refresh_token: refreshToken }
        } = response;
        commit('registrationStep', { registrationStep: 'register-mobilephone' });
        commit('setUser', user);
        commit('authSuccess', { token, refreshToken });
        commit('expiresAt', { expiresAt: getExpireTime() });
        await router.push({ name: 'register-mobilephone' });
      } else if (backendErrors.hasError(TranslatedErrorCode.PASSWORD_ALREADY_GIVEN)) {
        commit('setPasswordAlreadyGiven', true);
        await router.push({ name: 'login' });
      }

      return backendErrors;
    },

    async submitMobilephone({ commit }, mobileNumber: string):Promise<TranslatedBackendErrors> {
      const response = await RegistrationService.submitMobilephone(mobileNumber);
      const backendErrors = new TranslatedBackendErrors(response);
      if (!backendErrors.hasErrors()) {
        commit('registrationStep', { registrationStep: 'register-mobilephone-verify' });
        commit('mobileNumber', { mobileNumber });
        commit('expiresAt', { expiresAt: getExpireTime() });
        await router.push({ name: 'register-mobilephone-verify' });
      }
      return backendErrors;
    },

    async verifyMobilephone({ commit }, verificationCode: string) {
      const response = await RegistrationService.verifyMobilephone(verificationCode);
      const backendErrors = new TranslatedBackendErrors(response);
      if (!backendErrors.hasErrors()) {
        commit('removeRegistrationData');
        commit('expiresAt', { expiresAt: getExpireTime() });
        commit('setPasswordAlreadyGiven', false);
        await router.push({ name: 'register-finished' });
      }
      return backendErrors;
    },
  },
};

export default module;
