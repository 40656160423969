import { BaseUserWithRole, User } from '@/models/User';
import axios from '@/plugins/axios';
import {
  Chat, ChatMeta, ChatMetaObject, ChatStub, PostMessageRequest, PostToForumRequest, SendMessageResponse, UpdateMessageRequest
} from '@/models/Messenger';
import { AxiosError } from 'axios';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';

export default {
  async getChatStubs():Promise<ChatStub[]> {
    const response = await axios.get('/v1/messaging/chats');
    return response.data;
  },
  async getChatDetails(id:string):Promise<Chat | ChatStub> {
    const response = await axios.get(`/v1/messaging/chats/${id}`);
    return response.data;
  },
  async getChatsMeta():Promise<ChatMetaObject | null> {
    const response = await axios.get('/v1/messaging/chats/meta');
    const meta: ChatMeta = response.data;
    if (!meta?.numberOfMessagesList) return null;
    return Object.fromEntries(meta.numberOfMessagesList.map(({ chatId, numberOfMessage, numberOfEdits = 0 }) => ([chatId, {
      messages: numberOfMessage, edited: numberOfEdits
    }])));
  },
  async removeChat(id:string):Promise<void> {
    await axios.delete(`/v1/messaging/chats/${id}`);
  },
  async postMessageToForum(forumTalkGroupId: string, data: PostToForumRequest): Promise<SendMessageResponse> {
    data.messageContent = BCXHTMLSanitizer.beforeSave(data.messageContent);
    const response = await axios.post(
      `/v1/messaging/forums/${data.forumId}/groups/${forumTalkGroupId}/threads/${data.forumThreadId}/messages`,
      data
    ).catch((err:AxiosError) => ({ data: err.response?.data }));
    return response.data;
  },
  async postMessage(data: PostMessageRequest): Promise<SendMessageResponse> {
    data.content = BCXHTMLSanitizer.beforeSave(data.content);
    const response = await axios.post('/v1/messaging/chats', data).catch((err:AxiosError) => ({ data: err.response?.data }));
    return response.data;
  },
  async updateMessage(chatId: string, messageId: string, data: UpdateMessageRequest): Promise<any> {
    data.messageContent = BCXHTMLSanitizer.beforeSave(data.messageContent);
    const response = await axios.put(`v1/messaging/chats/${chatId}/messages/${messageId}`, data).catch((err:AxiosError) => ({ data: err.response?.data }));
    return response.data;
  },
  async markMessageAsRead(messageId:string): Promise<boolean> {
    const response = await axios.post(`/v1/messaging/messages/${messageId}/read`);
    return response.data;
  },
  async searchChatUsers(name: string):Promise<User[]> {
    const response = await axios.get(`/v1/users/list?nameFilter=${encodeURIComponent(name)}`);
    return response.data || [];
  },
  async getChatContacts():Promise<BaseUserWithRole[]> {
    const response = await axios.get('/v1/messaging/contactlist');
    return response.data || [];
  }
};
