import DashboardInfoBox from '@/views/home/components/DashboardInfoBox.vue';
import { RouteConfig } from 'vue-router';
import HomeDashboard from '@/views/home/Dashboard.vue';
import store from '@/store';
import Home from '@/views/home/Home.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'home',
    component: HomeDashboard,
    beforeEnter: (_to, _from, next) => {
      store.dispatch('subscriptions/fetchSubscriptionFeed');
      store.dispatch('dashboard/fetchDashboardUsersList');
      store.dispatch('dashboard/fetchDashboardUserProfileStatus');
      next();
    },
    meta: {
      infoBox: {
        headline: 'infoBox.dashboard.headline',
        text: 'infoBox.dashboard.texts',
        mobileComponent: DashboardInfoBox,
      },
      auth: true,
      layout: 'full',
    },
  },
  {
    path: '/home-old',
    name: 'homeOld',
    component: Home,
    meta: {
      auth: true,
      layout: 'full',
    }
  }
];

export default routes;
