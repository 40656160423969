import DashboardService from '@/services/DashboardService';
import { shuffle } from 'lodash';
import { Module } from 'vuex';
import { LatestUserDashboard, UserProfileStatus } from '../models/Dashboard';

interface State {
  dashboardUsersList: LatestUserDashboard[];
  usersLoading: boolean;
  userProfileStatus: UserProfileStatus;
}

const module: Module<State, any> = {
  namespaced: true,
  state: {
    dashboardUsersList: [],
    usersLoading: true,
    userProfileStatus: {
      missingImage: false, missingSummary: false, missingSkills: false, missingExperiences: false
    },
  },
  getters: {
    getDashboardUsersList(state): LatestUserDashboard[] {
      return state.dashboardUsersList;
    },
    getRandomizedDashboardUsersList(state): LatestUserDashboard[] {
      return shuffle(state.dashboardUsersList);
    }
  },
  mutations: {
    setDashboardUsersList(state, payload: LatestUserDashboard[]) {
      state.dashboardUsersList = payload;
    },
    setUsersLoading(state, status: boolean) {
      state.usersLoading = status;
    },
    setUserProfileStatus(state, payload: UserProfileStatus) {
      state.userProfileStatus = payload;
    }
  },
  actions: {
    fetchDashboardUsersList({ commit }) {
      commit('setUsersLoading', true);
      DashboardService.getLatestUsers().then(({ dashboardUsersList }) => {
        commit('setDashboardUsersList', dashboardUsersList);
        commit('setUsersLoading', false);
      });
    },
    fetchDashboardUserProfileStatus({ commit }) {
      DashboardService.getUserProfileStatus().then((data) => {
        commit('setUserProfileStatus', data);
      });
    }
  }
};

export default module;
