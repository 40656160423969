
import BCExpansionPanel from '@/components/molecules/BCExpansionPanel.vue';
import Triangle from '@/components/svg-components/Triangle.vue';
import ThreadRepliesSkeleton from '@/views/forum/components/ThreadRepliesSkeleton.vue';
import { mdiChevronRight } from '@mdi/js';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import useUser from '@/mixins/useUser';
import { Message, MessagePost } from '@/views/forum/models/Forum';
import CreateMessage from './CreateMessage.vue';
import ThreadReply from './ThreadReply.vue';

export default Vue.extend({
  name: 'ThreadReplies',
  components: {
    'thread-reply': ThreadReply,
    'create-message': CreateMessage,
    'bc-expansion-panel': BCExpansionPanel,
    Triangle,
    ThreadRepliesSkeleton
  },
  props: {
    replies: {
      type: Array,
      default() {
        return [];
      }
    },
    threadCreator: {
      type: Object,
      default() {
        return {};
      }
    },
    initialMessage: {
      type: Object,
      default() {
        return {};
      }
    },
    disableReply: {
      type: Boolean,
      default: false
    },
    forumId: {
      type: String,
      default: undefined
    },
    groupId: {
      type: String,
      default: undefined
    },
    threadId: {
      type: String,
      default: undefined
    },
    editMessageId: {
      type: String,
      default: ''
    },
    sortParam: {
      type: String,
      default: 'TIMESTAMP'
    },
    sortOrder: {
      type: String,
      default: 'DESC'
    },
    numberOfReplies: {
      type: Number,
      default: 0
    },
    threadSubscribed: {
      type: Boolean,
      default: false
    },
    parentType: {
      type: String,
      default: 'FORUM_THREAD'
    }
  },

  data() {
    return {
      settings: {},
      watchThread: false,
      createMessageVisible: false,
      scrolledToMessage: false,
      mdiChevronRight,
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapState('projectForum', ['project']),
    labelReplyCount(): string {
      if (this.numberOfReplies === 1) {
        return `${this.numberOfReplies} ${this.$t('forum.labels.replies.singular')}`;
      }
      return `${this.numberOfReplies} ${this.$t('forum.labels.replies.plural')}`;
    },
    noForumReplies(): boolean {
      return this.numberOfReplies === 0;
    },
    isUserCreator(): boolean {
      return this.user.userId === this.threadCreator.userId;
    },
    isWatchSwitchDisabled(): boolean {
      return this.parentType === 'PROJECT_THREAD' && this.isUserCreator;
    },
    watchThreadTranslationKey(): string {
      if (this.parentType === 'PROJECT_THREAD') {
        return 'project-forum_USERTYPE.labels.watch-project';
      }
      return 'forum.labels.watch-thread';
    },
    isTestProject(): boolean {
      return this.project?.metaDataProject.testFlag || false;
    }
  },
  watch: {
    threadSubscribed(newValue: boolean) {
      this.watchThread = newValue;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.threadSubscribed || this.isUserCreator) {
        this.watchThread = true;
      }
    });
    window.addEventListener('scroll', () => {
      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 200;
      if (bottomOfWindow) {
        this.$emit('scrolled-to-bottom');
      }
    });
  },
  updated() {
    const { hash } = this.$route;
    this.$nextTick(() => {
      if (hash && !this.scrolledToMessage) {
        const element = document.querySelector(hash);
        const messageElement = element?.querySelector('.thread-message');
        if (element && messageElement) {
          messageElement.classList.add('scroll-highlight');
          setTimeout(() => {
            messageElement.classList.remove('scroll-highlight');
          }, 1250);
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }, 100);
          this.scrolledToMessage = true;
        }
      }
    });
  },
  methods: {
    tUser(key: string, ...params:any) {
      const { tUserFallback } = useUser('self');
      return tUserFallback(key, ...params);
    },

    hasReplies(reply: Message): boolean {
      return (reply?.subMessages?.length || 0) > 0;
    },

    isOP(userId: string): boolean {
      return this.threadCreator.userId === userId;
    },

    hasOPReply(replies: Array<Message>): boolean {
      let hasOPReply = false;
      replies.forEach((reply) => {
        if (reply.creator.userId === this.threadCreator.userId) {
          hasOPReply = true;
        }
      });
      return hasOPReply;
    },

    toggleCreateMessageVisible(): void {
      this.createMessageVisible = !this.createMessageVisible;
    },

    expansionPanelStateChange(state: string, id: string) {
      const el = document.querySelector(`#inner-replies-${id}`);
      if (el) {
        switch (state) {
          case 'open':
            el.classList.add('expansion-panel--open');
            break;
          case 'close':
          default:
            el.classList.remove('expansion-panel--open');
            break;
        }
      }
    },

    getReplyText(replyCount: number): string {
      if (replyCount === 1) {
        return `${replyCount} ${this.$t('forum.labels.replies.singular') as string}`;
      }
      return `${replyCount} ${this.$t('forum.labels.replies.plural')}`;
    },

    postMessage(postMessage: MessagePost) {
      this.$emit('post-message', postMessage);
      this.createMessageVisible = false;
    },

    firstReply(message: string) {
      const postMessage: MessagePost = {
        forumId: this.forumId,
        forumThreadId: this.threadId,
        replyToUserId: this.initialMessage.creator.userId,
        replyToMessageId: this.initialMessage.messageId,
        messageContent: message
      };
      this.postMessage(postMessage);
    },

    updateMessage(postMessage: MessagePost, messageCount: string) {
      this.$emit('update-message', postMessage, messageCount);
    },

    editMessage(editMessageId: string) {
      this.$emit('edit-message', editMessageId);
    },

    sort(sortParam: string) {
      let sortOrder = null;
      if (sortParam === this.sortParam && this.sortOrder === 'ASC') {
        sortOrder = 'DESC';
      } else if (sortParam === this.sortParam && this.sortOrder === 'DESC') {
        sortOrder = 'ASC';
      } else if (sortParam === 'VOTES') {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      this.$emit('sort-replies', sortParam, sortOrder);
    },

    isSortActive(sort: string) {
      return sort === this.sortParam ? 'active' : '';
    },
    toggleWatchThread() {
      if (this.watchThread) {
        this.$emit('subscribe');
      } else {
        this.$emit('unsubscribe');
      }
    }
  }
});
