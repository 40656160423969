
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import {
  computed, defineComponent, PropType, ref, WritableComputedRef
} from 'vue';
import { useVModel } from '@vueuse/core';
import BCXVuelidateErrors from '@/components/molecules/BCXVuelidateErrors.vue';
import EditIcon from '@/components/molecules/EditIcon.vue';

export default defineComponent({
  name: 'BCXValidatedInput',
  components: { EditIcon, BCXVuelidateErrors, BCXIcon },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    topLabel: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<'text' | 'password'>,
      default: 'text'
    },
    maxLength: {
      type: Number,
      required: false
    },
    vuelidateErrors: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    placeholder: {
      type: String
    },
    hasHelpIcon: {
      type: Boolean
    }
  },
  emits: ['keydown', 'blur'],
  setup(props) {
    const vmInput = useVModel(props) as WritableComputedRef<string>;

    const currentType = ref(props.type);
    const charactersLeft = computed(() => props.maxLength! - vmInput.value.length);

    const togglePasswordVisibility = () => {
      currentType.value = currentType.value === 'password' ? 'text' : 'password';
    };

    return {
      vmInput,
      currentType,
      mdiEye,
      mdiEyeOff,
      togglePasswordVisibility,
      charactersLeft,
    };
  }
});
