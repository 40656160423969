import { Module } from 'vuex';
import { BlogEntries } from '../models/Blog';

interface State {
    blog: {
      blogEntries: BlogEntries;
    };
  }

const module: Module<State, any> = ({
  getters: {},
  actions: {
    fetchBlogEntries: (): boolean => false
  },
  mutations: {}
});

export default module;
