

import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { ExternalProjectListEntry } from '@/__generated__/types';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProjectListItemOpenState',
  props: {
    status: {
      type: String as PropType<ExternalProjectListEntry['status']>,
      required: true,
    }
  },
  setup(props) {
    const { status } = toRefs(props);
    const { tUserFallback: t } = useUser('self');

    const statusIndicatorClass = computed(() => {
      switch (status.value) {
        case 'ACTIVE':
          return 'bc-project-list-item-open-state--open';
        case 'HOLD':
          return 'bc-project-list-item-open-state--hold';
        default:
          return 'bc-project-list-item-open-state--closed';
      }
    });

    return {
      t,
      statusIndicatorClass
    };
  }
});

