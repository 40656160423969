import { Ref } from 'vue';

const useListTools = <T>(list:Ref<T[] | null>) => {
  const moveUp = (idx:number) => {
    if (typeof idx === 'undefined') throw new Error('idx must not be undefined');
    if (list.value?.length && idx > 0) {
      const [listItem] = list.value.splice(idx, 1);
      list.value.splice(idx - 1, 0, listItem);
    }
  };

  const deleteItem = (idx:number) => {
    if (list.value?.length) {
      list.value.splice(idx, 1);
    }
  };

  const addItem = (newItem:T) => {
    if (list.value && Array.isArray(list.value)) list.value.push(newItem);
  };

  return {
    moveUp,
    deleteItem,
    addItem,
  };
};

export default useListTools;
