import useI18n from '@/mixins/useI18n';
import { InfoBoxState, InfoBoxType } from '@/models/InfoBox';
import {
  computed, reactive, ref, watch
} from 'vue';
import { sample } from 'lodash';
import { createGlobalState } from '@vueuse/core';
import asyncWait from '@/utils/asyncWait';

const useInfoBoxState = createGlobalState(() => {
  const { t, locale } = useI18n();
  const isInlineHelpOpened = ref(false);

  const defaultBoxState:InfoBoxState = {
    icon: null,
    isOpened: true,
    headline: t('infoBox.default.headline') as string,
    type: InfoBoxType.INFOBOX_INFO,
    text: null,
    isActive: false
  };

  const hasCustomSidebar = ref(false);

  const infoBoxState = reactive<InfoBoxState>({
    ...defaultBoxState
  });
  const metaBoxState = ref<InfoBoxState | null>(null);

  const sampledText = computed(() => {
    const { text } = infoBoxState;
    return text ? (Array.isArray(text) ? sample(text) : text) as string : '';
  });

  const setInfoBox = (newState: Partial<InfoBoxState>) => {
    Object.assign(infoBoxState, defaultBoxState, { ...newState, isActive: true, isOpened: true });
  };

  const resetInfoBox = () => {
    infoBoxState.isActive = false;
  };

  watch([metaBoxState, hasCustomSidebar, locale], async ([metaBoxState, hasCustomSidebar]) => {
    if (metaBoxState && !hasCustomSidebar) {
      await asyncWait(10);
      setInfoBox({
        headline: metaBoxState.headline ? t(metaBoxState.headline) as string : '',
        text: t(metaBoxState?.text as string) as string | string[]
      });
    } else {
      resetInfoBox();
    }
  }, {
    immediate: true
  });

  return ({
    hasCustomSidebar,
    setInfoBox,
    resetInfoBox,
    infoBoxState,
    sampledText,
    isInlineHelpOpened,
    metaBoxState
  });
});
export default useInfoBoxState;
