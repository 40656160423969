
import { defineComponent } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  name: 'Index',
  components: { },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const register = () => {
      store.commit('removeRegistrationData');
      store.dispatch('logout');
      router.push({ name: 'register' });
    };

    return {
      register,
      t,
    };
  },
});
