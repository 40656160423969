import page from '@/store/page';
import authentication from '@/views/authentication/store';
import blog from '@/views/blog/store';
import forum from '@/views/forum/store';
import projectForum from '@/views/project-forum/store';
import registration from '@/views/registration/store';
import subscriptions from '@/views/subscriptions/store';
import voting from '@/store/voting';
import Vue from 'vue';
import Vuex from 'vuex';
import dashboard from '@/views/home/store';
import * as avatarCache from './avatarCache';
import * as userStates from './userStates';

Vue.use(Vuex);

export interface RootState {
  validationErrors: any;
  errorMessage: any;
}

const store = new Vuex.Store({
  state: {
    validationErrors: {},
    errorMessage: null,
  },
  getters: {
    validationErrors: (state) => state.validationErrors,
    errorMessage: (state) => state.errorMessage,
  },
  mutations: {
    validationErrors(state, data) {
      state.validationErrors = data.validationErrors;
    },

    errorMessage(state, data) {
      state.errorMessage = data.errorMessage;
    },
  },
  actions: {
  },
  modules: {
    registration,
    authentication,
    page,
    forum,
    blog,
    projectForum,
    subscriptions,
    userStates,
    avatarCache,
    dashboard,
    voting,
  },
});

export default store;
