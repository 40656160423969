
import VueSimpleSuggest from 'vue-simple-suggest';
import {
  computed, defineComponent, PropType, ref, toRefs, Ref, watch
} from 'vue';
import { templateRef } from '@vueuse/core';
import TaggingService, { ExternalTagAssignment, Tag } from '@/services/TaggingService';

export default defineComponent({
  name: 'BCXTagSelect',
  components: { VueSimpleSuggest },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'skill' | 'role'>, default: 'skill',
    },
    usedTags: {
      type: Array as PropType<ExternalTagAssignment[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { value, type, usedTags } = toRefs(props);
    const simpleSuggest = ref<any>(null);
    const isListShown = ref<boolean>(false);
    const roleList = ref<Tag[]>([]);
    const mode = computed(() => (type.value === 'skill' ? 'input' : 'select'));
    const refSimpleSuggest = templateRef('simpleSuggest') as Ref<any>;
    const usedTagIds = computed(() => (usedTags.value.map((tag) => tag.tagId)));
    const text = computed(() => (refSimpleSuggest.value?.text) as string);
    const selected = computed(() => (refSimpleSuggest.value?.selected) as Tag | null);
    const suggestions = computed(() => (refSimpleSuggest.value?.suggestions) as Tag[]);
    const isRole = computed(() => type.value === 'role');
    if (type.value === 'role') {
      TaggingService.getAllRoles().then((allRoleTags) => {
        roleList.value = allRoleTags
          .filter((tag) => tag.tagName !== undefined && tag.tagName !== '')
          .sort((a, b) => (a.tagName ?? '').localeCompare(b.tagName ?? '')); // Sortieren nach tagName
      });
    }

    const unusedRoleTags = computed<Tag[]>(() => roleList.value.filter((roleTag) => !usedTagIds.value.includes(roleTag.tagId)));
    watch(unusedRoleTags, () => {
      if (unusedRoleTags.value.length === 0) {
        emit('noroles');
      }
    }, { deep: true });

    // We have lots of skills, so we filter them while entering on the input filter
    const getSkillSuggestionList = async (value: string) => {
      if (value) {
        const allSuggestedTags = await TaggingService.getTags(value);
        const unusedSuggestedTags = allSuggestedTags.filter((suggestedTag) => !usedTagIds.value.includes(suggestedTag.tagId));
        return unusedSuggestedTags;
      }
      return '';
    };

    // Input field value
    const simpleSuggestInput = computed({
      set: (v:any) => {
        if (typeof v === 'string') {
          emit('input', v ?? '');
          emit('update', v ?? '');
        } else {
          emit('input', v?.tagName ?? '');
          emit('update', v?.tagName ?? '');
        }
      },
      get: () => value.value ?? '',
    });

    const select = (item: Tag | string) => {
      emit('select', item ?? '');
      emit('update', item ?? '');
      // Workaround because min-length 0 will force into dropdown mode (and somehow doesn't close automatically)
      setTimeout(() => {
        simpleSuggest.value.inputElement.blur();
        simpleSuggest.value.hideList();
      },
      10);
    };

    const focus = () => {
      emit('focus');
    };

    const onHideList = () => {
      isListShown.value = false;

      // Check if we can select a suggested item, because it basically matches the suggestion list entry
      if (!selected.value && suggestions.value.length) {
        const autoSelectMatchedItem = (suggestions.value as Tag[]).find((tag) => tag.tagName && tag.tagName.toLowerCase() === text.value.toLowerCase());
        simpleSuggest.value.select(autoSelectMatchedItem);
      }
    };

    return {
      simpleSuggest,
      simpleSuggestInput,
      isListShown,
      mode,
      getSkillSuggestionList,
      isRole,
      select,
      focus,
      refSimpleSuggest,
      unusedRoleTags,
      onHideList,
    };
  },
});
