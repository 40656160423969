
import { Security2FaMethodType, SettingsVerificationType } from '@/models/Settings';
import Settings from '@/views/settings/pages/Settings.vue';
import { computed, defineComponent, ref } from 'vue';
import SettingsPopup from '@/views/settings/components/utils/SettingsPopup.vue';
import useSettings from '@/state/settingsState';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import { sendVerification } from '@/services/Settings/Api';

export default defineComponent({
  name: 'SettingVerificationPopup',
  components: {
    ActionButtons,
    BCXValidatedInput,
    BCXMarkdown,
    SettingsPopup
  },
  setup(props, { emit }) {
    const {
      settings, pendingVerification, loadSettings, resend, isResendDisabled
    } = useSettings();
    const isLoading = ref(false);
    const code = ref('');
    const isInvalidCode = ref(false);

    const authMethod = computed(() => (pendingVerification.value === SettingsVerificationType.MobilePhone
      ? Security2FaMethodType.Email : settings.value?.securitySettings.security2FaMethod ?? null));

    const verify = async () => {
      if (!pendingVerification.value) return;
      isLoading.value = true;
      const result = await sendVerification(pendingVerification.value, code.value);
      if (result) {
        pendingVerification.value = null;
        await loadSettings();
        emit('close');
      } else {
        isInvalidCode.value = true;
      }
      isLoading.value = false;
    };

    return {
      isLoading,
      isInvalidCode,
      isResendDisabled,
      authMethod,
      code,
      resend,
      verify,
      pendingVerification
    };
  }
});
