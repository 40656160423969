import {
  computed, onMounted, onUnmounted, ref, watch
} from 'vue';
import { ExternalVideoBarChatMatchingResult } from '@/__generated__/types';
import { useNow } from '@vueuse/core';

export default function useVideoChatTimer(room: ExternalVideoBarChatMatchingResult, isBarChat: boolean) {
  const timeNow = useNow();
  const countDownEndingTime = ref<number>(0);
  const isBarChatTimerAlreadyRunning = ref(false);

  function createBarChatCountDownEndingTime(minutes = 5) {
    const endingTime = new Date();
    endingTime.setMinutes(timeNow.value.getMinutes() + minutes);
    return endingTime.getTime();
  }

  function getRoomEndingTime(roomEndingTime: string) {
    const endingDate = new Date(roomEndingTime);
    const isUTC = roomEndingTime.endsWith('Z');
    const addToEndingTime = isUTC ? endingDate.getTimezoneOffset() * 1000 * 60 : 0; // Adjust for UTC time
    return endingDate.getTime() + addToEndingTime;
  }

  onMounted(() => {
    countDownEndingTime.value = room?.endingTime && !isBarChat
      ? getRoomEndingTime(room.endingTime)
      : createBarChatCountDownEndingTime();
  });

  const minutes = ref(5);
  const seconds = ref(0);
  const hours = ref(0);

  const stopTimer = watch(timeNow, (value) => {
    // Find the distance between now and the count down date
    const distance = countDownEndingTime.value - value.getTime();

    // Time calculations for minutes and seconds
    hours.value = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes.value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    seconds.value = Math.floor((distance % (1000 * 60)) / 1000);
  });

  onUnmounted(stopTimer);

  const isCounterLastMinute = computed(() => hours.value <= 0 && minutes.value <= 0);
  const isCounterLastSeconds = computed(() => isCounterLastMinute.value && seconds.value <= 30);
  const isCounterExpired = computed(() => isCounterLastMinute.value && seconds.value <= 0);

  function setBarChatTimer() {
    if (!isBarChatTimerAlreadyRunning.value && isBarChat) {
      countDownEndingTime.value = createBarChatCountDownEndingTime();
      isBarChatTimerAlreadyRunning.value = true;
    }
  }

  function appendPrefix(timeValue: number) {
    return `0${timeValue}`.slice(-2);
  }

  const formattedTimer = computed(() => {
    let timeString = `${appendPrefix(seconds.value)}`;

    if (!isCounterLastMinute.value) {
      timeString = `${appendPrefix(minutes.value)}:${timeString}`;
    }

    if (!isBarChat && !isCounterLastMinute.value) {
      if (hours.value > 0) {
        timeString = `${appendPrefix(hours.value)}:${appendPrefix(minutes.value)}:${appendPrefix(seconds.value)}`;
      } else {
        timeString = `${appendPrefix(minutes.value)}:${appendPrefix(seconds.value)}`;
      }
    }

    return timeString;
  });

  const showTimerInTableChat = computed(() => !isBarChat && hours.value === 0 && minutes.value <= 10);

  return {
    setBarChatTimer,
    formattedTimer,
    isCounterExpired,
    isRunning: computed(() => isBarChatTimerAlreadyRunning.value),
    isCounterLastSeconds,
    showTimerInTableChat
  };
}
