import { Chat, ChatMessage } from '@/models/Messenger';

const FAKE_RANDOM_NEW_MESSAGES = false;

const splitConversations = (chats: Chat[]): Chat[] => {
  const newChats: Chat[] = [];

  chats.forEach((chat) => {
    if (chat.forum) {
      const { messages } = chat;

      let numScans = 0;

      do {
        const rootMessage = messages[0];

        const traverseTree = (message: ChatMessage) => {
          let arr: ChatMessage[] = [];
          arr.push(message);
          const messageIndex = messages.findIndex(({ messageId }) => messageId === message.messageId);
          if (messageIndex >= 0) {
            messages.splice(messageIndex, 1);
          }
          const replies = messages.filter(({ replyTo }) => replyTo === message.messageId);
          replies.forEach((replyMessage) => {
            arr = [...arr, ...traverseTree(replyMessage)];
          });
          return arr;
        };

        const conversation = traverseTree(rootMessage);

        // if (showData) console.log(`Conversation (new id ${chat.id}_${numScans}): `, conversation);

        if (conversation.length) {
          if (FAKE_RANDOM_NEW_MESSAGES) (conversation.at(-1) as ChatMessage).read = Math.random() > 0.5;
          const chatObject = {
            ...chat,
            messages: conversation,
            id: `${chat.id}_${numScans}`
          };
          newChats.push(chatObject);
        }

        numScans++;
      } while (messages.length);
    } else {
      newChats.push(chat);
    }
  });

  return newChats;
};

export default splitConversations;
