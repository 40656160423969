
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import TagList from '@/components/tagging/TagList.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useResponsiveness from '@/mixins/useResponsiveness';
import { RouterEntry } from '@/models/Router';
import { BlogForm } from '@/views/blog/models/Blog';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    BCXContentArea,
    BCXBreadcrumbs,
    TagList,
    BCXUserBanner,
    BCXIcon
  },
  props: {
    article: {
      type: Object as () => BlogForm,
      required: true
    }
  },
  setup(props) {
    const previewArticle = ref<BlogForm>(props.article);
    const { isMobileLayout } = useResponsiveness();
    const { getFormattedTimeByAge } = useFormattedDateTime();
    const articleRoute = computed(() => {
      switch (previewArticle.value?.type) {
        case 'BC_NEWS':
          return { text: 'News', to: { name: 'blog news' } };
        case 'HELP_ARTICLE':
          return { text: 'Wiki', to: { name: 'wiki help' } };
        default:
          return { text: 'Articles', to: { name: 'blog articles' } };
      }
    });
    const entryBreadcrumbItems = computed(
      (): Array<RouterEntry> => {
        const breadcrumbItems: Array<RouterEntry> = [
          articleRoute.value,
          { text: props.article?.title, to: { name: 'blog article', params: { articleId: previewArticle.value.externalId || '' } } }
        ];
        if (isMobileLayout) {
          breadcrumbItems.unshift({ text: 'ByteCookie', to: { name: 'home' } });
        }
        return breadcrumbItems;
      }
    );

    const publishedDate = ref(getFormattedTimeByAge(props.article.lastUpdated));
    const markdown = computed(() => {
      if (props.article?.content) {
        return props.article.content;
      }
      return '';
    });
    return {
      entryBreadcrumbItems,
      isMobileLayout,
      markdown,
      publishedDate
    };
  }
});
