
import MobileBurger from '@/components/svg-components/MobileBurger.vue';
import { onClickOutside, templateRef, useMediaQuery } from '@vueuse/core';
import { ref } from 'vue';
import LPLanguageSwitcher from '@/views/landingpage/components/LPLanguageSwitcher.vue';
import useTokenState from '@/state/tokenState';

export default {
  name: 'LPHeaderNav',
  components: { LPLanguageSwitcher, MobileBurger },
  setup() {
    const isSmartphoneWidth = useMediaQuery('(width < 800px)');
    const isOpen = ref(false);
    const { isLoggedIn } = useTokenState();
    const menu = templateRef<HTMLElement>('menu');
    onClickOutside(menu, () => {
      isOpen.value = false;
    });

    return {
      isOpen,
      isSmartphoneWidth,
      isLoggedIn,
    };
  }
};
