
import { defineComponent } from 'vue';
import X from '@/components/svg-components/X.vue';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  name: 'BCXButtonDropdownResetButton',
  components: { X },
  setup() {
    const { t } = useI18n();
    return { t };
  }
});
