import {
  computed, onMounted, onUnmounted, watchEffect
} from 'vue';
import { useUserMedia, whenever } from '@vueuse/core';
import { useDeviceSettingsContext } from './useDeviceSettingsContext';
import useManipulateAudioStream from './useManipulateAudioStream';

const useDeviceSettings = () => {
  const {
    currentMicrophone, currentCamera, currentSpeaker, microphones, cameras, speakers
  } = useDeviceSettingsContext();

  const constraints = computed(() => ({
    video: {
      deviceId: currentCamera.value
    },
    audio: {
      deviceId: currentMicrophone.value
    }
  }));

  const {
    stream,
    stop: stopVideo,
    enabled,
    start
  } = useUserMedia({
    autoSwitch: true,
    constraints
  });

  const {
    sourceNode, destinationNode, gain, level
  } = useManipulateAudioStream(stream);

  const watchStream = watchEffect(() => {
    if (stream.value) {
      stream.value.getVideoTracks().forEach((x) => x.applyConstraints({ aspectRatio: { ideal: 16 / 9 }, width: 1920 }));
    }
  });

  whenever(() => !enabled.value, () => {
    sourceNode.value?.mediaStream.getTracks().forEach((track) => track.stop());
    destinationNode.value?.stream.getTracks().forEach((track) => track.stop());
  });

  onMounted(async () => {
    await start();
  });

  onUnmounted(() => {
    enabled.value = false;
    watchStream();
    stopVideo();
  });

  const toggleVideo = () => {
    enabled.value = !enabled.value;
  };

  function mapDevices(devices: MediaDeviceInfo[]) {
    return devices.map((device) => ({ value: device.deviceId, text: device.label }));
  }

  return {
    microphones: computed(() => mapDevices(microphones.value)),
    currentMicrophone,
    cameras: computed(() => mapDevices(cameras.value)),
    currentCamera,
    speakers: computed(() => mapDevices(speakers.value)),
    currentSpeaker,
    stream: computed(() => sourceNode.value?.mediaStream),
    toggleVideo,
    stopVideo,
    isVideoEnabled: computed(() => enabled.value),
    gain,
    level
  };
};

export default useDeviceSettings;
