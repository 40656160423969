
import useAudioInput from '@/views/virtual-pub/composable/settings/useAudioInput';
import { useDeviceSettingsContextProvider } from '@/views/virtual-pub/composable/settings/useDeviceSettingsContext';
import useVideoInput from '@/views/virtual-pub/composable/settings/useVideoInput';
import useAudioOutput from '@/views/virtual-pub/composable/settings/useAudioOutput';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const { currentMicrophone, microphones } = useAudioInput();
    const { currentCamera, cameras } = useVideoInput();
    const { currentSpeaker, speakers } = useAudioOutput();

    useDeviceSettingsContextProvider({
      currentCamera,
      cameras,
      currentMicrophone,
      microphones,
      currentSpeaker,
      speakers
    });

    return {
      currentCamera,
      cameras,
      currentMicrophone,
      microphones,
      currentSpeaker,
      speakers
    };
  }
});
