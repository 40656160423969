
import {
  computed, ComputedRef, defineComponent, Ref, ref
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import useMessengerState from '@/state/messenger/messengerState';

import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXTextarea from '@/components/molecules/forms/BCXTextArea.vue';
import BCXExtendedDropdown, { BCXExtendedDropdownItem } from '@/components/molecules/forms/BCXExtendedDropdown.vue';
import X from '@/components/svg-components/X.vue';
import { User } from '@/models/User';
import { businessObjectType } from '@/models/Voting';

interface ReportOption{
  [item: string]: any;
}

export default defineComponent({
  name: 'ReportPopup',
  components: {
    BCXAvatar,
    BCXTextarea,
    BCXExtendedDropdown,
    X,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const user: ComputedRef<User> = computed(() => store.getters.user);
    const reportedUser: ComputedRef<User> = computed(() => store.state.voting.user);
    const businessObjectType: ComputedRef<businessObjectType> = computed(() => store.state.voting.businessObjectType);

    const {
      createNewChat, isOpened
    } = useMessengerState();

    const node = ref(null);

    const headerTitle:string = t('voting.report.headline') as string;
    const reportingDescription: string = t('voting.report.description') as string;
    const reportingSuggestion: string = t('voting.report.suggestion',
      {
        fname: reportedUser.value.firstname,
        feedback_link: `<button type="button" onclick="openChat"><mark>${t('voting.report.user-feedback')}</mark></button>`
      }) as string;
    const labelTypeOfReport: string = t('voting.report.type-of-report') as string;
    const labelTypeOfReportPlaceholder: string = t('voting.report.type-of-report-placeholder') as string;
    const labelMessage: string = t('voting.report.explanation') as string;
    const placeholderExplanation: string = t('voting.report.placeholder-text') as string;

    const minMessageLength: Ref<number> = ref(40);
    const reportMessage: Ref<string> = ref('');
    const reportReason: Ref<string> = ref('');

    const minCharsLeft:ComputedRef<string> = computed(() => t('voting.report.min-chars',
      {
        minChars: minMessageLength.value,
        count: reportMessage.value.length > minMessageLength.value ? 0 : minMessageLength.value - reportMessage.value.length
      }) as string);
    const showMinCharsLeft: ComputedRef<boolean> = computed(() => reportMessage.value.length < minMessageLength.value);

    const reportOptions = computed<BCXExtendedDropdownItem[]>(() => {
      const options: ReportOption = t('voting.report.options') as any;
      const returnValue: BCXExtendedDropdownItem[] = [];
      Object.keys(options).forEach((item) => {
        if ((item.includes(`_${businessObjectType.value}`))
        || (!['PROFILE', 'PROJECT'].includes(businessObjectType.value) && !item.includes('_PROFILE') && !item.includes('_PROJECT'))) {
          returnValue.push({ label: options[item].label, value: item, description: options[item].description });
        }
      });

      const returnValueKeys = Object.keys(returnValue);
      returnValueKeys.forEach((item, idx) => {
        if (returnValueKeys.includes(`${item}_PROJECT`)
        || returnValueKeys.includes(`${item}_PROFILE`)) {
          delete returnValue[idx];
        }
      });

      return Object.values(returnValue);
    });

    const textAreaHeight: ComputedRef<number> = computed(() => {
      if (isMobileLayout.value) {
        return 210;
      }
      return 120;
    });

    const sendButtonDisabled = computed(() => (reportMessage.value.length < minMessageLength.value) || (reportReason.value === ''));

    function closeModal() {
      store.dispatch('voting/resetVotingReporting');
    }

    function sendReport() {
      const payload = {
        reason: reportReason.value,
        message: reportMessage.value,
      };
      store.dispatch('voting/report', payload).then(() => {
        closeModal();
      });
    }

    function openChat() {
      isOpened.value = true;
      createNewChat(reportedUser.value);
      closeModal();
    }

    return {
      node,
      user,
      reportedUser,
      headerTitle,
      reportingDescription,
      reportingSuggestion,
      labelTypeOfReport,
      labelTypeOfReportPlaceholder,
      labelMessage,
      placeholderExplanation,
      reportOptions,
      reportMessage,
      minCharsLeft,
      showMinCharsLeft,
      reportReason,
      textAreaHeight,
      closeModal,
      sendReport,
      openChat,
      sendButtonDisabled,
      t
    };
  },
});
