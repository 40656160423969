
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';

import useResponsiveness from '@/mixins/useResponsiveness';
import {
  defineComponent, computed, ref, watch
} from 'vue';
import MyProjectsWatchlist from '@/views/my-projects/components/MyProjectsWatchlist.vue';
import useUser from '@/mixins/useUser';
import MyProjectsOverview from '@/views/my-projects/components/overview/MyProjectsOverview.vue';
import { useOffersProvider } from '@/views/my-projects/composables/useReceivedOffers';
import MyProjectsReceivedOffers from '@/views/my-projects/components/offers/MyProjectsReceivedOffers.vue';
import BCXAbsoluteTransition from '@/components/molecules/BCXAbsoluteTransition.vue';
import MyProjectStatusLayer from '@/views/my-projects/components/offers/MyProjectStatusLayer.vue';
import MyProjectsIntro from '@/views/my-projects/components/MyProjectsIntro.vue';
import MyProjectsMadeOffers from '@/views/my-projects/components/offers/MyProjectsMadeOffers.vue';
import { useDocumentVisibility, useIntervalFn } from '@vueuse/core';
import BCXNewStuffBanner from '@/components/molecules/BCXNewStuffBanner.vue';

export default defineComponent({
  name: 'MyProjects',
  components: {
    MyProjectsMadeOffers,
    MyProjectsIntro,
    MyProjectStatusLayer,
    BCXAbsoluteTransition,
    MyProjectsOverview,
    MyProjectsReceivedOffers,
    MyProjectsWatchlist,
    BCXBreadcrumbs,
    BCXNewStuffBanner
  },
  setup() {
    const { isMobileWidth } = useResponsiveness();
    const isDocumentVisibleValue = useDocumentVisibility();
    const isDocumentVisible = computed(() => isDocumentVisibleValue.value === 'visible');
    const { tUserFallback: t, isCustomer } = useUser('self');
    const {
      projectsWithOffers, selectedProjectWithOffers, isStatusLayerOpen, madeOffers, loadProjectsWithOffers,
      fullReloadProjectsWithOffers, fullCheckForChanges, hasChanges, isLoading, madeProjectsWithOffers
    } = useOffersProvider({
      isNarrow: isMobileWidth
    });
    const isSliding = ref(false);

    const breadcrumbItems = computed(() => {
      const breadcrumbItems:Array<{text:string; to?:any}> = [
        {
          text: t('project-forum_USERTYPE.labels.projects').toString(),
          to: { name: 'projectList' }
        },
        {
          text: t('project-forum_USERTYPE.labels.myProjects').toString(),
          to: { name: 'myProjects' }
        }
      ];
      if (isMobileWidth.value) {
        // display logo as first item
        breadcrumbItems.unshift({ text: 'ByteCookie' });
      }
      return breadcrumbItems;
    });

    loadProjectsWithOffers();

    const intervalValue = ref(60000);
    const { resume, pause } = useIntervalFn(() => {
      fullCheckForChanges();
    }, intervalValue.value);

    watch(() => isDocumentVisible.value, (value) => {
      if (value) {
        fullCheckForChanges();
        resume();
      } else {
        pause();
      }
    });
    const closeBanner = () => {
      hasChanges.value = false;
    };

    return {
      isMobileWidth,
      breadcrumbItems,
      projectsWithOffers,
      fullReloadProjectsWithOffers,
      closeBanner,
      hasChanges,
      madeProjectsWithOffers,
      selectedProjectWithOffers,
      isStatusLayerOpen,
      isSliding,
      isCustomer,
      madeOffers,
      isLoading
    };
  }
});
