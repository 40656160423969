
import {
  defineComponent, PropType, Ref, ref
} from 'vue';
import IconCheckmark from '@/components/svg/checkmark.svg?inline';

export type CheckboxType = 'normal' | 'dark' | 'outlined'

export default defineComponent({
  name: 'BCXCheckbox',
  components: { IconCheckmark },
  inheritAttrs: false,
  props: {
    checked: Boolean,
    readonly: Boolean,
    isRadio: Boolean,
    type: {
      type: String as PropType<CheckboxType>,
      default: 'normal'
    },
    align: {
      type: String as PropType<'top' | 'center'>,
      default: 'center'
    }
  },
  setup(props, { emit }) {
    const input = ref(null) as Ref<HTMLInputElement | null>;

    const onChange = () => {
      const isChecked = !!input?.value?.checked;
      emit('input', isChecked);
    };

    return {
      input,
      onChange,
    };
  },
});
