
import { defineComponent } from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';

export default defineComponent({
  name: 'BCXMessengerChatBot',
  components: { BCXMarkdown },
  props: {
    name: { type: String, default: '' }
  }
});
