
import MostAppreciated from '@/components/most-appreciated/MostAppreciated.vue';
import SidebarContent from '@/components/SidebarContent.vue';
import useI18n from '@/mixins/useI18n';
import { MostAppreciatedType } from '@/models/MostAppreciated';
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue2-helpers/vue-router';

export default defineComponent({
  name: 'MostAppreciatedContainer',
  components: { MostAppreciated, SidebarContent },
  props: {
    inBox: {
      type: Boolean
    }
  },
  setup() {
    const { t } = useI18n();
    const isOpened = ref(true);

    const route = useRoute();

    const routeName = computed(() => route?.name);

    const type = computed(() => (route?.path?.startsWith('/projects') ? MostAppreciatedType.Projects : MostAppreciatedType.Talks));
    const title = computed(() => {
      if (type.value === MostAppreciatedType.Projects) {
        return t('mostAppreciated.titleProjects');
      } if (type.value === MostAppreciatedType.Talks) {
        return t('mostAppreciated.titleTalks');
      }
      return t('mostAppreciated.title');
    });

    return {
      isOpened,
      type,
      routeName,
      title
    };
  }
});
