const animationFrameWait = (numFrames = 1):Promise<void> => new Promise((resolve):void => {
  let frames = numFrames;

  const waitingFunction = () => {
    if (!frames--) {
      resolve();
      return;
    }
    requestAnimationFrame(waitingFunction);
  };

  waitingFunction();
});

export default animationFrameWait;
