
import Vue, { toRefs } from 'vue';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';

export default Vue.extend({
  name: 'BCXFloatingMenu',
  components: { BCXCollapsible },
  props: {
    isOpened: { type: Boolean },
  },
  setup(props, { emit }) {
    const { isOpened } = toRefs(props);

    const toggleOpened = () => {
      emit('update:is-opened', !isOpened.value);
    };

    return {
      toggleOpened,
    };
  },
});
