
import { defineComponent } from 'vue';
import SearchInput from '@/components/molecules/SearchInput.vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';
import UserSearchSearchButton from '@/views/user-search/components/UserSearchSearchButton.vue';

export default defineComponent({
  components: { UserSearchSearchButton, SearchInput },
  setup() {
    const {
      searchTerm, excludeSearchTerm, isSimple, search
    } = useUserSearch();

    return {
      searchTerm,
      excludeSearchTerm,
      search,
      isSimple
    };
  }
});
