// Gets all current visitors in the pub, to display in the visitors-list

import { ExternalVideoLobbyUsers } from '@/__generated__/types';
import _axios from '@/plugins/axios';
import { useBarOpeningHours } from '@/views/virtual-pub/composable/useBarOpeningHours';
import { computed } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/lobby/users';
const REQUEST_INTERVAL_PUB_OPEN = 1000 * 30;
const REQUEST_INTERVAL_PUB_CLOSED = 1000 * 60 * 3;

const useUsersInPub = () => {
  const {
    data, execute
  } = useAxios<ExternalVideoLobbyUsers>(ENDPOINT, { method: 'GET' }, _axios, { immediate: false });
  const { isLobbyOpen } = useBarOpeningHours();

  const interval = computed(() => (isLobbyOpen.value ? REQUEST_INTERVAL_PUB_OPEN : REQUEST_INTERVAL_PUB_CLOSED));

  useIntervalFn(execute, interval, {
    immediateCallback: true
  });

  const users = computed(() => data.value?.videoLobbyUserList ?? []);

  return {
    users,
  };
};

export default useUsersInPub;
