import { createGlobalState } from '@vueuse/core';
import useI18n from '@/mixins/useI18n';
import { computed } from 'vue';

const useLanguageSwitcher = createGlobalState(() => {
  const { locale } = useI18n();

  const otherLanguage = computed(() => (locale.value === 'en' ? 'de' : 'en'));

  const switchLanguage = () => {
    locale.value = otherLanguage.value;
  };

  return {
    switchLanguage, otherLanguage, locale
  };
});

export default useLanguageSwitcher;
