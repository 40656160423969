
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LPStepIcon',
  props: {
    size: {
      type: Number,
      default: 100
    }
  },
});
