
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import { SettingsVerificationType } from '@/models/Settings';
import { setNewPassword } from '@/services/Settings/Api';
import useSettings from '@/state/settingsState';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import useUpdateTools from '@/views/settings/utils/useUpdateTools';
import {
  defineComponent, reactive, ref, watch
} from 'vue';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
  name: 'PasswordSetting',
  components: {
    BackendErrors,
    ActionButtons,
    BCXValidatedInput
  },
  setup() {
    const hasPasswordBeenChanged = ref(false);
    const { lastVerification, isSafeToAutoSave, areSettingsLoaded } = useSettings();

    watch(areSettingsLoaded, (areLoaded) => {
      if (areLoaded && isSafeToAutoSave.value && lastVerification.value === SettingsVerificationType.Password) hasPasswordBeenChanged.value = true;
    });

    const fields = reactive({
      newPassword: '',
      oldPassword: ''
    });

    const { passwordValidation } = useBCValidators();

    const rules = {
      newPassword: passwordValidation,
      oldPassword: passwordValidation
    };

    const v = useVuelidate(rules, fields);

    const fnReset = () => {
      Object.assign(fields, {
        newPassword: '',
        oldPassword: ''
      });
    };

    const fnSave = async () => setNewPassword(fields);

    const {
      isLoading,
      save,
      reset,
      backendErrors,
      isDirty
    } = useUpdateTools(v, fnSave, fnReset, SettingsVerificationType.Password);

    return {
      fields,
      reset,
      v,
      save,
      isLoading,
      backendErrors,
      isDirty,
      hasPasswordBeenChanged
    };
  }
});
