
import X from '@/components/svg-components/X.vue';
import RecommendUserForProjects from '@/views/recommendations/components/recommend-user-for-projects/RecommendUserForProjects.vue';
import ProfileSidebarTitle from '@/views/profile/components/sidebar/ProfileSidebarTitle.vue';
import useRecommendations from '@/views/profile/composables/useRecommendations';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RecommendUserForProjectsLayer',
  components: { X, RecommendUserForProjects, ProfileSidebarTitle },
  props: {
    isInQuickInfo: {
      type: Boolean
    }
  },
  setup(_, { emit }) {
    const { user, availability, sidebarType } = useRecommendations();

    const onRecommendedMobile = () => {
      setTimeout(() => {
        emit('close');
      }, 2000);
    };

    const onClose = () => {
      emit('close');
    };

    return {
      user,
      availability,
      sidebarType,
      onRecommendedMobile,
      onClose
    };
  }
});
