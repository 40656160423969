import { ref } from 'vue';

const useBCXConfirm = () => {
  let resolve:((is:boolean)=>void)|null = null;
  const showConfirm = ref(false);
  const onConfirm = (is:boolean) => {
    if (resolve) resolve(is);
  };

  const ask = () => new Promise<boolean>((res) => {
    resolve = res;
    showConfirm.value = true;
  });

  return {
    onConfirm, showConfirm, ask
  };
};

export default useBCXConfirm;
