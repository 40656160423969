import { HtmlTarget } from '@/models/HtmlTarget';
import { User } from '@/models/User';
import { ref, unref } from 'vue';
import { MaybeRef } from '@vueuse/core';

const quickInfoUser = ref<User | null>(null);
export const DELETED_USER_FULLNAME = 'ehemaliger benutzer';

const quickInfoX = ref<number>(0);
const quickInfoY = ref<number>(0);
const hasCoordinatesAlready = ref(false);
const quickInfoShowContactHistory = ref<boolean>(false);
const target = ref<HtmlTarget>('_self');

function closeQuickInfo() {
  quickInfoUser.value = null;
}
const quickInfoShowBadgesLegend = ref<boolean>(false);

const setQuickInfoUser = async (user:User | null) => {
  quickInfoUser.value = user;
};

const setQuickInfoCoords = (x: number, y: number) => {
  quickInfoX.value = x;
  quickInfoY.value = y;
  hasCoordinatesAlready.value = true;
};

const setQuickInfo = async (user: User | null, x: number, y: number, toTarget: HtmlTarget = '_self') => {
  if ((user?.fullname ?? '').trim().toLowerCase() === DELETED_USER_FULLNAME) {
    return;
  }
  target.value = toTarget;
  await setQuickInfoUser(user);
  if (!hasCoordinatesAlready.value) setQuickInfoCoords(x, y);
};

const setShowContactHistory = (enable: boolean) => {
  quickInfoShowBadgesLegend.value = false;
  quickInfoShowContactHistory.value = enable;
};

const setShowBadgesLegend = (enable: boolean) => {
  quickInfoShowContactHistory.value = false;
  quickInfoShowBadgesLegend.value = enable;
};

const useQuickInfo = (showContactHistory = false) => {
  const showQuickInfo = async (el:MaybeRef<HTMLElement | SVGElement | null>, user:MaybeRef<User>) => {
    const parentElement = unref(el)?.parentElement;
    if (parentElement) {
      const { left, top } = parentElement.getBoundingClientRect();
      setShowContactHistory(showContactHistory);
      await setQuickInfo(unref(user), left, top + 50);
    }
  };

  return ({
    quickInfoUser,
    quickInfoX,
    quickInfoY,
    quickInfoShowContactHistory,
    quickInfoShowBadgesLegend,
    setQuickInfo,
    setQuickInfoCoords,
    setQuickInfoUser,
    setShowContactHistory,
    showQuickInfo,
    target,
    closeQuickInfo,
    setShowBadgesLegend,
  });
};

export default useQuickInfo;
