import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { RawLocation } from 'vue-router';

const useRouterIgnoreDuplicate = () => {
  const route = useRoute();
  const router = useRouter();

  const isSameLocationAsRoute = (location:RawLocation):boolean => {
    const normalize = (obj: any) => JSON.stringify(obj || {});

    if (typeof location === 'object') {
      return location?.name === route?.name
        && normalize(location?.params) === normalize(route?.params)
        && normalize(location?.query) === normalize(route?.query)
        && normalize(location?.hash) === normalize(route?.hash);
    }
    return location === route?.fullPath;
  };

  const pushRoute = async (to:RawLocation):Promise<boolean> => {
    if (!isSameLocationAsRoute(to)) {
      await router.push(to);
    } else {
      window.location.reload();
    }
    return true;
  };

  const replaceRoute = async (to:RawLocation):Promise<boolean> => {
    if (!isSameLocationAsRoute(to)) {
      await router.push(to);
    } else {
      window.location.reload();
    }
    return true;
  };

  return {
    pushRoute, replaceRoute, router
  };
};

export default useRouterIgnoreDuplicate;
