var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bc-message-preview",class:{
    'bc-unread': !_vm.isChatRead,
    'bc-is-flat': _vm.isFlat,
    [`bc-type--${_vm.chat.type}`]: _vm.isFlat,
    'bc-selected': _vm.isSelected,
    'bc-marked': _vm.marked,
    'bc-markable': _vm.isMarkable,
    'bc-is-expanded': _vm.isExpanded
  },on:{"click":_vm.select}},[(_vm.isFlat)?[_c('div',{staticClass:"bc-type"},[_vm._v(" "+_vm._s(_vm.$t(`messenger.types.${_vm.chat.type}.chat`))+" ")]),(_vm.linkLabel)?_c('div',{staticClass:"bc-forum bcx-truncated-text"},[_vm._v(" "+_vm._s(_vm.linkLabel)+" ")]):_vm._e()]:_vm._e(),(_vm.isMarkable)?_c('div',{staticClass:"bc-marker"},[_c('EditIcon',{attrs:{"icon":_vm.marked ? 'add' : 'circle'},on:{"execute":_vm.toggleMarked}})],1):_vm._e(),_c('div',{staticClass:"c-avatar"},[_c('BCXAvatar',{staticClass:"bcx-unclickable",attrs:{"user-id":_vm.chat.privatePartner.userId,"size":40,"show-online-indicator":"","show-cookies":"","is-lazy":""}})],1),_c('span',{staticClass:"bcx-truncated-text bc-user-name"},[_vm._v(_vm._s(_vm.userName))]),(_vm.lastMessage)?[_c('span',{staticClass:"bc-posted"},[_vm._v(_vm._s(_vm.lastMessageDate))]),_c('span',{staticClass:"bc-text"},[(_vm.isMe)?_c('em',[_vm._v(_vm._s(_vm.$t('messenger.chat.me'))+":")]):_vm._e(),_c('span',{staticClass:"bcx-truncated-text"},[_c('BCXMarkdown',{staticClass:"bcx-markdown--inlined bc-text--title",attrs:{"mode":"nolinks","text":_vm.lastMessage.text,"strip-tags":"","tag":"span"}})],1)])]:_vm._e(),_c('span',{staticClass:"bc-new"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }