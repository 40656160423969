import { NavigationTreeItems } from '@/models/Navigation';
import { traverseNavigationTreeItems } from '@/navigation/traverseNavigationTreeItems';
import _navigation from '@/navigation/navigation.yaml';
import { reactive } from 'vue';
import useUser from '@/mixins/useUser';
import { createGlobalState } from '@vueuse/core';

const useNavigationTree = createGlobalState(() => {
  const { isCustomer } = useUser('self');

  const prepareNavigation = (navigation: NavigationTreeItems): NavigationTreeItems => {
    let id = 1;
    traverseNavigationTreeItems(navigation, (item, level, key, parent) => {
      item._id = id++;
      item._opened = false;
      item._active = false;
      item._key = key;
      item._level = level;
      item._parent = parent;
    });
    return navigation;
  };

  const navigationRoot = prepareNavigation(isCustomer.value ? _navigation.company_root : _navigation.freelancer as NavigationTreeItems);

  const navigation = reactive(navigationRoot);

  return {
    navigation
  };
});

export default useNavigationTree;
