import _axios from '@/plugins/axios';
import { ref, watchEffect } from 'vue';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/reminders/reminder/VIDEO_PUB_OPENING';
const ENDPOINT_SET_REMINDER = '/v1/reminders';

interface ExternalReminder {
  active: boolean;
}

const useBarChatReminder = () => {
  const isEnabled = ref(false);
  const { data, isLoading, execute: refreshStatus } = useAxios<ExternalReminder>(ENDPOINT, { method: 'GET' }, _axios);

  watchEffect(() => {
    isEnabled.value = data.value?.active ?? false;
  });

  const { execute: setReminder } = useAxios(ENDPOINT_SET_REMINDER, {
    method: 'POST',
    data: {
      reminderType: 'VIDEO_PUB_OPENING'
    },
  }, _axios, { immediate: false });

  const { execute: removeReminder } = useAxios(ENDPOINT, { method: 'DELETE' }, _axios, { immediate: false });

  return {
    isEnabled,
    isLoading,
    setReminder,
    removeReminder,
    refreshStatus,
  };
};

export default useBarChatReminder;
