import { render, staticRenderFns } from "./BCXMessengerHeader.vue?vue&type=template&id=bdb96c34&scoped=true"
import script from "./BCXMessengerHeader.vue?vue&type=script&lang=ts"
export * from "./BCXMessengerHeader.vue?vue&type=script&lang=ts"
import style0 from "./BCXMessengerHeader.vue?vue&type=style&index=0&id=bdb96c34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdb96c34",
  null
  
)

export default component.exports