
import { defineComponent, PropType } from 'vue';
import { useVModel } from '@vueuse/core';

import IconUnChecked from '@/components/svg/circle.svg?inline';
import IconChecked from '@/components/svg/checkmark-circle.svg?inline';

export default defineComponent({
  name: 'BCXRoundWhiteCheckbox',
  components: {
    IconUnChecked, IconChecked
  },
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const valueVM = useVModel(props);

    return {
      valueVM
    };
  }
});
