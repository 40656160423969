
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import Vue from 'vue';
import useI18n from '@/mixins/useI18n';
import { mapActions, mapGetters, mapState } from 'vuex';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import Voting from '@/components/voting/Voting.vue';
import Reporting from '@/components/voting/Reporting.vue';
import CreateMessage from './CreateMessage.vue';

export default Vue.extend({
  name: 'ThreadEntry',
  components: {
    'create-message': CreateMessage,
    BCXUserBanner,
    Voting,
    Reporting,
  },
  props: {
    entryUser: {
      type: Object,
      default: undefined,
    },
    entryCount: {
      type: String,
      default: undefined,
    },
    voteResult: {
      type: Number,
      default: 0,
    },
    userVoted: {
      type: String,
      default: undefined,
    },
    flagged: {
      type: Boolean,
      default: false,
    },
    forumId: {
      type: String,
      default: undefined,
    },
    groupId: {
      type: String,
      default: undefined,
    },
    threadId: {
      type: String,
      default: undefined,
    },
    messageId: {
      type: String,
      default: undefined,
    },
    messageEdited: {
      type: Boolean,
      default: false,
    },
    created: {
      type: String,
      default: '',
    },
    subReplyText: {
      type: [String, Boolean],
      default: false,
    },
    disableReply: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userImg: '',
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('forum', ['getOpenReplyToMessageId', 'getThreadTopic']),
    ...mapGetters('page', ['isMobileLayout']),
    ...mapState('page', ['isSmartphoneWidth']),
    createdTimestamp(): string {
      return this.formatDate(this.created);
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    classMobile(): string {
      return this.isMobileLayout ? 'is-mobile' : '';
    },
    createMessageAttachedTo(): string {
      if (this.isMobileLayout) {
        return `#${this.$root.$el.id}`;
      }
      return `#message-${this.messageId} .forum-message-reply`;
    },
    createMessageVisible: {
      get(): boolean {
        return this.messageId === this.getOpenReplyToMessageId;
      },
      set(messageId: string) {
        this.replyToMessage(messageId);
      },
    },
    isUserCreator(): boolean {
      return this.user.userId === this.entryUser.userId;
    },
    userCanEdit(): boolean {
      return this.user.userId === this.entryUser.userId
          || this.user.roles.includes('FORUM_MODERATOR')
          || this.user.roles.includes('PROJECT_MODERATOR');
    },
    isThreadTopic(): boolean {
      return this.messageId === this.getThreadTopic.messageId;
    },
  },
  methods: {
    ...mapActions('forum', ['replyToMessage']),
    formatDate(timestamp: string): string {
      const { getFormattedTimeByAge } = useFormattedDateTime();
      return getFormattedTimeByAge(timestamp);
    },
    scrollToEntry(event: Event): void {
      const element: HTMLElement | null = event.target as HTMLElement;
      const entryId = element.getAttribute('href');
      if (entryId) {
        const threadEntry = document.querySelector(entryId);
        if (threadEntry) {
          threadEntry.classList.add('scroll-highlight');
          setTimeout(() => {
            threadEntry.classList.remove('scroll-highlight');
          }, 1250);
        }
        this.$vuetify.goTo(entryId, { offset: 80 });
      }
    },
    toggleCreateMessageVisible(): void {
      if (this.createMessageVisible) {
        this.createMessageVisible = '';
      } else {
        this.createMessageVisible = this.messageId;
      }
    },
    editMessage() {
      this.$emit('edit-message');
    },
    postMessage(message: string) {
      const postMessage = {
        forumId: this.forumId,
        forumThreadId: this.threadId,
        messageContent: message,
        replyToMessageId: this.messageId,
        replyToUserId: this.entryUser.userId,
      };
      this.$emit('post-message', postMessage);
      this.createMessageVisible = false;
    },
  },

});
