
import Vue from 'vue';
import useResponsiveness from '@/mixins/useResponsiveness';

export default Vue.extend({
  name: 'ResponsiveTest',
  setup() {
    const {
      isSmartphoneWidth, isMobileWidth, isMini, isFlat, isTabletWidth, isDesktopWidth,
    } = useResponsiveness();

    return {
      isSmartphoneWidth, isMobileWidth, isMini, isFlat, isTabletWidth, isDesktopWidth,
    };
  },
});
