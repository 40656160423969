<template>
  <div class="c-dashboard-latest-blogs-skeleton">
    <BCXSkeleton
      class="top-title"
      height="20px"
    />
    <BCXSkeleton
      class="top-content"
      height="312px"
    />
    <BCXSkeleton
      class="low-title"
      width="200px"
    />
    <BCXSkeleton
      v-for="i in 3"
      :key="i"
      class="other-link"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import BCXSkeleton from '@/components/molecules/BCXSkeleton.vue';

export default defineComponent({
  components: {
    BCXSkeleton
  },
  setup() {
    return {

    };
  },
});
</script>
<style lang="scss" scoped>
.c-dashboard-latest-blogs-skeleton {
  width: 100%;
  .top-title{

  }
  .top-content {
    margin-top: 16px;
  }
  .low-title{
    margin-top: 24px;
  }
  .other-link {
    margin-top: 8px;
  }
}
</style>
