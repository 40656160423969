
import { defineComponent } from 'vue';
import { onClickOutside, templateRef } from '@vueuse/core';
import BlogButton from './BlogButton.vue';

export default defineComponent({
  components: { BlogButton },
  setup(_, { emit }) {
    const deleteConfirm = templateRef<HTMLElement>('deleteConfirm');
    onClickOutside(deleteConfirm, () => emit('close'));
    return {};
  }
});
