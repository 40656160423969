import {
  deleteData, getData, postData, putData
} from '@/utils/apiTools';
import { User } from '@/models/User';
import {
  BasicDataRequest,
  CertificateListItem,
  CertificatesRequest, CompanyInformationRequest,
  EducationListItem,
  EducationsRequest,
  Experiences,
  Profile,
  ProjectPreferencesRequest,
  ReferencesRequest,
  SkillListItemRequest,
  SkillListRequest
} from '@/models/Profile';
import { ExternalFile } from '@/models/Download';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';

// eslint-disable-next-line import/prefer-default-export
export const getUser = async (userId:string):Promise<User> => {
  const user = await getData(`/v1/users/${userId}/banner`);
  user.fullname = `${user.firstname} ${user.lastname}`;
  user.userId = userId;
  return user;
};

const sortProfileItemsList = <T extends CertificateListItem | EducationListItem>(list: T[]) => {
  list.sort((a:T, b:T) => (a?.position ?? 0) - (b?.position ?? 0));
};

const sortProfileItems = (profile: Profile) => {
  if (profile?.certificates?.certificatesList?.length) sortProfileItemsList(profile.certificates.certificatesList);
  if (profile?.educations?.educationsList?.length) sortProfileItemsList(profile.educations.educationsList);
};

export const getProfile = async (userId:string):Promise<Profile> => {
  const profile = await getData(`/v1/users/${userId}/profile`);
  sortProfileItems(profile);
  return profile;
};
export const getOwnProfile = async ():Promise<Profile> => getProfile('user');

export const updateBasicData = async (data:BasicDataRequest):Promise<any> => putData(
  '/v1/users/user/profile/basic', data,
);

export const updateProjectPreferences = async (data:ProjectPreferencesRequest):Promise<any> => putData(
  '/v1/users/user/profile/projectpreferences', data,
);

export const updateCertificates = async (data:CertificatesRequest):Promise<any> => {
  data.certificates.certificatesList = data.certificates.certificatesList.map((item, index) => ({ ...item, position: index }));
  return putData('/v1/users/user/profile/certificates', data);
};
export const updateEducations = async (data:EducationsRequest):Promise<any> => {
  data.educations.educationsList = data.educations.educationsList.map((item, index) => ({ ...item, position: index }));
  return putData('/v1/users/user/profile/educations', data);
};

export const addReferences = async (data:ReferencesRequest):Promise<any> => putData('/v1/users/user/profile/references', data);

export const getReferenceDownload = async (referenceId:string):Promise<ExternalFile> => getData(
  `/v1/users/user/profile/references/${referenceId}/download`,
);

export const addSkill = async (data:SkillListItemRequest):Promise<any> => postData(
  '/v1/users/user/profile/skills/', data,
);
export const updateSkill = async (userSkillId:string, data:SkillListItemRequest):Promise<any> => putData(
  `/v1/users/user/profile/skills/${userSkillId}`, data,
);

export const updateSkills = async (data: SkillListRequest):Promise<any> => putData(
  '/v1/users/user/profile/skills', data
);

export const getExperiences = async ():Promise<any> => getData('/v1/users/user/profile/experiences');

export const updateExperiences = async (data: Experiences): Promise<Experiences> => {
  data.experiencesText = BCXHTMLSanitizer.beforeSave(data.experiencesText);
  return putData(
    '/v1/users/user/profile/experiences', data,
  );
};

export const deleteExperiences = async () => deleteData('/v1/users/user/profile/experiences');

export const getOwnExperienceDownload = async ():Promise<any> => getData(
  '/v1/users/user/profile/experiences/download'
);
export const getExperienceDownload = async (userId:string):Promise<any> => getData(
  `/v1/users/${userId}/profile/experiences/download`
);

export const updateCompanyInformation = async (data: CompanyInformationRequest) => putData('/v1/users/user/profile/company-information', data);
