import { getData, putData } from '@/utils/apiTools';
import {
  InviteCode, InviteCodesResponse, InviteCodeUpdateRequest, Invitees
} from '@/models/Invitation';

// eslint-disable-next-line import/prefer-default-export
export const getInviteCodes = async ():Promise<InviteCodesResponse> => getData('/v1/invitations/codes');

export const getInvitees = async ():Promise<Invitees> => getData('/v1/invitations/invitees');

export const updateInviteCode = async (code:string, data:InviteCodeUpdateRequest):Promise<InviteCode> => putData(`/v1/invitations/codes/${code}`, data);
