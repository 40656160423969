
import Vue, { computed, Ref, toRefs } from 'vue';

export default Vue.extend({
  name: 'X',
  props: {
    width: { type: Number, default: 16 },
    height: { type: Number, default: 16 },
    strokeWidth: { type: Number, default: 1.5 },
  },
  setup(props) {
    const { width, height, strokeWidth } = toRefs(props) as {
      width:Ref<number>;
      height:Ref<number>;
      strokeWidth:Ref<number>;
    };
    // M1,1 l14,14 m-14,0 l14,-14
    const w = computed(() => width.value - strokeWidth.value);
    const h = computed(() => height.value - strokeWidth.value);
    const path = computed(() => `M${strokeWidth.value * 0.5},${strokeWidth.value * 0.5} l${w.value},${h.value} m${-w.value},0 l${w.value},${-h.value}`);
    return {
      path,
    };
  },
});
