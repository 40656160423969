
import BCXPagination from '@/components/molecules/BCXPagination.vue';
import {
  defineComponent, PropType, ref, toRefs
} from 'vue';
import usePaginationHelper from '@/mixins/usePaginationHelper';

export default defineComponent({
  components: { BCXPagination },
  props: {
    array: {
      type: Array as PropType<unknown[]>,
      default: () => []
    },
    pageSize: {
      type: Number,
      required: true
    },
    gap: {
      type: String,
      default: '32px'
    },
    paginationIsAlwaysShown: Boolean
  },
  setup(props) {
    const { array, pageSize } = toRefs(props);
    const currentPage = ref(1);
    const { slicedArray, total } = usePaginationHelper(pageSize, currentPage, array);
    return {
      slicedArray, total, currentPage
    };
  }
});
