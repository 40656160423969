
import Vue from 'vue';
import Chevron from '@/components/svg-components/Chevron.vue';

export default Vue.extend({
  name: 'ThreadTopicTitle',
  components: {
    Chevron
  },
  props: {
    backLink: {
      type: Object,
      default: undefined
    }
  }
});
