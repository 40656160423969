
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import { defineComponent } from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'BCXShowAllToggle',
  components: { BCXChevronSwitch },
  props: {
    value: {
      type: Boolean
    },
  },
  setup(props) {
    const showAll = useVModel(props);
    const toggle = () => { showAll.value = !showAll.value; };

    return {
      showAll, toggle
    };
  }
});
