
import { defineComponent } from 'vue';
import EditIcon from '@/components/molecules/EditIcon.vue';

export default defineComponent({
  name: 'DownloadableItem',
  components: { EditIcon },
  props: {
    disabled: { type: Boolean }
  },
  setup(props, { emit }) {
    // @todo implement more ways to download, like simple href, etc.
    const download = () => {
      emit('download');
    };
    return { download };
  }
});
