
import SmileyButton from '@/components/molecules/SmileyButton.vue';
import { SmileyTypes } from '@/components/svg-components/Smiley.vue';
import { defineComponent, PropType } from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'SmileyButtons',
  components: { SmileyButton },
  props: {
    value: {
      type: String as PropType<SmileyTypes | ''>,
      required: true
    },
    gap: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const vmValue = useVModel(props);

    const buttons = [SmileyTypes.Good, SmileyTypes.Neutral, SmileyTypes.Bad];

    return { buttons, vmValue };
  }
});
