
import useSettings from '@/state/settingsState';
import { computed } from 'vue';
import GeneralSetting from '@/views/settings/components/utils/GeneralSetting.vue';

export default {
  name: 'SalutationSetting',
  components: { GeneralSetting },
  setup() {
    const { settings } = useSettings();

    const valueRef = computed(() => settings.value?.userDataSettings.salutation);

    return {
      valueRef
    };
  }
};
