
import {
  computed, defineComponent, ref, toRefs
} from 'vue';
import useI18n from '@/mixins/useI18n';
import { UserRole } from '@/models/User';
import { BlogEntriesAdmin, BlogForm } from '../models/Blog';

export default defineComponent({
  props: {
    entries: {
      type: Object as () => BlogEntriesAdmin,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const sortKey = ref('lastUpdated');
    const sortDesc = ref(false);
    const { entries } = toRefs(props);
    const sortData = (key: string, keepDirection = false) => {
      if (key === sortKey.value && !keepDirection) {
        sortDesc.value = !sortDesc.value;
      }
      switch (key) {
        case 'position':
          if (sortDesc.value) {
            entries.value.blogEntries.sort((a: BlogForm, b: BlogForm) => a.position - b.position);
          } else {
            entries.value.blogEntries.sort((a: BlogForm, b: BlogForm) => b.position - a.position);
          }
          break;
        default:
          if (sortDesc.value) {
            entries.value.blogEntries.sort((a: Record<string, any>, b: Record<string, any>) => (a[key] < b[key] ? 1 : -1));
          } else {
            entries.value.blogEntries.sort((a: Record<string, any>, b: Record<string, any>) => (a[key] > b[key] ? 1 : -1));
          }
          break;
      }
      sortKey.value = key;
    };
    const sortableList = computed(() => {
      sortData(sortKey.value, true);
      return entries.value;
    });
    const sortStateByKey = (key: string) => sortDesc.value && sortKey.value === key;
    const sortActive = (key: string) => sortKey.value === key;
    const tableHead = [
      {
        key: 'type',
        label: t('blog.type')
      },
      {
        key: 'title',
        label: t('blog.title')
      },
      {
        key: 'status',
        label: t('blog.status')
      },
      {
        key: 'lastUpdated',
        label: t('blog.changed')
      },
      {
        key: 'position',
        label: 'P'
      },
      {
        key: 'audience',
        label: t('blog.audience')
      }
    ];

    const getAudience = (audience: UserRole[] | undefined) => {
      if (!audience) {
        return '';
      }
      return audience.join(', ');
    };

    return {
      sortData,
      tableHead,
      sortStateByKey,
      sortActive,
      sortableList,
      getAudience,
    };
  },
  methods: {
    editEntry(entry: BlogForm) {
      this.$emit('edit', entry);
    }
  }
});
