
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import { Settings, SettingsVerificationType } from '@/models/Settings';
import { setMobilePhone } from '@/services/Settings/Api';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import useUpdateTools from '@/views/settings/utils/useUpdateTools';
import { reactive } from 'vue';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'MobileNumberSetting',
  components: {
    BackendErrors,
    ActionButtons,
    BCXValidatedInput
  },
  setup() {
    const fields = reactive({
      newMobilephone: '',
      currentPassword: ''
    });

    const { phoneValidation } = useBCValidators();

    const rules = {
      newMobilephone: phoneValidation
    };

    const v = useVuelidate(rules, fields);

    const setBySettings = (settings: Settings | null) => {
      fields.newMobilephone = settings?.securitySettings?.mobilephoneNumber ?? '';
    };

    const fnSave = async () => setMobilePhone(fields);

    const {
      save,
      reset,
      isLoading,
      backendErrors,
      isDirty
    } = useUpdateTools(v, fnSave, setBySettings, SettingsVerificationType.MobilePhone);

    return {
      v,
      save,
      reset,
      isLoading,
      backendErrors,
      isDirty,
      fields
    };
  }
};
