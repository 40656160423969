import {
  createInjectionState, useArrayFilter, useMemoize
} from '@vueuse/core';
import {
  computed, Ref, ref, watch, watchEffect
} from 'vue';
import {
  ProjectOffer, ProjectOfferSearchRequest, ProjectOfferSearchResponse, ProjectWithOffers
} from '@/views/my-projects/types';
import { ExternalProjectListEntry } from '@/__generated__/types';
import useSortedArray from '@/mixins/useSortedArray';
import { getProjectOffer, searchOffers } from '@/services/OffersService';
import useUser from '@/mixins/useUser';
import ProjectForumService from '@/services/ProjectForumService';
import { logDebug } from '@/utils/logger';
import { Project } from '@/views/project-forum/models/ProjectForum';
import asyncWait from '@/utils/asyncWait';
import { AxiosError } from 'axios';
import usePageState from '@/state/pageState';
import useI18n from '@/mixins/useI18n';
import getAxiosResponseErrorCode from '@/utils/getAxiosResponseErrorCode';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';

const projectFieldValue = (fieldName: string, obj: ProjectWithOffers): string | number => {
  switch (fieldName) {
    case 'created': return obj.project.created ?? '';
    case 'latest': return obj.latestOfferCreated;
    case 'title': return obj.project.title ?? '';
    case 'offers': return obj.offers?.length;
  }
  return 0;
};

interface ReceivedOffersOptions {
  isNarrow: Ref<boolean>;
}

const [useOffersProvider, useOffersInjected] = createInjectionState((options: ReceivedOffersOptions) => {
  const receivedProjectsWithOffers = ref<ProjectWithOffers[]>([]);
  const madeProjectsWithOffers = ref<ProjectWithOffers[]>([]);
  const selectedProjectWithOffers = ref<ProjectWithOffers | null>(null);
  const isLoading = ref(false);
  const hasChanges = ref(false);
  const isStatusLayerOpen = ref(false);
  const statusLayerProject = ref<Project | null>(null);
  const projectIdsToKeepInProjectFilter = ref<string[]>([]);
  const { globalErrorMessage } = usePageState();

  const route = useRoute();

  watch(isStatusLayerOpen, (is) => {
    if (is) return;
    statusLayerProject.value = null;
  });

  const loadOffer = useMemoize(
    async (projectId: string, offerId: string):Promise<ProjectOffer | null> => {
      isLoading.value = true;
      const offer = await getProjectOffer(projectId, offerId).catch((err:AxiosError) => {
        globalErrorMessage.value = {
          i18Key: 'my-projects.errors.load-offer-details-error',
          code: getAxiosResponseErrorCode(err),
          component: 'useReceivedOffers.ts'
        };
        return null;
      });
      isLoading.value = false;
      return offer;
    }
  );

  const loadProject = useMemoize(
    async (projectId: string):Promise<ProjectWithOffers['project'] | null> => {
      isLoading.value = true;
      const project = await ProjectForumService.getProject(projectId).catch((err:AxiosError) => {
        globalErrorMessage.value = {
          i18Key: 'my-projects.errors.load-project-details-error',
          code: getAxiosResponseErrorCode(err),
          component: 'useReceivedOffers.ts',
        };
        return null;
      });
      isLoading.value = false;
      return project as unknown as ProjectWithOffers['project'];
    }
  );

  const projectFilter = ref<ExternalProjectListEntry['status']>('ACTIVE');

  const defaultOfferFilters:ProjectOffer['status'][] = [
    'ACCEPTED', 'DECLINED', 'NEGOTIATIONS', 'OPEN', 'WITHDRAWN',
  ];

  const {
    companyId, userId, isCustomer, isFreelancer
  } = useUser('self');

  const offersWithProjects2ProjectsWithOffers = (offersWithProjects: ProjectOfferSearchResponse[], dontUseReadStatus = false):ProjectWithOffers[] => {
    const projectMap = new Map<string, ProjectWithOffers>();

    offersWithProjects.forEach((offerWithProject) => {
      const { project, ...offer } = offerWithProject;
      const projectId = project.id;
      if (!projectId) return;

      let projectWithOffers: ProjectWithOffers | undefined = projectMap.get(projectId);

      project.status ??= 'ACTIVE';
      if (!projectWithOffers) {
        projectWithOffers = {
          id: projectId,
          offers: [],
          latestOfferCreated: '???',
          project
        };
        if (!projectWithOffers) return; // TS can be quite stupid...

        projectMap.set(projectId, projectWithOffers);
      }

      // @todo REMOVE WHEN IMPLEMENTED!!!
      if (dontUseReadStatus) offer.read = true;
      projectWithOffers.offers.push(offer);
    });
    return [...projectMap.values()].map((item) => {
      const offersCreated = item.offers.map((offer) => offer.created).sort();
      const latestOfferCreated = offersCreated?.pop() ?? '';
      return {
        ...item,
        latestOfferCreated
      } as ProjectWithOffers;
    });
  };

  type ClearCacheProps = {
    invalidateOfferId?: string;
    invalidateProjectId?: string;
    invalidateAll: boolean;
  }

  const loadProjectsWithOffers = async (invalidations?:ClearCacheProps) => {
    isLoading.value = true;
    hasChanges.value = false;
    const sPWOId = selectedProjectWithOffers.value?.id;

    if (invalidations?.invalidateAll) {
      loadProject.clear();
      loadOffer.clear();
    } else if (invalidations?.invalidateProjectId) {
      loadProject.delete(invalidations.invalidateProjectId);

      if (invalidations?.invalidateOfferId) {
        loadOffer.delete(invalidations.invalidateProjectId, invalidations.invalidateOfferId);
      }
    }

    const searchOffersOrNothing = async (key: keyof ProjectOfferSearchRequest, value?: string): Promise<ProjectOfferSearchResponse[]> => {
      if (!value) return [];
      const request = {
        [key]: value
      };
      return searchOffers(request).catch((err: AxiosError) => {
        globalErrorMessage.value = {
          i18Key: 'my-projects.errors.load-offers-error',
          code: getAxiosResponseErrorCode(err),
          component: 'useReceivedOffers.ts',
        };
        return [];
      });
    };

    const [offersWithProjectsReceived, offersWithProjectsMade] = await Promise.all([
      companyId.value
        ? searchOffersOrNothing('projectCreatorCompanyId', companyId.value)
        : searchOffersOrNothing('projectCreatorId', userId.value),
      searchOffersOrNothing('creatorId', userId.value)
    ]);

    receivedProjectsWithOffers.value = offersWithProjects2ProjectsWithOffers(offersWithProjectsReceived);
    madeProjectsWithOffers.value = offersWithProjects2ProjectsWithOffers(offersWithProjectsMade, true);

    // important to trigger updates further down the road:
    selectedProjectWithOffers.value = receivedProjectsWithOffers.value.find((owp) => owp.id === sPWOId) ?? null;

    isLoading.value = false;
  };

  const fullCheckForChanges = async () => {
    const [offersWithProjectsReceived, offersWithProjectsMade] = await Promise.all([
      companyId.value
        ? searchOffers({ projectCreatorCompanyId: companyId.value, projectId: selectedProjectWithOffers.value?.id })
        : searchOffers({ projectCreatorId: userId.value, projectId: selectedProjectWithOffers.value?.id }),
      searchOffers({ creatorId: userId.value })
    ]);

    const receivedProjectsWithOffersNew = offersWithProjects2ProjectsWithOffers(offersWithProjectsReceived);
    const madeProjectsWithOffersNew = offersWithProjects2ProjectsWithOffers(offersWithProjectsMade, true);

    if (!selectedProjectWithOffers.value) {
      const totalOfferCountCurrent = receivedProjectsWithOffers.value.reduce((acc, cur) => acc + cur.offers.length, 0)
        + madeProjectsWithOffers.value.reduce((acc, cur) => acc + cur.offers.length, 0);
      const totalOfferCountNew = receivedProjectsWithOffersNew.reduce((acc, cur) => acc + cur.offers.length, 0)
        + madeProjectsWithOffersNew.reduce((acc, cur) => acc + cur.offers.length, 0);

      if (totalOfferCountCurrent !== totalOfferCountNew) {
        hasChanges.value = true;
        return;
      }
    } else {
      let selectedProjectWithOffersNew = receivedProjectsWithOffersNew.find((projectWithOffers) => projectWithOffers.id === selectedProjectWithOffers.value?.project.id);

      if (!selectedProjectWithOffersNew) {
        selectedProjectWithOffersNew = madeProjectsWithOffersNew.find((projectWithOffers) => projectWithOffers.id === selectedProjectWithOffers.value?.project.id);
      }

      if (selectedProjectWithOffersNew && selectedProjectWithOffersNew.offers.length > selectedProjectWithOffers.value.offers.length) {
        hasChanges.value = true;
        return;
      }
    }

    const hasOfferStatusChanged = (offersWithProjects: ProjectOfferSearchResponse[], projectsWithOffers: ProjectWithOffers[]) => offersWithProjects.some((offerWithProject) => {
      const existingOffer = projectsWithOffers
        .find((projectWithOffers) => projectWithOffers.id === offerWithProject.project.id)
        ?.offers.find((o) => o.id === offerWithProject.id);
      return existingOffer && offerWithProject.status !== existingOffer.status;
    });

    hasChanges.value = hasOfferStatusChanged(offersWithProjectsReceived, receivedProjectsWithOffers.value)
      || hasOfferStatusChanged(offersWithProjectsMade, madeProjectsWithOffers.value);
  };

  const reloadOffer = async (projectId: string, offerId: string) => {
    loadOffer.delete(projectId, offerId);
    const offer = await loadOffer(projectId, offerId);
    if (!offer) return;

    let projectWithOffers = receivedProjectsWithOffers.value.find((projectWithOffers) => projectWithOffers.id === projectId);
    if (!projectWithOffers) {
      projectWithOffers = madeProjectsWithOffers.value.find((projectWithOffers) => projectWithOffers.id === projectId);
    }
    if (!projectWithOffers) return;
    const offerIndex = projectWithOffers.offers.findIndex((o) => o.id === offerId);
    if (offerIndex < 0) return;
    projectWithOffers.offers.splice(offerIndex, 1, offer);
  };

  const checkOfferForChanges = async (projectId: string, offerId: string) => {
    isLoading.value = true;
    const offer = await getProjectOffer(projectId, offerId);
    let existingOffer = receivedProjectsWithOffers.value
      .find((projectWithOffers) => projectWithOffers.id === projectId)
      ?.offers.find((o) => o.id === offerId);
    if (!existingOffer) {
      existingOffer = madeProjectsWithOffers.value.find((projectWithOffers) => projectWithOffers.id === projectId)?.offers.find((o) => o.id === offerId);
    }

    if (!existingOffer) return;

    if (offer.status !== existingOffer.status) {
      await reloadOffer(projectId, offerId);
    }
    isLoading.value = false;
  };

  const fullReloadProjectsWithOffers = async () => {
    logDebug('reloadProjectsWithOffers');
    await loadProjectsWithOffers({ invalidateAll: true });
  };

  const offers = computed(() => selectedProjectWithOffers.value?.offers ?? []);
  const madeOffers = computed(() => []);
  const projectFilterFunction = (obj: ProjectWithOffers) => obj.project.status === projectFilter.value || projectIdsToKeepInProjectFilter.value.includes(obj.project.id);

  const filteredProjectsWithOffers = useArrayFilter(receivedProjectsWithOffers, projectFilterFunction);

  const useProjectsWithOffersSorted = useSortedArray(filteredProjectsWithOffers, projectFieldValue, 'latest', true);

  const markOfferAsRead = (offerId: string) => {
    [
      ...receivedProjectsWithOffers.value,
      ...madeProjectsWithOffers.value
    ].forEach((projectsWithOffers) => {
      const offerIndex = projectsWithOffers.offers.findIndex((offer) => offer.id === offerId);
      if (offerIndex < 0) return;
      const offer = projectsWithOffers.offers[offerIndex];
      projectsWithOffers.offers.splice(
        offerIndex, 1, { ...offer, read: true }
      );
    });
  };

  watchEffect(() => {
    const projectId = route?.params?.projectId;
    if (!projectId) {
      selectedProjectWithOffers.value = null;
      return;
    }

    selectedProjectWithOffers.value = useProjectsWithOffersSorted.sortedArray.value.find((project) => project.id === projectId) ?? null;
  });

  return {
    projectsWithOffers: receivedProjectsWithOffers,
    loadProjectsWithOffers,
    fullReloadProjectsWithOffers,
    fullCheckForChanges,
    checkOfferForChanges,
    reloadOffer,
    hasChanges,
    projectFilter,
    selectedProjectWithOffers,
    useProjectsWithOffersSorted,
    offers,
    madeOffers,
    isStatusLayerOpen,
    isNarrow: options.isNarrow,
    // service pullthrough:
    isCustomer,
    isFreelancer,
    defaultOfferFilters,
    isLoading,
    madeProjectsWithOffers,
    loadOffer,
    loadProject,
    markOfferAsRead,
    statusLayerProject,
    projectIdsToKeepInProjectFilter,
  };
});

export { useOffersProvider };

export function useReceivedOffers() {
  const store = useOffersInjected();
  if (store == null) throw new Error('Please call `useReceivedOffersProvider` on the appropriate parent component');
  return store;
}
