const useSafariCantAspectRatio = (selector: string, aspect = 16 / 9) => {
  const elements = [...document.querySelectorAll(selector)] as HTMLElement[];

  const clearHeights = () => {
    elements.forEach((elm) => elm.style.removeProperty('height'));
  };

  const setHeights = () => {
    elements.forEach((elm) => {
      // const elementInside = elm.querySelector('video, img');
      // if (!elementInside) return;
      const { width } = elm.getBoundingClientRect();
      const height = width / aspect;
      elm.style.setProperty('height', `calc(${height}px * var(--scale))`);
    });
  };

  return {
    clearHeights, setHeights
  };
};

export default useSafariCantAspectRatio;
