
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';

export enum SmileyTypes {
  Good = 'good',
  Neutral = 'neutral',
  Bad = 'bad'
}

export enum SmileySizes {
  Small = 'small',
  Normal = 'normal',
  Big = 'big',
}

const smileyFacePaths:Record<SmileyTypes, string> = {
  [SmileyTypes.Bad]: 'M15.827 9.77a.5.5 0 1 0 .004 1 .5.5 0 0 0-.004-1M8.138 9.75\n'
    + 'a.5.5 0 1 0 .004 1 .5.5 0 0 0-.004-1M8.5 16s1.313-1.312 3.5-1.312c2.188 0 3.5 1.312 3.5 1.312',
  [SmileyTypes.Good]: 'M15.5 14.688S14.187 16 12 16c-2.188 0-3.5-1.312-3.5-1.312M15.326 9.77'
    + 'c-.414 0-.75.224-.747.5 0 .276.336.5.75.5'
    + 's.75-.224.75-.5-.336-.5-.753-.5M8.637 9.75c-.414 0-.75.224-.747.5 0 .276.336.5.75.5s.75-.224.75-.5-.336-.5-.753-.5',
  [SmileyTypes.Neutral]: 'M9 15h6 M14.827 9.77a.5.5 0 1 0 .004 1 .5.5 0 0 0-.004-1M9.138 9.75a.5.5 0 1 0 .004 1 .5.5 0 0 0-.004-1'
};

export default defineComponent({
  name: 'Smiley',
  props: {
    type: {
      type: String as PropType<SmileyTypes>,
      required: true,
    },
    size: {
      type: String as PropType<SmileySizes>,
      default: 'normal',
    },
    isActive: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { type } = toRefs(props);

    const facePath = computed(() => smileyFacePaths[type.value]);

    return {
      facePath
    };
  }
});
