import { render, staticRenderFns } from "./BCXButtonDropdownResetButton.vue?vue&type=template&id=3a9226fa&scoped=true"
import script from "./BCXButtonDropdownResetButton.vue?vue&type=script&lang=ts"
export * from "./BCXButtonDropdownResetButton.vue?vue&type=script&lang=ts"
import style0 from "./BCXButtonDropdownResetButton.vue?vue&type=style&index=0&id=3a9226fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9226fa",
  null
  
)

export default component.exports