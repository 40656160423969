
import { defineComponent, PropType } from 'vue';
import BCXPanel from '@/components/molecules/BCXPanel.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import BCXPanelFullscreenMobile from '@/components/molecules/BCXPanelFullscreenMobile.vue';
import LRPopupMobileHeader from '@/components/login-register/LRPopupMobileHeader.vue';

export default defineComponent({
  name: 'LRPopup',
  components: { LRPopupMobileHeader, BCXPanel, BCXPanelFullscreenMobile },
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<'confirm' | 'content'>,
      default: 'confirm'
    },
    value: {
      type: [Boolean, String],
      default: false
    }
  },
  setup(props, { emit }) {
    const { isMobileWidth } = useResponsiveness();

    const close = () => {
      emit('input', false);
    };

    const confirm = (which: boolean) => {
      close();
      emit('confirm', which);
    };

    return {
      confirm,
      close,
      isMobileWidth
    };
  }
});
