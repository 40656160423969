import { LinkMdNode, HeadingMdNode, MdNode } from '@toast-ui/editor';

const BCXRendererNoLinks: any = {

  strike(node: MdNode, context: any) {
    return {
      type: 'html',
      content: `<span class="bcx-highlighted-text">${node.firstChild?.literal}</span>`,
    };
  },
  link(linkNode: LinkMdNode, context: any) {
    context.skipChildren();
    return null;
  },
  image(imgNode: LinkMdNode, context: any) {
    context.skipChildren();
    return null;
  },
};

export default BCXRendererNoLinks;
