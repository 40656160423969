
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BCXMessengerCollapsible',
  props: {
    isOpened: {
      type: Boolean
    }
  },
});
