
import Vue from 'vue';
import Close from '@/components/svg-components/X.vue';

export default Vue.extend({
  components: {
    Close,
  },
  props: {
    isNewStuffAvailable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loadStuff() {
      this.$emit('load-stuff');
    },
    close() {
      this.$emit('close');
    },
  },
});
