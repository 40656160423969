import placeholderImageWideSrc1 from '@/assets/svg/video-placeholders/placeholder_1_16to9.svg';
import placeholderImageWideSrc2 from '@/assets/svg/video-placeholders/placeholder_2_16to9.svg';
import placeholderImageWideSrc3 from '@/assets/svg/video-placeholders/placeholder_3_16to9.svg';
import placeholderImageWideSrc4 from '@/assets/svg/video-placeholders/placeholder_4_16to9.svg';
import placeholderImageSrc1 from '@/assets/svg/video-placeholders/placeholder_1.svg';
import placeholderImageSrc2 from '@/assets/svg/video-placeholders/placeholder_2.svg';
import placeholderImageSrc3 from '@/assets/svg/video-placeholders/placeholder_3.svg';
import placeholderImageSrc4 from '@/assets/svg/video-placeholders/placeholder_4.svg';
import { unref } from 'vue';
import { createGlobalState, MaybeElementRef } from '@vueuse/core';
import { logDebug } from '@/utils/logger';

const placeholderImages = [placeholderImageSrc1, placeholderImageSrc2, placeholderImageSrc3, placeholderImageSrc4];
const placeholderImagesWide = [placeholderImageWideSrc1, placeholderImageWideSrc2, placeholderImageWideSrc3, placeholderImageWideSrc4];

const usePlaceholderImage = createGlobalState(() => {
  const insertPlaceholderImage = (target: MaybeElementRef) => {
    target = unref(target);
    if (!target) return;
    if (!(target instanceof HTMLElement)) {
      logDebug('no html element:', target);
      return;
    }

    const isWide = !!target.closest('.one-to-many');

    const img = document.createElement('img');
    const placeholderImageURLs = (!isWide ? placeholderImages : placeholderImagesWide);
    const placeholderImageURL = placeholderImageURLs[Math.floor(Math.random() * placeholderImageURLs.length)];

    img.classList.add('video-placeholder');
    img.src = placeholderImageURL;

    target.appendChild(img);
    logDebug('Added placeholder image to', target);
  };

  return { insertPlaceholderImage };
});

export default usePlaceholderImage;
