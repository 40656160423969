import { MaybeElementRef, MaybeRef, useResizeObserver } from '@vueuse/core';
import { ref, unref, watch } from 'vue';

const useMarkBeforeWrappedElements = (element:MaybeElementRef, markerClass:MaybeRef<string> = 'before-wrapped-element') => {
  const markWrapped = () => {
    const elm = unref(element) as HTMLElement;
    if (!elm) return;

    const children = Array.from(elm.children);
    const mc = unref(markerClass);

    let lastChild: Element | null = null;
    let lastRight = 0;

    const childrenToAddClass:Element[] = [];
    children.forEach((child) => {
      const { left: childLeft, right: childRight } = child.getBoundingClientRect();

      if (lastChild !== null && childLeft < lastRight - 1) {
        childrenToAddClass.push(lastChild);
      }

      lastRight = childRight;
      lastChild = child;
    });

    children.forEach((child) => {
      if (childrenToAddClass.includes(child)) child.classList.add(mc);
      else child.classList.remove(mc);
    });
  };

  useResizeObserver(element, () => {
    setTimeout(() => markWrapped(), 100); // prevent nasty infinite loops (well, make them breakable at least)
  });

  watch(ref(element), markWrapped, { immediate: true });
};

export default useMarkBeforeWrappedElements;
