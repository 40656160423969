import { logDebug } from '@/utils/logger';
import { computed, ref, watch } from 'vue';
import {
  createGlobalState, useLocalStorage, useTimestamp, whenever
} from '@vueuse/core';

const IGNORE_NOTIFICATION_TIME = 1000 * 60 * 10;

const useAppVersionState = createGlobalState(() => {
  const timestamp = useTimestamp({ interval: 500 });

  const currentAppVersion = useLocalStorage('appVersion', '');
  const latestAppVersion = ref<string>('');

  const calculateAppVersion = (version: string) => {
    const versionBuild = version.split('-');
    const versionNumber = versionBuild[0];
    const versionBuildNumber = versionBuild[1];

    const versionArray = versionNumber.split('.');
    const major = parseInt(versionArray[0], 10);
    const minor = parseInt(versionArray[1], 10);
    const patch = parseInt(versionArray[2], 10);
    const build = parseInt(versionBuildNumber, 10);

    const majorMultiplier = 10 ** 12;
    const minorMultiplier = 10 ** 9;
    const patchMultiplier = 10 ** 6;

    return (major * majorMultiplier)
      + (minor * minorMultiplier)
      + (patch * patchMultiplier)
      + build;
  };

  const isAppUpdateAvaliable = computed(() => currentAppVersion.value !== latestAppVersion.value
    && (currentAppVersion.value !== '' && latestAppVersion.value !== '')
    && calculateAppVersion(currentAppVersion.value) < calculateAppVersion(latestAppVersion.value));

  const updateApp = () => {
    currentAppVersion.value = latestAppVersion.value;
    window.location.reload();
  };

  const ignoreNotificationUntil = ref(0);

  const ignoreNotification = () => {
    ignoreNotificationUntil.value = new Date().getTime() + IGNORE_NOTIFICATION_TIME;
  };

  const isNotificationIgnored = computed(() => ignoreNotificationUntil.value > timestamp.value);
  whenever(isNotificationIgnored, () => {
    logDebug('Notification ignored until:', new Date(ignoreNotificationUntil.value).toLocaleString());
  });

  return {
    currentAppVersion,
    latestAppVersion,
    isAppUpdateAvaliable,
    ignoreNotification,
    isNotificationIgnored,
    ignoreNotificationUntil,
    updateApp
  };
});

export default useAppVersionState;
