import { RouteConfig } from 'vue-router';
import Logout from '@/views/authentication/pages/Logout.vue';
import Login from '../pages/Login.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'login-register',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      layout: 'login-register'
    }
  }
];

export default routes;
