import { computed, Ref } from 'vue';
import { Chat, Group } from '@/models/Messenger';

/**
 * Takes an array ouf Chats and groups them by their groupType.
 * @author nc
 *
 * @param chatsRef
 */
const useGrouper = (chatsRef:Ref<Chat[]>) => {
  const groups = computed(() => {
    const groupMap = new Map<string, Group>();

    chatsRef.value.forEach((chat) => {
      if (!chat.messages.length) return;

      const linkLabel = chat?.links?.find((chatLink) => chatLink.LINKLABEL)?.LINKLABEL ?? '';

      const groupKey = chat.type + linkLabel;
      if (!groupMap.has(groupKey)) {
        groupMap.set(groupKey, {
          type: chat.type,
          title: linkLabel,
          chats: [],
          id: chat.id,
        });
      }
      const group = groupMap.get(groupKey) as Group;

      // use highest found id for the group id. Because of difference in
      // fetching order just using the first id would result in changing
      // id on some requests for the group:
      if (chat.id > group.id) group.id = chat.id;

      if (group) {
        group.chats.push(chat);
      }
    });

    return Array.from(groupMap.values()) as Group[];
  });

  return { groups };
};

export default useGrouper;
