
import { defineComponent } from 'vue';
import EditIcon from '@/components/molecules/EditIcon.vue';

export default defineComponent({
  name: 'AddIcon',
  components: { EditIcon },
  props: {
    canAdd: { type: Boolean }
  }
});
