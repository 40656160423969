
import { computed, defineComponent } from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import useI18n from '../../../../mixins/useI18n';

export default defineComponent({
  name: 'TableChatListItem',
  components: { BCXAvatar },
  props: {
    topic: {
      type: String,
      required: false,
      default: () => ''
    },
    creatorId: {
      type: String,
      required: false,
      default: () => ''
    },
    creatorFirstName: {
      type: String,
      required: false,
      default: () => ''
    },
    creatorLastName: {
      type: String,
      required: false,
      default: () => ''
    },
    participantsCount: {
      type: Number,
      required: false,
      default: () => 0
    },
    roomSize: {
      type: Number,
      required: false,
      default: () => 0
    }
  },
  emits: ['join'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const classRoomSize = computed(() => (props.participantsCount < props.roomSize ? 'room-item--info-room-size' : 'room-item--info-room-size--empty'));

    const canJoin = computed(() => props.participantsCount < props.roomSize);

    function handleJoin() {
      emit('join');
    }

    return {
      t,
      classRoomSize,
      canJoin,
      handleJoin
    };
  }
});
