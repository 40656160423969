const viewInBrowser = (attachment: string, mimeType:string) => {
  // decode the base64
  const pdfData = atob(attachment);
  // Convert data
  const byteCharacters = Array.from(pdfData).map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteCharacters);
  const blob = new Blob([byteArray], { type: mimeType });
  // Create URL
  const objectUrl = URL.createObjectURL(blob);

  window.open(objectUrl, '_blank');
};

export default viewInBrowser;
