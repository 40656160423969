
import useI18n from '@/mixins/useI18n';
import { defineComponent } from 'vue';
import BytecookieLogoOnly from '@/components/svg/logo/logo-circles-only.svg?inline';
import { useRouter } from 'vue2-helpers/vue-router';
import { logDebug } from '@/utils/logger';

export default defineComponent({
  name: 'TableChatFeedback',
  components: {
    BytecookieLogoOnly
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    function goToPub() {
      logDebug('goToPub');
      router.push({ name: 'virtual-pub' });
    }

    return {
      t,
      goToPub,
    };
  }
});
