
import { defineComponent } from 'vue';
import { templateRef } from '@vueuse/core';
import useMarkBeforeWrappedElements from '@/mixins/useMarkBeforeWrappedElements';

export default defineComponent({
  name: 'BCXMarkBeforeWrapped',
  setup() {
    const elm = templateRef<HTMLElement>('elm');
    useMarkBeforeWrappedElements(elm);
  }
});
