import { RouteConfig } from 'vue-router';
import GuestsEmployees from '@/views/guests-employees/GuestsEmployees.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/guests-employees',
    name: 'guests-employees',
    component: GuestsEmployees,
    meta: {
      auth: true,
      layout: 'full',
    },
  },
];

export default routes;
