
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import Vue from 'vue';
import useMessengerState from '@/state/messenger/messengerState';
import useResponsiveness from '@/mixins/useResponsiveness';

export default Vue.extend({
  name: 'BCXMessengerInputResizer',
  components: { BCXIcon },
  setup() {
    const {
      isInputMaximized,
      isInputFocussed,
      preventInputUnFocusOnce,
    } = useMessengerState();
    const { isMobileWidth } = useResponsiveness();
    const toggleIsInputMaximized = () => {
      isInputMaximized.value = !isInputMaximized.value;
    };

    const preventFocus = () => {
      if (isInputFocussed.value) {
        preventInputUnFocusOnce.value = true;
      }
    };

    return {
      isInputMaximized,
      toggleIsInputMaximized,
      preventFocus,
      isMobileWidth,
    };
  },
});
