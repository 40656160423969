
import useI18n from '@/mixins/useI18n';
import useTokenState from '@/state/tokenState';
import { computed, defineComponent, onMounted } from 'vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useRoute } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import useTriggerLegalTextsPopup from '@/mixins/useTriggerLegalTextsPopup';
import { LegalItem } from '@/models/Legal';
import { User } from '@/models/User';
import useUser from '@/mixins/useUser';

export default defineComponent({
  components: {
    LRPopup,
    BCXMarkdown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const { isLoggedIn } = useTokenState();

    const user = computed<User>(() => store.getters.user);
    const { displayName, isCustomer } = useUser(user);

    const { legalText, setLegalText } = useTriggerLegalTextsPopup();

    const isLandingpage = computed(() => route.name?.toLowerCase().includes('landingpage'));

    onMounted(() => {
      if (route && ['#!terms', '#!privacy', '#!legalnotice'].includes(route?.hash)) {
        setLegalText(route.hash.replace('#!', '') as LegalItem);
      }
    });

    return {
      t,
      legalText,
      setLegalText,
      isLoggedIn,
      isLandingpage,
      user,
      isCustomer,
      displayName
    };
  }
});
