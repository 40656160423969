import axios from '@/plugins/axios';
import {
  Forums, MessagePost, Forum, GROUP_ID_BUG, GROUP_ID_FEATURE, GROUP_ID_FEEDBACK, ThreadMetaResponse, ThreadUpdate,
} from '@/views/forum/models/Forum';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';
import { Tag } from './TaggingService';

export default {
  async getForumList(): Promise<Forums> {
    return axios.get('/v1/messaging/forums').then((response) => response.data);
  },
  async getForum(forumId: string): Promise<Forum> {
    return axios.get(`/v1/messaging/forums/${forumId}/groups`).then((response) => response.data);
  },
  async getThreads(forumId: string, groupId: string): Promise<Forum> {
    return axios.get(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads`).then((response) => response.data);
  },
  async createThread(forumId: string, groupId: string | undefined, title: string, messageContent: string, tagAssignmentList: Tag[] = []): Promise<Forum> {
    let uniqueBusinessType;
    switch (groupId) {
      case GROUP_ID_BUG:
        uniqueBusinessType = 'PARTICIPATION_BUG_THREAD_ENTRY';
        break;
      case GROUP_ID_FEATURE:
        uniqueBusinessType = 'PARTICIPATION_FEATURE_REQUEST_THREAD_ENTRY';
        break;
      case GROUP_ID_FEEDBACK:
        uniqueBusinessType = 'PARTICIPATION_FEEDBACK_THREAD_ENTRY';
        break;
      default:
        break;
    }
    title = BCXHTMLSanitizer.beforeSave(title);
    messageContent = BCXHTMLSanitizer.beforeSave(messageContent);
    return axios.post(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads`, {
      title, messageContent, uniqueBusinessType, tagAssignmentList
    }).then((response) => response.data);
  },
  async getThread(forumId: string, groupId: string, threadId: string): Promise<Forum> {
    return axios.get(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads/${threadId}/messages`)
      .then((response) => response.data);
  },
  async getThreadMeta(forumId: string, groupId: string, threadId: string): Promise<ThreadMetaResponse> {
    return axios.get(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads/${threadId}/meta`).then((response) => response.data);
  },
  async postThreadMessage(forumId: string, groupId: string, threadId: string, postData: MessagePost): Promise<Forum> {
    postData.messageContent = BCXHTMLSanitizer.beforeSave(postData.messageContent);
    return axios.post(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads/${threadId}/messages`, postData)
      .then((response) => response.data);
  },
  async updateThreadMessage(forumId: string, groupId: string, threadId: string, messageId: string, message: string): Promise<Forum> {
    message = BCXHTMLSanitizer.beforeSave(message);
    const postData = {
      forumId,
      threadId,
      messageId,
      messageContent: message,
    };
    return axios.put(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads/${threadId}/messages/${messageId}`, postData)
      .then((response) => response.data);
  },
  async updateThread(forumId: string, groupId: string, threadId: string, title: string): Promise<Forum> {
    title = BCXHTMLSanitizer.beforeSave(title);
    const postData: ThreadUpdate = {
      title
    };
    return axios.put(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads/${threadId}`, postData)
      .then((response) => response.data);
  },
  async trackPageViewThread(forumId: string, threadId: string, groupId: string | null = null) {
    return axios.put(`v1/messaging/forums/${forumId}/groups/${groupId}/threads/${threadId}/views`).then((response) => response.data);
  },
};
