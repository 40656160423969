
import EditIcon from '@/components/molecules/EditIcon.vue';
import useFileUpload from '@/mixins/useFileUpload';
import Vue, {
  computed, Ref, toRefs, watch
} from 'vue';

export default Vue.extend({
  name: 'UploadableItem',
  components: { EditIcon },
  props: {
    fileName: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    allowedMimeTypes: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean
    },
    contents: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    maxFileSize: {
      type: Number,
      required: false,
      default: 0
    },
    hasUploadError: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { allowedMimeTypes, maxFileSize } = toRefs(props) as {
      allowedMimeTypes: Ref<string[]>;
      maxFileSize: Ref<number>;
    };

    const {
      upload, error: fileError, fileName: uploadFileName, fileContents, mimeType, maxFileSizeMB
    } = useFileUpload(allowedMimeTypes.value, maxFileSize.value);

    watch(fileContents, (contents) => {
      emit('update:contents', contents);
    });
    watch(uploadFileName, (uploadFileName) => {
      emit('update:fileName', uploadFileName);
    });
    watch(mimeType, (mimeType) => {
      emit('update:fileType', mimeType);
    });

    const error = computed(() => (props.hasUploadError ? 'unknown_error' : fileError.value));

    return {
      error,
      maxFileSizeMB,
      upload
    };
  }
});
