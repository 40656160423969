
import useResponsiveness from '@/mixins/useResponsiveness';
import useRouterIgnoreDuplicate from '@/mixins/useRouterIngoreDuplicate';
import { Chat } from '@/models/Messenger';
import getChatLink from '@/state/messenger/getChatLink';
import useMessengerState from '@/state/messenger/messengerState';
import Vue, {
  computed, nextTick, ref, Ref, toRefs, watch
} from 'vue';
import { createVuexHelpers } from 'vue2-helpers';

export default Vue.extend({
  name: 'BCXMessengerChatHeader',
  props: {
    chat: {
      type: Object as () => Chat,
      default: () => null
    }
  },
  setup(props) {
    const { chat } = toRefs(props) as {
      chat: Ref<Chat>;
    };
    const { useState } = createVuexHelpers();
    const { isOpened } = useMessengerState();
    const { isMobileWidth } = useResponsiveness();
    const { pushRoute } = useRouterIgnoreDuplicate();
    const { isFetchingMessages } = useState('forum', ['isFetchingMessages']);

    const scrollTarget = ref<string>('');

    const link = computed(() => getChatLink(chat.value));
    const title = computed(() => link.value?.label);

    const scrollToTarget = () => {
      if (scrollTarget.value) {
        const scrollElement = document.getElementById(scrollTarget.value);
        if (scrollElement) scrollElement.scrollIntoView({ behavior: 'smooth' });
        scrollTarget.value = '';
      }
    };

    watch(isFetchingMessages, (is) => {
      if (!is) {
        nextTick(() => {
          scrollToTarget();
        });
      }
    });

    const gotoLink = async () => {
      if (!link.value?.path) return;
      if (isMobileWidth.value) {
        isOpened.value = false;
      }
      await pushRoute(link.value.path);
      if (link.value?.scrollTarget) {
        scrollTarget.value = link.value.scrollTarget;
        scrollToTarget();
      }
    };

    return {
      title,
      gotoLink
    };
  }
});
