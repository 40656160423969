import { render, staticRenderFns } from "./InvitesCodesTable.vue?vue&type=template&id=9897d06c&scoped=true"
import script from "./InvitesCodesTable.vue?vue&type=script&lang=ts"
export * from "./InvitesCodesTable.vue?vue&type=script&lang=ts"
import style0 from "./InvitesCodesTable.vue?vue&type=style&index=0&id=9897d06c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9897d06c",
  null
  
)

export default component.exports