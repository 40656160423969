import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';
import { LegalItem } from '@/models/Legal';

const useTriggerLegalTextsPopup = createGlobalState(() => {
  const legalText = ref<LegalItem | false>(false);
  const setLegalText = (type: LegalItem) => {
    legalText.value = type;
  };

  return {
    legalText,
    setLegalText
  };
});

export default useTriggerLegalTextsPopup;
