
import { defineComponent } from 'vue';
import UserDataSetting from '@/views/settings/components/settings/UserDataSetting.vue';
import EmailSetting from '@/views/settings/components/settings/EmailSetting.vue';
import CompanySetting from '@/views/settings/components/settings/CompanySetting.vue';
import useUser from '@/mixins/useUser';
import { is } from '@babel/types';

export default defineComponent({
  name: 'SectionUserDataEmail',
  components: {
    EmailSetting,
    UserDataSetting,
    CompanySetting
  },
  setup() {
    const { isCustomer } = useUser('self');
    return { isCustomer };
  },
  methods: { is }
});
