
import Vue, { computed, toRefs } from 'vue';
import CertificateEducationItem from '@/views/profile/components/CertificateEducationItem.vue';
import useProfileState from '@/state/profile/profileState';

export default Vue.extend({
  name: 'ProfileCertificatesEducationsView',
  components: { CertificateEducationItem },
  props: {
    type: { type: String, default: 'certificates' },
  },
  setup(props) {
    const { type } = toRefs(props);
    const { profile } = useProfileState();

    const list = computed(() => (type.value === 'certificates'
      ? profile.value?.certificates?.certificatesList ?? []
      : profile.value?.educations?.educationsList ?? []));

    return { list };
  },
});
