
import BCXPanel from '@/components/molecules/BCXPanel.vue';
import useMessengerState from '@/state/messenger/messengerState';
import { defineComponent } from 'vue';
import IconCleanup from '@/components/svg/cleanup.svg?inline';
import IconSound from '@/components/svg/sound.svg?inline';
import IconSoundOff from '@/components/svg/sound-off.svg?inline';
import IconNote from '@/components/svg/note.svg?inline';

export default defineComponent({
  name: 'BCXMessengerOptionsPanel',
  components: {
    BCXPanel, IconCleanup, IconSound, IconNote, IconSoundOff
  },
  setup(props, { emit }) {
    const {
      isMarkingChats, isExpanded, selectedChatId, isSoundEnabled, sendTestTone, isSoundTemporarilyMuted
    } = useMessengerState();

    const enableMarking = () => {
      isMarkingChats.value = true;
      if (!isExpanded.value && selectedChatId.value) {
        selectedChatId.value = '';
      }
      emit('close-panel');
    };

    const toggleSound = () => {
      isSoundEnabled.value = !isSoundEnabled.value;
    };
    const testSound = () => {
      sendTestTone.value = true;
    };

    return {
      enableMarking,
      isMarkingChats,
      toggleSound,
      isSoundEnabled,
      isSoundTemporarilyMuted,
      testSound
    };
  },
});
