
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BCXPoints',
  props: {
    points: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'userbanner',
    },
  },
  emits: ['points-clicked'],
  setup(props, { emit }) {
    const handleClick = () => {
      emit('points-clicked');
    };

    return {
      handleClick,
    };
  },
});
