
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import { defineComponent, ref } from 'vue';
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import useResponsiveness from '@/mixins/useResponsiveness';

export default defineComponent({
  name: 'SettingsSection',
  components: { BCXChevronSwitch, BCXCollapsible },
  props: {
    title: {
      type: String, default: ''
    }
  },
  setup() {
    const { isMobileWidth } = useResponsiveness();
    const isOpen = ref(!isMobileWidth.value);

    return {
      isOpen,
    };
  }
});
