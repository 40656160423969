
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import InvitesIcon from '@/components/svg/group.svg?inline';
import useResponsiveness from '@/mixins/useResponsiveness';
import { Invitee, PAGE_SIZE_INVITEES } from '@/models/Invitation';
import { getInvitees } from '@/services/Invitation/Api';
import InviteesTable from '@/views/invites/InviteesTable.vue';
import InviteesTableMobile from '@/views/invites/InviteesTableMobile.vue';
import { defineComponent, onMounted, ref } from 'vue';
import BCXPaginatedContent from '@/components/molecules/BCXPaginatedContent.vue';

export default defineComponent({
  name: 'Invitees',
  components: {
    BCXPaginatedContent,
    BCXMarkdown,
    InviteesTableMobile,
    InviteesTable,
    InvitesIcon
  },
  setup() {
    const { isMobileWidth } = useResponsiveness();
    const invitees = ref<Invitee[]>([]);

    onMounted(async () => {
      invitees.value = (await getInvitees())?.inviteeList ?? [];
    });

    return {
      invitees,
      isMobileWidth,
      PAGE_SIZE_INVITEES
    };
  }
});
