
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { LegalType, SettingsLegal } from '@/models/Settings';
import { getDownload } from '@/services/Settings/Api';
import downloadFile from '@/utils/downloadFile';
import { computed, defineComponent, PropType } from 'vue';
import useI18n from '@/mixins/useI18n';
import useTriggerLegalTextsPopup from '@/mixins/useTriggerLegalTextsPopup';

export default defineComponent({
  name: 'LegalButton',
  props: {
    legalObject: {
      type: Object as PropType<SettingsLegal | null>,
      default: null
    },
    isCurrent: Boolean,
    usePopup: Boolean
  },
  setup(props) {
    const { getFormattedDate } = useFormattedDateTime();
    const { t } = useI18n();
    const { setLegalText } = useTriggerLegalTextsPopup();

    const typeKey = computed(() => {
      switch (props.legalObject?.type) {
        case LegalType.LegalTerms:
          return 'terms';
        case LegalType.Privacy:
          return 'privacy';
        default:
          return '?';
      }
    });

    const download = async () => {
      const data = await getDownload(props.legalObject?.type === LegalType.Privacy ? 'privacy' : 'legalterms');
      if (data) {
        // console.log('Got data:', data);
        downloadFile(data.attachment, data.filename, data.fileType);
      }
    };

    const version = computed(() => {
      if (props.isCurrent) return t('settings.legal.current_version');
      return `${t('settings.legal.version')} ${props.legalObject?.version} (${getFormattedDate(props.legalObject?.acceptedDate)})`;
    });

    const onClick = () => {
      if (props.usePopup) {
        setLegalText(props.legalObject?.type === LegalType.LegalTerms ? 'terms' : 'privacy');
      } else download();
    };

    return {
      typeKey, download, version, onClick
    };
  }
});
