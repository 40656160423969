
import { computed, defineComponent } from 'vue';
import useI18n from '@/mixins/useI18n';
import ProjectGuestsInviteNew from '@/views/project-forum/components/ProjectGuestsInviteNew.vue';
import ProjectGuestsAddExisting from '@/views/project-forum/components/ProjectGuestsAddExisting.vue';
import ProjectGuestsTable from '@/views/project-forum/components/ProjectGuestsTable.vue';
import { useVModel } from '@vueuse/core';
import { ProjectGuest } from '@/models/Project';

export default defineComponent({
  name: 'ProjectGuests',
  components: {
    ProjectGuestsInviteNew,
    ProjectGuestsAddExisting,
    ProjectGuestsTable,
  },
  props: {
    value: {
      type: Array as () => ProjectGuest[],
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();

    const guests = useVModel(props, 'value');
    const hasGuests = computed(() => guests.value.length > 0);

    const addNewGuest = (newGuest: ProjectGuest) => {
      guests.value.push(newGuest);
    };

    return {
      t,
      guests,
      hasGuests,
      addNewGuest,
    };
  },
});
