import { render, staticRenderFns } from "./ThreadTopic.vue?vue&type=template&id=b57b92fe&scoped=true"
import script from "./ThreadTopic.vue?vue&type=script&lang=ts"
export * from "./ThreadTopic.vue?vue&type=script&lang=ts"
import style0 from "./ThreadTopic.vue?vue&type=style&index=0&id=b57b92fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b57b92fe",
  null
  
)

export default component.exports