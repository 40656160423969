
import { defineComponent } from 'vue';
import PasswordSetting from '@/views/settings/components/settings/PasswordSetting.vue';
import TwoFactorAuthSettings from '@/views/settings/components/settings/TwoFactorUsageSetting.vue';
import TwoFactorMethodSetting from '@/views/settings/components/settings/TwoFactorMethodSetting.vue';
import MobileNumberSetting from '@/views/settings/components/settings/MobileNumberSetting.vue';

export default defineComponent({
  name: 'SectionSecurity',
  components: {
    MobileNumberSetting,
    TwoFactorMethodSetting,
    TwoFactorAuthSettings,
    PasswordSetting,
  }
});
