import BytecookieLogo from '../components/svg-components/BytecookieLogo.vue';
import Search from '../components/svg-components/Search.vue';

import BytecookieLogoOnly from '../components/svg/logo/logo-circles-only.svg?inline';
import CommentDots from '../components/svg/comment-dots.svg?inline';
import Dashboard from '../components/svg/dashboard.svg?inline';
import Home from '../components/svg/home.svg?inline';
import Lightbulb from '../components/svg/profile-lightbulb.svg?inline';
import Share from '../components/svg/share.svg?inline';
import Times from '../components/svg/times.svg?inline';
import Users from '../components/svg/users.svg?inline';
import BarChat from '../components/svg/bar-chat.svg?inline';
import NewTable from '../components/svg/new-table.svg?inline';
import JoinTable from '../components/svg/join-table.svg?inline';

export default {
  bytecookieLogoOnly: {
    component: BytecookieLogoOnly,
    props: {
      name: 'bytecookieLogoOnly',
    },
  },
  bytecookieLogo: {
    component: BytecookieLogo,
    props: {
      name: 'bytecookieLogo',
    },
  },
  commentDots: {
    component: CommentDots,
    props: {
      name: 'commentDots',
    },
  },
  dashboard: {
    component: Dashboard,
    props: {
      name: 'dashboard',
    },
  },
  home: {
    component: Home,
    props: {
      name: 'home',
    },
  },
  lightbulb: {
    component: Lightbulb,
    props: {
      name: 'lightbulb',
    },
  },
  search: {
    component: Search,
    props: {
      name: 'search',
    },
  },
  share: {
    component: Share,
    props: {
      name: 'share',
    },
  },
  times: {
    component: Times,
    props: {
      name: 'times',
    },
  },
  users: {
    component: Users,
    props: {
      name: 'users',
    },
  },
  barChat: {
    component: BarChat,
    props: {
      name: 'barChat',
    },
  },
  newTable: {
    component: NewTable,
    props: {
      name: 'newTable',
    },
  },
  joinTable: {
    component: JoinTable,
    props: {
      name: 'joinTable',
    },
  },
};
