// Interval timers
// Beginning: Starting interval in milliseconds
// Ending: Ending interval in milliseconds
// Timespan: Timespan in which the interval goes from the starting to the ending interval.
export const REFRESH_INTERVAL_MESSENGER_BEGINNING = 60000;
export const REFRESH_INTERVAL_MESSENGER_ENDING = 60000 * 5;
export const REFRESH_INTERVAL_MESSENGER_TIMESPAN = 60000 * 60;

export const REFRESH_INTERVAL_NOTIFICATIONS_BEGINNING = 25000;
export const REFRESH_INTERVAL_NOTIFICATIONS_ENDING = 60000 * 4;
export const REFRESH_INTERVAL_NOTIFICATIONS_TIMESPAN = 60000 * 60;

export const TAB_INACTIVE_MULTIPLIER = 1;

export const REFRESH_INTERVAL_BANNER_ONLINE_STATUS = 60000 * 6;
// Every nth time, it will be a full status fetch:
export const REFRESH_INTERVAL_BANNER_FULL_STATUS_TIMES = 10;

// Update configuration
export const REFRESH_SERVER_CONFIG = 1000 * 3 * 60 * 60;
