
import viewInBrowser from '@/utils/viewInBrowser';
import Vue, { computed } from 'vue';
import useProfileState from '@/state/profile/profileState';
import { getReferenceDownload } from '@/services/Profile/Api';
import ViewableItem from '@/components/molecules/ViewableItem.vue';
import downloadFile from '@/utils/downloadFile';
import { useStore } from 'vue2-helpers/vuex';

export default Vue.extend({
  name: 'ProfileReferencesView',
  components: { ViewableItem },
  setup() {
    const { profile } = useProfileState();
    const references = computed(() => {
      let refs = profile.value?.references?.referencesList ?? [];
      refs = refs.sort((a, b) => b.year - a.year);
      return refs;
    });

    const isMobileOS = /iPad|iPhone|iPod|Android/.test(navigator.userAgent);
    const store = useStore();

    const view = async (referenceId:string) => {
      const { attachment, filename } = await getReferenceDownload(referenceId);
      if (attachment) {
        if (isMobileOS) {
          downloadFile(attachment, filename, 'application/pdf');
        } else {
          viewInBrowser(attachment, 'application/pdf');
        }
      }
    };

    return {
      references,
      view,
    };
  },
});
