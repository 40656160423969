import { render, staticRenderFns } from "./ReminderSwitch.vue?vue&type=template&id=e03d65b4&scoped=true"
import script from "./ReminderSwitch.vue?vue&type=script&lang=ts"
export * from "./ReminderSwitch.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e03d65b4",
  null
  
)

export default component.exports