
import BCXMultiselectFilter from '@/components/molecules/BCXMultiselectFilter.vue';
import { defineComponent } from 'vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';
import UserSearchSearchButton from '@/views/user-search/components/UserSearchSearchButton.vue';
import useI18n from '@/mixins/useI18n';
import UserSearchAvailabilitySelect from '@/views/user-search/components/UserSearchAvailabilitySelect.vue';
import { SearchCriteriumType } from '@/views/user-search/models/UserSearch';

export default defineComponent({
  components: { UserSearchAvailabilitySelect, UserSearchSearchButton, BCXMultiselectFilter },
  setup() {
    const { t } = useI18n();
    const {
      searchCriteriaItems, filters, resetEverything, isSimple, selectItems, initialFilters
    } = useUserSearch();

    return {
      searchCriteriaItems,
      filters,
      resetEverything,
      t,
      isSimple,
      selectItems,
      SearchCriteriumType,
      initialFilters
    };
  }
});
