
import Vue, { computed, ref, watch } from 'vue';
import BCXFloatingMenu from '@/components/molecules/BCXFloatingMenu.vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import { createVuexHelpers } from 'vue2-helpers';
import useResponsiveness from '@/mixins/useResponsiveness';
import useMessengerState from '@/state/messenger/messengerState';

export default Vue.extend({
  name: 'BCXFloatingNavigation',
  components: { BCXIcon, BCXFloatingMenu },
  setup(props, { emit }) {
    const { isMobileWidth, isTabletWidth } = useResponsiveness();
    const { unreadMessages, isOpened: isMessengerOpened } = useMessengerState();

    const menuOpened = ref(false);
    const { useMutations } = createVuexHelpers();
    const { toggleIsParticipateVisible, toggleIsMobileSearchVisible }:any = useMutations('page', [
      'toggleIsParticipateVisible',
      'toggleIsMobileSearchVisible',
    ]);

    const isNewMessages = computed(() => !!unreadMessages.value);

    const showMessages = () => {
      emit('show-messages');
    };

    watch(isMobileWidth, (is) => {
      if (!is && menuOpened.value) {
        menuOpened.value = false;
      }
    });

    const close = () => { menuOpened.value = false; };

    const isVisible = computed(() => !isTabletWidth.value || !isMessengerOpened.value);

    return {
      close,
      menuOpened,
      isNewMessages,
      showMessages,
      toggleIsMobileSearchVisible,
      isMobileWidth,
      toggleIsParticipateVisible,
      isVisible
    };
  },
});
