
import {
  computed, defineComponent, PropType, ref, toRefs
} from 'vue';
import GroupIcon from '@/components/svg/group.svg?inline';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import BCXPaginatedContent from '@/components/molecules/BCXPaginatedContent.vue';
import { useGuestsEmployees } from '@/views/guests-employees/composables/useGuestsEmployees';
import MemberTable from '@/views/guests-employees/components/MemberTable.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import MemberTableMobile from '@/views/guests-employees/components/MemberTableMobile.vue';
import { User } from '@sentry/vue';
import BCXConfirm from '@/components/molecules/BCXConfirm.vue';
import useBCXConfirm from '@/mixins/useBCXConfirm';
import MemberProjectList from '@/views/guests-employees/components/MemberProjectList.vue';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';

export default defineComponent({
  name: 'MemberList',
  components: {
    MemberProjectList,
    BCXConfirm,
    MemberTableMobile,
    MemberTable,
    BCXPaginatedContent,
    BCXInfoCollapsible,
    BCXHelpTextTriggerIcon,
    GroupIcon
  },
  props: {
    type: {
      type: String as PropType<'guest' | 'employee'>,
      required: true
    }
  },
  setup(props) {
    const isHelptextVisible = ref(false);
    const { isMobileWidth } = useResponsiveness();
    const { type } = toRefs(props);

    const { guests, employees, fakeProjectList } = useGuestsEmployees();

    const members = computed(() => {
      const members = (type.value === 'guest' ? guests.value : employees.value);
      return members.map((member) => {
        const numProjects = Math.floor(Math.random() * 10);
        const begin = Math.floor(Math.random() * (fakeProjectList.value.length - numProjects));
        const end = begin + numProjects;
        const fakeProjects = fakeProjectList.value.slice(begin, end);
        return {
          ...member,
          projects: fakeProjects
        };
      });
    });

    const total = computed(() => members.value?.length ?? 0);

    const { showConfirm: showDeleteConfirm, ask: askDelete, onConfirm: onConfirmDelete } = useBCXConfirm();
    const { showConfirm: showResendConfirm, ask: askResend, onConfirm: onConfirmResend } = useBCXConfirm();

    const onSendAgain = async (member: User) => {
      console.log('Would send again to:', member);
      const result = await askResend();
      console.log('Result:', result);
    };

    const onDelete = async (member: User) => {
      console.log('Would delete:', member);
      const result = await askDelete();
      console.log('Result:', result);
    };

    const onDeleteMemberProject = (member:User, project:ProjectListProject) => {
      console.log('Would delete', member.firstname, project.title);
    };

    const onAddMemberProject = (member:User, project: ProjectListProject) => {
      console.log('Would add', member.firstname, project.title);
    };

    return {
      total,
      isHelptextVisible,
      members,
      isMobileWidth,
      onSendAgain,
      onDelete,
      showDeleteConfirm,
      showResendConfirm,
      onConfirmDelete,
      onConfirmResend,
      onDeleteMemberProject,
      onAddMemberProject
    };
  }
});
