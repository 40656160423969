
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { EmailNotificationInterval, EmailNotificationType, SettingsNotificationRequest } from '@/models/Settings';
import { setNotificationSettings } from '@/services/Settings/Api';
import useSettings from '@/state/settingsState';
import { i18nToItemList } from '@/utils/selectItemTools';
import {
  computed, defineComponent, PropType, ref, toRefs, watch
} from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';

export default defineComponent({
  name: 'NotificationSetting',
  components: {
    BCXSearchSelect,
    BCXMarkdown,
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    intervalType: {
      type: String as PropType<EmailNotificationType>,
      default: ''
    },
    listTranslationKey: {
      type: String,
      default: ''
    },
    infoTextBelow: {
      type: String,
      default: null
    },
  },
  setup(props) {
    const {
      settings, loadSettings, isSafeToAutoSave
    } = useSettings();
    const {
      intervalType, listTranslationKey
    } = toRefs(props);

    const getIntervalForType = (type:EmailNotificationType):EmailNotificationInterval => (settings.value?.notificationSettings.find(
      (notification) => notification.emailNotificationType === type
    )?.emailNotificationInterval ?? EmailNotificationInterval.Never) as EmailNotificationInterval;

    const isLoading = ref(false);
    const list = i18nToItemList(listTranslationKey.value);

    const initialValue = computed(
      () => getIntervalForType(intervalType.value)
    );
    const value = ref<EmailNotificationInterval>(initialValue.value);

    const setValue = (v:EmailNotificationInterval) => {
      value.value = v;
    };

    watch(initialValue, setValue);

    const save = async () => {
      if (!settings.value) return;

      isLoading.value = true;

      const itemData = {
        emailNotificationType: intervalType.value,
        emailNotificationInterval: value.value,
      };

      const data:SettingsNotificationRequest = {
        emailNotificationList: Array.of(itemData)
      };

      await setNotificationSettings(data);
      isLoading.value = false;
      await loadSettings();
    };

    const dirty = computed(() => isSafeToAutoSave && value.value !== (initialValue.value ?? ''));

    watch(dirty, async (is) => {
      if (is) {
        await save();
      }
    });

    return {
      isLoading, list, value
    };
  }
});
