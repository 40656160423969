var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",staticClass:"bc-button",class:[
    `bc-button--type-${_vm.type}`,
    `bc-button--icon-color-${_vm.iconColor}`,
    `bc-button--color-${_vm.color}`, {
      disabled: _vm.disabled,
      active: _vm.active, outlined: _vm.outlined,
      'allow-wrap': _vm.allowWrap,
      'no-padding': _vm.noPadding,
      'no-background': _vm.noBackground,
    } ]},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.icon)?_c('div',{staticClass:"bc-button__icon"},[_c('icon-'+_vm.icon,{tag:"component"})],1):_vm._e(),_c('span',{staticClass:"bc-button__label"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }