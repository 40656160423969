
import { SETTINGS_RESEND_INTERVAL } from '@/models/ResendTimout';
import { useTimeout } from '@vueuse/core';
import Vue, { reactive, ref } from 'vue';
import RegistrationService from '@/services/RegistrationService';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vue2-helpers/vuex';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import { useRouter } from 'vue2-helpers/vue-router';
import { TranslatedErrorCode } from '@/models/BackendError';

export default Vue.extend({
  name: 'VerifyMobilephone',
  components: {
    ButtonBlock, BackendErrors, BCXValidatedInput, LRPopup, BCXMarkdown
  },

  setup() {
    const submitting = ref(false);
    const { isPending: isResending, start: startResendTimer } = useTimeout(SETTINGS_RESEND_INTERVAL, { controls: true, immediate: false });
    const store = useStore();
    const router = useRouter();
    const showConfirm = ref(false);
    const backendErrors = ref<TranslatedErrorCode[]>([]);
    const { mobileNumber } = store.getters;
    const { required } = useBCValidators();
    const fields = reactive({
      smsCode: ''
    });

    const rules = {
      smsCode: { required }
    };

    const v = useVuelidate(rules, fields);

    const submit = async () => {
      if (!submitting.value && await v.value.$validate()) {
        submitting.value = true;
        const backendErrorsObject = await store.dispatch('verifyMobilephone', fields.smsCode);
        backendErrors.value = backendErrorsObject.allErrors;
        submitting.value = false;
      }
    };

    const resend = () => {
      showConfirm.value = true;
    };

    const goBack = () => {
      router.push({
        name: 'register-mobilephone'
      });
    };

    const resendSMS = async (isConfirmed: boolean) => {
      if (isResending.value) return;
      if (!isConfirmed) {
        goBack();
        return;
      }
      startResendTimer();
      await RegistrationService.resendSMS();
    };

    return {
      isResending,
      mobileNumber,
      fields,
      v,
      onConfirmed: resendSMS,
      showConfirm,
      goBack,
      resend,
      submit,
      backendErrors
    };
  },

});
