
import { computed, defineComponent, toRefs } from 'vue';

import gfxStep1 from '@/assets/img/landingpage-customer/gfx-step-1_x3.webp';
import gfxStep2 from '@/assets/img/landingpage-customer/gfx-step-2_x3.webp';
import gfxStep3 from '@/assets/img/landingpage-customer/gfx-step-3_x3.webp';
import gfxStep4 from '@/assets/img/landingpage-customer/gfx-step-4_x3.webp';
import gfxStep5 from '@/assets/img/landingpage-customer/gfx-step-5_x3.webp';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useMediaQuery } from '@vueuse/core';
import LPStepIcon from '@/views/landingpage/components/LPStepIcon.vue';
import LPBgArrow from '@/views/landingpage/components/LPBgArrow.vue';

const gfxSteps = [gfxStep1, gfxStep2, gfxStep3, gfxStep4, gfxStep5];

export default defineComponent({
  name: 'LPCustomerStep',
  components: { LPBgArrow, LPStepIcon, BCXMarkdown },
  props: {
    step: {
      type: Number,
      default: 1
    },
    text: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const { step } = toRefs(props);

    const gfxStepImage = computed(() => gfxSteps[step.value - 1]);

    const isSmartphone = useMediaQuery('(max-width: 799px)');

    const finalStepSize = computed(() => (isSmartphone.value ? 75 : 100));

    const color = computed(() => {
      switch (step.value) {
        case 1:
        case 4:
          return '#3092D0';
        case 5:
          return '#00B01C';
        default:
          return '#F4A226';
      }
    });

    return {
      gfxStepImage,
      color,
      finalStepSize,
      isSmartphone
    };
  }
});
