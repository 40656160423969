import { Ability } from '@/models/Ability';

interface Permissions {
  [key: string]: Ability[];
}

export default {
  rolePermissions: {
    ALL_USERS: [
      {
        action: 'use',
        subject: 'Platform',
      },
    ],
    USER_IN_REGISTRATION: [],
    USER_IN_APPROVAL: [],
    SYSTEM_USER: [],
  } as Permissions,

  additionalPermissions: {
    START_CONVERSATIONS: [
      {
        action: 'create',
        subject: 'VideoChat',
      },
    ],
  } as Permissions,
};
