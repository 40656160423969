import {
  PropertyState, RecommendationApiWarning, RecommendationErrorCodeMapping, ResponseRecommendationVerify
} from '@/models/Recommendations';
import { PossibleErrorResponse } from '@/models/BackendError';
import { MaybeRef } from '@vueuse/core';
import { computed, unref } from 'vue';

const useRecommendationVerifyResult = (verification: MaybeRef<ResponseRecommendationVerify & PossibleErrorResponse | null>) => {
  const recommendationKey = computed(() => {
    const v = unref(verification);
    if (!v) return null;
    const code = v?.error?.code ? RecommendationErrorCodeMapping[v.error.code] : v?.warning as RecommendationApiWarning ?? null;
    if (code === RecommendationApiWarning.PROJECTPREFERENCES_DO_NOT_MATCH) return null;
    return code;
  });

  const messageKey = computed(() => `recommendations.apiErrors.${recommendationKey.value}`);

  const notMatchingReasons = computed<string[] | null>(() => unref(verification)?.projectPreferencesPropertyList?.verifyResultList?.map(
    (entry) => entry.propertyKey
  ) ?? null);

  const hasMandatoryMatchingReason = computed(
    () => unref(verification)?.projectPreferencesPropertyList?.verifyResultList?.find(
      ({ propertyState }) => propertyState === PropertyState.MANDATORY
    )
  );

  const isWarning = computed(() => !!unref(verification)?.warning);
  const isError = computed(() => !!unref(verification)?.error);

  const canRecommend = computed(() => !(
    isError.value || hasMandatoryMatchingReason.value
  ));

  return {
    recommendationKey,
    messageKey,
    notMatchingReasons,
    isWarning,
    isError,
    canRecommend,
    hasMandatoryMatchingReason
  };
};

export default useRecommendationVerifyResult;
