
import { ExternalRecommendation } from '@/models/Recommendations';
import MyRecommendationsTableMobileItem from '@/views/recommendations/components/my-recommendations/MyRecommendationsTableMobileItem.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'MyRecommendationsTableMobile',
  components: { MyRecommendationsTableMobileItem },
  props: {
    recommendations: {
      type: Array as PropType<ExternalRecommendation[]>,
      default: () => []
    }
  }
});
