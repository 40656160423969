
import Vue, { computed, ref } from 'vue';
import PasswordResetService from '@/services/PasswordResetService';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import { useStore } from 'vue2-helpers/vuex';
import { useRouter } from 'vue2-helpers/vue-router';

export default Vue.extend({
  name: 'PasswordResetConfirmation',
  components: { LRPopup, BCXMarkdown },
  setup() {
    const store = useStore();
    const router = useRouter();
    const resent = ref(false);
    const resending = ref(false);
    const resendError = ref(false);
    const showConfirm = ref(false);
    const isConfirmed = ref(false);

    const email = computed(() => store.getters.email);

    const resend = () => {
      if (!isConfirmed.value) {
        showConfirm.value = true;
        // console.log('ShowConfirm is true now.');
        return;
      }
      // console.log('Would resend.');
      isConfirmed.value = false;
      resending.value = true;
      PasswordResetService.resendEmail(store.getters.email)
        .then(() => {
          resent.value = true;
          resending.value = false;
          resendError.value = false;
        })
        .catch(() => {
          resendError.value = true;
          resending.value = false;
        });
    };

    const onConfirmed = (is:boolean) => {
      // console.log('Confirmed?', is);
      isConfirmed.value = is;
      if (is) resend();
      else {
        router.push({ name: 'password-reset' });
      }
    };

    return {
      resent,
      resending,
      resendError,
      email,
      resend,
      onConfirmed,
      showConfirm
    };
  },
});
