
import { onClickOutside, templateRef } from '@vueuse/core';
import Vue, { computed } from 'vue';
import NotificationPanel from '@/components/toolbar/notification/NotificationPanel.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import useNotificationState from '@/state/notificationState';
import { NotificationType } from '@/models/Notification';
import NotificationBar from './NotificationBar.vue';

export default Vue.extend({
  components: {
    NotificationPanel,
    // NotificationBanner,
    NotificationBar,
  },
  setup() {
    const { isSmartphoneWidth } = useResponsiveness();
    const {
      whichPanel, showPanel, panelPos
    } = useNotificationState();

    const active = computed(() => (isSmartphoneWidth.value && showPanel.value ? whichPanel.value : ''));

    const togglePanel = (which: NotificationType | '', pos:number) => {
      if (isSmartphoneWidth.value) {
        panelPos.value = pos;
        showPanel.value = whichPanel.value === which ? !showPanel.value : true;
      } else {
        showPanel.value = !showPanel.value;
      }
      whichPanel.value = which;
    };

    const clickOutside = () => {
      showPanel.value = false;
    };

    onClickOutside(templateRef<HTMLElement>('notification'), () => clickOutside());

    return {
      showPanel,
      whichPanel,
      panelPos,
      isSmartphoneWidth,
      active,
      togglePanel,
      clickOutside,
    };
  },
});
