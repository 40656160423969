
import Vue from 'vue';
import CookieFull from '@/components/svg/cookie-full.svg?inline';
import CookieBite from '@/components/svg/cookie-bite.svg?inline';
import CookieCrumbs from '@/components/svg/cookie-crumbs.svg?inline';

export default Vue.extend({
  name: 'BcxProgressCookies',
  components: {
    CookieFull,
    CookieBite,
    CookieCrumbs,
  },
  props: {
    currentStatus: {
      type: Number,
      default: 0,
    },
    maxProgress: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
    };
  },
  computed: {

  },
  methods: {
    getProgressCookieFullStatus(index: number):boolean {
      if (index > this.currentStatus) {
        return true;
      }
      return false;
    },
    getProgressCookieBiteStatus(index: number):boolean {
      if (index === this.currentStatus) {
        return true;
      }
      return false;
    },
    getProgressCookieCrumbsStatus(index: number):boolean {
      if (index < this.currentStatus) {
        return true;
      }
      return false;
    },
    getProgressCookieClass(index: number): string {
      const className = ['bcx-progress-cookies__progress-cookie'];
      if (index < this.currentStatus) {
        className.push('bcx-progress-cookies__progress-cookie--done');
      } else if (index === this.currentStatus) {
        className.push('bcx-progress-cookies__progress-cookie--current');
      } else {
        className.push('bcx-progress-cookies__progress-cookie--todo');
      }
      return className.join(' ');
    },
  }
});
