import Recommendations from '@/views/recommendations/pages/Recommendations.vue';
import { RouteConfig } from 'vue-router';
import store from '@/store';

const routes: Array<RouteConfig> = [
  {
    path: '/recommendations',
    name: 'recommendations',
    component: Recommendations,
    meta: {
      layout: 'full',
      auth: true,
    },
  }
];

export default routes;
