import {
  computed, ref, Ref, watch
} from 'vue';

const useIndexedOpenStates = (array: Ref<unknown[]>) => {
  const openStates = ref<boolean[]>([]);

  const length = computed(() => array.value.length);

  watch(length, (length) => {
    openStates.value = Array(length).fill(false);
  }, { immediate: true });

  const setState = (index: number, state: boolean) => {
    openStates.value.splice(index, 1, state);
  };

  const toggleState = (index: number) => {
    setState(index, !openStates.value[index]);
  };

  return {
    openStates,
    toggleState,
    setState
  };
};

export default useIndexedOpenStates;
