
import { computed, defineComponent } from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import LPButton from '@/views/landingpage/components/LPButton.vue';
import { computedEager, useMediaQuery } from '@vueuse/core';
import useMultiLineBreaks from '@/mixins/useMultiLineBreaks';
import useI18n from '@/mixins/useI18n';
import LPBgImage from '@/views/landingpage/components/LPBgImage.vue';

import BGLogoDesktop from '@/assets/img/landingpage-switch/Frame Logo Desktop.webp';
import BGLogoTablet from '@/assets/img/landingpage-switch/Frame Logo Tablet.webp';
import BGLogoSmartphone from '@/assets/img/landingpage-switch/Frame Logo Smartphone.webp';

import BGCircleDesktop from '@/assets/img/landingpage-switch/Frame Circle Desktop.webp';
import BGCircleTablet from '@/assets/img/landingpage-switch/Frame Circle Tablet.webp';
import BGCircleSmartphone from '@/assets/img/landingpage-switch/Frame Circle Smartphone.webp';

import BGPeopleDesktop from '@/assets/img/landingpage-switch/Frame People Desktop.webp';
import BGPeopleTablet from '@/assets/img/landingpage-switch/Frame People Tablet.webp';
import BGPeopleSmartphone from '@/assets/img/landingpage-switch/Frame People Smartphone.webp';

const bgsLogo = [BGLogoDesktop, BGLogoTablet, BGLogoSmartphone];
const bgsCircle = [BGCircleDesktop, BGCircleTablet, BGCircleSmartphone];
const bgsPeople = [BGPeopleDesktop, BGPeopleTablet, BGPeopleSmartphone];

export default defineComponent({
  name: 'LandingpageSwitch',
  components: {
    LPBgImage,
    LPButton,
    BCXMarkdown
  },
  setup() {
    const { t } = useI18n();
    const isSmartphone = useMediaQuery('(width < 800px)');
    const isTablet = useMediaQuery('(800px <= width < 1020px)');

    const backgroundNr = computedEager(() => {
      if (isSmartphone.value) return 2;
      if (isTablet.value) return 1;
      return 0;
    });

    const lineBreak = computed(() => (isSmartphone.value ? 'mobile' : 'desktop'));
    const linebreaks = t('landingpage-switch.linebreaks') as any;
    const { tl } = useMultiLineBreaks(linebreaks, lineBreak);

    const bgLogo = computed(() => bgsLogo[backgroundNr.value]);
    const bgCircle = computed(() => bgsCircle[backgroundNr.value]);
    const bgPeople = computed(() => bgsPeople[backgroundNr.value]);

    return {
      tl,
      bgLogo,
      bgCircle,
      bgPeople
    };
  }
});
