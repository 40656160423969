
import { defineComponent } from 'vue';
import BCXSkeleton from '@/components/molecules/BCXSkeleton.vue';

export default defineComponent({
  components: {
    BCXSkeleton
  },
  setup() {
    return {};
  },
});
