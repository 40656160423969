import _axios from '@/plugins/axios';
import router from '@/router';
import {
  computed, isRef, ref, watch
} from 'vue';
import {
  createGlobalState, useLocalStorage, useTimestamp, whenever
} from '@vueuse/core';
import * as Sentry from '@sentry/vue';
import useOnlineState from '@/state/onlineState';
import {
  isDebugLogLevel, logDebug, logError, logWarning
} from '@/utils/logger';

const TOKEN_REFRESH_TIMEOUT_THRESHOLD_TIMER = 120000;
const TOKEN_REFRESH_TIMEOUT_THRESHOLD_REQUEST = 300000;
const MIN_RENEW_THRESHOLD = 60000;

const useTokenState = createGlobalState(() => {
  const accessToken = useLocalStorage('token', '');
  const refreshToken = useLocalStorage('refresh_token', '');
  const timestamp = useTimestamp({ interval: 500 });
  const isLoggedIn = computed(() => !!accessToken.value);
  const isRefreshing = useLocalStorage('isRefreshing', false);
  const lastRefresh = ref(0);
  const { isOnline } = useOnlineState();

  isRefreshing.value = false;

  const getExpiresFromToken = (token:string): number => {
    // return new Date().getTime() + 20000;
    try {
      const payload = token.split('.')[1];
      const decodedPayload = JSON.parse(window.atob(payload));
      return decodedPayload.exp * 1000;
    } catch (e) {
      logError('Error while parsing token', e);
      return 0;
    }
  };

  const accessTokenExpireTime = computed(() => getExpiresFromToken(accessToken.value));
  const accessTokenTimeLeft = computed(() => (accessToken.value ? accessTokenExpireTime.value - timestamp.value : 1000000));
  const isAccessTokenExpired = computed(() => accessTokenTimeLeft.value <= 0);
  const isAccessTokenExpiringSoonTimer = computed(() => (accessTokenTimeLeft.value < TOKEN_REFRESH_TIMEOUT_THRESHOLD_TIMER && isOnline.value));
  const isAccessTokenExpiringSoonRequest = computed(() => accessTokenTimeLeft.value < TOKEN_REFRESH_TIMEOUT_THRESHOLD_REQUEST);
  const isReadyForNextRenewal = computed(() => (lastRefresh.value < timestamp.value - MIN_RENEW_THRESHOLD) || (accessTokenTimeLeft.value < 0));

  const addBearerIfNotPresent = (token:string) => (token.startsWith('Bearer') ? token : `Bearer ${token}`);
  const removeBearerIfPresent = (token:string) => token.replace('Bearer ', '');

  const accessTokenBearer = computed(() => addBearerIfNotPresent(accessToken.value));
  const accessTokenPlain = computed(() => removeBearerIfPresent(accessToken.value));

  const renew = async (): Promise<boolean> => {
    const refreshTokenExpires = getExpiresFromToken(refreshToken.value);
    if (Date.now() >= refreshTokenExpires) {
      throw new Error('refresh token expired');
    }

    logDebug('renewing token, time left till expiration:', accessTokenTimeLeft.value);

    const params = new URLSearchParams({
      access_token: accessTokenPlain.value,
      refresh_token: refreshToken.value,
      grant_type: 'refresh_token'
    });

    if (isReadyForNextRenewal.value) {
      const response = await _axios
        .post('/v1/users/user/token', params.toString(), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).catch((error) => {
          if (error.response.status === 428 || error.response.status === 409) {
            Sentry.captureMessage(`Got ${error.response.status} status from the server. The x-request-ID is ${error?.config?.headers?.['X-Request-ID'] ?? '[unset]'}`);
            Sentry.captureException(error);
          }
          return null;
        });

      logDebug(`Response with status ${response?.status} from renewing token`);

      if (response === null) {
        throw new Error('Couldn\'t renew token');
      }

      if (response?.status === 200) {
        accessToken.value = addBearerIfNotPresent(response.headers.authorization);
        refreshToken.value = response.headers.refresh_token;
        lastRefresh.value = new Date().getTime();
        logDebug('token renewed');
        return true;
      }
    } else logWarning(`Not ready for next renewal. last refresh: ${new Date(lastRefresh.value)}, now: ${new Date()}`);

    return false;
  };

  const tryTokenRenewal = async (callback?: CallableFunction): Promise<void> => {
    logDebug('Trying to renew token');
    if (isRefreshing.value === true) {
      logDebug('Already refreshing token');
      return;
    }
    isRefreshing.value = true;
    await renew()
      .then(() => {
        if (callback) callback();
      })
      .catch(async (status: string) => {
        if (status.toString().includes('refresh token expired')) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          logWarning('refresh token expired, logging out');
          router.push({ name: 'logout' });
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
        logWarning(`renew failed! Status: ${status} trying again:`);
        await renew()
          .then(() => {
            logDebug('Second try successful');
            if (callback) callback();
          })
          .catch(async (status) => {
            logError(`renew failed again, Status: ${status} logging out`);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            router.push({ name: 'logout' });
          }).finally(() => {
            isRefreshing.value = false;
          });
      }).finally(() => {
        isRefreshing.value = false;
      });
  };

  const clear = () => {
    accessToken.value = '';
    refreshToken.value = '';
  };

  whenever(isAccessTokenExpiringSoonTimer, async () => {
    logDebug('Access token expires soon, refresh by timer');
    if (isOnline.value) {
      tryTokenRenewal();
    }
  });

  if (isDebugLogLevel()) {
    watch(isRefreshing, (is) => logDebug(is ? 'REFRESH START' : 'REFRESH FINISHED'));
  }

  return {
    accessToken,
    refreshToken,
    renew,
    isAccessTokenExpired,
    clear,
    isLoggedIn,
    isAccessTokenExpiringSoonTimer,
    isAccessTokenExpiringSoonRequest,
    accessTokenTimeLeft,
    accessTokenExpireTime,
    accessTokenPlain,
    accessTokenBearer,
    isRefreshing,
    isOnline,
    tryTokenRenewal
  };
});

export default useTokenState;
