import TestingArea from '@/views/testingarea/TestingArea.vue';
import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    name: 'testingarea',
    path: '/testingarea',
    component: TestingArea,
    meta: {
      layout: 'full',
      auth: true
    }
  }
];

export default routes;
