

import { defineComponent, PropType, toRefs } from 'vue';
import { User } from '@/models/User';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import useIndexedOpenStates from '@/utils/useIndexedOpenStates';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import EditIcon from '@/components/molecules/EditIcon.vue';

export default defineComponent({
  name: 'MemberTable',
  components: {
    EditIcon,
    BCXCollapsible,
    BCXChevronSwitch,
    BCXUserBanner,
  },
  props: {
    members: {
      type: Array as PropType<User[]>,
      default: () => []
    },
    type: {
      type: String as PropType<'guest' | 'employee'>,
      required: true
    }
  },
  setup(props) {
    const { members } = toRefs(props);
    const rnd = () => Math.random() > 0.5;

    const { openStates, setState } = useIndexedOpenStates(members);

    return {
      openStates,
      setState,
      rnd
    };
  }
});

