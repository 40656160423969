
import {
  defineComponent, computed, ref, PropType
} from 'vue';
import { BCXExtendedDropdownItem } from '@/components/molecules/forms/BCXMultiselectSection.vue';
import { useVModel } from '@vueuse/core';
import useI18n from '@/mixins/useI18n';
import BCXMultiselectFilter from '@/components/molecules/BCXMultiselectFilter.vue';
import { UserRoles } from '@/models/User';

export default defineComponent({
  name: 'BlogEntryAudienceFilter',
  components: {
    BCXMultiselectFilter
  },
  props: {
    value: {
      type: Array as PropType<BCXExtendedDropdownItem[]>,
      default: () => [],
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const audienceSelected = useVModel(props);

    const audienceItems = computed(() => Object.keys(UserRoles).sort().map((key, index) => ({ value: key, label: key })));
    return {
      t,
      audienceSelected,
      audienceItems
    };
  }
});
