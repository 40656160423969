// Proxy for easier use in composition api

import VueI18n from '@/i18n';
import { computed } from 'vue';
import { UserType } from '@/models/User';
import { getUserTypeTranslationKey } from '@/utils/user';

export default () => ({

  t: (key:string, ...args:any) => VueI18n.t(key, ...args),
  tc: (key:string, ...args:any) => VueI18n.tc(key, ...args),
  te: (key:string, ...args:any) => VueI18n.te(key, ...args),
  tUserType: (key:string, userType: UserType, ...args:any) => VueI18n.t(getUserTypeTranslationKey(key, userType), ...args),
  tUserTypeFallBack: (key:string, userType: UserType, ...args:any) => {
    const userKey = getUserTypeTranslationKey(key, userType);
    const fallbackKey = key.replaceAll('_USERTYPE', '');

    return VueI18n.te(userKey) ? VueI18n.t(userKey, ...args) : VueI18n.t(fallbackKey, ...args);
  },
  locale: computed({
    get():string {
      return VueI18n.locale;
    },
    set(locale:string) {
      VueI18n.locale = locale;
    },
  }),
});
