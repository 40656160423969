
import {
  computed, defineComponent, onMounted, ref
} from 'vue';
import { templateRef, useIntervalFn } from '@vueuse/core';

export default defineComponent({
  setup() {
    const media = templateRef<HTMLDivElement>('media');
    const aspectRatio = ref(16 / 9);
    const partnerIsPortrait = computed(() => aspectRatio.value < 1.2);

    onMounted(() => {
      useIntervalFn(
        () => {
          const videoElement = media.value.querySelector('video');
          if (!videoElement) return;
          aspectRatio.value = videoElement.videoWidth / videoElement.videoHeight;
        },
        500,
        {
          immediateCallback: true
        }
      );
    });

    return {
      aspectRatio,
      partnerIsPortrait
    };
  }
});
