import {
  computed, onUnmounted, ref, watchEffect
} from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';

export default function useChatRoomRouter() {
  const uniqueRoomNameQuery = 'unique-room-name';
  const barChatQuery = 'bar-chat';

  const router = useRouter();
  const route = useRoute();
  const isBarChat = ref(false);
  const uniqueRoomNameFromRoute = ref();
  const isSimulation = computed(() => route.query.simulation);

  const barChatQueryStop = watchEffect(() => {
    if (router.currentRoute.query[barChatQuery]) {
      isBarChat.value = router.currentRoute.query[barChatQuery] === 'true';
    }
  });

  const uniqueRoomNameQueryStop = watchEffect(async () => {
    if (router.currentRoute.query[uniqueRoomNameQuery]) {
      uniqueRoomNameFromRoute.value = router.currentRoute.query[uniqueRoomNameQuery];
    }
  });

  onUnmounted(() => {
    barChatQueryStop();
    uniqueRoomNameQueryStop();
  });

  async function routeTo(barChat: 'true' | 'false', uniqueRoomName?: string) {
    await router.push({ name: 'chat-room', query: { 'bar-chat': barChat, 'unique-room-name': uniqueRoomName } });
  }

  async function routeToTableChatAsOwner(uniqueRoomName: string) {
    await routeTo('false', uniqueRoomName);
  }

  async function routeToBarChat() {
    await routeTo('true');
  }

  return {
    isBarChat,
    uniqueRoomNameFromRoute,
    routeToTableChatAsOwner,
    routeToBarChat,
    isSimulation
  };
}
