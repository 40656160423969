
import { computed, defineComponent, ref } from 'vue';

import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import EditIcon from '@/components/molecules/EditIcon.vue';
import ProjectSelector from '@/views/project-forum/components/sidebar-actions/ProjectSelector.vue';
import { useGuestsEmployees } from '@/views/guests-employees/composables/useGuestsEmployees';
import { ExternalProjectListEntry } from '@/__generated__/types';

export default defineComponent({
  name: 'AddGuest',
  components: { ProjectSelector, EditIcon, BCXInfoSection },
  setup() {
    const selectedProject = ref<ExternalProjectListEntry | null>(null);
    const { projectList, projectName } = useGuestsEmployees();

    const onSelectProject = (project: ExternalProjectListEntry) => {
      selectedProject.value = project;
      projectName.value = project.title ?? '';
    };

    const canAdd = computed(() => !!selectedProject.value);

    return {
      canAdd, projectList, projectName, onSelectProject
    };
  }
});
