
import {
  computed, defineComponent, Ref, ref, ComputedRef
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';

import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXRadio from '@/components/molecules/forms/BCXRadio.vue';
import X from '@/components/svg-components/X.vue';
import { useRoute } from 'vue2-helpers/vue-router';
import router from '@/router';
import { VoteReasonType, VoteOption, UpvotePayload } from '@/models/Voting';
import { Message } from '@/views/forum/models/Forum';

export default defineComponent({
  components: {
    BCXAvatar,
    BCXRadio,
    X,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();

    const user = computed(() => store.getters.user);
    const node = ref(null);
    const showThx: Ref<boolean> = ref(false);

    const headerTitle = computed(() => t('voting.positive.what'));

    const upvoteOptions: ComputedRef<VoteOption[]> = computed(() => {
      const options: VoteOption[] = Object.values(t('voting.positive.options') as any);
      if (options.length > 0) {
        return options.map((item) => {
          const { label, value } = item;
          return { label, value };
        });
      }
      return [];
    });

    function sendVote(value: VoteReasonType) {
      const payload: UpvotePayload = {
        voteType: 'UP',
        reason: value,
        link: undefined,
      };

      let { href } = router.resolve({
        name: 'projectForumThread',
        params: { projectId: store.state.projectForum.project.id },
        hash: `#message-${store.state.voting.businessObjectId}`
      });
      const projectOrThreadIncludesBusinessObjectId = computed(() => {
        const { businessObjectId } = store.state.voting;
        return store.state.projectForum.thread.initialMessage.messageId === businessObjectId
        || store.state.forum.thread.initialMessage.messageId === businessObjectId
        || store.getters['projectForum/getThreadRepliesSorted'].map((reply: Message) => reply.messageId).includes(businessObjectId)
        || Object.keys(store.getters['forum/getThreadMessagesMap']).includes(businessObjectId);
      });

      if (['MESSAGE', 'PROJECT'].includes(store.state.voting.businessObjectType) && projectOrThreadIncludesBusinessObjectId.value) {
        if (route?.name === 'projectForumThread') {
          payload.link = href;
        }
        if (route?.name === 'thread') {
          href = router.resolve(
            {
              name: 'thread',
              params: {
                forumId: store.state.forum.thread.forumId,
                groupId: store.state.forum.thread.groupId,
                threadId: store.state.forum.thread.threadId
              },
              hash: `#message-${store.state.voting.businessObjectId}`
            }
          ).href;
          payload.link = href;
        }
      }
      store.dispatch('voting/vote', payload).then(() => {
        showThx.value = true;
      });
    }

    function closeModal() {
      store.dispatch('voting/resetVotingReporting');
    }

    return {
      node,
      showThx,
      user,
      headerTitle,
      upvoteOptions,
      closeModal,
      sendVote,
      t,
    };
  },
});
