
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import DownloadableItem from '@/components/molecules/DownloadableItem.vue';
import ViewableItem from '@/components/molecules/ViewableItem.vue';
import downloadFile from '@/utils/downloadFile';
import { getExperienceDownload, getOwnExperienceDownload } from '@/services/Profile/Api';
import useProfileState from '@/state/profile/profileState';
import viewInBrowser from '@/utils/viewInBrowser';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'ProfileProjectsView',
  components: { BCXMarkdown, DownloadableItem, ViewableItem },
  setup() {
    const {
      user, profile, isSelf, userId
    } = useProfileState();
    const isMobileOS = /iPad|iPhone|iPod|Android/.test(navigator.userAgent);

    const store = useStore();

    const documentLabel = computed(() => profile.value?.experiences?.experiencesDocumentLabel ?? '');
    const text = computed(() => profile.value?.experiences?.experiencesText ?? '');

    const getDownloadData = async () => (isSelf.value ? getOwnExperienceDownload() : getExperienceDownload(userId.value));
    let sanitizedFullname = 'User';

    if (user.value && user.value.fullname) {
      sanitizedFullname = user.value.fullname.replace(/ /g, '-');
    }

    const download = async () => {
      if (documentLabel.value) {
        const { attachment = '', filename = '' } = await getDownloadData();
        if (attachment) {
          const newFilename = `ByteCookie_${sanitizedFullname}_Projekte-und-Erfahrungen`;
          downloadFile(attachment, newFilename, 'application/pdf');
        }
      }
    };

    const view = async () => {
      if (documentLabel.value) {
        const { attachment = '' } = await getDownloadData();
        if (attachment) {
          if (isMobileOS) {
            const newFilename = `ByteCookie_${sanitizedFullname}_Projekte-und-Erfahrungen`;
            downloadFile(attachment, newFilename, 'application/pdf');
          } else {
            viewInBrowser(attachment, 'application/pdf');
          }
        }
      }
    };

    return {
      text,
      documentLabel,
      download,
      view,
      isSelf,
      user
    };
  }
});

