
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import ProfileSection from '@/views/profile/components/ProfileSection.vue';
import useProfileState from '@/state/profile/profileState';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileInvitedBy',
  components: {
    BCXUserBanner,
    ProfileSection,
  },
  props: {
    isEmployee: Boolean
  },
  setup() {
    const { profile, isQuickInfo } = useProfileState();
    const user = computed(() => profile.value?.basic?.invitedBy);
    return { user, isQuickInfo };
  },
});
