
import { defineComponent } from 'vue';
import BgImage from '@/views/landingpage/components/freelancer/BgImage.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useFreelancerLandingPage from '@/views/landingpage/components/freelancer/useFreelancerLandingPage';

export default defineComponent({
  name: 'FreelancerWelcome',
  components: { BCXMarkdown, BgImage },
  setup() {
    const { tl } = useFreelancerLandingPage();
    return { tl };
  }
});
