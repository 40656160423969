
import { PropType } from 'vue';
import { NotificationListItem } from '@/models/Notification';

export default {
  name: 'NotificationPanelProjectItem',
  props: {
    gotoItem: { type: Function },
    items: { type: Array as PropType<NotificationListItem[]>, default: () => [] },
    header: { type: String, default: '' }
  }
};
