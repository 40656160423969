import axios from '@/plugins/axios';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';
import { Forum, MessagePost } from '@/views/forum/models/Forum';
import {
  PostProject, Project, ProjectListProject
} from '@/views/project-forum/models/ProjectForum';

export default {

  async getProjectList(): Promise<ProjectListProject[]> {
    /**
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Projects-Endpoint/get_api_v1_projects
     */
    return axios.get('v1/projects').then(({ data }) => data.externalProjectList);
  },

  async getProject(projectId: string): Promise<Project> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Projects-Endpoint/get_api_v1_projects__projectId_
     *
     * 167a5a5b-bce2-4031-845d-00f3bb146dc1 Project X
     * 67878fda-e1d1-45f1-a5d7-65f5fff00f7d Das Lazarus Projekt
     */
    return axios.get(`v1/projects/${projectId}`).then(({ data }) => data);
  },

  async getProjectForumMessages(projectId: string): Promise<Forum> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Messaging-Forum-Endpoints/get_api_v1_messaging_forums_projects__projectId_
     */
    return axios.get(`v1/messaging/forums/projects/${projectId}`).then(({ data }) => data);
  },

  async postNewProject(project: PostProject): Promise<Project> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Projects-Endpoint/post_api_v1_projects
     */
    project.descriptionLong = BCXHTMLSanitizer.beforeSave(project.descriptionLong);
    project.title = BCXHTMLSanitizer.beforeSave(project.title);
    return axios.post('v1/projects', project).then(({ data }) => data);
  },

  async updateProject(projectId: string, project: PostProject): Promise<Project> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Projects-Endpoint/put_api_v1_projects__projectId_
     */
    project.descriptionLong = BCXHTMLSanitizer.beforeSave(project.descriptionLong);
    project.title = BCXHTMLSanitizer.beforeSave(project.title);
    return axios.put(`v1/projects/${projectId}`, project).then(({ data }) => data);
  },

  async postThreadMessage(forumId: string, groupId: string, threadId: string, postData: MessagePost): Promise<Forum> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Messaging-Forum-Endpoints/post_api_v1_messaging_forums__forumId__groups__groupId__threads__threadId__messages
     */
    postData.messageContent = BCXHTMLSanitizer.beforeSave(postData.messageContent);
    return axios.post(`/v1/messaging/forums/${forumId}/groups/${groupId}/threads/${threadId}/messages`, postData)
      .then((response) => response.data);
  },
};
