
import { defineComponent } from 'vue';
import LPExplainVideo from '@/views/landingpage/components/LPExplainVideo.vue';
import FreelancerLogo from '@/views/landingpage/components/freelancer/FreelancerLogo.vue';
import FreelancerWelcome from '@/views/landingpage/components/freelancer/FreelancerWelcome.vue';
import FreelancerFeatures from '@/views/landingpage/components/freelancer/FreelancerFeatures.vue';
import BgImage from '@/views/landingpage/components/freelancer/BgImage.vue';
import FreelancerInvitation from '@/views/landingpage/components/freelancer/FreelancerInvitation.vue';

export default defineComponent({
  name: 'LandingpageFreelancer',
  components: {
    FreelancerInvitation,
    BgImage,
    FreelancerFeatures,
    FreelancerWelcome,
    FreelancerLogo,
    LPExplainVideo,
  },
});
