import { RouteConfig } from 'vue-router';
import Invites from '@/views/invites/Invites.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/invites',
    name: 'invites',
    component: Invites,
    meta: {
      auth: true,
      layout: 'full',
    },
  },
];

export default routes;
