
import useProfileState from '@/state/profile/profileState';
import { computed, defineComponent } from 'vue';
import { ProjectPreferences } from '@/models/Profile';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  name: 'ProfilePreferencesView',
  setup() {
    const { profile } = useProfileState();
    const { t } = useI18n();

    const companySizeObject = computed(() => t('profile.projectPreferences.companySizeOptions'));
    const remoteWorkObject = computed(() => t('profile.projectPreferences.remoteWorkOptions'));
    const microProjectObject = computed(() => t('profile.projectPreferences.microProjectOptions'));

    const preferences = computed(() => profile.value?.projectPreferences as ProjectPreferences);

    const location = computed(() => {
      const p = preferences.value;
      if (!p.country) return '';
      return `${p.placeName ? `${p.placeName}, ` : ''}${p.country}${p.radiusInKm ? ` (+${p.radiusInKm}km)` : ''}`;
    });

    return {
      location,
      preferences,
      companySizeObject,
      microProjectObject,
      remoteWorkObject,
    };
  },
});
