export default function isEmptyDeep(obj:any, deathCounter = 0):boolean {
  if (!obj) return false;
  if (Array.isArray(obj)) {
    if (!obj.length) {
      return false;
    }
  }
  if (typeof obj === 'object') {
    if (deathCounter > 100) return true;
    return Object.values(obj).every((value) => !isEmptyDeep(value, deathCounter++));
  }
  return true;
}
