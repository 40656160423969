
import { defineComponent, toRef } from 'vue';

export default defineComponent({
  name: 'BcxProgressDots',
  props: {
    currentStatus: {
      type: Number,
      default: 0,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    maxProgress: {
      type: Number,
      default: 5,
    },
  },
  emits: [
    'itemClicked'
  ],
  setup(props, { emit }) {
    const status = toRef(props, 'currentStatus');

    function getProgressCookieClass(index: number): string {
      const className = ['bcx-progress-dots__progress-dot'];
      if (index < status.value) {
        className.push('bcx-progress-dots__progress-dot--done');
      } else if (index === status.value) {
        className.push('bcx-progress-dots__progress-dot--current');
      } else {
        className.push('bcx-progress-dots__progress-dot--todo');
      }
      if (props.isClickable) {
        className.push('bcx-progress-dots__progress-dot--clickable');
      }
      return className.join(' ');
    }

    function itemClicked(index: number): void {
      if (props.isClickable) {
        emit('itemClicked', index);
      }
    }

    return {
      getProgressCookieClass,
      itemClicked,
    };
  }
});
