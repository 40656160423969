
import {
  computed, defineComponent, onUnmounted, ref, watch, onMounted
} from 'vue';
import { useMediaControls } from '@vueuse/core';
import useI18n from '@/mixins/useI18n';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import RefreshIcon from '@/components/svg/refresh.svg?inline';
import X from '@/components/svg-components/X.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import useDeviceSettings from '../../../composable/settings/useDeviceSettings';
import useAudioOutputLevel from '../../../composable/settings/useAudioOutputLevel';
import ProgressIndicator from '../../ProgressIndicator.vue';

export default defineComponent({
  components: {
    BCXSearchSelect,
    ProgressIndicator,
    X,
    RefreshIcon
  },

  props: {
    showTwilioVideo: {
      type: Boolean,
      default: false
    },
    isClosable: {
      type: Boolean,
      default: false
    }
  },

  emits: ['openChat', 'changeDeviceSettings', 'close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const { isMobileWidth } = useResponsiveness();

    const isAddressBarVisible = computed(() => {
      if (!isMobileWidth.value) {
        return false;
      }
      return window.innerHeight < window.screen.availHeight;
    });

    const {
      gain, level, microphones, currentMicrophone, cameras, currentCamera, speakers, currentSpeaker, stream, toggleVideo, isVideoEnabled
    } = useDeviceSettings();
    const streamReady = computed(() => (stream.value?.getVideoTracks().length ?? 0) > 0);

    /* video */
    const videoRef = ref<HTMLVideoElement>();

    const audioRef = ref<HTMLAudioElement>();
    const { volume } = useMediaControls(videoRef);
    const { level: speakerLevel, release: releaseOutputLevel } = useAudioOutputLevel(audioRef);

    watch([volume], () => {
      if (!audioRef.value) {
        return;
      }
      audioRef.value.volume = volume.value;
    });

    watch(
      () => stream.value,
      (newStream) => {
        if (!newStream || !streamReady.value) {
          return;
        }
        setTimeout(() => {
          const deviceSettings = JSON.parse(localStorage.getItem('barChatDeviceSettings') ?? '{}');
          volume.value = deviceSettings.volume ?? 0.5;
          gain.value = deviceSettings.gain ?? 0.6;
          if (videoRef.value && stream.value) {
            videoRef.value.srcObject = stream.value;
          }
        }, 50);
      }
    );

    const isOpenChatEnabled = computed(() => currentCamera.value && currentMicrophone.value);

    const currentDeviceSettings = computed(() => ({
      cameraDeviceId: currentCamera.value,
      microphoneDeviceId: currentMicrophone.value,
      speakerDeviceId: currentSpeaker.value,
      isVideoEnabled: isVideoEnabled.value,
      gain: gain.value,
      volume: volume.value
    }));

    const saveCurrentDeviceSettings = () => {
      localStorage.setItem('barChatDeviceSettings', JSON.stringify(currentDeviceSettings.value));
    };

    onUnmounted(() => {
      saveCurrentDeviceSettings();
      releaseOutputLevel();
    });

    function openChat() {
      if (isOpenChatEnabled.value) {
        saveCurrentDeviceSettings();
        emit(props.showTwilioVideo ? 'changeDeviceSettings' : 'openChat', {
          cameraDeviceId: currentCamera,
          microphoneDeviceId: currentMicrophone,
          speakerDeviceId: currentSpeaker
        });
      }
    }

    onMounted(() => {
      const deviceSettings = JSON.parse(localStorage.getItem('barChatDeviceSettings') ?? '{}');
      volume.value = deviceSettings.volume ?? 0.5;
      currentCamera.value = deviceSettings.cameraDeviceId;
      currentMicrophone.value = deviceSettings.microphoneDeviceId;
      gain.value = 0;
      volume.value = 0;
    });

    const isRefreshingDevices = ref(false);
    const refreshDevices = async () => {
      isRefreshingDevices.value = true;
      navigator.mediaDevices.dispatchEvent(new Event('devicechange'));
      setTimeout(() => {
        isRefreshingDevices.value = false;
      }, 1000);
    };

    const close = () => {
      emit('close');
    };

    const handleTestVolumeClick = () => {
      if (audioRef.value) {
        audioRef.value.volume = 1;
        audioRef.value.play();
      }
    };

    return {
      t,
      isAddressBarVisible,
      openChat,
      isOpenChatEnabled,
      /* microphone */
      currentMicrophone,
      microphones,
      microphonePlaceholder: computed(() => t('participate.category_placeholder') as string),
      microphoneLevel: level,
      gain,
      /* /microphone */
      /* video */
      video: videoRef,
      isVideoEnabled,
      cameras,
      currentCamera,
      cameraPlaceholder: computed(() => t('participate.category_placeholder') as string),
      toggleVideo,
      /* /video */
      /* speaker */
      speakers,
      currentSpeaker,
      speakerPlaceholder: computed(() => t('participate.category_placeholder') as string),
      volume,
      speakerLevel,
      audioRef,
      stream,
      streamReady,
      /* /speaker */
      close,
      handleTestVolumeClick,
      isRefreshingDevices,
      refreshDevices
    };
  }
});
