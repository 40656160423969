
import { computed, defineComponent } from 'vue';
import { ExternalVideoGroupRoom } from '@/__generated__/types';
import useI18n from '../../../../mixins/useI18n';
import VirtualPubSectionPanel from '../molecules/VirtualPubSectionPanel.vue';
import useRooms from '../../composable/rooms/useRooms';
import TableChatListItem from './TableChatListItem.vue';
// import TableChatListFilters from './TableChatListFilters.vue';
import useChatRoomRouter from '../../composable/useChatRoomRouter';
import { useBarOpeningHours } from '../../composable/useBarOpeningHours';

export default defineComponent({
  components: {
    VirtualPubSectionPanel,
    TableChatListItem,
    // TableChatListFilters
  },
  setup() {
    const { t } = useI18n();
    const { rooms } = useRooms();
    const { routeToTableChatAsOwner } = useChatRoomRouter();
    const { isBarOpen } = useBarOpeningHours();

    const availableSeats = computed(() => rooms.value.map((room) => ((room.maxParticipants ?? 0) - (room.numberOfActiveUsers ?? 0))).reduce((a, b) => a + b, 0));
    const canJoinAnyRoom = computed(() => rooms.value.length > 0 && availableSeats.value > 0 && isBarOpen.value);

    async function joinRoom(room?: ExternalVideoGroupRoom) {
      if (room) {
        const { uniqueRoomName } = room;
        if (!uniqueRoomName) return;
        await routeToTableChatAsOwner(uniqueRoomName);
        return;
      }
      const availableRooms = rooms.value.filter((r) => r.maxParticipants !== undefined && r.numberOfActiveUsers !== undefined && (r.maxParticipants - r.numberOfActiveUsers) > 0);
      const { uniqueRoomName } = availableRooms[Math.floor(Math.random() * availableRooms.length)];
      if (uniqueRoomName) {
        await routeToTableChatAsOwner(uniqueRoomName);
      }
    }

    return {
      t,
      rooms,
      canJoinAnyRoom,
      joinRoom
    };
  }
});
