import { BannerUserState } from '@/models/UserState';

export const MIN_DISPLAYED_CODES = 5;

export const PAGE_SIZE_INVITEES = 5;

export enum InviteStatus {
  OPEN = 'OPEN',
  SUCCESSFUL = 'SUCCESSFUL',
  NOT_SUCCESSFUL = 'NOT_SUCCESSFUL',
}

export interface InviteCode {
  inviteCode: string;
  userMarkedAsUsed: boolean;
  userNote?: string;
  userMarkedAsUsedTs?: string;
  affiliate: boolean;
}

export interface InviteCodesResponse {
  inviteCodes: InviteCode[];
}

export interface Invitee {
  invitedUserId: string;
  inviteCode?: string;
  affiliateCode?: string;
  userNote?: string;
  status: InviteStatus;
  userState?: BannerUserState;
}

export interface Invitees {
  inviteeList: Invitee[];
}

export interface InviteCodeUpdateRequest {
  userNote: string;
  userMarkedAsUsed: boolean;
}
