import { RouteConfig } from 'vue-router';
import useI18n from '@/mixins/useI18n';
import LandingpageSwitch from '@/views/landingpage/LandingpageSwitch.vue';
import Index from '../Index.vue';

const { t } = useI18n();

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: /^(www\.)?bytecookie\.net$/i.test(window.location.host) ? LandingpageSwitch : Index,
    meta: {
      layout: /^(www\.)?bytecookie\.net$/i.test(window.location.host) ? 'landingpage' : 'login-register',
      title: t('landingpage.title'),
      description: t('landingpage.meta'),
    },
  },
];

export default routes;
