import { ConnectOptions } from 'twilio-video';
import { useDeviceSettingsContext } from '../settings/useDeviceSettingsContext';

export default function useConnectOptions() {
  const { currentCamera, currentMicrophone } = useDeviceSettingsContext();

  function createConnectionOptions(uniqueRoomName: string, isBarChat = false, isMobile = false) {
    let connectOptions: ConnectOptions = {
      name: uniqueRoomName,
      audio: {
        deviceId: currentMicrophone.value
      },
      maxAudioBitrate: 16000, // For music remove this line
      video: {
        width: 1280,
        height: 720,
        frameRate: 24,
        deviceId: currentCamera.value,
      },
      ...isMobile && {
        bandwidthProfile: {
          video: {
            maxSubscriptionBitrate: 2500000
          }
        }
      }
    };
    if (!isBarChat) {
      connectOptions = {
        ...connectOptions,
        bandwidthProfile: {
          video: {
            mode: 'grid'
          }
        },
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: { local: 1, remote: 1 }
      };
    }

    return connectOptions;
  }

  return {
    createConnectionOptions
  };
}
