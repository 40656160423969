import axios from '@/plugins/axios';

export interface UserProjectCountResponse {
  remainingProjectsCount?: number;
}

export const getUserProjectCount = async ():Promise<UserProjectCountResponse> => {
  const result = await axios.get('/v1/users/user/remaining-projects').catch(() => null);
  return result?.data ?? null;
};
