
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import Marvin from '@/components/svg/marvin/marvin_sleeping.svg?inline';
import { AvailabilityInterface } from '@/models/Availability';
import { ProfileStoreKey } from '@/models/Profile';
import { ExternalProject } from '@/models/Recommendations';
import recommendationService from '@/services/RecommendationService';
import useMessengerState from '@/state/messenger/messengerState';
import useProfileState from '@/state/profile/profileState';
import ProfileSidebarAvailability from '@/views/profile/components/sidebar/ProfileSidebarAvailability.vue';
import ProjectSelector from '@/views/project-forum/components/sidebar-actions/ProjectSelector.vue';
import ProfileSidebarProjectSummary from '@/views/profile/components/sidebar/ProfileSidebarProjectSummary.vue';
import {
  computed, defineComponent, PropType, ref, toRefs
} from 'vue';
import { asyncComputed, useDebounce } from '@vueuse/core';
import RecommendWarningErrors from '@/views/recommendations/components/RecommendWarningErrors.vue';
import RecommendNotMatchingReasons from '@/views/recommendations/components/RecommendNotMatchingReasons.vue';
import RecommendedMessage from '@/views/recommendations/components/recommend-project-to-users/RecommendedMessage.vue';
import BCXSidebarHelpContainer from '@/components/molecules/BCXSidebarHelpContainer.vue';
import useRecommendation from '@/views/recommendations/composables/useRecommendation';
import useUser from '@/mixins/useUser';
import RecommendationMessageBox from '@/views/recommendations/components/RecommendationMessageBox.vue';

export default defineComponent({
  name: 'RecommendUserForProjects',
  components: {
    RecommendationMessageBox,
    BCXSidebarHelpContainer,
    RecommendedMessage,
    RecommendNotMatchingReasons,
    RecommendWarningErrors,
    Marvin,
    BCXMarkdown,
    ProjectSelector,
    ProfileSidebarProjectSummary,
    ProfileSidebarAvailability
  },
  props: {
    availability: {
      type: Object as PropType<AvailabilityInterface | null>,
      default: null
    },
    isInQuickInfo: {
      type: Boolean
    }
  },
  setup(props) {
    const { isInQuickInfo } = toRefs(props);
    const projectName = ref('');
    const selectedProject = ref<ExternalProject | null>(null);
    const projectNameDebounced = useDebounce(projectName, 250);
    const { isOpened: isMessengerOpened } = useMessengerState();
    const { userId, user } = useProfileState(ProfileStoreKey.PROFILE);
    const { displayName: userName } = useUser(user);

    const projectList = asyncComputed<ExternalProject[]>(
      async () => (await recommendationService.searchProjects(projectNameDebounced.value.toLocaleLowerCase())).externalProjectList,
      []
    );

    const projectId = computed(() => selectedProject.value?.id ?? '');

    const {
      projectVerification,
      recommendationKey, messageKey, notMatchingReasons,
      cantRecommend: disabled,
      recommend,
      message,
      hasBeenRecommended,
      verifiedProject
    } = useRecommendation(projectId, userId);

    const resultMessageKey = computed(() => (!recommendationKey.value ? null : messageKey.value));

    const select = (item: any) => {
      selectedProject.value = item;
      if (item) {
        if (!isInQuickInfo.value) isMessengerOpened.value = false;
        hasBeenRecommended.value = false;
      }
    };

    return {
      resultMessageKey,
      projectName,
      projectList,
      projectVerification,
      verifiedProject,
      select,
      selectedProject,
      recommend,
      disabled,
      message,
      userId,
      userName,
      user,
      hasBeenRecommended,
      notMatchingReasons,
    };
  }
});
