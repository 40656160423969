
import BCXMessengerGroup from '@/components/messenger/Group/BCXMessengerGroup.vue';
import useMessengerState from '@/state/messenger/messengerState';
import {
  CHAT_TYPE_DIRECT, CHAT_TYPE_FORUM, CHAT_TYPE_PROJECT, Group
} from '@/models/Messenger';
import BCXMessengerGroupCollapsible from '@/components/messenger/Group/BCXMessengerGroupCollapsible.vue';
import {
  computed, defineComponent, provide
} from 'vue';
import { traverseMessagesInGroups } from '@/state/messenger/traverseHelpers';
import withNewCount from '@/utils/withNewCount';
import { debouncedRef, templateRef } from '@vueuse/core';
import BCXMessengerLoadingSkeleton from '@/components/messenger/Group/BCXMessengerLoadingSkeleton.vue';
import BCXMessengerChatPreview from '@/components/messenger/Chat/BCXMessengerChatPreview.vue';
import MarvinLookDown from '@/components/svg/marvin/marvin_look_down_straight.svg?inline';
import MarvinSad from '@/components/svg/marvin/marvin_sad.svg?inline';
import BCXLazy from '@/components/molecules/BCXLazy.vue';
import BCXMessengerChatPreviewLoader from '@/components/messenger/Chat/BCXMessengerChatPreviewLoader.vue';

export default defineComponent({
  name: 'BCXMessengerConversations',
  components: {
    BCXMessengerChatPreviewLoader,
    BCXMessengerChatPreview,
    BCXMessengerLoadingSkeleton,
    BCXMessengerGroupCollapsible,
    BCXMessengerGroup,
    BCXLazy,
    MarvinLookDown,
    MarvinSad
  },
  setup() {
    const {
      groupsFilteredSorted: groups,
      isForumGroupOpened,
      isProjectGroupOpened,
      isMarkingChats,
      isLoading,
      markedChats,
      isLoadingFirstTime,
      isFlat,
      chatsSorted,
      searchTerm,
      showNewFilter,
      groupFilters,
      chats,
      removeMarkedChats,
    } = useMessengerState();

    provide('scrollBoundary', templateRef('groups'));

    const privateGroups = computed(() => groups.value.filter(({ type }: { type: string }) => type === CHAT_TYPE_DIRECT));
    const forumGroups = computed(() => groups.value.filter(({ type }: { type: string }) => type === CHAT_TYPE_FORUM));
    const projectGroups = computed(() => groups.value.filter(({ type }: { type: string }) => type === CHAT_TYPE_PROJECT));

    const shouldForumGroupsOpen = computed(() => forumGroups.value.some(({ shouldOpen }) => shouldOpen));
    const shouldProjectGroupsOpen = computed(() => projectGroups.value.some(({ shouldOpen }) => shouldOpen));

    const groupsUnreadMessages = (groups: Group[]) => {
      let unread = 0;
      traverseMessagesInGroups(groups, (message) => {
        if (!message.read) unread++;
      });
      return unread;
    };

    const forumGroupsUnreadMessages = computed(() => groupsUnreadMessages(forumGroups.value));
    const projectGroupsUnreadMessages = computed(() => groupsUnreadMessages(projectGroups.value));

    const isForumGroupReallyOpened = computed<boolean>({
      get: () => shouldForumGroupsOpen.value || isForumGroupOpened.value,
      set: (is) => {
        isForumGroupOpened.value = is;
      },
    });

    const isProjectGroupReallyOpened = computed<boolean>({
      get: () => shouldProjectGroupsOpen.value || isProjectGroupOpened.value,
      set: (is) => {
        isProjectGroupOpened.value = is;
      },
    });

    const noResults = computed(() => {
      const resultsAvailable = !!chats.value.length;
      const filtersActive = !!searchTerm.value || showNewFilter.value || !groupFilters.all;
      const noEntries = isFlat.value ? !chatsSorted.value.length
        : !forumGroups.value.length && !privateGroups.value.length && !projectGroups.value.length;

      return resultsAvailable && filtersActive && noEntries;
    });

    const noChats = computed(() => !chats.value.length);

    return {
      isMarkingChats,
      isLoading: debouncedRef(isLoading, 500),
      privateGroups,
      forumGroups,
      projectGroups,
      isForumGroupReallyOpened,
      isProjectGroupReallyOpened,
      forumGroupsUnreadMessages,
      projectGroupsUnreadMessages,
      withNewCount,
      removeMarkedChats,
      markedChats,
      isLoadingFirstTime,
      noResults,
      isFlat,
      chatsSorted,
      noChats
    };
  },
});
