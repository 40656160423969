// eslint-disable-next-line import/prefer-default-export
import { Ref } from 'vue';
import { Editor } from '@toast-ui/vue-editor';

export type ButtonType = 'undo' | 'redo';

export const buttonSVGs = {
  undo: `<svg width="32" height="32" viewBox="0 2 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8 10L9 12.8L11.8 15.6" style="fill:var(--messenger-icon-color)"/>
    <path d="M11.8 10L9 12.8L11.8 15.6V10Z" style="stroke:var(--messenger-icon-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 12.8H17.4C20.4931 12.8 23 15.0979 23 17.9333C23 20.7688 20.4931 23.0667 17.4 23.0667H10"
    style="stroke:var(--messenger-icon-color)"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,

  redo: `<svg width="32" height="32" viewBox="0 2 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2 10L23 12.8L20.2 15.6" style="fill:var(--messenger-icon-color)"/>
    <path d="M20.2 10L23 12.8L20.2 15.6V10Z" style="stroke:var(--messenger-icon-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 12.8H14.6C11.5069 12.8 8.99998 15.0979 8.99998 17.9333C8.99998 20.7688 11.5069 23.0667
    14.6 23.0667H22.0667" style="stroke:var(--messenger-icon-color)"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
};

const useToastUiEditorTools = (editorRef:Ref<Editor | undefined>) => {
  const createButton = (which:ButtonType): HTMLButtonElement => {
    const button = document.createElement('button');
    button.style.margin = '0';
    button.innerHTML = buttonSVGs[which];
    button.addEventListener('click', () => {
      if (editorRef.value) editorRef.value.invoke('exec', which);
    });
    return button;
  };

  return { createButton };
};

export default useToastUiEditorTools;
