
import { defineComponent, ref } from 'vue';
import { templateRef, useElementVisibility, watchImmediate } from '@vueuse/core';

export default defineComponent({
  name: 'BCXLazy',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    removeInvisible: Boolean
  },
  emits: ['show', 'hide'],
  setup(props, { emit }) {
    const el = templateRef<HTMLElement>('el');
    const hasBeenVisible = ref(false);
    // const isVisible = ref(false);
    const elementBounding = ref<DOMRect | null>(null);
    let isNextIntersectionIgnored = false;

    const measureElement = () => {
      elementBounding.value = el.value.getBoundingClientRect();
    };

    const isVisible = useElementVisibility(el);

    watchImmediate(isVisible, (is) => {
      if (isNextIntersectionIgnored) {
        isNextIntersectionIgnored = false;
        return;
      }
      if (is) {
        hasBeenVisible.value = true;
        emit('show');
      } else if (hasBeenVisible.value && props.removeInvisible) {
        measureElement();
        emit('hide');
        isNextIntersectionIgnored = true;
      }
    });

    return { hasBeenVisible, isVisible, elementBounding };
  }
});
