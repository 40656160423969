import axios from '@/plugins/axios';
import { UpdateVoting, UpdateVotingResponse } from '@/models/Voting';
import { AxiosResponse } from 'axios';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';

export default {
  async updateVoting(payload: UpdateVoting): Promise<UpdateVotingResponse> {
    if (payload.downVoteReason) {
      payload.downVoteReason = BCXHTMLSanitizer.beforeSave(payload.downVoteReason);
    }
    return axios
      .post('/v1/voting/vote', payload)
      .then((res: AxiosResponse) => res.data);
  },
};
