import useI18n from '@/mixins/useI18n';
import { SelectItem } from '@/models/SelectItem';
import { ref, Ref, watch } from 'vue';

export const getItemByValue = (listItems:SelectItem[] | undefined | null, searchValue:string | number) => (
  listItems?.length ? listItems.find(({ value }) => value === searchValue) : null);

export const getItemByText = (listItems:SelectItem[] | undefined | null, searchText:string) => (
  listItems?.length ? listItems.find(({ text }) => text === searchText) : null);

export const getTextByValue = (listItems:SelectItem[] | undefined | null, searchValue:string) => (
  listItems?.length ? getItemByValue(listItems, searchValue)?.text ?? '' : '');

export const getValueByText = (listItems:SelectItem[] | undefined | null, searchText:string) => (
  listItems?.length ? getItemByText(listItems, searchText)?.value ?? '' : '');

export const objectToItemList = (object: Record<string, string>): SelectItem[] => Object.entries(object)
  .map(([key, value]) => ({
    value: key,
    text: value,
  }));
export const convertToItemList = (items:any[], valueKey:string, textKey:string):SelectItem[] => items.map((item:any) => ({
  value: item[valueKey],
  text: item[textKey],
}));

export const i18nToItemList = (translationKey:string, tSubstitute:any = null):Ref<SelectItem[] | null> => {
  const { locale, t: tI18n } = useI18n();

  const t = tSubstitute ?? tI18n;

  const selectedItems = ref<SelectItem[] | null>(null);

  const getList = () => {
    const translateResult = t(translationKey);
    if (typeof translateResult === 'object') {
      if (Array.isArray(translateResult)) {
        return translateResult.map((result, idx) => ({
          value: `${idx}`,
          text: result as string
        }));
      }
      return Object.entries(translateResult)
        .map(([value, translateResult]) => ({
          value,
          text: `${translateResult}`
        }));
    }
    return null;
  };

  watch(locale, () => {
    selectedItems.value = getList();
  }, { immediate: true });

  return selectedItems;
};
