
import Smiley, { SmileySizes, SmileyTypes } from '@/components/svg-components/Smiley.vue';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'SmileyButton',
  components: { Smiley },
  props: {
    type: {
      type: String as PropType<SmileyTypes>,
      required: true
    },
    value: {
      type: String as PropType<SmileyTypes | ''>,
      required: true
    }
  },
  setup(props) {
    const { type } = toRefs(props);
    const value = useVModel(props);

    const isSelected = computed(() => value.value === type.value);
    const isActive = computed(() => !value.value || isSelected.value);
    const size = computed(() => (isSelected.value ? SmileySizes.Big : SmileySizes.Normal));

    const select = () => {
      value.value = isSelected.value ? '' : type.value;
    };

    return {
      isActive, size, select
    };
  }
});
