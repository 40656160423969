
import {
  computed, defineComponent, PropType, ref, Ref
} from 'vue';
import BCXButtonDropdown from '@/components/molecules/forms/BCXButtonDropdown.vue';
import BCXButtonDropdownResetButton from '@/components/molecules/forms/BCXButtonDropdownResetButton.vue';
import BCXMultiselectSection, { BCXExtendedDropdownItem, BCXExtendedDropdownItemValue } from '@/components/molecules/forms/BCXMultiselectSection.vue';
import { useVModel } from '@vueuse/core';
import hasArrayEqualContents from '@/utils/hasArrayEqualContents';

export default defineComponent({
  name: 'BCXMultiselectFilter',
  components: {
    BCXMultiselectSection,
    BCXButtonDropdownResetButton,
    BCXButtonDropdown
  },
  props: {
    items: {
      type: Array as () => BCXExtendedDropdownItem[],
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [Array, String] as PropType<BCXExtendedDropdownItemValue[] | BCXExtendedDropdownItemValue | null>,
      default: null
    },
    defaultValue: {
      type: [Array, String] as PropType<BCXExtendedDropdownItemValue[] | BCXExtendedDropdownItemValue | null>,
      default: null
    },
    required: Boolean,
    maxPanelWidth: {
      type: Number,
      default: 250
    },
    customSizeSelection: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const showDropdown: Ref<boolean> = ref(false);
    const isMultiple = computed(() => Array.isArray(props.value));
    const selectedValue = useVModel(props);

    const selectionCount = computed(() => {
      if (isMultiple.value) {
        return (selectedValue.value as BCXExtendedDropdownItemValue[]).length;
      }
      return selectedValue.value ? 1 : 0;
    });

    const setShowDropdown = (value: boolean) => {
      showDropdown.value = value;
    };

    const hasCustomSelection = computed(() => {
      if (Array.isArray(props.defaultValue) && Array.isArray(props.value)) return !hasArrayEqualContents(props.defaultValue, props.value);
      return props.defaultValue !== props.value;
    });

    const resetFilter = () => {
      selectedValue.value = props.defaultValue;
    };

    const titleWithCount = computed(() => (hasCustomSelection.value ? `${props.title} (${selectionCount.value})` : props.title));

    return {
      selectedValue,
      setShowDropdown,
      showDropdown,
      resetFilter,
      hasCustomSelection,
      titleWithCount
    };
  }
});
