
import Vue, {
  computed, ref, toRef, watch, nextTick, onMounted,
  toRefs
} from 'vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { convertToItemList } from '@/utils/selectItemTools';
import useUser from '@/mixins/useUser';
import { useStore } from 'vue2-helpers/vuex';
import { useVModel } from '@vueuse/core';

export default Vue.extend({
  components: {
    BCXSearchSelect
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    countryCode: {
      type: String,
      default: 'DE'
    },
    placeName: {
      type: String,
      default: undefined
    },
    postalCode: {
      type: String,
      default: undefined
    },
    industryTag: {
      type: String,
      default: ''
    },
    companySizeValue: {
      type: String,
      default: 'RANGE_50_500'
    },
    countryList: {
      type: Array,
      default: () => []
    },
    cityList: {
      type: Array,
      default: () => []
    },
    industryList: {
      type: Array,
      default: () => []
    },
    isCreatingProject: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const { tUserFallback: tUser, isCustomer } = useUser('self');
    const model = useVModel(props, 'value', emit);

    const text = ref('');
    watch(() => text.value, (value) => {
      emit('input', value);
    });

    const isCompanyProject = computed(() => store.getters['projectForum/isCompanyProject']);
    const companyUser = computed(() => {
      if (isCompanyProject.value) {
        return store.getters['userStates/getUserInfo'](store.state.projectForum.project.company.id);
      }
      return null;
    });

    const selectedCityName = ref('');
    const selectedCityPostalcode = ref('');
    const selectedCityCountryCode = ref('');

    const country = ref('DE');
    const countryCode: any = toRef(props, 'countryCode');
    const countryList: any = toRef(props, 'countryList');
    const placeName: any = toRef(props, 'placeName');
    const countryItems = computed(() => convertToItemList(countryList.value, 'isoCode', 'countryName'));

    const city = ref('');
    const cityList: any = toRef(props, 'cityList');
    const postalCode: any = toRef(props, 'postalCode');
    const placeText = ({
      placeName,
      postalCode
    }: any) => (postalCode || placeName ? `${postalCode}, ${placeName}` : '');
    const cityItems = computed(() => cityList.value.map((city: any) => {
      const text = placeText(city);
      return ({
        text,
        value: text
      });
    }));

    const getDropdownItems = (translationKey: string) => {
      const translation = {
        ...(tUser(
          `project-forum_USERTYPE.form-section.client.${translationKey}.dropdown`
        ) as any)
      };
      const keys = Object.keys(translation);
      return keys.map((key) => ({
        text: translation[key],
        value: key
      }));
    };

    const companySizeItems = computed(() => getDropdownItems('company-size'));
    const companySize = ref('RANGE_50_500');
    const companySizeValue: any = toRef(props, 'companySizeValue');
    const showCompanySize = computed(() => true);

    const industry = ref('');
    const industryList: any = toRef(props, 'industryList');
    const industryTag: any = toRef(props, 'industryTag');
    const industryItems = computed(() => convertToItemList(industryList.value, 'externalId', 'label'));
    const showIndustry = computed(() => true);

    const pushValue = () => {
      const clientData = {
        countryCode: country.value,
        cityPlaceName: selectedCityName.value,
        cityPostalCode: selectedCityPostalcode.value,
        companySize: companySize.value,
        industry: industry.value
      };
      emit('input', clientData);
    };

    const selectCity = (value: string) => {
      cityList.value.forEach((city: any) => {
        if (placeText(city) === value) {
          selectedCityName.value = city.placeName;
          selectedCityPostalcode.value = city.postalCode;
          selectedCityCountryCode.value = city.countryCode;
        }
      });
      pushValue();
    };

    watch(() => country.value, (value) => {
      if (value !== selectedCityCountryCode.value) {
        city.value = '';
        selectedCityName.value = '';
        selectedCityPostalcode.value = '';
      }
      pushValue();
    });

    watch(() => companySize.value, (value) => {
      if (value !== companySizeValue.value) {
        nextTick(() => {
          pushValue();
        });
      }
    });

    watch(() => model.value.companySize, (value) => {
      if (value !== companySize.value) {
        nextTick(() => {
          companySize.value = value;
        });
      }
    });

    watch(() => industry.value, (value) => {
      if (value !== industryTag.value) {
        nextTick(() => {
          pushValue();
        });
      }
    });

    const selectedPlaceText = computed(() => placeText({
      postalCode: selectedCityPostalcode.value,
      placeName: selectedCityName.value
    }));

    const fetchCities = (countryId: string, searchString: string) => {
      store.dispatch('projectForum/fetchCities', { countryId, searchString });
    };

    const inputCity = (value: string) => {
      fetchCities(country.value, value);
    };

    onMounted(() => {
      if (countryCode.value) {
        country.value = countryCode.value;
      }

      if (placeName.value) {
        city.value = placeName.value;
        selectedCityName.value = placeName.value;
        selectedCityPostalcode.value = postalCode.value;
        selectedCityCountryCode.value = countryCode.value;
      }

      if (companySizeValue.value) {
        companySize.value = companySizeValue.value;
      }

      if (industryTag.value) {
        industry.value = industryTag.value;
      }
    });

    return {
      text,
      country,
      city,
      cityItems,
      companySize,
      companySizeItems,
      industry,
      industryItems,
      selectedCityName,
      selectedCityPostalcode,
      selectedCityCountryCode,
      countryItems,
      selectedPlaceText,
      fetchCities,
      tUser,
      getDropdownItems,
      inputCity,
      selectCity,
      isCompanyProject,
      companyUser,
      isCustomer,
      showCompanySize,
      showIndustry
    };
  }
});
