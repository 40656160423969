
import { CreateRoomErrorResponse, ExternalVideoBarChatMatchingResult } from '@/__generated__/types';
import BCXMessenger from '@/components/messenger/BCXMessenger.vue';
import useFullPageState from '@/state/fullPageState';
import useMessengerState from '@/state/messenger/messengerState';
import useMessengerRefresher from '@/state/messenger/useMessengerRefresher';
import usePingVirtualPubLobby from '@/views/virtual-pub/composable/virtual-pub/usePingVirtualPubLobby';
import {
  computed, defineComponent, onMounted, onUnmounted, ref, watchEffect
} from 'vue';
import { templateRef } from '@vueuse/core';
import dayjs from 'dayjs';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import onSizeChange from '@/mixins/onSizeChange';
import { logError, LogLevel } from '@/utils/logger';
import BarChatLobby from '../components/chat-room/BarChatLobby.vue';
import BarChatNavigation from '../components/chat-room/BarChatNavigation.vue';
import CreateRoomError from '../components/chat-room/CreateRoomError.vue';
import EndScreen from '../components/chat-room/end-screen/EndScreen.vue';
import DeviceSettings from '../components/chat-room/settings/DeviceSettings.vue';
import DeviceSettingsContextProvider from '../components/chat-room/settings/DeviceSettingsContextProvider.vue';
import VideoChat from '../components/chat-room/VideoChat.vue';
import useBarChatMatch from '../composable/bar-chat/useBarChatMatch';
import useEnterBarChat, { onlyNewContacts } from '../composable/bar-chat/useEnterBarChat';
import useCreateRoom from '../composable/rooms/useCreateRoom';
import useRooms from '../composable/rooms/useRooms';
import useChatRoomRouter from '../composable/useChatRoomRouter';
import useDeleteVirtualPubLobby from '../composable/virtual-pub/useDeleteVirtualPubLobby';
import { DeviceSettingsModel } from '../models/DeviceSettings';

export default defineComponent({
  components: {
    BCXMessenger,
    BarChatNavigation,
    VideoChat,
    DeviceSettings,
    BarChatLobby,
    EndScreen,
    DeviceSettingsContextProvider,
    CreateRoomError
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const deviceSettings = ref<DeviceSettingsModel>();
    const showDeviceSettings = ref(true);
    const showBarChatLobby = ref(false);
    const showAfterChat = ref(false);
    const showEvaluateBarChat = ref(false);
    const showTwilioVideo = ref(false);
    const room = ref<ExternalVideoBarChatMatchingResult>();
    const creator = ref();
    const showBarChatNavigation = computed(() => !showTwilioVideo.value);
    const isVideoSettingsClosable = ref(true);
    const createTableChatRoomError = ref<CreateRoomErrorResponse>();
    const {
      isOpened: isMessengerOpen, isSoundTemporarilyMuted, openLatestUnreadMessage
    } = useMessengerState();
    const chatroom = templateRef<HTMLElement>('chatroom');
    const chatHeight = ref(0);
    const chatBoxHeight = ref(0);

    isSoundTemporarilyMuted.value = true;
    onUnmounted(() => {
      isSoundTemporarilyMuted.value = false;
    });

    useFullPageState(); // let refreshers run
    useMessengerRefresher();

    usePingVirtualPubLobby();

    const { deleteVirtualPubLobby } = useDeleteVirtualPubLobby();
    const { enterBarChat } = useEnterBarChat();
    const { data: barChatMatchData, resume: barChatMatchStartPolling, pause: barChatMatchStopPolling } = useBarChatMatch();
    const {
      rooms, pause, resume, execute
    } = useRooms();
    const { createRoom, error, data } = useCreateRoom();

    const { isBarChat, uniqueRoomNameFromRoute, isSimulation } = useChatRoomRouter();

    watchEffect(() => {
      if (isBarChat.value) {
        pause();
      }
    });

    function setShowVideoChat() {
      showBarChatLobby.value = false;
      showTwilioVideo.value = true;
    }

    watchEffect(() => {
      if (barChatMatchData.value?.videopubClosed) {
        router.push({ name: 'virtual-pub' });
      }
      if (barChatMatchData.value?.hasMatch && barChatMatchData.value.matchAborted === false) {
        barChatMatchStopPolling();
        room.value = barChatMatchData.value;
        setShowVideoChat();
      }
    });

    async function enterBarAndStartBarMatchPolling() {
      await enterBarChat({ onlyNewContacts: onlyNewContacts.value });
      barChatMatchStartPolling();
    }

    async function enterTableChat() {
      pause();
      if (rooms.value.length) {
        const data = rooms.value.find((x) => x.uniqueRoomName === uniqueRoomNameFromRoute.value);
        if (data) {
          room.value = data;
          creator.value = data.creator;
          setShowVideoChat();
        }
      } else {
        resume();
      }
    }

    function setDeviceSettings(selectedDeviceSettingsFrom: DeviceSettingsModel) {
      deviceSettings.value = selectedDeviceSettingsFrom;
    }

    function changeDeviceSettings(selectedDeviceSettingsFrom: DeviceSettingsModel) {
      setDeviceSettings(selectedDeviceSettingsFrom);
      showDeviceSettings.value = false;
    }

    function openDeviceSettings() {
      showDeviceSettings.value = true;
      isVideoSettingsClosable.value = false;
    }

    async function openChat(selectedDeviceSettingsFrom: DeviceSettingsModel) {
      setDeviceSettings(selectedDeviceSettingsFrom);

      showDeviceSettings.value = false;
      showBarChatLobby.value = true;

      if (!uniqueRoomNameFromRoute.value && !isBarChat.value) {
        await createRoom({ maxParticipants: parseInt(route.query.maxParticipants.toString()), roomTopic: route.query.roomTopic.toString() });
        if (!error.value && data?.value?.uniqueRoomName) {
          uniqueRoomNameFromRoute.value = data.value.uniqueRoomName;
          await router.replace({ name: 'chat-room', query: { 'bar-chat': 'false', 'unique-room-name': data.value.uniqueRoomName } });
          await execute();
        } else {
          logError('Error creating room', error.value);
          createTableChatRoomError.value = error.value as unknown as CreateRoomErrorResponse;
        }
      }

      // ! This part may only be executed if no video chat is running yet
      if (!showTwilioVideo.value) {
        if (isBarChat.value) {
          await enterBarAndStartBarMatchPolling();
        } else if (uniqueRoomNameFromRoute.value) {
          await enterTableChat();
        }
      }
    }

    const roomJoinFailed = (error: any) => {
      showTwilioVideo.value = false;
      createTableChatRoomError.value = error;
    };

    function leaveVideoChat() {
      showDeviceSettings.value = false;
      showBarChatLobby.value = false;
      showEvaluateBarChat.value = false;
      showTwilioVideo.value = false;
      showAfterChat.value = true;
    }

    const waitingParticipants = computed(() => (barChatMatchData.value?.numberOfWaitingParticipants ? barChatMatchData.value?.numberOfWaitingParticipants - 1 : 0));

    const handleRematch = () => {
      showEvaluateBarChat.value = false;
      showBarChatLobby.value = true;
      showAfterChat.value = false;
      if (deviceSettings.value) {
        const { cameraDeviceId, microphoneDeviceId, speakerDeviceId } = deviceSettings.value;
        openChat({ cameraDeviceId, microphoneDeviceId, speakerDeviceId });
      }
      barChatMatchStartPolling();
    };

    const endScreenVariant = computed(() => (isBarChat.value ? 'bar-chat' : 'table-chat'));

    const hasParticipantLeaved = computed(() => {
      if (!room.value?.endingTime || !room.value.endingTime) {
        return false;
      }

      return dayjs(room.value.endingTime).subtract(10, 'seconds') > dayjs();
    });

    const setChatHeight = () => {
      const { height } = chatroom.value.getBoundingClientRect();
      chatHeight.value = height - 50;
      chatBoxHeight.value = height < 768 ? -50 : 0;
    };

    onMounted(() => {
      isMessengerOpen.value = false;
      setChatHeight();
      window.onbeforeunload = () => {
        if (isBarChat.value) {
          deleteVirtualPubLobby();
        }
      };
    });

    onUnmounted(() => {
      isMessengerOpen.value = false;
      if (isBarChat.value) {
        deleteVirtualPubLobby();
      }
    });

    const leave = () => {
      router.push('virtual-pub');
    };

    const toggleMessenger = () => {
      isMessengerOpen.value = !isMessengerOpen.value;
      if (isMessengerOpen.value) {
        openLatestUnreadMessage();
      }
    };

    onSizeChange(chatroom, setChatHeight);

    return {
      toggleMessenger,
      isMessengerOpen,
      barChatMatchData,
      waitingParticipants,
      room,
      creator,
      showAfterChat,
      showEvaluateBarChat,
      showDeviceSettings,
      showBarChatNavigation,
      showBarChatLobby,
      showTwilioVideo,
      deviceSettings,
      openChat,
      roomJoinFailed,
      changeDeviceSettings,
      leaveVideoChat,
      openDeviceSettings,
      handleRematch,
      endScreenVariant,
      hasParticipantLeaved,
      isVideoSettingsClosable,
      leave,
      isSimulation,
      createTableChatRoomError,
      chatHeight,
      chatBoxHeight,
    };
  }
});
