
import MostAppreciatedItem from '@/components/most-appreciated/MostAppreciatedItem.vue';
import { MostAppreciatedEntry, MostAppreciatedType } from '@/models/MostAppreciated';
import MostAppreciatedService from '@/services/MostAppreciatedService';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { asyncComputed } from '@vueuse/core';

export default defineComponent({
  name: 'MostAppreciated',
  components: { MostAppreciatedItem },
  props: {
    type: {
      type: String as PropType<MostAppreciatedType>,
      default: MostAppreciatedType.Talks
    }
  },
  setup(props) {
    const { type } = toRefs(props);
    const usersSort = (a: MostAppreciatedEntry, b: MostAppreciatedEntry) => a.listPosition - b.listPosition;

    const users = asyncComputed(async () => (await MostAppreciatedService.getMostAppreciated(type.value))
      .sort(usersSort), []);

    return {
      users
    };
  }
});
