
import { defineComponent } from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import InvitesCodes from '@/views/invites/InvitesCodes.vue';
import Invitees from '@/views/invites/Invitees.vue';

export default defineComponent({
  name: 'Invites',
  components: {
    Invitees,
    InvitesCodes,
    BCXBreadcrumbs,
    BCXContentArea,
  },
});
