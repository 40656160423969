import _axios from '@/plugins/axios';
import { RequestVideoSessionPeerChatMatchingPreferences, ExternalVideoBarChatMatchingResult } from '@/__generated__/types';
import { ref } from 'vue';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/bar';

export const onlyNewContacts = ref(false);

const useEnterBarChat = () => {
  const enterBarChatAxios = useAxios<ExternalVideoBarChatMatchingResult>(ENDPOINT, { method: 'POST' }, _axios, {
    immediate: false
  });

  async function enterBarChat(data: RequestVideoSessionPeerChatMatchingPreferences) {
    await enterBarChatAxios.execute(ENDPOINT, { data });
  }

  return {
    enterBarChat,
    ...enterBarChatAxios,
  };
};

export default useEnterBarChat;
