// only used to somehow handle some kind of update event

import { recommendationsEventKey } from '@/models/Recommendations';
import { createGlobalState, useEventBus } from '@vueuse/core';

const useRecommendationState = createGlobalState(() => {
  const eventBus = useEventBus(recommendationsEventKey);
  return { recommendationsEventBus: eventBus };
});

export default useRecommendationState;
