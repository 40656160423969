
import Vue, { Ref, ref } from 'vue';

export default Vue.extend({
  name: 'BCXRadio',
  inheritAttrs: false,
  props: {
    selected: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const input = ref(null) as Ref<HTMLInputElement | null>;

    const onChange = () => {
      if (input.value) {
        emit('update:selected', input.value.value);
      }
    };

    return {
      input,
      onChange,
    };
  },
});
