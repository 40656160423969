import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useI18n from '@/mixins/useI18n';
import { AvailabilityInterface, AvailabilityMode } from '@/models/Availability';
import { computed, unref } from 'vue';
import { MaybeRef } from '@vueuse/core';

const useAvailability = (availability: MaybeRef<AvailabilityInterface>) => {
  const { t } = useI18n();
  const { getFormattedDate } = useFormattedDateTime();

  const availableFrom = computed(() => {
    const { availableFrom } = unref(availability) ?? {};
    if (availableFrom) return getFormattedDate(availableFrom);
    return '';
  });

  const mode = computed<AvailabilityMode>(() => {
    const { available, availableNow, availableFulltime } = unref(availability) ?? {};
    if (!available) return AvailabilityMode.AVAILABILITY_NOT_AVAILABLE;
    if (!availableNow || !availableFulltime) return AvailabilityMode.AVAILABILITY_PARTLY_AVAILABLE;
    return AvailabilityMode.AVAILABILITY_FULLY_AVAILABLE;
  });

  const label = computed(() => `${
    mode.value === AvailabilityMode.AVAILABILITY_NOT_AVAILABLE
      ? t('availability.unavailable')
      : `${t('availability.title')}:`
  }`);

  const whenParts = computed(() => {
    const { availableFulltime, availableHoursPerWeek, available } = unref(availability) ?? {};

    const parts = [];

    if (available) {
      if (availableFrom.value) parts.push(`${t('availability.from')} ${availableFrom.value}`);
      if (availableFulltime) parts.push(t('availability.fullTime'));
      else if (availableHoursPerWeek) parts.push(`${availableHoursPerWeek} ${t('availability.hoursPerWeekShort')}`);
      else parts.push(t('availability.partTime'));
    }
    return parts;
  });

  const when = computed(() => whenParts.value.join(' • '));

  return {
    mode,
    label,
    when,
    whenParts,
    availableFrom
  };
};

export default useAvailability;
