import { BlogEntry } from '@/views/blog/models/Blog';
import { filter, orderBy, shuffle } from 'lodash';

export const sortHelpter = () => false;

export const prepareBlog = (entries: BlogEntry[], type: string, max?: number) => {
  const displayArticles = entries.filter((entry: BlogEntry) => entry.type === type);

  const featuredArticles = filter(displayArticles, (article) => article.position === 1);
  const notPositonOneArticles = filter(displayArticles, (article) => article.position !== 1);
  const positonOneWithoutFeature = [];
  if (featuredArticles.length > 1) {
    const [featuredArticle] = shuffle(featuredArticles);
    positonOneWithoutFeature.push(...filter(featuredArticles, (articles) => articles.externalId !== featuredArticle.externalId));
    return [featuredArticle, ...orderBy([...positonOneWithoutFeature, ...notPositonOneArticles], 'publishDate', 'desc')];
  }
  return [...featuredArticles, ...orderBy(notPositonOneArticles, 'publishDate', 'desc')];
};
