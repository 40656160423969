
import useProfileState from '@/state/profile/profileState';
import Vue, {
  inject, ref, Ref, toRefs
} from 'vue';

export default Vue.extend({
  name: 'ProfileEditActionButtons',
  props: {
    saveFunction: {
      type: Function as () => any,
      default: () => null,
    },
    disabled: {
      type: Boolean,
    },
  },
  setup(props) {
    const { saveFunction } = toRefs(props) as {
      saveFunction: Ref<() => Promise<any>>;
    };
    const isSaving = ref(false);
    const { closeEditSection, loadProfile } = useProfileState();
    const currentEditSection = inject<Ref<string>>('currentEditSection');

    const close = () => {
      closeEditSection(currentEditSection?.value ?? '');
    };

    const save = async () => {
      if (saveFunction.value) {
        const stayInEditor = await saveFunction.value();
        if (stayInEditor === 'abort') return;
        isSaving.value = true;
        await loadProfile();
        isSaving.value = false;
        if (!stayInEditor) close();
      }
    };

    return { close, save, isSaving };
  },
});
