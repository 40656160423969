
import Chevron from '@/components/svg-components/Chevron.vue';
import { and, not } from '@vueuse/math';
import Vue, { computed, inject, Ref } from 'vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import { CHAT_TYPE_DIRECT } from '@/models/Messenger';
import useResponsiveness from '@/mixins/useResponsiveness';
import X from '@/components/svg-components/X.vue';
import useMessengerState from '@/state/messenger/messengerState';

export default Vue.extend({
  name: 'BCXMessengerHeader',
  components: { X, BCXIcon, Chevron },
  setup() {
    const {
      isOpened,
      isExpanded,
      selectedChat,
      isNewChat,
      unreadMessages,
      selectChat,
      startNewChat,
      showOptionsPanel,
    } = useMessengerState();
    const { isDesktopWidth, isTooFlatForMessenger, isSidebarVisible } = useResponsiveness();

    const isCollapseVisible = and(isSidebarVisible, not(isTooFlatForMessenger));

    const toggleOpenedOrClose = () => {
      if (isDesktopWidth.value) {
        isOpened.value = !isOpened.value;
      } else {
        isOpened.value = false;
      }
    };

    const goBack = () => {
      selectChat('');
    };

    const goBackLabel = computed(() => (selectedChat.value?.type ?? CHAT_TYPE_DIRECT));

    const clickExpanded = () => {
      const newExpanded = !isExpanded.value;
      isExpanded.value = newExpanded;
      if (!newExpanded && isTooFlatForMessenger.value) {
        isOpened.value = false;
      }
      if (newExpanded && !isOpened.value) {
        requestAnimationFrame(() => { isOpened.value = true; });
      }
    };

    return {
      goBack,
      goBackLabel,
      isOpened,
      isExpanded,
      isDesktopWidth,
      isNewChat,
      selectedChat,
      unreadMessages,
      toggleOpenedOrClose,
      clickExpanded,
      startNewChat,
      isCollapseVisible,
      showOptionsPanel,
    };
  },
});
