import { ref, Ref, watch } from 'vue';

const useManipulateAudioStream = (stream: Ref<MediaStream | undefined>) => {
  const ctx = ref<AudioContext>();
  const sourceNode = ref<MediaStreamAudioSourceNode>();
  const destinationNode = ref<MediaStreamAudioDestinationNode>();

  const gainNode = ref<GainNode>();
  const gain = ref(1);

  const analyserNode = ref<AnalyserNode>();
  const processorNode = ref<ScriptProcessorNode>();
  const level = ref(0);

  watch([stream], () => {
    if (!stream.value) {
      return;
    }

    ctx.value = new AudioContext();
    sourceNode.value = ctx.value.createMediaStreamSource(stream.value);
    destinationNode.value = ctx.value.createMediaStreamDestination();
    sourceNode.value.mediaStream.getAudioTracks().forEach((track) => {
      if (track.kind === 'audio') {
        destinationNode.value?.stream.addTrack(track);
      }
    });

    gainNode.value = ctx.value.createGain();

    analyserNode.value = ctx.value.createAnalyser();
    processorNode.value = ctx.value.createScriptProcessor(2048, 1, 1);
    analyserNode.value.smoothingTimeConstant = 0.8;
    analyserNode.value.fftSize = 1024;

    sourceNode.value.connect(gainNode.value);
    gainNode.value.connect(analyserNode.value);
    gainNode.value.connect(destinationNode.value);
    analyserNode.value.connect(destinationNode.value);
    analyserNode.value.connect(processorNode.value);
    processorNode.value.connect(destinationNode.value);
    processorNode.value.onaudioprocess = () => {
      if (!analyserNode.value) {
        return;
      }
      const array = new Uint8Array(analyserNode.value.frequencyBinCount);
      analyserNode.value.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;

      level.value = Math.round(average);
    };
  });

  watch([gain], () => {
    if (!gainNode.value) {
      return;
    }
    gainNode.value.gain.value = gain.value;
  });

  return {
    ctx,
    sourceNode,
    destinationNode,
    gain,
    level
  };
};

export default useManipulateAudioStream;
