
import BCSwitch from '@/atoms/BCSwitch.vue';
import {
  defineComponent
} from 'vue';
import { watchDebounced } from '@vueuse/core';
import useBarChatReminder from '../../composable/bar-chat/useBarChatReminder';

export default defineComponent({
  name: 'ReminderSwitch',
  components: { BCSwitch },
  setup() {
    const {
      isEnabled, isLoading, setReminder, removeReminder
    } = useBarChatReminder();

    watchDebounced(isEnabled, (is) => {
      if (is) setReminder();
      else removeReminder();
    }, { debounce: 200, maxWait: 2000 });

    return {
      isEnabled, isLoading
    };
  }
});
