
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import { onClickOutside, templateRef } from '@vueuse/core';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BCXPanel',
  components: { BCXIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    primaryTheme: {
      type: Boolean
    }
  },
  setup(_, { emit }) {
    const panel = templateRef<HTMLElement>('panel');

    onClickOutside(panel, () => {
      emit('close-panel');
    }, { ignore: ['.bc-panel'] });
  },
});
