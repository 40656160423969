import { Chat, MessengerForumLink } from '@/models/Messenger';
import { last } from 'lodash';

type LinkHandlerReturn = {
  label: string;
  path: string;
  scrollTarget?: string;
} | null;

const getLegacyForumLink = (chat: Chat) => {
  if (!chat.forumTalkLink) return null;
  return JSON.parse(chat.forumTalkLink) as MessengerForumLink;
};

const getChatLink = (chat: Chat): LinkHandlerReturn => {
  if (!chat?.links?.length || !chat?.messages?.length) return null;

  if (chat.type === 'PROJECT_INTERNAL_MESSAGING') {
    const linkObject = chat.links.find((link) => link.LINK_BO_TYPE === 'PROJECT' && link.projectId);
    if (linkObject) {
      return {
        label: linkObject.LINKLABEL,
        path: `/projects/${linkObject.projectId}`
      };
    }
  } else if (chat.type === 'FORUM_INTERNAL_MESSAGING') {
    const linkObject = chat.links.find((link) => link.LINK_BO_TYPE === 'MESSAGING_GROUP' && link.forumId && link.forumTalkId && link.forumTalkGroupId);
    const scrollTarget = `message-${last(chat.messages)?.messageId}`;
    if (linkObject) {
      return {
        label: linkObject.LINKLABEL,
        path: `/talks/${linkObject.forumId}/groups/${linkObject.forumTalkGroupId}/threads/${linkObject.forumTalkId}`,
        scrollTarget
      };
    }
    const legacyLink = getLegacyForumLink(chat);
    if (legacyLink) {
      return {
        label: legacyLink.LINKLABEL,
        path: `/talks/${legacyLink.forumId}/groups/${legacyLink.forumTalkGroupId}/threads/${legacyLink.forumTalkId}`,
        scrollTarget
      };
    }
  }
  return null;
};

export default getChatLink;
