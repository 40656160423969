import { createGlobalState, useLocalStorage } from '@vueuse/core';
import ConfigurationService from '@/services/ConfigurationService';
import { REFRESH_SERVER_CONFIG } from '@/models/IntervalTimes';

const useConfigState = createGlobalState(() => {
  const registrationConfig = useLocalStorage<Record<string, any>>('registration_config', {});
  const platformConfig = useLocalStorage<Record<string, any>>('platform_config', {});
  const expireDate = useLocalStorage<number>('expire_config', 0);

  const getConfig = async () => {
    const now = new Date().getTime();
    if (!expireDate.value || now > expireDate.value) {
      expireDate.value = now + REFRESH_SERVER_CONFIG;
      const { registration, platform } = await ConfigurationService.getConfig();
      registrationConfig.value = registration;
      platformConfig.value = platform;
    }

    setInterval(getConfig, REFRESH_SERVER_CONFIG + 1);
  };

  return {
    registrationConfig,
    platformConfig,
    getConfig
  };
});

export default useConfigState;
