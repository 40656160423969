
import { defineComponent } from 'vue';
import BytecookieLogo from '@/components/svg-components/BytecookieLogo.vue';

export default defineComponent({
  components: { BytecookieLogo },
  props: {
    showBack: {
      type: Boolean,
      required: false,
      default: () => true,
    }
  }
});
