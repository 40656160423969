import {
  onMounted, onUnmounted, ref, Ref
} from 'vue';
import { createGlobalState, useEventListener, whenever } from '@vueuse/core';
import {
  onBeforeRouteLeave, onBeforeRouteUpdate
} from 'vue2-helpers/vue-router';
import useI18n from '@/mixins/useI18n';

const isDirty = ref(false);
const isLeavingDirty = ref(false);
const allowedToLeave = ref(false);

const useConfirmation = createGlobalState(() => {
  const resolveFn: Ref<((confirm: boolean) => void) | undefined> = ref();
  const ask = () => new Promise((resolve) => {
    isLeavingDirty.value = true;
    resolveFn.value = resolve;
  });
  const onConfirm = (confirm: boolean) => {
    isLeavingDirty.value = false;
    if (resolveFn.value) return resolveFn.value(confirm);
    return false;
  };

  return {
    ask,
    onConfirm,
    resolveFn,
  };
});

const useIsLeavingDirty = () => {
  const { t } = useI18n();
  const isLeavingMessage = ref((t('common.leaving-dirty') as string));

  const { ask, onConfirm, resolveFn } = useConfirmation();

  useEventListener('beforeunload', async (ev) => {
    if (isDirty.value) {
      ev.preventDefault();
    }
  });

  onBeforeRouteLeave(async (_to, _from, next) => {
    const scrollPosition = window.scrollY;
    if (isDirty.value && !allowedToLeave.value) {
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 1);
      if (await ask()) {
        allowedToLeave.value = true;
        return next();
      }
      isLeavingDirty.value = false;
      return next(false);
    }
    isLeavingDirty.value = false;
    return next();
  });

  onBeforeRouteUpdate(async (_to, _from, next) => {
    const scrollPosition = window.scrollY;
    if (isDirty.value && !allowedToLeave.value) {
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 1);
      if (await ask()) {
        allowedToLeave.value = true;
        return next();
      }
      isLeavingDirty.value = false;
      return next(false);
    }
    isLeavingDirty.value = false;
    return next();
  });

  whenever(() => !isDirty.value, () => {
    isLeavingDirty.value = false;
    allowedToLeave.value = true;
  });
  whenever(() => isDirty.value, () => {
    allowedToLeave.value = false;
  });

  onMounted(() => {
    allowedToLeave.value = false;
  });

  onUnmounted(() => {
    isDirty.value = false;
    isLeavingDirty.value = false;
  });

  return {
    isDirty,
    isLeavingDirty,
    onConfirm,
    isLeavingMessage,
  };
};

export default useIsLeavingDirty;
