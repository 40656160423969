
import {
  computed, defineComponent, ref, toRefs, watchEffect
} from 'vue';

export default defineComponent({
  name: 'LPBgImage',
  props: {
    src: {
      type: String,
      required: true
    },
    scaleFactor: {
      type: Number,
      default: 1
    },
  },
  setup(props, { emit }) {
    const { src, scaleFactor } = toRefs(props);
    const isLoading = ref(false);
    const width = ref(0);
    const height = ref(0);

    let img:HTMLImageElement | null = null;

    watchEffect(() => {
      if (!src.value) return;

      img = new Image();

      const evaluateImage = () => {
        isLoading.value = false;
        if (!img) return;
        width.value = img.width;
        height.value = img.height;
        emit('loaded');
      };

      img.src = src.value;

      if (img.complete) evaluateImage();
      else {
        isLoading.value = true;
        img.onload = () => {
          evaluateImage();
        };
      }
    });

    const style = computed(() => {
      if (isLoading.value) {
        return {
          visibility: 'hidden'
        };
      }
      const w = width.value / scaleFactor.value;
      const h = height.value / scaleFactor.value;
      return {
        '--width': `${w}px`,
        minHeight: `${h}px`,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url("${src.value}")`,
        backgroundSize: `${w}px ${h}px`,
        backgroundPosition: 'center top'
      };
    });

    return {
      style
    };
  }
});
