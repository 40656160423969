import { useAxios } from '@vueuse/integrations/useAxios';
import _axios from '@/plugins/axios';
import { ExternalVideoGroupRoom, RequestVideoCreateRoom } from '@/__generated__/types';

const ENDPOINT = '/v1/community/videochat/rooms';

const useCreateRoom = () => {
  const createRoomAxios = useAxios<ExternalVideoGroupRoom>(ENDPOINT, { method: 'POST' }, _axios, { immediate: false });

  const createRoom = async (params: RequestVideoCreateRoom) => {
    const data: RequestVideoCreateRoom = {
      ...params,
      roomType: 'GROUP'
    };

    await createRoomAxios.execute(ENDPOINT, {
      data
    });
  };

  return {
    createRoom,
    ...createRoomAxios,
  };
};

export default useCreateRoom;
