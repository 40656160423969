// reads the current tablechat-room list

import { ExternalVideoGroupRooms } from '@/__generated__/types';
import _axios from '@/plugins/axios';
import { useBarOpeningHours } from '@/views/virtual-pub/composable/useBarOpeningHours';
import {
  computed, inject, provide, ref, watchEffect
} from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';
import { orderBy as orderByFn } from 'lodash';

const USE_ROOMS_KEY = Symbol('useRooms');
const ENDPOINT = '/v1/community/videochat/rooms';
const PING_INTERVAL = ref(5000);

export enum RoomOrderBy {
  SEATS = 'seats',
  TOPIC = 'roomTopic',
  HOST = 'host'
}

const useRooms = () => {
  const { execute, data, ...restAxios } = useAxios<ExternalVideoGroupRooms>(ENDPOINT, { method: 'GET' }, _axios);
  const orderBy = ref<RoomOrderBy>(RoomOrderBy.TOPIC);
  const order = ref<'asc' | 'desc'>('asc');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isBarOpen } = useBarOpeningHours();

  const { pause, resume } = useIntervalFn(async () => {
    if (isBarOpen.value) await execute();
  }, PING_INTERVAL);

  const rooms = computed(() => {
    if (!data.value || !data.value.videoRoomList) {
      return [];
    }

    return orderByFn(data.value.videoRoomList, orderBy.value, order.value);
  });

  watchEffect(() => {
    if (rooms.value.length > 0) {
      const hasRoomWithTwoOrLessSeats = rooms.value.some((room) => (room.maxParticipants ?? 0) - (room.numberOfActiveUsers ?? 0) <= 2);
      if (hasRoomWithTwoOrLessSeats) {
        PING_INTERVAL.value = 2000;
      } else {
        PING_INTERVAL.value = 5000;
      }
    }
  });

  const newRoomsAllowed = computed(() => {
    if (!data.value || !data.value.newRoomsAllowed) {
      return false;
    }
    return data.value.newRoomsAllowed;
  });

  const toggleOrder = () => {
    if (order.value === 'asc') {
      order.value = 'desc';
    } else {
      order.value = 'asc';
    }
  };

  const context = {
    pause,
    resume,
    execute,
    rooms,
    newRoomsAllowed,
    ...restAxios,
    orderBy,
    order,
    toggleOrder
  };

  provide(USE_ROOMS_KEY, context);

  return context;
};

export type UseRoomsContext = ReturnType<typeof useRooms>;

export const useRoomsContext = () => {
  const context = inject<UseRoomsContext>(USE_ROOMS_KEY);

  if (!context) {
    throw new Error('Did you forget to provide useRoomsContext?');
  }

  return context;
};

export default useRooms;
