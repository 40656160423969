
import { computed, defineComponent, toRefs } from 'vue';

export default defineComponent({
  props: {
    value: { type: Number, default: 0 },
    max: {
      type: String,
      default: '100'
    }
  },
  setup(props) {
    const { value, max } = toRefs(props);

    const ratio = computed(() => +value.value / +max.value);
    const progressClass = computed(() => {
      if (ratio.value < 0.3) return 'progress-indicator--low';
      if (ratio.value < 0.8) return 'progress-indicator--good';
      return 'progress-indicator--high';
    });

    return {
      progressClass
    };
  }
});
