
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import UploadableItem from '@/components/molecules/UploadableItem.vue';
import TagList from '@/components/tagging/TagList.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useToastUiEditorTools from '@/mixins/useToastUiEditorTools';
import DeleteDialog from '@/views/blog/components/DeleteDialog.vue';
import {
  deleteBlogEntryAdmin,
  getBlogEntryAdmin, postBlogEntryAdmin, postPublishBlogEntryAdmin, postUnPublishBlogEntryAdmin, putBlogEntryAdmin
} from '@/services/Blog/Api';
import { Editor } from '@toast-ui/vue-editor';
import { templateRef } from '@vueuse/core';
import {
  computed, defineComponent, reactive, Ref, ref, watch
} from 'vue';
import { logError } from '@/utils/logger';
import BlogEntryAudienceFilter from '@/views/blog/components/BlogEntryAudienceFilter.vue';
import useUser from '@/mixins/useUser';
import { BlogForm } from '../models/Blog';
import { getEmptyBlogForm } from '../models/BlogAdmin';
import BlogButton from './BlogButton.vue';
import BlogPublishMailDialog from './BlogPublishMailDialog.vue';

export default defineComponent({
  components: {
    BCXContentArea,
    BCXValidatedInput,
    Editor,
    UploadableItem,
    BCXSearchSelect,
    BCXIcon,
    TagList,
    BlogButton,
    DeleteDialog,
    BlogPublishMailDialog,
    BlogEntryAudienceFilter,
  },
  props: {
    entryId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const watchEntry = computed(() => props.entryId);
    const savingDraft = ref(false);
    const publishing = ref(false);
    const deleting = ref(false);
    const showDeleteDialog = ref(false);
    const showPublishMailDialog = ref(false);
    const closeConfirmMailDialog = () => { showPublishMailDialog.value = false; };
    const confirmedMailPublish = ref(false);
    const isEnlarged = ref(false);
    const editorText = ref('');
    const changeEntry = ref(false);
    const editor = templateRef('editor') as Ref<Editor>;
    const blogTypes = [
      { value: 'BC_NEWS', text: 'BC-News' },
      { value: 'ARTICLE', text: 'Article' },
      { value: 'HELP_ARTICLE', text: 'HELP_Article' },
      { value: 'DIRECT_MAIL', text: 'Direct-Mail' },
      { value: 'DIRECT_MAIL_TEST', text: 'Direct-Mail (TEST)' }
    ];
    const { getFormattedTimeByAge } = useFormattedDateTime();

    const audienceSelected = ref<string[]>([]);

    const blogEntry = reactive({ entry: { ...getEmptyBlogForm() } as BlogForm });
    const author = computed(() => {
      if (blogEntry.entry?.creator.userId?.length) {
        return useUser(blogEntry.entry.creator);
      }
      return useUser('self');
    });
    const form = computed(() => blogEntry.entry);
    const loadBlogEntry = async (externalId?: string) => {
      if (externalId?.length || props.entryId?.length) {
        const entry = await getBlogEntryAdmin(externalId ?? props.entryId);
        blogEntry.entry = entry;
        audienceSelected.value = blogEntry.entry?.audience ?? [];
      } else {
        blogEntry.entry = { ...getEmptyBlogForm() };
      }
      if (editor.value) {
        editor.value.invoke('setHTML', blogEntry.entry.content, false);
      }
    };
    const lastUpdated = computed(() => getFormattedTimeByAge(blogEntry.entry?.lastUpdated || new Date()));

    const isHelpArticle = computed(() => form.value.type === 'HELP_ARTICLE');

    const isMailArticle = computed(() => form.value.type === 'DIRECT_MAIL' || form.value.type === 'DIRECT_MAIL_TEST');

    const draftValid = computed(() => form.value.title.length > 0);

    const publishAble = computed(() => {
      if (isHelpArticle.value) {
        return form.value.title?.length > 0 && form.value.content.length >= 100;
      }
      if (isMailArticle.value) {
        return form.value.title?.length > 0 && form.value.content.length >= 100;
      }
      return (
        !publishing.value
        && audienceSelected.value.length > 0
        && form.value.title?.length > 0
        && form.value.summary?.length > 0
        && form.value.content.length >= 100
        && (form.value.fileContents?.length > 0 || form.value.titleImage?.length > 0)
      );
    });
    const previewValid = computed(() => {
      if (isHelpArticle.value) {
        return form.value.title?.length > 0;
      }
      if (isMailArticle.value) {
        return form.value.title?.length > 0;
      }
      return form.value.title?.length > 0 && form.value.summary?.length > 0 && (form.value.fileContents?.length > 0 || form.value.titleImage?.length > 0);
    });

    const blogMarkdown = () => {
      form.value.content = editor.value?.invoke('getHTML') || '';
    };

    const cancelChanges = () => {
      blogEntry.entry = getEmptyBlogForm();
      editor.value.invoke('setHTML', blogEntry.entry.content, false);
    };

    watch(watchEntry, (entryId) => {
      if (form.value.externalId !== entryId) {
        loadBlogEntry(entryId);
      }
    });

    watch(isEnlarged, (is) => {
      if (editor.value) {
        editor.value.invoke('setHeight', is ? '600px' : '300px');
      }
    });

    const { createButton } = useToastUiEditorTools(editor);

    const extendAutoLinks = () => null;

    return {
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: false,
        autofocus: false,
        extendAutoLinks,
        toolbarItems: [
          [
            'heading',
            'bold',
            'italic',
            'hr',
            'link',
            'quote',
            'ul',
            'ol',
            'image',
            'indent',
            'outdent',
            {
              el: createButton('undo'),
              command: 'undo',
              tooltip: 'Undo'
            },
            {
              el: createButton('redo'),
              command: 'redo',
              tooltip: 'Redo'
            }
          ]
        ]
      },
      blogEntryForm: blogEntry,
      author,
      form,
      editor,
      blogTypes,
      loadBlogEntry,
      editorText,
      cancelChanges,
      changeEntry,
      isEnlarged,
      draftValid,
      isHelpArticle,
      isMailArticle,
      previewValid,
      lastUpdated,
      savingDraft,
      publishing,
      publishAble,
      blogMarkdown,
      deleting,
      showDeleteDialog,
      showPublishMailDialog,
      confirmedMailPublish,
      closeConfirmMailDialog,
      audienceSelected
    };
  },
  methods: {
    confirmMailPublish() {
      this.confirmedMailPublish = true;
      this.$nextTick(() => {
        this.publishPost();
      });
    },
    async publishPost() {
      this.publishing = true;
      if (this.blogEntryForm.entry.externalId && this.blogEntryForm.entry.status !== 'PUBLISHED') {
        try {
          if (this.blogEntryForm.entry.type === 'DIRECT_MAIL' && !this.confirmedMailPublish) {
            // eslint-disable-next-line no-alert
            this.showPublishMailDialog = true;
            this.publishing = false;
            return;
          }
          await this.saveDraft(false);
          await postPublishBlogEntryAdmin(this.blogEntryForm.entry.externalId);
          this.blogEntryForm.entry.status = 'PUBLISHED';
        } catch (error) {
          logError(error);
        }
      }
      this.publishing = false;
      this.confirmedMailPublish = false;
      this.showPublishMailDialog = false;
      this.$emit('reloadEntries');
    },
    deleteDialog() {
      this.showDeleteDialog = !this.showDeleteDialog;
    },
    async deletePost() {
      this.showDeleteDialog = false;
      this.deleting = true;

      try {
        if (this.blogEntryForm.entry.externalId && this.blogEntryForm.entry.status !== 'DELETED') {
          await deleteBlogEntryAdmin(this.blogEntryForm.entry.externalId);
          this.blogEntryForm.entry.status = 'DELETED';
        }
      } catch (error) {
        logError(error);
      }
      this.cancelChanges();
      this.$emit('reloadEntries');
      this.deleting = false;
    },
    async unPublishPost() {
      this.publishing = true;
      if (this.blogEntryForm.entry.externalId && this.blogEntryForm.entry.status === 'PUBLISHED') {
        try {
          await postUnPublishBlogEntryAdmin(this.blogEntryForm.entry.externalId);
          this.blogEntryForm.entry.status = 'DRAFT';
        } catch (error) {
          logError(error);
        }
      }
      this.publishing = false;
      this.$emit('reloadEntries');
    },
    async saveDraft(reload = true) {
      if (!this.draftValid) return;
      this.savingDraft = true;
      const {
        type, title, summary, position, fileContents, titleImage, tagAssignmentList
      } = this.form;
      const entry = { data: {} };

      entry.data = {
        type,
        title,
        summary,
        content: this.editor?.invoke('getHTML'),
        position,
        titleImage: fileContents,
        preserveImage: !!(!fileContents?.length && titleImage),
        audience: this.audienceSelected
      };
      try {
        if (this.form.externalId) {
          this.blogEntryForm.entry = await putBlogEntryAdmin(this.form.externalId, entry.data);
        } else {
          entry.data = {
            tagAssignmentList,
            ...entry.data
          };
          this.blogEntryForm.entry = await postBlogEntryAdmin(entry.data);
          this.$emit('create', this.blogEntryForm.entry.externalId);
        }
        this.editor.invoke('setHTML', this.blogEntryForm.entry.content, true);
      } catch (error) {
        logError(error);
      }
      this.savingDraft = false;
      if (reload) this.$emit('reloadEntries');
    },
    async showPreview() {
      if (!this.previewValid) return;
      await this.saveDraft();
      this.$emit('showPreview', this.form);
    }
  }
});
