
import {
  defineComponent, PropType, toRefs, onMounted, computed
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import UserHistoryIcon from '@/components/svg/user-history.svg?inline';
import MarvinSleeping from '@/components/svg/marvin/marvin_sleeping.svg?inline';
import MarvinHappy from '@/components/svg/marvin/marvin_happy.svg?inline';
import Chevron from '@/components/svg-components/Chevron.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import { User } from '@/models/User';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useMessengerState from '@/state/messenger/messengerState';
import useQuickInfo from '@/state/quickInfo';
import useTrustLevels from '@/views/profile/composables/useTrustLevels';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ContactHistory',
  components: {
    UserHistoryIcon,
    Chevron,
    MarvinSleeping,
    MarvinHappy,
    BCXMarkdown
  },
  props: {
    user: {
      type: Object as PropType<User>,
      default: () => null
    }
  },
  setup(props, { emit }) {
    const store = useStore();

    const { user } = toRefs(props);
    const selfUser = computed(() => store.state.authentication.user);
    const isMe = computed(() => user.value.userId === selfUser.value.userId);
    const { isMobileWidth } = useResponsiveness();
    const { names, tUserFallback } = useUser(user);

    const { trustLevelEntries, fetch, isLoading } = useTrustLevels(names);

    onMounted(() => {
      if (!isMe.value) {
        fetch(user.value.userId ?? '');
      }
    });

    const { setQuickInfoUser } = useQuickInfo();
    const { createNewChat, isOpened } = useMessengerState();

    const writeMessage = async () => {
      if (user.value) {
        isOpened.value = true;
        await createNewChat(user.value);
        if (isMobileWidth.value) {
          await setQuickInfoUser(null);
        }
      }
    };

    const onHistoryLink = (evt: MouseEvent) => {
      const { href } = evt.currentTarget as HTMLAnchorElement;
      const url = new URL(href);

      if (url.hash === '#chat') {
        writeMessage();
        evt.preventDefault();
      }
    };

    return {
      emit,
      isMobileWidth,
      writeMessage,
      trustLevelEntries,
      isLoading,
      isMe,
      onHistoryLink,
      tUserFallback
    };
  }
});
