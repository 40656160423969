import { ProfileSectionKey, ProfileStore, ProfileStoreKey } from '@/models/Profile';
import { SelectItem } from '@/models/SelectItem';
import { User } from '@/models/User';
import { getPlatformCountries } from '@/services/Geo/Api';
import { getOwnProfile, getProfile, getUser } from '@/services/Profile/Api';
import isEmptyDeep from '@/utils/isEmptyDeep';
import toggleArrayItem from '@/utils/toggleArrayItem';
import {
  computed, inject, onBeforeUnmount, provide, ref, Ref, watch
} from 'vue';
import { asyncComputed } from '@vueuse/core';
import { isEmpty } from 'lodash';
import { Route } from 'vue-router';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';

const me = ref<User | null>(null);
const routerUserId = ref<string | null>(null);

const store:ProfileStore = {
  [ProfileStoreKey.PROFILE]: {
    profile: ref(null),
    userId: ref(''),
    isSelf: ref(false),
    user: asyncComputed(async () => {
      if (store.profile.isSelf.value) return me.value;
      return store.profile.profile.value ? getUser(store.profile.userId.value) : null;
    }, null, { lazy: true }),
    isLoading: ref(false),
  },
  [ProfileStoreKey.QUICK_INFO]: {
    profile: ref(null),
    userId: ref(''),
    isSelf: ref(false),
    user: ref(null),
    isLoading: ref(false),
  }
};

const editSections = ref<string[]>([]);
const countries = asyncComputed<SelectItem[]>(async () => {
  const countries = await getPlatformCountries();
  return countries
    .map(({
      countryName,
      isoCode,
    }) => ({
      value: isoCode,
      text: countryName,
    }));
},
[], { lazy: true });

watch(store.profile.isSelf, (is) => {
  if (!is) editSections.value = [];
});

const loadProfile = async (storeKey:ProfileStoreKey) => {
  store[storeKey].isLoading.value = true;
  const doGetOwnProfile = storeKey === ProfileStoreKey.PROFILE && store.profile.isSelf.value;

  const profile = doGetOwnProfile ? await getOwnProfile() : await getProfile(store[storeKey].userId.value);

  store[storeKey].isLoading.value = false;
  store[storeKey].profile.value = profile;
};

const init = (storeKey:ProfileStoreKey, userParam?:Ref<User>) => {
  const route = useRoute();
  const router = useRouter();
  const vuexStore = useStore();

  provide('storeKey', storeKey);

  if (storeKey === ProfileStoreKey.PROFILE) {
    me.value = vuexStore.getters['user'];
    const setUserByRoute = (route:Route | undefined) => {
      routerUserId.value = route?.params?.userId ? encodeURIComponent(route.params.userId) : null;
      store[storeKey].userId.value = routerUserId.value ?? me.value?.userId ?? '??';
      store[storeKey].isSelf.value = !routerUserId.value || routerUserId.value === me.value?.userId;
    };

    setUserByRoute(route);

    const stopGuard = router.beforeEach(async (to, from, next) => {
      setUserByRoute(to);
      await loadProfile(storeKey).then();
      next();
    });

    onBeforeUnmount(() => {
      stopGuard();
    });
  } else {
    store[storeKey].user.value = userParam?.value ?? null;
    store[storeKey].userId.value = userParam?.value?.userId ?? '';
    store[storeKey].isSelf.value = store[storeKey].userId.value === vuexStore.getters['user'].userId;
  }

  loadProfile(storeKey).then();
};

const toggleEditSection = (sectionName:string) => {
  toggleArrayItem(editSections.value, sectionName);
};

const closeEditSection = (section:string) => {
  const idx = editSections.value.indexOf(section);
  if (idx >= 0) editSections.value.splice(idx, 1);
};

const isEditSectionOpen = (section:string) => editSections.value.includes(section);

const useProfileState = (storeKeyInit?:ProfileStoreKey, user?:Ref<User>) => {
  const storeKey = inject<ProfileStoreKey | null>('storeKey', null) || storeKeyInit as ProfileStoreKey;

  if (!storeKey) throw new Error('Storekey not provided! Can\'t provide profileState without storekey.');

  const isSectionFilled = computed<Record<ProfileSectionKey, boolean>>(() => {
    const p = store[storeKey].profile.value;
    const hasCertificates = !!p?.certificates?.certificatesList?.length;
    const hasEducations = !!p?.educations?.educationsList?.length;

    const certificatesEducations = (storeKey === ProfileStoreKey.PROFILE && store[storeKey].isSelf.value) || hasCertificates || hasEducations;

    return ({
      basic: !isEmpty(p?.basic),
      certificates: hasCertificates,
      educations: hasEducations,
      projectPreferences: !isEmptyDeep(p?.projectPreferences),
      skills: true,
      references: !isEmpty(p?.references?.referencesList),
      projects: !!(p?.experiences?.experiencesDocumentLabel || p?.experiences?.experiencesText || p?.experiences?.experiencesDocument),
      certificatesEducations,
      invitedBy: !isEmpty(p?.basic?.invitedBy),
      aboutus: !!(p?.company?.employeeCount || p?.company?.industries?.length || p?.company?.aboutUs?.length),
      members: !!p?.company?.members?.length,
      publicprojects: !!p?.company?.publicProjects?.externalProjectList?.length,
    });
  });

  const isQuickInfo = storeKey === ProfileStoreKey.QUICK_INFO;

  const canEdit = computed(() => {
    const self = store[storeKey].isSelf.value;
    return self && !isQuickInfo;
  });

  return ({
    isSectionFilled,
    init: () => { init(storeKey, user); },
    editSections,
    countries,
    toggleEditSection,
    loadProfile: () => loadProfile(storeKey),
    closeEditSection,
    isEditSectionOpen,
    storeKey,
    isQuickInfo,
    ...store[storeKey],
    canEdit
  });
};

export default useProfileState;
