
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import { LoginError } from '@/views/authentication/models';
import {
  computed, defineComponent, onMounted, reactive, ref
} from 'vue';

import useVuelidate from '@vuelidate/core';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'Login',
  components: { ButtonBlock, BCXMarkdown, BCXValidatedInput },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loginError = ref<LoginError>(LoginError.NONE);

    const { emailValidation, required } = useBCValidators();

    const isPasswordAlreadyGiven = computed(() => store.state.registration.isPasswordAlreadyGiven);

    // const passwordRule =
    const fields = reactive({
      email: isPasswordAlreadyGiven.value ? (store.state.authentication?.user?.email ?? '') : '',
      password: ''
    });

    const rules = {
      email: emailValidation,
      password: { required }
    };

    const v = useVuelidate(rules, fields);

    const submitting = ref(false);

    const submit = async () => {
      const isValid = await v.value.$validate();
      if (!submitting.value && isValid) {
        const params = fields;
        submitting.value = true;
        await store.dispatch('login', params)
          .catch((error) => {
            if (error.response?.status === 401 || error.response?.status === 400) {
              switch (error.response?.data?.error?.code) {
                case 'BC-SEC-022':
                  loginError.value = LoginError.TOO_MANY_LOGINS;
                  break;
                case 'BC-SEC-012':
                  loginError.value = LoginError.ACCOUNT_SUSPENDED;
                  break;
                default:
                  loginError.value = LoginError.INVALID_LOGIN_DATA;
              }
            }
          });
        submitting.value = false;
      }
    };

    const getInviteCode = () => {
      // console.log('getInviteCode triggered');
      router.push({
        name: 'register',
        params: {
          showInvitePopup: true
        } as any
      });
    };

    const textBlock = computed(() => loginError.value || 'text_register');

    onMounted(() => {
      if (route && route.query.email) {
        fields.email = route.query.email as string;
        router.push({ query: {} });
      }
    });

    return {
      isPasswordAlreadyGiven,
      fields,
      v,
      submitting,
      textBlock,
      getInviteCode,
      submit
    };
  },
});
