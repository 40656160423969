import { Link } from '@/models/Link';
import { User } from '@/models/User';
import { EventBusKey } from '@vueuse/core';

export const NEW_CHAT_MESSAGE_ID = 'new_message_id';
export const CLEAR_CHAT_MESSAGE_ID = 'clear_message_id';
export const TEMPORARY_CHAT_MESSAGE_ID = 'temporary_message_id';
export const TEMPORARY_CHAT_USER_ID = 'temporary_message_user_id';
export const CHAT_TYPE_DIRECT = 'PRIVATE_MESSAGING';
export const CHAT_TYPE_FORUM = 'FORUM_INTERNAL_MESSAGING';
export const CHAT_TYPE_PROJECT = 'PROJECT_INTERNAL_MESSAGING';

export const BOT_NAMES = ['Marvin (ByteCookie)'];

export const MESSAGE_EVENT_REFRESH = 'refresh';
export const MESSAGE_EVENT_SET_TEXT = 'setText';

export const CONTACT_TYPE_CONTACT = 'contact';
export const CONTACT_TYPE_OTHER = 'other';

export const CONCURRENT_MESSAGE_LOADING = 5;
export const MINIMAL_CONSECUTIVE_LOADING_INTERVAL_MS = 2000;
export const MAX_FAILED_REQUESTS_IN_A_ROW = 20;

export enum SendMessageResult {
  OK = 'ok',
  PROJECT_CLOSED = 'BC-PRJ-019',
  UNKNOWN = 'unknown'
}

export enum MessengerMaxChars {
  CHAT_TYPE_DIRECT= 2000,
  CHAT_TYPE_FORUM = 5000,
  CHAT_TYPE_PROJECT = 5000,
  MESSAGE_SEARCH = 160,
  USER_SEARCH = 50,
}

export enum ChatMessageType {
  PRIVATE_FEEDBACK_MESSAGING = 'PRIVATE_FEEDBACK_MESSAGING',
  PRIVATE_MESSAGING_MESSAGE = 'PRIVATE_MESSAGING_MESSAGE',
  FORUM_THREAD_MESSAGE = 'FORUM_THREAD_MESSAGE',
  PROJECT_THREAD_MESSAGE = 'PROJECT_THREAD_MESSAGE',
  PARTICIPATION_FEEDBACK_THREAD_MESSAGE = 'PARTICIPATION_FEEDBACK_THREAD_MESSAGE',
}

export type ChatGroupType = 'PRIVATE_MESSAGING' | 'FORUM_INTERNAL_MESSAGING' | 'PROJECT_INTERNAL_MESSAGING'

export interface UserMarked extends User {
  isInContacts: number;
  showGroupLabel?: string;
}

export interface ChatText {
  id: string;
  text: string;
}

export interface ChatMessage {
  messageId: string;
  text: string;
  created: string;
  creator: string;
  read: boolean;
  edited: boolean;
  flagged: boolean;
  subMessages: [];
  upvoteCount: number;
  downvoteCount: number;
  voteResult: number;
  messageType: ChatMessageType;
  userVoted?: string;
  replyTo?: string;
  _marked?: boolean;
  hasFailedSending?: boolean;
}

export interface MessengerForumLink {
  forumId: string;
  forumTalkId: string;
  forumTalkGroupId: string;
  LINKLABEL: string;
}

export interface ChatMetaEntry {
  chatId: string;
  numberOfMessage: number;
  numberOfEdits?: number;
}

export interface ChatMeta {
  numberOfMessagesList?: ChatMetaEntry[];
}

export interface ChatMetaObjectEntry {
  messages: number;
  edited: number;
}

export interface ChatMetaObject {
  [chatId:string]: ChatMetaObjectEntry;
}

export interface ChatStub {
  externalId: string;
  groupType?: string;
  privatePartner?: User;
  forum?: MessengerForumLink;
  messages?: ChatMessage[];
  links?: string;
}

export interface Chat {
  forum?: MessengerForumLink;
  privatePartner: User;
  messages: ChatMessage[];
  type: ChatGroupType;
  id: string;
  chatId?: string;
  selected?: boolean;
  referenceId?: string;
  referenceType?: string;
  forumTalkLink?: string; // JSON representation of "forum"
  links?: Link[];
}

export interface Group {
  type: string;
  title?: string;
  shouldOpen?: boolean;
  chats: Chat[];
  id: string;
  read?: boolean;
}

export interface ChatFilter {
  all: boolean;
  [CHAT_TYPE_FORUM]: boolean;
  [CHAT_TYPE_PROJECT]: boolean;
  [CHAT_TYPE_DIRECT]: boolean;
}

export interface MessageVote {
  messageId: string;
  type: 'UP' | 'DOWN';
  reason: string;
  downvoteReason?: string;
}

export interface PostToForumRequest {
  forumId: string;
  forumThreadId: string;
  messageContent: string;
  replyToMessageId: string;
  replyToUserId: string;
}

export interface PostMessageRequest {
  targetUserId: string;
  content: string;
  messageType: ChatMessageType;
}

export interface UpdateMessageRequest {
  messageContent: string;
  targetUserId: string;
}

export interface SendMessageResponse {
  externalId?: string;
  groupType?: string;
  messages?: ChatMessage[];
  privatePartner?: User;
  error?: {
    code: string;
  };
}

type MessengerEvent = string;

export const messengerEventKey: EventBusKey<MessengerEvent> = Symbol('messengerEventKey');
