
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ButtonBlock',
  props: {
    label: {
      type: String, default: ''
    },
    secondaryLabel: {
      type: String, default: ''
    },
    disabled: {
      type: Boolean
    }
  },
  setup(props, { listeners }) {
    return {
      hasSecondaryButton: !!listeners['secondary-action']
    };
  }
});
