import { DirectiveOptions, VNode } from 'vue';

export default {
  bind(el:HTMLElement, binding, vnode:VNode):void {
    const { context } = vnode;
    const { handler, exclude } = binding.value;

    if (context) {
      const clickHandler = (e: Event) => {
        e.stopPropagation();
        const elements = [el, ...(exclude ? exclude.map((selector: string) => document.querySelector(selector)) : [])];
        if (!elements.some((element) => element?.contains(e.target as Node))) {
          handler();
        }
      };
      document.addEventListener('mousedown', clickHandler);
      document.addEventListener('touchstart', clickHandler);
      (el as any).clickOutsideHandler = clickHandler;
    }
  },
  unbind(el:HTMLElement):void {
    const handler = (el as any).clickOutsideHandler;
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
  },
} as DirectiveOptions;
