
import BCXMessengerChatMessage from '@/components/messenger/Chat/BCXMessengerChatMessage.vue';
import { Chat } from '@/models/Messenger';
import useMessengerState from '@/state/messenger/messengerState';
import scrollIntoView from '@/utils/scrollIntoView';
import {
  computed, defineComponent, onMounted, ref, Ref, toRefs, watch
} from 'vue';
import { autoResetRef, templateRef, useDebounce } from '@vueuse/core';
import onSizeChange from '@/mixins/onSizeChange';

export default defineComponent({
  name: 'BCXMessengerChatMessages',
  components: { BCXMessengerChatMessage },
  props: {
    chat: {
      type: Object as () => Chat,
      default: () => null,
    },
  },
  setup(props) {
    const {
      isInputFocussed, isExpanded, isInputMaximized, editMessageId,
    } = useMessengerState();
    const { chat } = toRefs(props);

    const isExpandedAfterAnimation = useDebounce(isExpanded, 500);
    const messages = templateRef('messages') as Ref<HTMLElement>;

    const areInitialMessagesShown = autoResetRef(true, 150);
    const isFirstInitialMessage = ref(true);

    const editedMessageElm = computed(() => (editMessageId.value && messages.value
      ? messages.value.querySelector(`#msg-${editMessageId.value}`) : null));

    const scrollToBottom = (reason = '?') => {
      if (editedMessageElm.value) {
        scrollIntoView(editedMessageElm.value as HTMLElement, messages.value);
      } else if (messages.value) {
        // console.log('ScrollToBottom because ', reason);
        const { scrollHeight, offsetHeight } = messages.value;
        const scrollTo = scrollHeight - offsetHeight;
        messages.value.scrollTo(0, scrollTo);
      }
    };

    const onMessageShown = () => {
      if (!areInitialMessagesShown.value || isFirstInitialMessage.value) {
        isFirstInitialMessage.value = false;
        areInitialMessagesShown.value = false;
        scrollToBottom('onMessageShown');
      }
    };

    // Keep scroll position to bottom, also when area resizes (Gets smaller)
    onSizeChange(messages, ({ height, oldHeight }) => {
      // console.log('messages size change:', height);
      if (height < oldHeight) {
        scrollToBottom('onMessageWindowSizeChange');
      }
    });

    onMounted(() => {
      watch(() => chat.value?.messages?.length, () => {
        isFirstInitialMessage.value = true;
        scrollToBottom('length change');
      });
      watch(() => chat.value.chatId, () => {
        isFirstInitialMessage.value = true;
      });
      watch([isExpandedAfterAnimation, isInputFocussed, isInputMaximized], ([curExpanded, isFocussed, isMaximized],
        [prevExpanded, prevIsFocussed, prevIsMaximized]) => {
        if (curExpanded !== prevExpanded
          || isFocussed !== prevIsFocussed
          || isMaximized !== prevIsMaximized) {
          scrollToBottom();
        }
      }, {
        immediate: true,
      });
      scrollToBottom();
    });

    const sortedMessages = computed(() => [...chat.value.messages].sort((msgA, msgB) => msgA.created.localeCompare(msgB.created)));

    return {
      sortedMessages,
      isInputFocussed,
      areInitialMessagesShown,
      onMessageShown
    };
  },
});
