
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import ThreadEntry from './ThreadEntry.vue';
import CreateMessage from './CreateMessage.vue';
import { MessagePost } from '../models/Forum';

export default Vue.extend({
  name: 'ThreadReply',
  components: {
    ThreadEntry,
    CreateMessage,
    BCXMarkdown,
  },
  props: {
    reply: {
      type: Object,
      default() {
        return {};
      },
    },
    threadId: {
      type: String,
      default: undefined,
    },
    disableReply: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
      default: undefined,
    },
    forumId: {
      type: String,
      default: undefined,
    },
    editMessageId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('page', ['isMobileLayout']),
    ...mapState('page', ['isSmartphoneWidth']),
    noReplies(): boolean {
      return this.reply.replies?.length === 0;
    },
    editMessageAttachedTo(): string {
      if (this.isMobileLayout) {
        return `#${this.$root.$el.id}`;
      }
      return `#message-${this.reply.messageId}`;
    },
    editMessageVisible: {
      set() {
        this.toggleEditMessage();
      },
      get(): boolean {
        return this.reply.messageId === this.editMessageId;
      },
    },
  },
  methods: {
    ...mapActions('forum', ['editMessage']),
    postMessageEdit(text: string): void {
      const postMessage = {
        forumId: this.forumId,
        threadId: this.threadId,
        forumThreadMessageId: this.reply.messageId,
        messageContent: text,
      };
      this.$emit('update-message', postMessage, this.reply.messageCount);
      this.toggleEditMessage();
    },
    postMessage(postMessage: MessagePost) {
      this.$emit('post-message', postMessage);
    },
    toggleEditMessage(): void {
      this.$emit('edit-message', this.reply.messageId);
    },
  },
});
