
import { ProfileStoreKey } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import ProfileSection from '@/views/profile/components/ProfileSection.vue';
import ProfileBasicEditor from '@/views/profile/components/editors/ProfileBasicEditor.vue';
import ProfileCertificatesEducationsEditor from '@/views/profile/components/editors/ProfileCertificatesEducationsEditor.vue';
import ProfilePreferencesEditor from '@/views/profile/components/editors/ProfilePreferencesEditor.vue';
import ProfileProjectsEditor from '@/views/profile/components/editors/ProfileProjectsEditor.vue';
import ProfileReferencesEditor from '@/views/profile/components/editors/ProfileReferencesEditor.vue';
import ProfileSkillsEditor from '@/views/profile/components/editors/ProfileSkillsEditor.vue';
import ProfileBasicView from '@/views/profile/components/views/ProfileBasicView.vue';
import ProfileCertificatesEducationsView from '@/views/profile/components/views/ProfileCertificatesEducationsView.vue';
import ProfileInvitedBy from '@/views/profile/components/views/ProfileInvitedBy.vue';
import ProfilePreferencesView from '@/views/profile/components/views/ProfilePreferencesView.vue';
import ProfileProjectsView from '@/views/profile/components/views/ProfileProjectsView.vue';
import ProfileReferencesView from '@/views/profile/components/views/ProfileReferencesView.vue';
import ProfileSkillsView from '@/views/profile/components/views/ProfileSkillsView.vue';
import Vue, { inject } from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';

export default Vue.extend({
  name: 'ProfileFreelancer',
  components: {
    BCXBreadcrumbs,
    BCXContentArea,
    ProfileProjectsView,
    ProfileProjectsEditor,
    ProfileReferencesView,
    ProfileReferencesEditor,
    ProfileCertificatesEducationsEditor,
    ProfileCertificatesEducationsView,
    ProfileSkillsEditor,
    ProfileSkillsView,
    ProfilePreferencesEditor,
    ProfilePreferencesView,
    ProfileSection,
    ProfileBasicEditor,
    ProfileInvitedBy,
    ProfileBasicView,
  },
  setup() {
    const {
      isSelf, isEditSectionOpen, isSectionFilled, isQuickInfo,
    } = useProfileState(ProfileStoreKey.PROFILE);

    const breadcrumbs = inject('breadcrumbs', []);

    return {
      isEditSectionOpen,
      isSelf,
      isSectionFilled,
      isQuickInfo,
      breadcrumbs
    };
  },
});
