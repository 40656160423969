import { getIsChatRead } from '@/state/messenger/traverseHelpers';
import { computed, Ref } from 'vue';
import { Chat, ChatFilter, Group } from '@/models/Messenger';

interface UseFilterOptions {
  showNew: Ref<boolean>;
  searchTerm: Ref<string>;
  filters: ChatFilter;
}

/**
 * Filters groups by "read" state, group type and search term
 * @author nc
 *
 * @param groupsRef
 * @param chatsRef
 * @param showNew
 * @param searchTerm
 * @param filters
 */
const useFilter = (groupsRef:Ref<Group[]>, chatsRef:Ref<Chat[]>, {
  showNew, searchTerm, filters,
}:UseFilterOptions) => {
  const groupHasNewMessages = (group: Group):boolean => group.chats.some(
    (chat) => chat.messages.some(
      ({ read }) => !read,
    ),
  );

  const searchTermLower = computed(() => searchTerm.value.toLocaleLowerCase());

  const groupFilterFn = (group: Group):boolean => {
    // group type selected?
    if (!(filters.all || Object.entries(filters).some(
      ([filterName, isSet]) => group.type === filterName && isSet,
    ))) return false;
    // show new Messages selected?
    return !(showNew.value && !groupHasNewMessages(group));
  };

  const chatSearchTextFn = (chat: Chat): boolean => {
    const nameFound = chat?.privatePartner?.fullname?.toLocaleLowerCase()?.includes(searchTermLower.value);

    const textFound = chat.messages.some(
      ({
        text,
      }: any) => (
        text.toLocaleLowerCase()
          .includes(searchTermLower.value)
      ),
    );

    const titleFound = chat.forum && chat.forum.LINKLABEL.toLocaleLowerCase().includes(searchTermLower.value);

    return !!(nameFound || textFound || titleFound);
  };

  const chatFilterFn = (chat: Chat):boolean => {
    if (!(filters.all || Object.entries(filters).some(
      ([filterName, isSet]) => chat.type === filterName && isSet,
    ))) return false;
    return chatSearchTextFn(chat) && !(showNew.value && !getIsChatRead(chat));
  };

  const groupNewMessagesMapper = ({
    chats,
    ...group
  }: Group):Group => ({
    chats: chats.filter(
      (chat) => chat.messages.some(({ read }) => !read),
    ),
    ...group,
  });

  const groupSearchedMessagesMapper = ({
    chats,
    ...group
  }: Group) => {
    const filteredChats = chats.filter(chatSearchTextFn);
    return ({
      ...group,
      shouldOpen: !!filteredChats.length,
      chats: filteredChats,
    });
  };

  const groupsFiltered = computed(() => {
    const filteredGroups = groupsRef.value.filter(groupFilterFn);
    const newGroups = showNew.value
      ? filteredGroups.map(groupNewMessagesMapper) as Group[]
      : filteredGroups;

    if (searchTerm.value) {
      return newGroups.map(groupSearchedMessagesMapper)
        .filter(
          ({ chats }) => chats.some(({ messages }) => messages.length),
        ) as Group[];
    }
    return newGroups;
  });

  const chatsFiltered = computed<Chat[]>(() => chatsRef.value.filter(chatFilterFn));

  return {
    groupsFiltered,
    chatsFiltered,
    getIsChatRead
  };
};

export default useFilter;
