
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import {
  defineComponent, ref,
} from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';

import UserSearchSearchFields from '@/views/user-search/components/UserSearchSearchFields.vue';
import UserSearchSwitchSimpleMode from '@/views/user-search/components/UserSearchSwitchSimpleMode.vue';
import UserSearchResults from '@/views/user-search/components/UserSearchResults.vue';
import UserSearchFilterBox from '@/views/user-search/components/UserSearchFilterBox.vue';
import UserSearchHeader from '@/views/user-search/components/UserSearchHeader.vue';
import UserSearchFilters from '@/views/user-search/components/UserSearchFilters.vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';

export default defineComponent({
  name: 'UserSearch',
  components: {
    UserSearchFilters,
    UserSearchHeader,
    UserSearchFilterBox,
    UserSearchResults,
    UserSearchSwitchSimpleMode,
    UserSearchSearchFields,
    BCXInfoCollapsible,
    BCXContentArea,
  },
  setup() {
    const isInlineHelpOpen = ref(false);

    const {
      hasSearched
    } = useUserSearch();

    return {
      isInlineHelpOpen,
      hasSearched
    };
  }
});
