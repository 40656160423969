
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import IconLightBulb from '@/components/svg/profile-lightbulb.svg?inline';
import {
  computed, defineComponent, ref, toRefs
} from 'vue';
import BCXInfoHelpText from '@/components/molecules/BCXInfoHelpText.vue';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';

export default defineComponent({
  name: 'BCXInfoSection',
  components: {
    BCXHelpTextTriggerIcon,
    BCXInfoHelpText,
    BCXInfoCollapsible,
    IconLightBulb,
  },
  props: {
    text: { type: String, default: '' },
    helpText: { type: String, default: '' },
    noLightbulb: { type: Boolean, default: false },
    hideInfoBoxPadding: { type: Boolean },
    infoBoxPadding: { type: String, default: '32px' }
  },
  setup(props) {
    const { hideInfoBoxPadding, infoBoxPadding } = toRefs(props);
    const isInlineHelpOpened = ref(false);

    const infoBoxDistance = computed(() => {
      if (hideInfoBoxPadding.value) {
        return infoBoxPadding.value;
      }
      return isInlineHelpOpened.value ? infoBoxPadding.value : 0;
    });

    return {
      isInlineHelpOpened,
      infoBoxDistance
    };
  }
});
