
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { defineComponent, PropType } from 'vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import { RawLocation } from 'vue-router';
import { mapGetters } from 'vuex';
import useQuickInfo from '@/state/quickInfo';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import useUser from '@/mixins/useUser';
import { ForumThreadMetaData, Thread } from '@/views/forum/models/Forum';

export default defineComponent({
  components: {
    BCXAvatar,
    TagDisplay
  },
  props: {
    thread: {
      type: Object as PropType<Thread>,
      required: true,
      default() {
        return {};
      },
    },
    showTags: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      x: false,
    };
  },
  computed: {
    ...mapGetters('forum', ['getForumId', 'getGroupId']),
    ...mapGetters('userStates', ['getUserInfo']),
    creationDateFormatted(): string {
      return this.formatDate(this.thread.created);
    },
    metaData(): ForumThreadMetaData {
      return this.thread.forumThreadMetaData;
    },
    latestReplyMessageUserFullname(): string {
      const user = this.metaData.latestReplyMessageUser;
      const { displayName } = useUser(user);
      return displayName.value;
    },
    creatorFullName(): string {
      const { creator } = this.thread;
      const { displayName } = useUser(creator);
      return displayName.value;
    },
    latestReplyDateFormatted(): string {
      return this.formatDate(this.metaData.latestReplyMessageTimestamp);
    },
    numberOfReplies(): number {
      return this.metaData.numberOfReplies || 0;
    },
    hasReplies(): boolean {
      return this.numberOfReplies > 0;
    },
    numberOfViews(): number {
      return this.metaData.numberOfViews || 0;
    },
    repliesLabel(): string {
      if (this.numberOfReplies === 1) {
        return this.$t('forum.labels.replies.singular') as string;
      }
      return this.$t('forum.labels.replies.plural') as string;
    },
    viewsLabel(): string {
      if (this.numberOfViews === 1) {
        return this.$t('forum.labels.views.singular') as string;
      }
      return this.$t('forum.labels.views.plural') as string;
    },
    voteResult(): number {
      return this.metaData.voteResult;
    },
    routerLink(): RawLocation {
      return { name: 'thread', params: { forumId: this.getForumId, groupId: this.getGroupId, threadId: this.thread.threadId } };
    },
  },
  methods: {
    goToThread() {
      this.$router.push(this.routerLink);
    },
    formatDate(timestamp: string): string {
      const { getFormattedTimeByAge } = useFormattedDateTime();
      return getFormattedTimeByAge(timestamp);
    },
    showUserQuickInfoCreator(ev: Event) {
      const { setQuickInfo, quickInfoShowContactHistory, quickInfoShowBadgesLegend } = useQuickInfo();
      quickInfoShowContactHistory.value = false;
      quickInfoShowBadgesLegend.value = false;
      const el = ev.target as HTMLElement;
      const user = {
        userId: this.thread.creator.userId,
        fullname: this.creatorFullName,
      };
      const { left, top } = el.getBoundingClientRect();
      setQuickInfo(this.getUserInfo(user.userId), left, top + 50);
    },
    showUserQuickInfoLatestReply(ev: Event) {
      const {
        quickInfoShowContactHistory, quickInfoShowBadgesLegend, showQuickInfo
      } = useQuickInfo();
      quickInfoShowContactHistory.value = false;
      quickInfoShowBadgesLegend.value = false;
      showQuickInfo(ev.target as HTMLElement, this.metaData.latestReplyMessageUser);
    },
  },
});
