import axios from '@/plugins/axios';
import {
  AcceptOfferRequest,
  ProjectHasOffersResponse, ProjectOffer,
  ProjectOfferRequest, ProjectOfferResponse, ProjectOfferSearchRequest, ProjectOfferSearchResponse, RejectOfferRequest
} from '@/views/my-projects/types';

// eslint-disable-next-line import/prefer-default-export
export async function postOffer(proejctId: string, offer: ProjectOfferRequest): Promise<ProjectOfferResponse> {
  const result = await axios.post(`/v1/projects/${proejctId}/offers`, offer);
  return result.data;
}

export async function searchOffers(request: ProjectOfferSearchRequest): Promise<ProjectOfferSearchResponse[]> {
  const result = await axios.post('v1/projects/offers/search', request);
  return result.data;
}

export async function getHasOffers(projectId: string): Promise<ProjectHasOffersResponse> {
  const result = await axios.get(`/v1/projects/${projectId}/offers`);
  return result.data;
}

export async function declineOffer(projectId: string, offerId: string, data: RejectOfferRequest): Promise<void> {
  return axios.post(`/v1/projects/${projectId}/offers/${offerId}/decline`, data);
}

export async function acceptanceOffer(projectId: string, offerId: string, data: AcceptOfferRequest): Promise<void> {
  return axios.post(`/v1/projects/${projectId}/offers/${offerId}/accept`, data);
}

export async function getProjectOffer(projectId: string, offerId: string): Promise<ProjectOffer> {
  const result = await axios.get(`/v1/projects/${projectId}/offers/${offerId}`);
  return result.data;
}

export async function deleteProjectOffer(projectId: string, offerId: string): Promise<void> {
  return axios.delete(`/v1/projects/${projectId}/offers/${offerId}`);
}

export async function negotiateProjectOffer(projectId: string, offerId: string): Promise<void> {
  return axios.post(`/v1/projects/${projectId}/offers/${offerId}/negotiate`);
}
