
import {
  defineComponent, PropType, toRef
} from 'vue';
import Triangle from '@/components/svg-components/Triangle.vue';
import MinusCircle from '@/components/svg/minus-circle.svg?inline';
import { ExternalTagAssignment, UserVoted } from '@/services/TaggingService';

export default defineComponent({
  components: { Triangle, MinusCircle },
  props: {
    tag: {
      type: Object as PropType<ExternalTagAssignment>,
      required: true
    },
    disableVoting: {
      type: Boolean,
      default: false
    },
    isRemoveable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const refTag = toRef(props, 'tag');

    return { UserVoted, refTag };
  }
});
