
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import SidebarContent from '@/components/SidebarContent.vue';
import useInfoBoxState from '@/state/infoBox';
import {
  computed, defineComponent, toRef, watch
} from 'vue';

export default defineComponent({
  name: 'SidebarContainer',
  components: {
    SidebarContent,
    BCXMarkdown,
  },
  setup(_, { slots, emit }) {
    const {
      infoBoxState, resetInfoBox, hasCustomSidebar, sampledText
    } = useInfoBoxState();

    const hasSlotContent = computed(() => !!slots?.default);

    const isOpened = toRef(infoBoxState, 'isOpened');

    const showCloseIcon = computed(() => hasSlotContent.value && hasCustomSidebar.value);

    const anythingInside = computed(() => infoBoxState.isActive || hasCustomSidebar.value);

    watch(anythingInside, (anything) => {
      emit('anything-inside', anything);
    }, {
      immediate: true
    });

    return {
      showCloseIcon,
      isOpened,
      hasSlotContent,
      resetInfoBox,
      infoBoxState,
      text: sampledText
    };
  }
});
