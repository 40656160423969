import { RouteConfig } from 'vue-router';
import store from '@/store';
import SubscriptionFeed from '@/views/subscriptions/pages/SubscriptionFeed.vue';
import SubscriptionManager from '@/views/subscriptions/pages/SubscriptionManager.vue';

const routes: Array<RouteConfig> = [
  {
    name: 'subscriptions',
    path: '/subscriptions',
    component: SubscriptionFeed,
    meta: {
      layout: 'full',
      auth: true,
    },
    beforeEnter(_to, _from, next) {
      store.dispatch('subscriptions/fetchSubscriptionFeed').then(() => {
        next();
      });
    }
  },
  {
    name: 'manageSubscriptions',
    path: '/subscriptions/manage',
    component: SubscriptionManager,
    meta: {
      layout: 'full',
      auth: true,
    },
    beforeEnter(_to, _from, next) {
      store.dispatch('subscriptions/fetchSubscriptions').then(() => {
        next();
      });
    }
  }
];

export default routes;
