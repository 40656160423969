

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LPBGArrow',
  props: {
    isDownwards: Boolean,
    isDarker: Boolean
  }
});

