
import { defineComponent } from 'vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';

export default defineComponent({
  setup() {
    const { showTags, actualResultSize } = useUserSearch();
    return { showTags, actualResultSize };
  }
});
