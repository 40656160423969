import useI18n from '@/mixins/useI18n';
import BCXRenderer from '@/utils/markdown/BCXRenderer';
import BCXRendererNoLinks from '@/utils/markdown/BCXRendererNoLinks';
import BCXRendererRestricted from '@/utils/markdown/BCXRendererRestricted';
import { emojis } from '@/utils/markdown/emojis';
import { ViewerOptions } from '@toast-ui/editor';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import BCXHTMLSanitizer from '../BCXHTMLSanitizer';

const bcxRenderer = BCXRenderer;
const bcxRendererNoLinks = BCXRendererNoLinks;
const bcxRendererRestricted = BCXRendererRestricted;
let renderMode = '';

export const setMode = (el: HTMLElement, mode: string): ViewerOptions => {
  switch (mode) {
    case 'restricted':
      return {
        el,
        customHTMLRenderer: bcxRendererRestricted,
        customHTMLSanitizer: BCXHTMLSanitizer.beforeRenderMarkdown,
        extendedAutolinks: true,
        usageStatistics: false,
      };
    case 'nolinks':
    case 'no-links':
      return {
        el,
        customHTMLRenderer: bcxRendererNoLinks,
        usageStatistics: false,
      };
    case 'normal':
    default:
      return {
        el,
        customHTMLRenderer: bcxRenderer,
        extendedAutolinks: true,
        usageStatistics: false,
        customHTMLSanitizer: BCXHTMLSanitizer.beforeRenderMarkdown,
      };
  }
};

const markdown = (el: HTMLElement, mode = 'normal') => {
  if (renderMode !== mode) {
    renderMode = mode;
  }
  const viewer = new Viewer({
    ...setMode(el, mode),
  });
  return viewer;
};

export {
  markdown, emojis
};
