
import {
  computed, defineComponent, ref, watch
} from 'vue';
import axios from '@/plugins/axios';

export default defineComponent({
  name: 'Requester',
  setup() {
    const api = ref<any>(null);
    const body = ref('');
    const url = ref('');
    const response = ref<any>(null);
    const endpointURL = ref('');
    const requestType = ref('');
    const jsonSchema = ref<any>(null);
    const actualURL = ref('');

    (async () => {
      const url = `${process.env.BASE_URL}apixga0c2.json`;
      api.value = await (await fetch(url)).json();
    })();

    const selectedEndpoint = computed(() => (endpointURL.value ? api.value.paths[endpointURL.value] : null));

    watch(selectedEndpoint, (endpoint) => {
      requestType.value = Object.keys(endpoint)?.[0] ?? '';
    });

    const selectedRequest = computed(() => selectedEndpoint.value?.[requestType?.value]);

    const queryString = computed(() => {
      const parameters = selectedRequest.value?.parameters as any[];
      if (parameters) {
        const queries = parameters.filter((parameter) => parameter?.in === 'query').map((parameter) => `${parameter.name}=...`);
        return queries.length ? `?${queries.join('&')}` : '';
      }
      return '';
    });

    watch(selectedRequest, (request) => {
      const jsonSchemaPath = request?.requestBody?.content?.['application/json']?.schema?.$ref;
      if (jsonSchemaPath) {
        const [_, ...path] = jsonSchemaPath.split('/');
        let obj = api.value;

        while (path.length) {
          const key = path.shift();
          obj = obj?.[key];
        }
        jsonSchema.value = obj;
      } else {
        jsonSchema.value = null;
      }
    });

    watch([queryString, endpointURL], () => {
      actualURL.value = endpointURL.value + queryString.value;
    });

    watch(jsonSchema, (jsonSchema) => {
      if (!jsonSchema) {
        body.value = '';
        return;
      }

      const obj = Object.fromEntries(
        Object.entries(jsonSchema?.properties as Record<string, any> ?? []).map(
          ([property, info]):[string, any] => {
            let initialValue:any;
            switch (info?.type) {
              case 'string':
                initialValue = '';
                break;
              case 'number':
                initialValue = 0;
                break;
              case 'boolean':
                initialValue = false;
                break;
              default:
            }
            return (
              [property, initialValue]
            );
          }
        )
      );
      body.value = JSON.stringify(obj, null, 2);
    });

    const sendRequest = async () => {
      if (!endpointURL.value) return;

      const url = actualURL.value.replace(/^\/api/, '');
      response.value = await axios.request({
        method: requestType.value,
        url,
        ...body.value && { data: JSON.stringify(body.value) },
      }).catch((e) => {
        const { data, status, headers } = e.response;
        return {
          data, status, headers
        };
      });
    };

    const endpoints = computed(() => (api.value ? api.value.paths : []));

    return {
      sendRequest,
      url,
      body,
      response,
      requestType,
      endpointURL,
      selectedEndpoint,
      selectedRequest,
      jsonSchema,
      endpoints,
      actualURL
    };
  }
});
