
import BCXInfoBanner from '@/components/molecules/BCXInfoBanner.vue';
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import IconRecommendations from '@/components/svg/project-recommendations.svg?inline';
import IconRecommendationsForMe from '@/components/svg/recommendations-4-me.svg?inline';
import useI18n from '@/mixins/useI18n';
import {
  ExternalRecommendation,
  PAGE_SIZE_RECOMMENDATIONS,
  RecommendationAction,
  RECOMMENDATIONS_EVENT_REFRESH,
  RecommendationsForMeResponse
} from '@/models/Recommendations';
import recommendationService from '@/services/RecommendationService';
import useRecommendationState from '@/state/recommendationsState';
import MyProjectDetails from '@/views/my-projects/components/MyProjectDetails.vue';
import ProjectListItem from '@/views/project-forum/components/ProjectListItem.vue';
import RecommendationFeedbackPopUp from '@/views/recommendations/components/RecommendationFeedbackPopUp.vue';
import { computed, defineComponent, ref } from 'vue';
import { refAutoReset } from '@vueuse/core';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';
import BCXPaginatedContent from '@/components/molecules/BCXPaginatedContent.vue';
import { getAllNotifications } from '@/services/NotificationsService';
import { INTERVAL_RESET, intervalRefresherEventBus } from '@/mixins/useIntervalRefresher';

export default defineComponent({
  name: 'RecommendationsForMe',
  components: {
    BCXPaginatedContent,
    BCXHelpTextTriggerIcon,
    BCXInfoCollapsible,
    BCXInfoBanner,
    RecommendationFeedbackPopUp,
    MyProjectDetails,
    ProjectListItem,
    BCXMarkdown,
    IconRecommendations,
    IconRecommendationsForMe
  },
  setup() {
    const { t } = useI18n();
    const isInlineHelpOpened = ref(false);
    const feedbackRecommendation = ref<null | ExternalRecommendation>(null);
    const actionAfterFeedback = ref<RecommendationAction>('');
    const recommendationsForMeResponse = ref<RecommendationsForMeResponse | null>(null);
    const isAddedProjectInfoBannerVisible = refAutoReset(false, 10000);
    const { recommendationsEventBus } = useRecommendationState();

    const load = async () => {
      recommendationsForMeResponse.value = await recommendationService.getRecommendationsForMe();
    };

    load();

    recommendationsEventBus.on((event) => {
      if (event === RECOMMENDATIONS_EVENT_REFRESH) load();
    });

    const recommendations = computed(() => {
      const recommendations = recommendationsForMeResponse.value?.recommendations ?? [];
      return recommendations.sort((a, b) => b.created.localeCompare(a.created));
    });

    const giveFeedback = (recommendation: ExternalRecommendation, action: RecommendationAction) => {
      feedbackRecommendation.value = recommendation;
      actionAfterFeedback.value = action;
    };

    const showInfoBox = () => {
      isAddedProjectInfoBannerVisible.value = true;
    };

    const feedbackSent = async () => {
      await load();
      showInfoBox();
      intervalRefresherEventBus.emit(INTERVAL_RESET, 'reload after recommendations update');
    };

    const latestActionText = computed(() => t(`recommendations.recommendations4Me.${
      actionAfterFeedback.value === 'add' ? 'projectAdded' : 'projectRemoved'}`));

    return {
      recommendations,
      giveFeedback,
      feedbackSent,
      feedbackRecommendation,
      actionAfterFeedback,
      isInlineHelpOpened,
      load,
      showInfoBox,
      latestActionText,
      isAddedProjectInfoBannerVisible,
      PAGE_SIZE_RECOMMENDATIONS
    };
  }
});
