
import { ExternalVideoChatParticipant } from '@/__generated__/types';
import simulationPlaceholder from '@/assets/svg/video-placeholders/testScreen.svg';
import useSafariCantAspectRatio from '@/utils/useSafariCantAspectRatio';
import useChatRoomRouter from '@/views/virtual-pub/composable/useChatRoomRouter';
import { defineComponent, onMounted, PropType } from 'vue';
import {
  templateRef, useDebounceFn, useEventListener, useIntervalFn
} from '@vueuse/core';

const PORTRAIT_VIDEO_SCALE_INFLUENCE = 0.5;

export default defineComponent({
  name: 'OneToManyChat',

  props: {
    participants: {
      type: Array as PropType<ExternalVideoChatParticipant[]>,
      default: () => []
    }
  },

  setup() {
    const { isSimulation } = useChatRoomRouter();
    const SCREEN_ASPECT = 16 / 9;

    const videoContainer = templateRef<HTMLElement>('videoContainer');
    const el = templateRef<HTMLElement>('el');

    const isAspectRatioReady = CSS.supports('aspect-ratio', '16/9');

    const calc = async () => {
      if (!(el.value && videoContainer.value)) {
        return;
      }

      const { clearHeights, setHeights } = useSafariCantAspectRatio('#videoInputPreview, #videoContainerCounter .participant-container');

      const maxCols = 12;
      const chatRoomContainer = document.querySelector('.chat-room-container');
      const bottomBar = document.querySelector('.video-bottom-bar');

      el.value.style.setProperty('--scale', '1');
      if (!isAspectRatioReady) clearHeights();

      const { width: vw = 0, height: _vh = 0 } = chatRoomContainer?.getBoundingClientRect() ?? {};
      const { height: bbh = 0 } = bottomBar?.getBoundingClientRect() ?? {};
      const vh = _vh - bbh * 2;

      const deviceAspect = vw / vh;
      const numElements = videoContainer.value.children.length;

      const cols = Math.min(Math.round(Math.sqrt(numElements / SCREEN_ASPECT / deviceAspect) * deviceAspect), maxCols, numElements);
      const rows = Math.ceil(numElements / cols);

      el.value.style.setProperty('--cols', cols.toString());

      if (!isAspectRatioReady) setHeights();
      const allHeight = (vw / cols / SCREEN_ASPECT) * rows;
      const height = vh;

      const scale = isAspectRatioReady ? Math.min(height / allHeight, 1) : height / allHeight;

      // console.log('allHeight:', allHeight, 'height:', height, 'scale:', scale);

      el.value.style.setProperty('--scale', scale.toString());

      [...el.value.querySelectorAll('video')].forEach((element: HTMLVideoElement) => {
        const videoAspect = element.videoWidth / element.videoHeight;
        const scale = (1 / videoAspect) * PORTRAIT_VIDEO_SCALE_INFLUENCE + (1 - PORTRAIT_VIDEO_SCALE_INFLUENCE);
        if (videoAspect < 1) {
          element.style.setProperty('--image-scale', `${scale}`);
        } else {
          element.style.removeProperty('--image-scale');
        }
      });
    };

    const calcDebounced = useDebounceFn(calc, 100);

    // calc on resize
    useEventListener(window, 'resize', calcDebounced);

    // --- Simulation stuff ---
    const addSimulationPlaceholder = (element: Element) => {
      const img = document.createElement('img');
      img.src = simulationPlaceholder;
      element.appendChild(img);
    };

    onMounted(() => {
      calc();

      useIntervalFn(() => {
        calc();
      }, 5000);

      if (isSimulation.value) {
        const element = document.querySelector('#videoInputPreview');
        if (element) addSimulationPlaceholder(element);
      }
    });

    const addScreen = () => {
      const newScreen = document.createElement('div');
      newScreen.className = 'participant-container';
      addSimulationPlaceholder(newScreen);

      videoContainer.value?.appendChild(newScreen);
    };

    return {
      isSimulation,
      addScreen
    };
  }
});
