import { UserVoted } from '@/services/TaggingService';

export enum VoteReason {
  Content = 'CONTENT',
  Communication = 'COMMUNICATION',
  Both = 'BOTH',
}

export enum VoteType {
  Up = 'UP',
  Down = 'DOWN',
  NOTVOTED = 'NOTVOTED'
}

export type businessObjectType = 'MESSAGE' | 'PROFILE' | 'MESSAGING_GROUP' | 'PROJECT' | 'TAG' | 'BLOG_ENTRY' | 'TAG_ASSIGNMENT';
export type VoteReasonType = 'CONTENT' | 'COMMUNICATION' | 'BOTH' | '';

export interface MetaDataVoting {
  voteResult: number;
  upvoteCount: number;
  downvoteCount: number;
}

export interface UserMetaDataVoting {
  userVoted: UserVoted;
}

export interface UpdateVoting {
  businessObjectType: businessObjectType;
  businessObjectId: string;
  type: UserVoted;
  reason: VoteReasonType;
  downVoteReason?: string;
  link?: string;
}

export interface UpdateVotingResponse {
  businessObjectId: boolean;
  metaDataVoting: MetaDataVoting;
  userMetaDataVoting: UserMetaDataVoting;
}
export interface VoteOption {
  label: string;
  value: string | number;
}

export interface UpvotePayload {
  voteType: 'UP';
  reason: VoteReasonType;
  link?: string;
}
