
import useMobileNavigationVisibility from '@/mixins/useMobileNavigationVisibility';
import useResponsiveness from '@/mixins/useResponsiveness';
import { InfoBoxState } from '@/models/InfoBox';
import usePageState from '@/state/pageState';
import Full from '@/views/layouts/Full.vue';
import LoginRegister from '@/views/layouts/LoginRegister.vue';
import Plain from '@/views/layouts/Plain.vue';
import Landingpage from '@/views/layouts/Landingpage.vue';
import Vue, { computed, provide, watch } from 'vue';
import { useRoute } from 'vue2-helpers/vue-router';
import { mapMutations, MutationMethod } from 'vuex';
import { logDebug } from '@/utils/logger';
import { whenever } from '@vueuse/core';
import useTokenState from '@/state/tokenState';
import { useStore } from 'vue2-helpers/vuex';
import useAppVersionState from './state/appVersionState';
import BCXInfoBanner from './components/molecules/BCXInfoBanner.vue';
import BCXMarkdown from './components/molecules/BCXMarkdown.vue';
import useI18n from './mixins/useI18n';

const defaultLayout = 'plain';

export default Vue.extend({
  name: 'App',

  components: {
    Plain,
    Full,
    LoginRegister,
    Landingpage,
    BCXInfoBanner,
    BCXMarkdown
  },

  setup() {
    const { isHidden } = useMobileNavigationVisibility();
    const { isSidebarVisible, isDesktopWidth } = useResponsiveness();
    const { mainClasses, pageNotFound, globalErrorMessage } = usePageState();
    const { t, locale } = useI18n();
    const { isLoggedIn } = useTokenState();
    const store = useStore();

    whenever(isLoggedIn, () => {
      const { user } = store.getters;
      const prefLocale = user.preferredLocale?.substring?.(0, 2);
      if (prefLocale) locale.value = prefLocale;
    }, { immediate: true });

    const route = useRoute();

    const {
      isAppUpdateAvaliable, isNotificationIgnored, ignoreNotification, updateApp
    } = useAppVersionState();

    const showAppUpdateNotification = computed(() => (
      isAppUpdateAvaliable.value
      || pageNotFound.value
      || globalErrorMessage.value)
      && !isNotificationIgnored.value);

    watch(showAppUpdateNotification, (is) => {
      if (is) {
        logDebug('update notification shown');
      } else {
        pageNotFound.value = false;
      }
    });

    const reloadAppIfNeeded = () => {
      if (pageNotFound.value) {
        ignoreNotification();
      } else {
        updateApp();
      }
    };

    provide('isMobileLayerHidden', isHidden);

    const layout = computed(() => route.meta?.layout ?? defaultLayout);

    const classes = computed(() => [layout.value, ...mainClasses.value]);

    const appUpdateText = computed(() => {
      let text: string;
      if (globalErrorMessage.value) {
        const { code, i18Key } = globalErrorMessage.value;
        const message = `${t(i18Key, { code })}`;
        text = `${t('common.globalError', { message })}`;
      } else if (pageNotFound.value) {
        text = `${t('common.pageNotFound')}`;
      } else {
        text = `${t('common.appUpdateAvailable')}`;
      }
      if (isDesktopWidth.value) {
        return text.replace('<br>', '');
      }
      return text;
    });

    const infoBannerType = computed(() => {
      if (pageNotFound.value || globalErrorMessage.value) return 'page-not-found';
      return 'app-update';
    });

    return {
      isSidebarVisible,
      classes,
      layout,
      route,
      showAppUpdateNotification,
      ignoreNotification,
      reloadAppIfNeeded,
      isDesktopWidth,
      appUpdateText,
      pageNotFound,
      infoBannerType
    };
  },

  data: () => ({
    showErrorMessage: false,
    errorMessageTimeout: -1,
    sidebarBox: null
  }),

  computed: {
    errorMessage(): string {
      return this.$store.getters.errorMessage;
    },
    sidebarComponent(): Vue | null {
      return this.$route.meta?.sidebarComponent ?? null;
    },
    infoBoxContent(): InfoBoxState | null {
      return this.$route.meta?.infoBox ?? null;
    }
  },

  created() {
    this.setInitialTimestamp();

    const config: { [key: string]: MutationMethod } = {
      '(max-width:480px)': this.setIsSmartphoneWidth,
      '(max-width:767px)': this.setIsMobileWidth,
      '(min-width:768px) and (max-width:1439px)': this.setIsMini,
      '(max-height:640px)': this.setIsFlat,
      '(min-width:768px) and (max-width:1255px)': this.setIsTabletWidth,
      '(min-width:1256px)': this.setIsDesktopWidth
    };
    Object.entries(config).forEach(([mediaQuery, setter]) => {
      const mql: MediaQueryList = window.matchMedia(mediaQuery);
      setter(mql.matches);
      mql.onchange = (evt) => setter(evt.matches);
    });
  },

  mounted() {
    this.checkLastReload();
  },

  methods: {
    setInitialTimestamp() {
      const timestamp = new Date().getTime();
      localStorage.setItem('last_reload', String(timestamp));
    },

    checkLastReload() {
      const timestamp = new Date().getTime();
      const lastReload = localStorage.getItem('last_reload');
      const latestVersion = localStorage.getItem('latest_version');

      if (parseInt(latestVersion || '0') > timestamp) {
        logDebug('NEW VERSION');
      } else if (parseInt(lastReload || '0') !== timestamp) {
        logDebug('PAGE RELOADED');
        localStorage.setItem('last_reload', String(timestamp));
      }
    },

    ...mapMutations('page', ['setIsFlat', 'setIsMini', 'setIsMobileWidth', 'setIsTabletWidth', 'setIsDesktopWidth', 'setIsSmartphoneWidth'])
  }
});
