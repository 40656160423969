import { RouteConfig } from 'vue-router';
import Settings from '../pages/Settings.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      layout: 'full',
      auth: true
    },
  },

];

export default routes;
