const removeTrailingEmptyLinesFromHTML = (html: string): string => {
  const tmpDiv = document.createElement('div');
  tmpDiv.innerHTML = html;

  let lastChild = tmpDiv.lastElementChild;
  while (lastChild?.textContent?.trim() === '') {
    lastChild.remove();
    lastChild = tmpDiv.lastElementChild;
  }

  return tmpDiv.innerHTML;
};

export default removeTrailingEmptyLinesFromHTML;
