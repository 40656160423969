
import useProfileState from '@/state/profile/profileState';
import Vue from 'vue';

export default Vue.extend({
  name: 'EmptySection',
  setup() {
    const { isSelf } = useProfileState();
    return { isSelf };
  }
});
