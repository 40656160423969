
import ForumService from '@/services/ForumService';
import BCXNewStuffBanner from '@/components/molecules/BCXNewStuffBanner.vue';
import { PostSubscription } from '@/views/subscriptions/models/Subscription';
import {
  computed, defineComponent, onMounted, ref, watchEffect
} from 'vue';
import {
  onBeforeRouteUpdate, useRoute, useRouter
} from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import { useIntervalFn } from '@vueuse/core';
import useIsLeavingDirty from '@/utils/isLeavingDirty';
import BCXConfirm from '@/components/molecules/BCXConfirm.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { ForumMaxChars, MessagePost } from '../models/Forum';
import ThreadReplies from '../components/ThreadReplies.vue';
import ThreadTopic from '../components/ThreadTopic.vue';

export default defineComponent({
  name: 'Thread',
  components: {
    ThreadTopic,
    ThreadReplies,
    'new-messages-banner': BCXNewStuffBanner,
    BCXConfirm,
    BCXMarkdown,
  },
  provide: () => ({
    maxCreateMessageTextLength: ForumMaxChars.TALK_CONTENT
  }),
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const newMessagesAvailable = ref(false);
    const intervalSeconds = ref(60);
    const numberOfReplies = ref(0);
    const numberOfEdits = ref(0);

    const getForumId = computed(() => store.getters['forum/getForumId']);
    const getThreadId = computed(() => store.getters['forum/getThreadId']);
    const getGroupId = computed(() => store.getters['forum/getGroupId']);
    const getThreadRepliesTotal = computed(() => store.getters['forum/getThreadRepliesTotal']);
    const getThreadRepliesSorted = computed(() => store.getters['forum/getThreadRepliesSorted']);
    const getOpenEditMessageId = computed(() => store.getters['forum/getOpenEditMessageId']);
    const getSubscriptionsByObjectId = computed(() => store.getters['subscriptions/getSubscriptionsByObjectId']);
    const thread = computed(() => store.state.forum.thread);
    const messagesSort = computed(() => store.state.forum.messagesSort);
    const threadSubscribed = computed(() => (Object.keys(getSubscriptionsByObjectId.value).includes(getThreadId.value) ?? thread.value.forumThreadMetaData.userSubscribed ?? false));

    const clearThread = () => {
      store.dispatch('forum/clearThread');
    };
    const clearThreadList = () => {
      store.dispatch('forum/clearThreadList');
    };
    const fetchThread = () => store.dispatch('forum/fetchThread', {
      forumId: getForumId.value,
      groupId: getGroupId.value,
      threadId: getThreadId.value,
      sortParam: messagesSort.value.sortParam,
      sortOrder: messagesSort.value.sortOrder
    });
    const sortThreadReplies = (params: any) => store.dispatch('forum/sortThreadReplies', params);
    const postThreadMessage = (message: any) => store.dispatch('forum/postThreadMessage', message);
    const updateThreadMessage = (message: any) => store.dispatch('forum/updateThreadMessage', message);
    const editMessage = (editMessageId: string) => store.dispatch('forum/editMessage', editMessageId);
    const updateThreadTitle = (updateData: any) => store.dispatch('forum/updateThreadTitle', updateData);
    const postSubscription = (postSubscriptionData: any) => store.dispatch('subscriptions/postSubscription', postSubscriptionData);
    const deleteSubscription = (subscriptionId: string) => store.dispatch('subscriptions/deleteSubscription', subscriptionId);
    const fetchSubscriptions = () => store.dispatch('subscriptions/fetchSubscriptions');
    const closeNewMessagesBanner = () => {
      newMessagesAvailable.value = false;
      intervalSeconds.value = Math.round(intervalSeconds.value * 1.25);
    };
    const loadNewMessages = () => {
      fetchThread().then(() => {
        closeNewMessagesBanner();
      });
    };
    const sortReplies = (sortParam: string, sortOrder: 'ASC' | 'DESC') => {
      sortThreadReplies({ sortParam, sortOrder });
    };

    const {
      isDirty, isLeavingDirty, isLeavingMessage, onConfirm
    } = useIsLeavingDirty();
    watchEffect(() => {
      isDirty.value = (getOpenEditMessageId.value.length > 0);
    });

    const postMessage = (message: MessagePost) => {
      postThreadMessage(message).then(() => {
        fetchSubscriptions();
        isDirty.value = false;
      });
      numberOfReplies.value++;
    };
    const updateMessage = (postMessage: MessagePost, messageCount: string) => {
      updateThreadMessage({ postMessage, messageCount });
      numberOfEdits.value++;
    };
    const toggleEditMessage = (editMessageId: string) => {
      if (getOpenEditMessageId.value === editMessageId) {
        editMessage('');
      } else {
        editMessage(editMessageId);
      }
    };
    const updateThreadTopic = (title: string) => {
      const updateData = {
        forumId: getForumId.value,
        groupId: getGroupId.value,
        threadId: getThreadId.value,
        title
      };
      updateThreadTitle(updateData);
    };
    const updatedThreadTopic = () => {
      numberOfEdits.value++;
    };
    const subscribeToThread = () => {
      if (!threadSubscribed.value) {
        const postSubscriptionData: PostSubscription = {
          businessObjectId: getThreadId.value,
          subscriptionType: 'FORUM_TALK'
        };
        postSubscription(postSubscriptionData);
      }
    };
    const unsubscribeFromThread = () => {
      if (Object.keys(getSubscriptionsByObjectId.value).length > 0 && threadSubscribed) {
        if (getSubscriptionsByObjectId.value[getThreadId.value]) {
          const { subscriptionId } = getSubscriptionsByObjectId.value[getThreadId.value];
          if (subscriptionId) {
            deleteSubscription(subscriptionId);
          }
        }
      }
    };
    const endOfPageReached = () => {
      // placeholder if needed;
    };

    const updateThreadMeta = (compare = false) => {
      ForumService.getThreadMeta(getForumId.value, getGroupId.value, getThreadId.value).then((threadMeta) => {
        if (compare) {
          const sumOfActionsMeta = (threadMeta.numberOfReplies ?? 0) + (threadMeta.numberOfEdits ?? 0);
          if (sumOfActionsMeta > (numberOfReplies.value + numberOfEdits.value)) {
            newMessagesAvailable.value = true;
          }
        }
        numberOfReplies.value = threadMeta.numberOfReplies;
        numberOfEdits.value = threadMeta.numberOfEdits;
      });
    };

    useIntervalFn(() => {
      updateThreadMeta(true);
    }, intervalSeconds.value * 1000);

    onMounted(() => {
      const { forumId, groupId, threadId } = route.params;
      store.dispatch('forum/fetchThread', {
        forumId, groupId, threadId, sortParam: 'TIMESTAMP', sortOrder: 'DESC',
      }).then(() => {
        updateThreadMeta();
      });
    });

    onBeforeRouteUpdate((to, _from, next) => {
      clearThread();
      const { forumId, groupId, threadId } = to.params;
      store.dispatch('forum/fetchThread', {
        forumId, groupId, threadId, sortParam: 'TIMESTAMP', sortOrder: 'DESC',
      }).then(() => {
        updateThreadMeta();
        next();
      });
    });

    return {
      t,
      newMessagesAvailable,
      intervalSeconds,
      numberOfReplies,
      numberOfEdits,
      getForumId,
      getThreadId,
      getGroupId,
      getThreadRepliesTotal,
      getThreadRepliesSorted,
      getOpenEditMessageId,
      getSubscriptionsByObjectId,
      thread,
      messagesSort,
      threadSubscribed,
      clearThread,
      clearThreadList,
      fetchThread,
      sortThreadReplies,
      postThreadMessage,
      updateThreadMessage,
      editMessage,
      updateThreadTitle,
      postSubscription,
      deleteSubscription,
      fetchSubscriptions,
      closeNewMessagesBanner,
      loadNewMessages,
      sortReplies,
      postMessage,
      updateMessage,
      toggleEditMessage,
      updateThreadTopic,
      updatedThreadTopic,
      subscribeToThread,
      unsubscribeFromThread,
      endOfPageReached,
      updateThreadMeta,
      isDirty,
      isLeavingDirty,
      isLeavingMessage,
      onConfirm
    };
  }
});
