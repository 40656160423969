
import { Editor } from '@toast-ui/vue-editor';
import {
  computed, defineComponent, onMounted, ref, toRefs, watch
} from 'vue';
import useToastUiEditorTools from '@/mixins/useToastUiEditorTools';
import { useVModel } from '@vueuse/core';
import useI18n from '@/mixins/useI18n';
import BCXIcon from '@/components/molecules/BCXIcon.vue';

export default defineComponent({
  components: { BCXIcon, Editor },
  props: {
    text: {
      type: String,
      default: ''
    },
    maxChars: {
      type: Number,
      default: 1000
    },
    maxCharsSource: {
      type: Number,
      default: 10000
    },
    charsLeftWarningThreshold: {
      type: Number,
      default: 50
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { maxChars, maxCharsSource, charsLeftWarningThreshold } = toRefs(props);
    const text = useVModel(props, 'text');
    const editor = ref<Editor>();
    const isEnlarged = ref(false);
    const tmpDiv = document.createElement('div');

    const { createButton } = useToastUiEditorTools(editor);

    const addImageBlobHook = (blob: any, callback: any) => {
      callback('', '');
    };

    const extendAutoLinks = () => null;

    watch(
      isEnlarged,
      (is) => {
        if (editor.value) {
          editor.value.invoke('setHeight', is ? '600px' : '300px');
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      if (editor.value) {
        editor.value.invoke('on', 'change', () => {
          if (editor.value) {
            text.value = editor.value.invoke('getHTML');
          }
        });
      }
    });

    const getPlainTextLength = (str: string) => {
      tmpDiv.innerHTML = str;
      return tmpDiv.innerText.length;
    };

    const charactersLeft = computed(() => maxChars.value - getPlainTextLength(text.value));

    const isPlaintextLengthOk = computed(() => charactersLeft.value >= 0);
    const isHTMLLengthOk = computed(() => text.value.length < maxCharsSource.value);
    const isPlaintextLengthCritical = computed(() => charactersLeft.value < charsLeftWarningThreshold.value);
    const canSend = computed(() => isHTMLLengthOk.value && isPlaintextLengthOk.value);

    const textLengthInfo = computed(() => {
      if (!isHTMLLengthOk.value) return t('common.tooMuchFormatting');
      const x = Math.abs(charactersLeft.value);
      if (charactersLeft.value < 0) return t('common.textTooLong', { x });
      return t('common.charactersLeft', { x });
    });

    const isTextLengthCritical = computed(() => isPlaintextLengthCritical.value || !isHTMLLengthOk.value);

    watch(canSend, (canSend) => {
      emit('update:can-send', canSend);
    }, { immediate: true });

    return {
      editor,
      isTextLengthCritical,
      textLengthInfo,
      isEnlarged,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
        autofocus: false,
        extendAutoLinks,
        hooks: {
          addImageBlobHook
        },
        toolbarItems: [
          [
            'heading',
            'bold',
            'italic',
            'hr',
            'quote',
            'ul',
            'ol',
            'indent',
            'outdent',
            'link',
            {
              el: createButton('undo'),
              command: 'undo',
              tooltip: 'Undo'
            },
            {
              el: createButton('redo'),
              command: 'redo',
              tooltip: 'Redo'
            }
          ]
        ]
      }
    };
  }
});
