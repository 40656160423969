// sends a post to the backend, signaling that the current user is a guest in the pub

import _axios from '@/plugins/axios';
import { useIntervalFn } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/lobby';
const PING_INTERVAL = 60000 * 2;

const usePingVirtualPubLobby = () => {
  const { execute } = useAxios(ENDPOINT, { method: 'POST' }, _axios, { immediate: false });

  useIntervalFn(execute, PING_INTERVAL, {
    immediateCallback: true
  });
};

export default usePingVirtualPubLobby;
