
import { computed, defineComponent, toRefs } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';
import { CreateRoomErrorResponse } from '@/__generated__/types';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  props: {
    error: {
      type: Object as () => CreateRoomErrorResponse,
      required: true
    }
  },
  setup(props) {
    const { error } = toRefs(props);
    const { t } = useI18n();
    const router = useRouter();

    const errorMessage = computed(() => {
      if (error.value.code === 'BC-VC-011') {
        return t('video_chat.join_room_errors.room-is-full.text').toString();
      }
      return t('video_chat.join_room_errors.default.text').toString();
    });

    const buttonText = computed(() => {
      if (error.value.code === 'BC-VC-011') {
        return t('video_chat.join_room_errors.room-is-full.button').toString();
      }
      return t('video_chat.join_room_errors.default.button').toString();
    });

    const buttonAction = () => {
      router.push({ name: 'virtual-pub' });
    };
    return {
      errorMessage,
      buttonText,
      buttonAction,
    };
  }
});
