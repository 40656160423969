
import { ExternalVideoBarChatMatchingResult, RequestVideoPeerFeedback, ResponseVideoPeerFeedback } from '@/__generated__/types';
import SmileyButtons from '@/components/molecules/SmileyButtons.vue';
import { SmileyTypes } from '@/components/svg-components/Smiley.vue';
import MarvinSad from '@/components/svg/marvin/marvin_sad.svg?inline';
import useI18n from '@/mixins/useI18n';
import _axios from '@/plugins/axios';
import useVirtualPubRouter from '@/views/virtual-pub/composable/useVirtualPubRouter';
import useVideoChat from '@/views/virtual-pub/composable/video-chat/useVideoChat';
import {
  computed, defineComponent, PropType, ref
} from 'vue';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useStore } from 'vue2-helpers/vuex';

const ENDPOINT = '/v1/community/videochat/bar/feedback';

export default defineComponent({
  components: {
    SmileyButtons,
    MarvinSad
  },
  props: {
    videoSessionId: {
      type: String,
      required: true
    },
    participantLeftEarly: {
      type: Boolean,
      default: () => false
    },
    room: {
      type: Object as PropType<ExternalVideoBarChatMatchingResult>,
      default: () => ({})
    }
  },
  emits: ['rematch'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const smileyFeedback = ref<SmileyTypes | ''>('');
    const store = useStore();
    const { execute } = useAxios<ResponseVideoPeerFeedback>(ENDPOINT, { method: 'POST' }, _axios, { immediate: false });
    const { push: routeToVirtualPub } = useVirtualPubRouter();

    const feedback = computed<RequestVideoPeerFeedback['feedbackStatus']>(() => {
      switch (smileyFeedback.value) {
        case SmileyTypes.Bad:
          return 'NEVER_AGAIN';
        case SmileyTypes.Neutral:
          return 'ACCEPTABLE';
        case SmileyTypes.Good:
          return 'PERFECT';
        default:
          return 'ACCEPTABLE';
      }
    });

    const translationKeyFeedback = computed(() => {
      switch (feedback.value) {
        case 'PERFECT':
          return 'positiv';
        case 'ACCEPTABLE':
          return 'neutral';
        case 'NEVER_AGAIN':
          return 'negativ';
        default:
          return '';
      }
    });

    const participantsFirstname = computed(() => (props.room?.participantA?.userId === store.getters.user.userId ? props.room?.participantB?.firstname : props.room?.participantA?.firstname));

    const { localAudioTrack, localVideoTrack } = useVideoChat();

    const submitFeedback = async (connectToNext: boolean) => {
      const data: RequestVideoPeerFeedback = {
        videoSessionId: props.videoSessionId,
        feedbackStatus: feedback.value,
        currentUserId: props.room?.participantA?.userId === store.getters.user.userId ? props.room?.participantA?.userId : props.room?.participantB?.userId,
        participant: props.room?.participantA?.userId === store.getters.user.userId ? props.room?.participantB?.userId : props.room?.participantA?.userId,
        connectToNextUser: connectToNext
      };
      await execute({ data });

      if (connectToNext) {
        emit('rematch');
      } else {
        localAudioTrack.value?.stop();
        localVideoTrack.value?.stop();
        await routeToVirtualPub();
      }
    };

    const handleCancelClick = () => submitFeedback(false);

    const handleSubmitClick = () => submitFeedback(true);

    return {
      t,
      handleCancelClick,
      handleSubmitClick,
      feedback,
      participantsFirstname,
      smileyFeedback,
      translationKeyFeedback
    };
  }
});
