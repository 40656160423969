
import { SETTINGS_RESEND_INTERVAL } from '@/models/ResendTimout';
import PasswordResetService from '@/services/PasswordResetService';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import { autoEncodeUserId } from '@/utils/userIdEncoding';
import {
  defineComponent, reactive, ref
} from 'vue';
import { useTimeout } from '@vueuse/core';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import useBCValidators from '@/mixins/useBCValidators';
import useVuelidate from '@vuelidate/core';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import { TranslatedErrorCode } from '@/models/BackendError';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import { AxiosResponse } from 'axios';

export default defineComponent({

  name: 'PasswordResetSetNewPassword',
  components: {
    BackendErrors,
    ButtonBlock,
    LRPopup,
    BCXValidatedInput,
    BCXMarkdown
  },

  setup() {
    const fields = reactive({
      smsCode: '',
      newPassword: ''
    });
    const codeEntered = ref(false);
    const code = ref('');
    const userId = ref('');
    const { isPending: isResending, start: startResendTimer } = useTimeout(SETTINGS_RESEND_INTERVAL, { controls: true, immediate: false });
    const showConfirm = ref(false);
    const backendErrors = ref<(TranslatedErrorCode | string)[] >([]);

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const { passwordValidation, required } = useBCValidators();

    const rules = {
      smsCode: { required },
      newPassword: passwordValidation
    };

    const v = useVuelidate(rules, fields);

    const checkForBackendErrors = (response: AxiosResponse) => {
      backendErrors.value = [];
      const backendErrorObject = new TranslatedBackendErrors(response);
      backendErrors.value = backendErrorObject.allErrors;
      return backendErrorObject.hasErrors();
    };

    const verifyCode = async () => {
      if (route) {
        code.value = route.query?.code as string;
        fields.smsCode = '';
        userId.value = autoEncodeUserId(route.query.uid as string);
        const response = await PasswordResetService.verifyCode(code.value, userId.value);
        checkForBackendErrors(response);
      }
    };

    const submit = async () => {
      if (await v.value.newPassword.$validate()) {
        const response = await PasswordResetService.setPassword(fields.smsCode, userId.value, fields.newPassword);
        if (!checkForBackendErrors(response)) {
          store.commit('errorMessage', { errorMessage: t('error_messages.password_reset') });
          await router.push({ name: 'login' });
        }
      }
    };

    const resendSmsCode = async () => {
      if (isResending.value) return;
      startResendTimer();
      const response = await PasswordResetService.resendSmsCode(code.value, userId.value);
      checkForBackendErrors(response);
    };

    const onConfirmed = async (isConfirmed:boolean) => {
      if (isConfirmed) await resendSmsCode();
    };

    const resend = () => {
      showConfirm.value = true;
    };

    const enterCode = async () => {
      if (await v.value.smsCode.$validate()) {
        codeEntered.value = true;
      }
    };

    verifyCode();

    return {
      isResending,
      fields,
      codeEntered,
      userId,
      showConfirm,
      v,
      enterCode,
      submit,
      resend,
      onConfirmed,
      backendErrors,
    };
  },

});
