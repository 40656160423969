
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import MarvinDepressed from '@/components/svg/marvin/marvin_depressed.svg?inline';
import MarvinSad from '@/components/svg/marvin/marvin_sad.svg?inline';
import useI18n from '@/mixins/useI18n';
import { TranslatedErrorCode } from '@/models/BackendError';
import { SettingsVerificationType } from '@/models/Settings';
import { deleteAccount as deleteAccountBE } from '@/services/Settings/Api';
import useSettings from '@/state/settingsState';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import SettingsPopup from '@/views/settings/components/utils/SettingsPopup.vue';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DeleteAccountPopup',
  components: {
    BackendErrors,
    ActionButtons,
    BCXValidatedInput,
    BCXMarkdown,
    SettingsPopup,
    MarvinSad,
    MarvinDepressed
  },
  setup(props, { emit }) {
    const backendErrors = ref<TranslatedErrorCode[] | string[]>([]);
    const confirm = ref('');
    const { t } = useI18n();
    const { pendingVerification, setResendFunction } = useSettings();

    const isConfirmed = computed(() => confirm.value === t('settings.legal.delete_account.confirm').toString());

    const deleteAccount = async () => {
      const response = await deleteAccountBE();
      const backendErrorObject = new TranslatedBackendErrors(response);
      backendErrors.value = backendErrorObject.allErrors;
      if (!backendErrors.value.length) {
        emit('close');
        pendingVerification.value = SettingsVerificationType.DeleteAccount;
      }
      setResendFunction(deleteAccount);
    };

    return {
      confirm,
      isConfirmed,
      deleteAccount,
      backendErrors
    };
  }
});
