
import { RawLocation } from 'vue-router';

export default {
  name: 'BCXParticipateLinks',
  props: {
    linkList: { type: Array, default: () => [] },
  },
  methods: {
    getThreadLink(link: any): RawLocation {
      const { forumId, groupId, topicId } = link;
      return { name: 'thread', params: { forumId, groupId, threadId: topicId } };
    },
  }
};
