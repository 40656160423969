
import { defineComponent, ref, watch } from 'vue';
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import IndustriesEditor from '@/views/profile/components/IndustriesEditor.vue';
import { Industry } from '@/models/Tags';
import IndustryTags from '@/views/profile/components/IndustryTags.vue';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import ProfileRichTextEditor from '@/views/profile/components/ProfileRichTextEditor.vue';
import { ProfileMaxChars } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import { updateCompanyInformation } from '@/services/Profile/Api';
import stripTags from '@/utils/stripTags';

export default defineComponent({
  name: 'ProfileAboutusEditor',
  components: {
    ProfileRichTextEditor,
    BCXInfoSection,
    IndustryTags,
    IndustriesEditor,
    ProfileEditActionButtons
  },
  setup() {
    const { profile } = useProfileState();
    const employeeCount = ref('');
    const industries = ref<Industry[]>([]);
    const aboutUs = ref('');
    const canSend = ref(false);

    watch(profile, (profile) => {
      const aboutUsHTML = profile?.company?.aboutUs ?? '';
      const aboutUsText = stripTags(aboutUsHTML).trim();
      employeeCount.value = `${profile?.company?.employeeCount ?? ''}`;
      industries.value = profile?.company?.industries ?? [];
      aboutUs.value = aboutUsText ? aboutUsHTML : '';
    }, {
      immediate: true
    });

    const save = async () => {
      await updateCompanyInformation({
        aboutUs: aboutUs.value,
        industries: industries.value,
        employeeCount: +employeeCount.value
      });
    };

    return {
      aboutUs,
      employeeCount,
      industries,
      ProfileMaxChars,
      canSend,
      save
    };
  }
});
