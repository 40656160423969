import { ResponseVideoChatAvailability } from '@/__generated__/types';
import _axios from '@/plugins/axios';
import { computed } from 'vue';
import { createGlobalState, useIntervalFn } from '@vueuse/core';
import { useAxios } from '@vueuse/integrations/useAxios';
import dayjs, { Dayjs } from 'dayjs';
import { useTimerCountdown } from './useTimerCountdown';

const ENDPOINT = '/v1/community/videochat/availability';
const PING_INTERVAL = 300000;

export interface OpeningDay {
  day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
  from: Dayjs;
  to: Dayjs;
}

export const weekdays: Array<keyof ResponseVideoChatAvailability> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const FAKE_AVAILABILITY = false;
export const FAKE_AVAILABILITY_TIMER = false;
export const fakeTime = '2023-07-13 17:29';

const fakeAvailability:ResponseVideoChatAvailability = {
  friday: true, // 2023-04-14
  fridayDurationMinutes: 86400,
  fridayEndTime: '22:00:00',
  fridayStartTime: '00:00:15',
  monday: true,
  mondayDurationMinutes: 86400,
  mondayEndTime: '19:40:00',
  mondayStartTime: '03:00:00',
  saturday: true,
  saturdayDurationMinutes: 86400,
  saturdayEndTime: '22:00:00',
  saturdayStartTime: '10:50:00',
  sunday: true,
  sundayDurationMinutes: 86400,
  sundayEndTime: '22:00:00',
  sundayStartTime: '02:00:00',
  thursday: true,
  thursdayDurationMinutes: 86400,
  thursdayEndTime: '17:30:00',
  thursdayStartTime: '00:00:00',
  today: {
    currentTimestamp: '2023-11-03T21:20:12.828798152+01:00',
    currentlyAvailable: true,
    durationInMinutes: 86300,
    endTime: '22:00',
    endTimestamp: '2023-11-03T22:00:00+01:00',
    startTime: '00:00'
  },
  tuesday: true,
  tuesdayDurationMinutes: 86400,
  tuesdayEndTime: '10:50:00',
  tuesdayStartTime: '00:00:00',
  wednesday: true,
  wednesdayDurationMinutes: 86400,
  wednesdayEndTime: '22:00:00',
  wednesdayStartTime: '00:00:00'
};

const useBarOpeningHours = createGlobalState(() => {
  const { data: availabilityData, execute } = useAxios<ResponseVideoChatAvailability>(ENDPOINT, { method: 'GET' }, _axios);

  const { pause, resume, isActive: isIntervallActive } = useIntervalFn(async () => {
    await execute();
  }, PING_INTERVAL);

  const reloadAvailabilityData = execute;

  const availability = computed(() => (FAKE_AVAILABILITY ? fakeAvailability : availabilityData.value));

  function createDayFromAvailabilityObject(dayName: Pick<OpeningDay, 'day'>['day'], start: string, end: string) {
    const timeFormat = 'HH:mm:ss';

    return {
      day: dayName,
      from: dayjs(start, timeFormat),
      to: dayjs(end, timeFormat)
    };
  }

  const openingDays = computed<OpeningDay[]>(() => {
    const days: OpeningDay[] = [];

    if (!availability.value) {
      return [];
    }

    if (availability.value.monday) {
      days.push(createDayFromAvailabilityObject('monday', availability.value.mondayStartTime!, availability.value.mondayEndTime!));
    }

    if (availability.value.tuesday) {
      days.push(createDayFromAvailabilityObject('tuesday', availability.value.tuesdayStartTime!, availability.value.tuesdayEndTime!));
    }

    if (availability.value.wednesday) {
      days.push(createDayFromAvailabilityObject('wednesday', availability.value.wednesdayStartTime!, availability.value.wednesdayEndTime!));
    }

    if (availability.value.thursday) {
      days.push(createDayFromAvailabilityObject('thursday', availability.value.thursdayStartTime!, availability.value.thursdayEndTime!));
    }

    if (availability.value.friday) {
      days.push(createDayFromAvailabilityObject('friday', availability.value.fridayStartTime!, availability.value.fridayEndTime!));
    }

    if (availability.value.saturday) {
      days.push(createDayFromAvailabilityObject('saturday', availability.value.saturdayStartTime!, availability.value.saturdayEndTime!));
    }

    if (availability.value.sunday) {
      days.push(createDayFromAvailabilityObject('sunday', availability.value.sundayStartTime!, availability.value.sundayEndTime!));
    }

    return days;
  });

  const {
    formattedTimer, isBarOpen, isBarClosingSoon, isBarOpeningSoon, isLobbyOpen, canJoinRoom
  } = useTimerCountdown({ availability });

  return {
    isBarOpen,
    isBarClosingSoon,
    isBarOpeningSoon,
    isLobbyOpen,
    availability,
    openingDays,
    formattedTimer,
    reloadAvailabilityData,
    pause,
    resume,
    isIntervallActive,
    canJoinRoom
  };
});

export {
  useBarOpeningHours
};
