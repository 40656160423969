var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"panel",staticClass:"c-avatar-upload bcx-all bcx-secondary"},[_c('div',{directives:[{name:"bcx-click-outside",rawName:"v-bcx-click-outside",value:({handler:_vm.closePanel, exclude:['.c-navigation','.c-navigation-mobile']}),expression:"{handler:closePanel, exclude:['.c-navigation','.c-navigation-mobile']}"}],attrs:{"data-body-scroll-lock-ignore":""}},[_c('div',{staticClass:"c-head"},[_c('div',{staticClass:"c-avatar"},[_c('BytecookieLogoOnly')],1),_c('h2',{staticClass:"c-headline"}),_vm._v(" "+_vm._s(_vm.t('profile.avatarUpload_USERTYPE.title'))+" ")]),_c('div',{staticClass:"image-loader",class:{'centered': !_vm.isSaveReady && !_vm.isSaving}},[_c('button',{staticClass:"button-load-image bcx-button",on:{"click":function($event){return _vm.file.click()}}},[_vm._v(" "+_vm._s(_vm.t('profile.avatarUpload_USERTYPE.loadImage'))+" "),_c('input',{ref:"file",attrs:{"id":"fileInput","type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadImage($event)}}})])]),_c('Cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.image.src,"stencil-component":"circle-stencil","stencil-props":{
        aspectRatio: 1
      },"debounce":false,"canvas":{
        minHeight: 120,
        minWidth: 120,
        maxHeight: 220,
        maxWidth: 220,
      }},on:{"change":_vm.onChange}}),(_vm.isSaveReady || _vm.isSaving)?_c('div',{staticClass:"avatar-preview"},[_c('div',{staticClass:"avatar-preview-title"},[_vm._v(" "+_vm._s(_vm.t('profile.avatarUpload_USERTYPE.preview'))+" ")]),_c('div',{staticClass:"avatar-preview-images"},[_vm._l(([50, 40, 32]),function(size,index){return [_c('BCXAvatar',{key:size,staticClass:"bcx-unclickable",attrs:{"show-cookies":"","show-online-indicator":"","amount-of-cookies":2 - index,"user-is-online":index < 2,"size":size}},[_c('Preview',{staticClass:"avatar-preview-image",attrs:{"image":_vm.result.image,"width":size,"height":size,"coordinates":_vm.result.coordinates}})],1)]})],2)]):_vm._e(),(_vm.isSaveReady || _vm.isSaving)?_c('div',{staticClass:"action-buttons"},[_c('button',{staticClass:"bcx-button bcx-button--secondary",attrs:{"disabled":_vm.isSaving},on:{"click":_vm.closePanel}},[_vm._v(" "+_vm._s(_vm.t('buttons.cancel'))+" ")]),_c('button',{staticClass:"bcx-button",attrs:{"disabled":!_vm.isSaveReady},on:{"click":function($event){return _vm.saveImage()}}},[_vm._v(" "+_vm._s(_vm.t('buttons.save'))+" ")])]):_vm._e(),_c('div',{staticClass:"c-close",on:{"click":_vm.closePanel}},[_c('x')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }