import { Ability } from '@casl/ability';
import { forEach } from 'lodash';
import { Ability as AbilityObject } from '@/models/Ability';
import Permissions from './permissions';

const userRoles = JSON.parse(localStorage.getItem('user_roles') || '[]');
const userPermissions = JSON.parse(localStorage.getItem('user_permissions') || '[]');

function createPermissions(roles: string[], permissions: string[]) {
  const allPermissions = [] as AbilityObject[];

  if (roles) {
    forEach(roles, (role) => {
      if (Permissions.rolePermissions[role]) {
        allPermissions.push(...Permissions.rolePermissions[role]);
      }
    });
  }

  if (permissions) {
    forEach(permissions, (permission) => {
      if (Permissions.additionalPermissions[permission]) {
        allPermissions.push(...Permissions.additionalPermissions[permission]);
      }
    });
  }

  return allPermissions;
}

export default new Ability([
  ...(createPermissions(userRoles, userPermissions)),
]);
