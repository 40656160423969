
import {
  computed,
  ComputedRef,
  defineComponent, onMounted, ref, Ref, toRefs, watch
} from 'vue';
import Chevron from '@/components/svg-components/Chevron.vue';
import useI18n from '@/mixins/useI18n';
import { onClickOutside } from '@vueuse/core';

export interface BCXExtendedDropdownItem {
  label: string;
  value: any;
  description?: string;
}

type tChevronOrientation = 'up' | 'down' | 'left' | 'right';

export default defineComponent({
  name: 'BCXExtendedDropdown',
  components: {
    Chevron
  },
  props: {
    placeholder: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array as () => BCXExtendedDropdownItem[],
      default: undefined
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String as ()=>string | number,
      default: undefined
    }
  },
  emits: ['update:modelValue', 'click', 'input'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { value, items } = toRefs(props);

    const element = ref(null);
    const showDropdown: Ref<boolean> = ref(false);

    function setShowDropdown(value: boolean) {
      showDropdown.value = value;
    }
    onClickOutside(element, (_event) => { setShowDropdown(false); });

    const chevronOrientation: ComputedRef<tChevronOrientation> = computed(() => {
      if (showDropdown.value) {
        return 'up';
      }
      return 'down';
    });

    const selectedValue: Ref<BCXExtendedDropdownItem|undefined> = ref(undefined);

    function selectValue(item: BCXExtendedDropdownItem) {
      selectedValue.value = item;
      setShowDropdown(false);
    }

    watch(selectedValue, (item: BCXExtendedDropdownItem | undefined) => {
      if (item) {
        emit('input', item.value);
      } else {
        emit('input', item);
      }
    }, { deep: true });

    const showPlaceholder: ComputedRef<boolean> = computed(() => {
      if (selectedValue.value === undefined) {
        return true;
      }
      return false;
    });

    onMounted(() => {
      if (value.value !== undefined) {
        items.value.forEach((item) => {
          if (item.value === value.value) {
            selectedValue.value = item;
          }
        });
      }
    });

    return {
      element,
      showDropdown,
      chevronOrientation,
      selectedValue,
      showPlaceholder,
      selectValue,
      setShowDropdown,
      t,
    };
  },
});
