import axios from '@/plugins/axios';
import { AvailabilityInterface, ProjectAvailabilityInterface } from '@/models/Availability';
import { logDebug, logError } from '@/utils/logger';

export default {
  async fetchAvailability(): Promise<AvailabilityInterface> {
    const availability: AvailabilityInterface = await axios.get('/v1/users/user/availability')
      .then((response) => response.data)
      .catch((reason) => {
        logError(reason);
      });
    if (availability) {
      window.localStorage.setItem('availability', JSON.stringify(availability));
    }
    return availability;
  },

  async getAvailabilityForRecommendedUser(recommendedUserId: string): Promise<ProjectAvailabilityInterface> {
    return axios.post('/v1/recommendations/project/availability', {
      recommendedUserId
    }).then((response) => response.data).catch((reason) => {
      logDebug(reason);
    });
  },

  async getAvailabiltyforUser(userId: string): Promise<AvailabilityInterface> {
    const availability: AvailabilityInterface = await axios.get(`/v1/users/${userId}/availability`)
      .then((response) => response.data)
      .catch((reason) => {
        logError(reason);
        return null;
      });
    if (availability) {
      window.localStorage.setItem('availability', JSON.stringify(availability));
    }
    return availability;
  },

  async saveAvailability(
    available: boolean,
    availableNow: boolean,
    availableFulltime: boolean,
    availableFrom?: string,
    availableTo?: string,
    availableHoursPerWeek?: number,
  ): Promise<AvailabilityInterface | false> {
    const availability: AvailabilityInterface = {
      available,
      availableNow,
      availableFulltime,
    };

    if (available === true && availableNow === false) {
      availability.availableFrom = availableFrom || '';
      availability.availableTo = availableTo || '';
    } else {
      delete availability.availableFrom;
      delete availability.availableTo;
    }

    if (available === true && availableFulltime === false && availableHoursPerWeek) {
      availability.availableHoursPerWeek = availableHoursPerWeek;
    } else {
      delete availability.availableHoursPerWeek;
    }

    if ((availability.available === false)
      || (availability.available === true && availability.availableNow === true && availability.availableFulltime === true)
      || (availability.available === true && availability.availableNow === false && availability.availableFrom !== ''
          && availability.availableFulltime === true)
      || (availability.available === true && availability.availableNow === true && availability.availableFulltime === false
          && availability.availableHoursPerWeek && availability.availableHoursPerWeek > 0)
      || (availability.available === true && availability.availableNow === false && availability.availableFrom !== ''
          && availability.availableFulltime === false && availability.availableHoursPerWeek && availability.availableHoursPerWeek > 0)) {
      const availabilityPromise = await axios.put('/v1/users/user/availability', availability)
        .then(() => {
          window.localStorage.setItem('availability', JSON.stringify(availability));
          return availability;
        });
      return availabilityPromise;
    }
    return false;
  },

  restoreAvailability(): AvailabilityInterface | false {
    try {
      if (window?.localStorage?.getItem('availability')) {
        const availability = JSON.parse(window.localStorage.getItem('availability') || '{}');
        return availability;
      }
      return false;
    } catch (err) {
      logError(err);
      return false;
    }
  },
};
