
import EditIcon from '@/components/molecules/EditIcon.vue';
import useI18n from '@/mixins/useI18n';
import useListTools from '@/mixins/useListTools';
import { CertificateListItem, EducationListItem, ProfileMaxChars } from '@/models/Profile';
import { updateCertificates, updateEducations } from '@/services/Profile/Api';
import useProfileState from '@/state/profile/profileState';
import AddIcon from '@/views/profile/components/AddIcon.vue';
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import {
  computed, defineComponent, onMounted, PropType, ref, toRefs
} from 'vue';
import { cloneDeep } from 'lodash';

export default defineComponent({
  name: 'ProfileCertificatesEditor',
  components: {
    AddIcon,
    EditIcon,
    ProfileEditActionButtons,
  },
  props: {
    type: {
      type: String as PropType<'certificates' | 'educations'>,
      default: 'certificates',
    },
  },
  setup(props) {
    const fullYear = new Date().getFullYear();
    const { type } = toRefs(props);
    const { t } = useI18n();
    const { profile } = useProfileState();
    const labelToAdd = ref('');
    const yearToAdd = ref(`${fullYear}`);
    const originToAdd = ref('');
    const linkToAdd = ref('');

    const certificatesEducations = ref<CertificateListItem[] | EducationListItem[]>([]);
    const {
      addItem: addToList,
      deleteItem,
      moveUp,
    } = useListTools<CertificateListItem | EducationListItem>(certificatesEducations);

    const getOriginalList = () => (type.value === 'certificates'
      ? cloneDeep(profile.value?.certificates?.certificatesList) as CertificateListItem[]
      : cloneDeep(profile.value?.educations?.educationsList) as EducationListItem[]);

    onMounted(() => {
      certificatesEducations.value = getOriginalList();
    });

    const itemLabel = computed(() => t(`profile.certificatesEducations.itemLabel.${type.value}`));
    const originLabel = computed(() => t(`profile.certificatesEducations.originLabel.${type.value}`));

    const canAdd = computed(() => !!labelToAdd.value && !!originToAdd.value && +yearToAdd.value > 1900 && +yearToAdd.value <= fullYear);

    const addItem = () => {
      addToList({
        issuer: originToAdd.value,
        institute: originToAdd.value,
        label: labelToAdd.value,
        link: linkToAdd.value,
        year: +yearToAdd.value,
      });
      labelToAdd.value = '';
      yearToAdd.value = `${fullYear}`;
      originToAdd.value = '';
      linkToAdd.value = '';
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-nested-ternary
    const stripId = (list:(CertificateListItem[] | EducationListItem[])) => list.map(({ externalId, ...rest }) => ({ ...rest }));

    const save = async () => {
      if (canAdd.value) {
        addItem();
      }
      const strippedAndSorted = stripId(certificatesEducations.value);
      if (type.value === 'certificates') {
        await updateCertificates({
          certificates: {
            certificatesList: strippedAndSorted as CertificateListItem[],
          },
        });
      } else {
        await updateEducations({
          educations: {
            educationsList: strippedAndSorted as EducationListItem[],
          },
        });
      }
    };

    const maxLengthItem = computed(() => (type.value === 'certificates' ? ProfileMaxChars.CERTIFICATE : ProfileMaxChars.DIPLOMA));

    return {
      labelToAdd,
      yearToAdd,
      originToAdd,
      linkToAdd,
      originLabel,
      itemLabel,
      deleteItem,
      moveUp,
      canAdd,
      certificatesEducations,
      save,
      addItem,
      maxLength: {
        issuer: ProfileMaxChars.ISSUER,
        institution: ProfileMaxChars.INSTITUTION,
        link: ProfileMaxChars.LINK,
      },
      maxLengthItem
    };
  },
});
