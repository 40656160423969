
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import useI18n from '@/mixins/useI18n';
import { RouterEntry } from '@/models/Router';
import usePageState from '@/state/pageState';
import BarChat from '@/views/virtual-pub/components/bar-chat/BarChat.vue';
import BarChatHeader from '@/views/virtual-pub/components/bar-chat/BarChatHeader.vue';
import TableChat from '@/views/virtual-pub/components/table-chat/TableChat.vue';
import TableChatList from '@/views/virtual-pub/components/table-chat/TableChatList.vue';
import { useBarOpeningHours } from '@/views/virtual-pub/composable/useBarOpeningHours';
import {
  computed, defineComponent, onBeforeUnmount, onMounted, ref, watch
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import usePingVirtualPubLobby from '../composable/virtual-pub/usePingVirtualPubLobby';
import useRooms from '../composable/rooms/useRooms';

export default defineComponent({
  components: {
    BCXBreadcrumbs,
    BarChatHeader,
    BarChat,
    TableChat,
    TableChatList
  },

  setup() {
    const { t } = useI18n();
    const {
      isBarOpen, reloadAvailabilityData, isIntervallActive, pause, resume
    } = useBarOpeningHours();
    const { addMainClass, removeMainClass } = usePageState();
    const store = useStore();
    usePingVirtualPubLobby();

    const userIsAdmin = computed(() => store.getters.user.roles.includes('ADMIN'));

    const renderCounter = ref(0);

    const breadcrumbItems = computed(
      (): Array<RouterEntry> => [
        {
          text: t('breadcrumb.community') as string
        },
        {
          text: t('breadcrumb.pub') as string
        }
      ]
    );

    watch(
      isBarOpen,
      (isBarOpen) => {
        if (isBarOpen) {
          addMainClass('is-virtual-pub-open');
        } else {
          removeMainClass('is-virtual-pub-open');
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      removeMainClass('is-virtual-pub-open');
      pause();
    });

    onMounted(() => {
      if (!isIntervallActive.value) {
        reloadAvailabilityData();
        resume();
      }
    });

    return {
      isDevelopmentEnv: computed(() => process.env.NODE_ENV !== 'production'),
      breadcrumbItems,
      renderCounter,
      userIsAdmin
    };
  }
});
