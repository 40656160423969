const sanostol = (str:string):string => {
  // const unwrap = (element:Element) => {
  //   console.log('unwrap element...');
  //   const parent = element.parentNode;
  //   if (!parent) return;
  //   while (element.firstChild) parent.insertBefore(element.firstChild, element);
  //   parent.removeChild(element);
  // };

  const changeTag = (element:Element, newTagType:string) => {
    if (!element.parentNode) return;
    const newTag = document.createElement(newTagType);
    newTag.innerHTML = element.innerHTML;
    element.parentNode.replaceChild(newTag, element);
  };

  const tmpDiv = document.createElement('div');
  tmpDiv.innerHTML = str;
  [...tmpDiv.querySelectorAll('pre > code')].forEach((element) => {
    const parent:Element = element.parentNode as Element;
    if (parent) {
      changeTag(parent, 'div');
    }
  });
  // [...tmpDiv.querySelectorAll('pre')].forEach((element) => {
  //   changeTag(element, 'code');
  // });

  return tmpDiv.innerHTML;
};

export default sanostol;
