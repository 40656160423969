import axios from '@/plugins/axios';
import { AxiosResponse } from 'axios';
import { SaveReporting } from '@/models/Reporting';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';

export default {
  async sendReport(payload: SaveReporting): Promise<any> {
    payload.reasonText = BCXHTMLSanitizer.beforeSave(payload.reasonText);
    return axios.post('/v1/flagging', payload).then((res: AxiosResponse) => res.data);
  },
};
