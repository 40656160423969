import { ExternalUser, User, UserType } from '@/models/User';
import { Project, ProjectListProject } from '@/views/project-forum/models/ProjectForum';

// eslint-disable-next-line import/prefer-default-export
export const getUserType = (user:User): UserType => {
  if (user?.role === 'COMPANY_ROOT' || user?.companyRole === 'ROOT' || user?.roles?.includes('COMPANY_ROOT')) return UserType.COMPANY;
  if (user?.role === 'COMPANY_GUEST' || user?.companyRole === 'GUEST' || user?.roles?.includes('COMPANY_GUEST')) return UserType.GUEST;
  if (user?.role === 'COMPANY_EMPLOYEE' || user?.companyRole === 'EMPLOYEE' || user?.roles?.includes('COMPANY_EMPLOYEE')) return UserType.EMPLOYEE;
  return UserType.FREELANCER;
};

export const userNames = (user:User) => ({
  firstname: user?.firstname ?? '',
  lastname: user?.lastname ?? '',
  fullname: `${user?.firstname ?? ''} ${user?.lastname ?? ''}`,
  companyname: user?.company?.name ?? '',
});

export const getUserTypeTranslationKey = (key:string, userType: UserType) => key.replaceAll('_USERTYPE', `_${userType}`);

export const getDisplayName = (user: User | ExternalUser | undefined) => {
  if (user?.company) {
    return user?.company?.name || '';
  }
  return `${user?.firstname} ${user?.lastname}`;
};

export const getNameSortString = (user: User | ExternalUser) => user?.company || user?.lastname;

export const getProjectCreatorName = (project: Project | ProjectListProject) => {
  const creator = project?.creator;

  return getDisplayName(creator);
};
