
import { defineComponent, PropType } from 'vue';
import CookieSmall from '@/components/svg/cookie-small.svg?inline';
import CookieMedium from '@/components/svg/cookie-medium.svg?inline';
import CookieLarge from '@/components/svg/cookie-large.svg?inline';

export default defineComponent({
  name: 'Cookie',
  components: {
    CookieSmall, CookieMedium, CookieLarge
  },
  props: {
    size: {
      type: String as PropType<'small'|'medium'|'large'>,
      default: 'normal'
    }
  }
});
