
import useResponsiveness from '@/mixins/useResponsiveness';
import { defineComponent } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import VirtualPubSection from './VirtualPubSection.vue';

export default defineComponent({
  name: 'VirtualPubSectionPanel',
  components: {
    VirtualPubSection
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    contentTitle: {
      type: String,
      default: () => null
    },
    localStorageKey: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { isDesktopWidth } = useResponsiveness();
    const isOpen = useLocalStorage(`virtual-pub-panel-${props.localStorageKey}`, true);

    return {
      isDesktopWidth,
      isOpen
    };
  }
});
