
import Vue, {
  computed, nextTick, ref, watch
} from 'vue';
import useI18n from '@/mixins/useI18n';
import { Industry } from '@/models/Tags';
import useProfileState from '@/state/profile/profileState';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { asyncComputed } from '@vueuse/core';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import { updateProjectPreferences } from '@/services/Profile/Api';
import { PROFILE_MAX_INDUSTRIES, ProfileMaxChars, ProjectPreferencesRequest } from '@/models/Profile';
import { getCountryCitiesByPostCode } from '@/services/Geo/Api';
import { getValueByText, objectToItemList } from '@/utils/selectItemTools';
import { SelectItem } from '@/models/SelectItem';
import IndustriesTags from '@/views/profile/components/IndustryTags.vue';
import IndustriesEditor from '@/views/profile/components/IndustriesEditor.vue';

export default Vue.extend({
  name: 'ProfilePreferencesEditor',
  components: {
    IndustriesEditor,
    IndustriesTags,
    BCXSearchSelect,
    BCXInfoSection,
    ProfileEditActionButtons,
  },
  setup() {
    const {
      countries: countryList,
      profile,
    } = useProfileState();
    const { t } = useI18n();

    const countries = computed<SelectItem[]>(() => [
      {
        selectedText: '-',
        text: '-',
        value: ''
      },
      ...countryList.value]);

    const remoteWorkOptions = objectToItemList(t('profile.projectPreferences.remoteWorkOptions') as any);
    const microProjectOptions = objectToItemList(t('profile.projectPreferences.microProjectOptions') as any);
    const companySizeOptions = objectToItemList(t('profile.projectPreferences.companySizeOptions') as any);

    const countryIsoCode = ref<string>('');
    const country = ref<string>('');
    const postalCode = ref<string>('');
    const postalCodePlace = ref<string>(''); // for selection
    const placeName = ref<string>('');
    const radius = ref<string>('');
    const remoteWork = ref<string>('');
    const microProjects = ref<string>('');
    const companySize = ref<string>('');
    const industries = ref<Industry[]>([]);
    const postalCodeSearch = ref<string>('');

    let protectLocation = true;

    const postalCodes = asyncComputed(async () => {
      if (countryIsoCode.value && postalCodeSearch.value.length > 3) {
        const result = await getCountryCitiesByPostCode(countryIsoCode.value, postalCodeSearch.value);
        return result.map(({
          placeName,
          postalCode,
        }) => ({
          value: `${postalCode}|${placeName}`,
          text: `${postalCode}, ${placeName}`,
          selectedText: postalCode,
        }));
      }
      return [];
    }, [], { lazy: true });

    watch(postalCodePlace, (postalCodePlace) => {
      const [code, name] = postalCodePlace.split('|');
      postalCode.value = code;
      placeName.value = name;
    });

    watch(profile, (profile) => {
      const projectPreferences = profile?.projectPreferences;
      watch(countries, (countries) => {
        if (countries.length > 1) {
          protectLocation = true;
          countryIsoCode.value = projectPreferences?.country
            ? getValueByText(countries, projectPreferences.country) ?? ''
            : '';
          nextTick(() => { protectLocation = false; });
        }
      }, {
        immediate: true,
      });
      country.value = projectPreferences?.country ?? '';
      postalCode.value = projectPreferences?.postalCode ?? '';
      radius.value = (projectPreferences?.radiusInKm ?? '') as string;
      remoteWork.value = projectPreferences?.remoteWork ?? '';
      const mp = projectPreferences?.microProjects ?? '';
      if (mp === '') {
        microProjects.value = '';
      } else {
        microProjects.value = mp ? '1' : '0';
      }
      companySize.value = projectPreferences?.companySize ?? '';
      industries.value = projectPreferences?.industrieList ?? [];
      placeName.value = projectPreferences?.placeName ?? '';
      postalCodePlace.value = projectPreferences?.postalCode ? `${projectPreferences?.postalCode}|${projectPreferences?.placeName}` : '';
    }, { immediate: true });

    const save = async () => {
      const updateData: ProjectPreferencesRequest = {};
      if (countryIsoCode.value) updateData.countryIsoCode = countryIsoCode.value;
      if (postalCode.value) {
        updateData.postalCode = postalCode.value;
        updateData.placeName = placeName.value;
      }
      if (companySize.value) updateData.companySize = companySize.value;
      if (industries.value.length) updateData.industryTagList = industries.value;
      if (microProjects.value) updateData.microProjects = !!+microProjects.value;
      if (radius.value) updateData.geoRadius = +radius.value;
      if (remoteWork.value) updateData.remoteWork = remoteWork.value;

      await updateProjectPreferences(updateData);
    };

    const clearLocation = () => {
      if (protectLocation) return;
      postalCode.value = '';
      postalCodePlace.value = '';
      postalCodeSearch.value = '';
      radius.value = '';
    };

    watch(countryIsoCode, clearLocation);
    watch(postalCode, (pc) => {
      if (!pc) radius.value = '';
    });

    return {
      countries,
      country,
      countryIsoCode,
      postalCode,
      postalCodes,
      postalCodeSearch,
      radius,
      remoteWork,
      microProjects,
      companySize,
      placeName,
      postalCodePlace,
      remoteWorkOptions,
      microProjectOptions,
      companySizeOptions,
      industries,
      maxIndustries: PROFILE_MAX_INDUSTRIES,
      save,
      clearLocation,
      maxLength: {
        plz: ProfileMaxChars.PLZ,
        radius: ProfileMaxChars.RADIUS
      }
    };
  },
});
