import { ExternalVideoBarChatMatchingResult } from '@/__generated__/types';
import _axios from '@/plugins/axios';
import { useAxios } from '@vueuse/integrations/useAxios';
import { useIntervalFn } from '@vueuse/core';

const ENDPOINT = '/v1/community/videochat/bar/match';
const MATCH_INTERVAL = 5000;

const useBarChatMatch = () => {
  const barChatMatchAxios = useAxios<ExternalVideoBarChatMatchingResult>(ENDPOINT, { method: 'GET' }, _axios, { immediate: false });

  const { pause, resume } = useIntervalFn(async () => {
    await barChatMatchAxios.execute();
  }, MATCH_INTERVAL, { immediate: false, immediateCallback: true });

  return {
    pause,
    resume,
    ...barChatMatchAxios,
  };
};

export default useBarChatMatch;
