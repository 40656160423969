
import BCXSuggestionBox from '@/components/molecules/BCXSuggestionBox.vue';
import {
  CONTACT_TYPE_CONTACT, CONTACT_TYPE_OTHER, MessengerMaxChars, UserMarked
} from '@/models/Messenger';
import { User } from '@/models/User';
import { getContacts, getUsers } from '@/state/messenger/Network';
import {
  computed, defineComponent, onBeforeUnmount, ref
} from 'vue';
import { asyncComputed, useDebounce, useMemoize } from '@vueuse/core';
import BCXMessengerUserSelectItem from '@/components/messenger/User/BCXMessengerUserSelectItem.vue';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'BCXMessengerUserSelect',
  components: { BCXMessengerUserSelectItem, BCXSuggestionBox },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean
    },
    autofocus: {
      type: Boolean
    }
  },
  setup(_, { emit }) {
    const userName = ref<string>('');
    const userNameDebounced = useDebounce(userName, 250);

    const { user: me } = useUser('self');

    const userContacts = asyncComputed(async () => getContacts(), []);
    const getContactsMemoized = useMemoize(async (userName:string) => getUsers(userName));

    const userList = asyncComputed(async () => (
      await getContactsMemoized(userNameDebounced.value))
      .filter((user) => user.userId !== me.value.userId), [], { lazy: true });

    const userIsInContacts = (userId:string) => userContacts.value.some((user) => user.userId === userId);

    const userListGrouped = computed(() => {
      // console.log('userList:', userList);
      const userListMarked = userList.value.map((user) => ({
        isInContacts: userIsInContacts(user.userId ?? '') ? 1 : 0,
        ...user,
      })) as UserMarked[];
      // console.log('userListMarked:', userListMarked);
      userListMarked.sort((userA, userB) => userB.isInContacts - userA.isInContacts);

      if (userListMarked.length) {
        if (userListMarked[0].isInContacts) {
          userListMarked[0].showGroupLabel = CONTACT_TYPE_CONTACT;
        } else {
          userListMarked[0].showGroupLabel = CONTACT_TYPE_OTHER;
        }
        userListMarked.reduce((prev, cur) => {
          if (prev.isInContacts !== cur.isInContacts) {
            cur.showGroupLabel = CONTACT_TYPE_OTHER;
          }
          return cur;
        });
      }

      return userListMarked;
    });

    onBeforeUnmount(() => {
      getContactsMemoized.clear();
    });

    const selectUser = (user:User | null) => {
      if (!user) return;
      emit('user-selected', user);
    };

    return {
      selectUser,
      userName,
      userListGrouped,
      userNameDebounced,
      maxLength: MessengerMaxChars.USER_SEARCH
    };
  },
});
