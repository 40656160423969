
import Vue from 'vue';
import { mdiChevronDown } from '@mdi/js';

export default Vue.extend({
  props: {
    iconBefore: {
      type: String,
      default: undefined,
    },
    iconAfter: {
      type: String,
      default: mdiChevronDown,
    },
    label: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  watch: {
    isActive(value: boolean): void {
      this.active = value;
    },
    active(value: boolean): void {
      this.$emit('change', { state: 'open' });
      if (value) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
  },
  mounted() {
    this.active = this.isActive;
    if (this.isActive !== undefined) {
      this.$emit((this.isActive ? 'open' : 'close'));
    }
  },
  methods: {
    togglePanelActive() {
      let previousScrollPosition = 0;
      if (!this.active) {
        previousScrollPosition = window.scrollY;
      }
      this.active = !this.active;
      this.$nextTick(() => {
        if (this.active) {
          window.scrollTo(0, previousScrollPosition);
        }
      });
    },
  },

});
