import { computed, Ref } from 'vue';
import { useDebounce } from '@vueuse/core';

const debouncedOnFalse = (target:Ref<any>, ms = 500) => {
  const debouncedRef = useDebounce(target, ms);
  return computed(() => {
    if (target.value) return target.value;
    return target.value || debouncedRef.value;
  });
};

export default debouncedOnFalse;
