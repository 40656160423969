import { AvailabilityInterface } from '@/models/Availability';
import { SkillListItem } from '@/models/Profile';
import { UserType } from '@/models/User';

export type SingleUserFromSearch = {
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  city?: string;
  countryIsoCode?: string;
  country?: string;
  type?: UserType;
  userProfileSummary?: string;
  userProfileSkills?: {
    skillList: SkillListItem[];
  };
  userAvailability: AvailabilityInterface;
};

export enum SearchQueryParam {
  SearchTerm = 'q',
  ExcludeSearchTerm = 'x',
  Attributes = 'f',
  Availability = 'a',
  AvailabilityWithin = 'aw',
  Countries = 'c'
}

export type UserListFromSearch = {
  globalResultSize: number;
  usersList: SingleUserFromSearch[] | null;
};

export enum SearchAttribute {
  Name = 'name',
  Location = 'location',
  Description = 'description',
  SkillsAndRoles = 'skillsandroles',
  Experiences = 'experiences',
  PDFProfile = 'pdfprofile',
}

export enum SearchAvailability {
  Available = 'FULL',
  Partially = 'PARTIAL',
  Unavailable = 'UNAVAILABLE',
}

export enum SearchAvailabilityStart {
  Immediately = 'IMMEDIATELY',
  Later = 'LATER',
}

export type SearchAvailabilityWithin = 'W1' | 'W2' | 'W4' | 'M2' | 'M3' | 'ALL' | 'IMMEDIATELY';

export enum SearchCountry {
  Germany = 'DE',
  Austria = 'AT',
  Switzerland = 'CH',
}

export type UserSearchField = 'NAME' | 'SUMMARY' | 'CITY' | 'COUNTRY' | 'EXPERIENCES' | 'SKILLS' | 'ROLES' | 'EXPERIENCES_DOC';
export type UserSearchMatch = 'EXACT' | 'LIKE' | 'NOT';

export type UserSearchExtendedSearchTerm = {
  term: string;
  match: UserSearchMatch;
}

export type UserSearchExtendedSkillsTerm = {
  skill: string;
  role: string;
  match: UserSearchMatch;
};

export type UserSearchRequest = {
  simple: {
    term: string;
    excludeTerm?: string;
    fields: UserSearchField[];
  };
  extended?: {
    name?: UserSearchExtendedSearchTerm;
    city?: UserSearchExtendedSearchTerm;
    summary?: UserSearchExtendedSearchTerm;
    skills?: UserSearchExtendedSkillsTerm[];
    experiences?: UserSearchExtendedSearchTerm;
  };
  filters?: {
    countryCodes?: SearchCountry[];
    availability: {
      from?: string;
      to?: string;
      states: SearchAvailability[];
    };
  };
}

export type UserSearchSkill = {
  roleId: string;
  roleLabel: string;
  skillId: string;
  skillLabel: string;
}

export type UserSearchEntry = {
  userLastUpdate: string;
  firstname: string;
  lastname: string;
  fullname: string;
  city: string;
  countryIsoCode: string;
  country: string;
  summary: string;
  availability: SearchAvailability;
  availableFrom?: string;
  availableTo?: string;
  availableHoursPerWeek?: number;
  reputationPoints: number;
  flagged: boolean;
  trustlevel: number;
  experiences?: string;
  id: string;
  type: UserType;
  skills: UserSearchSkill[];
}

export type UserSearchResponse = {
  globalResultSize: number;
  entries: UserSearchEntry[];
}

export enum SearchCriteriumType {
  Attributes = 'attributes',
  Availability = 'availabilities',
  AvailabilityStart = 'start',
  Countries = 'countries'
}
