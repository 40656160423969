
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import EditIcon from '@/components/molecules/EditIcon.vue';
import useFormattedDateTime, { TimeFormatting } from '@/mixins/useFormattedDateTime';
import { Chat, CHAT_TYPE_DIRECT } from '@/models/Messenger';
import { User } from '@/models/User';
import { getIsChatRead } from '@/state/messenger/traverseHelpers';
import useMessengerState from '@/state/messenger/messengerState';
import toggleArrayItem from '@/utils/toggleArrayItem';
import {
  computed, defineComponent, PropType, Ref, toRefs
} from 'vue';
import { createVuexHelpers } from 'vue2-helpers';

export default defineComponent({
  name: 'BCXMessengerChatPreview',
  components: {
    BCXMarkdown,
    EditIcon,
    BCXAvatar
  },
  props: {
    chat: {
      type: Object as PropType<Chat>,
      default: () => null
    },
    isFlat: {
      type: Boolean
    }
  },
  setup(props) {
    const { chat, isFlat } = toRefs(props);
    const { useGetters } = createVuexHelpers();
    const { user } = useGetters(['user']) as {
      user: Ref<User>;
    };
    const {
      selectedChatId, selectChat, isMarkingChats, markedChats, isExpanded
    } = useMessengerState();
    const { getFormattedTimeByAge } = useFormattedDateTime();

    const isSelected = computed(() => selectedChatId.value === chat.value.id);

    const lastMessage = computed(() => chat.value.messages.at(-1));

    const isMe = computed(() => user.value?.userId === lastMessage.value?.creator);

    const lastMessageDate = computed(() => {
      const date = lastMessage.value?.created;
      return date ? getFormattedTimeByAge(date, TimeFormatting.ChatMessageOverview) : '?';
    });

    const select = () => {
      selectChat(chat.value?.id ?? '');
    };

    // const isUnread = chat.value.messages.some((message) => {
    //   if (!message.read) console.log('Unread message:', message);
    //   return !message.read;
    // });

    const toggleMarked = () => {
      if (chat.value.chatId) toggleArrayItem(markedChats.value, chat.value.chatId);
    };

    const marked = computed(() => (chat.value.chatId ? markedChats.value.includes(chat.value.chatId) : false));

    const isMarkable = computed(() => isMarkingChats.value && (chat.value.type === CHAT_TYPE_DIRECT || isFlat.value));

    const isChatRead = computed(() => getIsChatRead(chat.value));

    const linkLabel = computed(() => chat.value?.links?.[0]?.LINKLABEL ?? '');

    const fullName = computed(() => `${chat.value?.privatePartner?.firstname} ${chat.value?.privatePartner?.lastname}`);
    const userName = computed(() => chat.value?.privatePartner?.company?.name ?? fullName?.value);

    return {
      lastMessageDate,
      select,
      toggleMarked,
      isMarkable,
      lastMessage,
      isMe,
      marked,
      isSelected,
      isExpanded,
      isChatRead,
      linkLabel,
      userName
    };
  }
});
