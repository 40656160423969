import ProfileSidebar from '@/views/profile/components/sidebar/ProfileSidebar.vue';
import { RouteConfig } from 'vue-router';
import ProfilePage from '@/views/profile/Profile.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
    meta: {
      auth: true,
      layout: 'full',
    },
  },
  {
    path: '/profile/:userId',
    name: 'userProfile',
    component: ProfilePage,
    meta: {
      sidebarComponent: ProfileSidebar,
      auth: true,
      layout: 'full',
    },
  },
];

export default routes;
