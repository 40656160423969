
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import BCXMessengerChatPreview from '@/components/messenger/Chat/BCXMessengerChatPreview.vue';
import { CHAT_TYPE_FORUM, CHAT_TYPE_PROJECT, Group } from '@/models/Messenger';
import useMessengerState from '@/state/messenger/messengerState';
import BCXMessengerGroupCollapsible from '@/components/messenger/Group/BCXMessengerGroupCollapsible.vue';
import withNewCount from '@/utils/withNewCount';
import { getGroupUnreadMessageCount } from '@/state/messenger/useGroupsUnreadMessages';
import EditIcon from '@/components/molecules/EditIcon.vue';
import toggleArrayItem from '@/utils/toggleArrayItem';
import { uniq } from 'lodash';
import BCXLazy from '@/components/molecules/BCXLazy.vue';
import BCXMessengerChatPreviewLoader from '@/components/messenger/Chat/BCXMessengerChatPreviewLoader.vue';

export default defineComponent({
  name: 'BCXMessengerGroup',
  components: {
    BCXMessengerChatPreviewLoader,
    BCXLazy,
    EditIcon,
    BCXMessengerGroupCollapsible,
    BCXMessengerChatPreview,
  },
  props: {
    group: {
      type: Object as PropType<Group>,
      default: () => null,
    },
  },
  setup(props) {
    const { group } = toRefs(props);
    const { openedGroupIDs, isMarkingChats, markedChats } = useMessengerState();

    const unreadMessages = computed(() => getGroupUnreadMessageCount(group.value));

    const groupIsOpened = computed(() => openedGroupIDs.openedIds.includes(group.value.id));

    const toggleList = (state:boolean) => {
      if (!state) {
        openedGroupIDs.openedIds = openedGroupIDs.openedIds.filter((id:string) => id !== group.value.id);
      } else {
        openedGroupIDs.openedIds.push(group.value.id);
      }
    };

    const isOpened = computed({
      get: ():boolean => (groupIsOpened.value || group.value.shouldOpen) ?? false,
      set: (state:boolean) => toggleList(state),
    });

    const isMarkable = computed(() => isMarkingChats.value && (group.value.type === CHAT_TYPE_FORUM || group.value.type === CHAT_TYPE_PROJECT));

    const toggleMarked = () => {
      const chatIds = uniq(group.value.chats.map(({ chatId }) => (chatId)));
      chatIds.forEach((id) => toggleArrayItem(markedChats.value, id ?? ''));
    };

    const marked = computed(() => markedChats.value.includes(group.value.chats?.[0].chatId ?? ''));

    return {
      unreadMessages,
      isOpened,
      isMarkable,
      toggleList,
      withNewCount,
      toggleMarked,
      marked,
    };
  },
});
