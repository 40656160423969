
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { defineComponent, ref } from 'vue';
import { BlogEntry } from '../models/Blog';

export default defineComponent({
  props: {
    entry: { type: Object as () => BlogEntry, required: true },
    type: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { getFormattedTimeByAge } = useFormattedDateTime();
    const formattedDate = ref(getFormattedTimeByAge(props.entry.lastUpdated));

    return { formattedDate };
  }
});
