
import BCXFooter from '@/components/BCXFooter.vue';
import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import { defineComponent, provide, ref } from 'vue';
import InviteCodePopup from '@/components/login-register/InviteCodePopup.vue';
import LPHeaderNav from '@/views/landingpage/components/LPHeaderNav.vue';

export default defineComponent({
  name: 'LandingpageLayout',
  components: {
    LPHeaderNav,
    InviteCodePopup,
    BCXFooter
  },
  setup() {
    const { locale } = useI18n();
    const { isLandingPageMobileWidth, isLandingPageTabletWidth } = useResponsiveness();

    const showInviteCodePopup = ref(false);
    const invitePopupType = ref('freelancer');

    const toggleInviteCodePopup = (type: 'freelancer' | 'customer') => {
      invitePopupType.value = type;
      showInviteCodePopup.value = !showInviteCodePopup.value;
    };

    provide('toggleInviteCodePopup', toggleInviteCodePopup);

    return {
      locale,
      isLandingPageMobileWidth,
      isLandingPageTabletWidth,
      showInviteCodePopup,
      invitePopupType
    };
  }
});
