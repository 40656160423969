
import Vue, { ref } from 'vue';
import RecommendationFeedback from '@/components/recommendation/RecommendationFeedback.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import ResponsiveTest from '@/components/ResponsiveTest.vue';
import { emojis } from '@/utils/markdown/markdown';
import SemanticPaletteShowcase from '@/views/home/SemanticPaletteShowcase.vue';
import TagList from '@/components/tagging/TagList.vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';

export default Vue.extend({
  components: {
    SemanticPaletteShowcase,
    ResponsiveTest,
    'recommendation-feedback-form': RecommendationFeedback,
    TagList,
    BCXAvatar,
  },
  setup() {
    const avatarDemo = ref({
      sizes: [120, 60, 50, 40, 36, 32],
      selectedSize: 120,
      showOnlineIndicator: true,
      showCookies: true,
      userIsOnline: true,
      userAmountOfCookies: 3,

    });
    return { emojis, avatarDemo };
  },
  methods: {
    superEvent(evt: CustomEvent) {
      // console.log('SUPER! Detail:', evt.detail);
    },
  },
});
