import axios from '@/plugins/axios';
import { filter, map } from 'lodash';

interface DividedPermissions {
  permissions: string[];
  roles: string[];
}

export default {
  async login(
    loginName: string,
    password: string,
  ): Promise<any> {
    const response = await axios.post('/v1/users/user/login', { loginName, password });
    return {
      ...response.data,
      token: response.headers.authorization,
      refreshToken: response.headers.refresh_token,
    };
  },
};
