

import { defineComponent, PropType } from 'vue';
import { User } from '@/models/User';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import EnvelopeIcon from '@/components/svg/envelope-open.svg?inline';
import XIcon from '@/components/svg/x-circle.svg?inline';

export default defineComponent({
  name: 'MemberTableMobile',
  components: { BCXUserBanner, EnvelopeIcon, XIcon },
  props: {
    members: {
      type: Array as PropType<User[]>,
      default: () => []
    },
    type: {
      type: String as PropType<'guest' | 'employee'>,
      required: true
    }
  },
  setup() {
    const rnd = () => Math.random() > 0.5;

    return {
      rnd
    };
  }
});
