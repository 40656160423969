
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { setGeneralSettings } from '@/services/Settings/Api';
import useSettings from '@/state/settingsState';
import { i18nToItemList } from '@/utils/selectItemTools';
import {
  computed, defineComponent, PropType, ref, toRefs, watch
} from 'vue';

export default defineComponent({
  name: 'GeneralSetting',
  components: {
    BCXSearchSelect,
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    valueRef: {
      type: String,
      default: ''
    },
    dataKey: {
      type: String as PropType<'locale' | 'salutation'>,
      default: ''
    },
    listTranslationKey: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      settings, loadSettings, isSafeToAutoSave
    } = useSettings();
    const {
      valueRef, dataKey, listTranslationKey
    } = toRefs(props);

    const isLoading = ref(false);
    const list = i18nToItemList(listTranslationKey.value);

    const value = ref(props.valueRef);

    const setValue = (v:string) => {
      value.value = v ?? '';
    };

    watch(valueRef, setValue);

    const save = async () => {
      if (!settings.value) return;

      isLoading.value = true;

      const data = {
        [dataKey.value]: value.value,
      };

      await setGeneralSettings(data);
      isLoading.value = false;
      await loadSettings();
    };

    const dirty = computed(() => isSafeToAutoSave && value.value !== (valueRef.value ?? ''));

    watch(dirty, async (is) => {
      if (is) {
        await save();
      }
    });

    return {
      isLoading, list, value
    };
  }
});
