
import useI18n from '@/mixins/useI18n';
import {
  defineComponent, computed, PropType, onMounted, toRefs
} from 'vue';
import { ExternalVideoBarChatMatchingResult } from '@/__generated__/types';
import BarChatFeedback from './BarChatFeedback.vue';
import TableChatFeedback from './TableChatFeedback.vue';

const BarChatFeedbackComponentName = 'BarChatFeedback';
const TableChatFeedbackComponentName = 'TableChatFeedback';

export default defineComponent({
  components: {
    BarChatFeedback,
    TableChatFeedback
  },
  props: {
    variant: {
      type: String as PropType<'bar-chat' | 'table-chat'>,
      default: () => 'bar-chat'
    },
    videoSessionId: {
      type: String,
      default: () => ''
    },
    hasParticipantLeaved: {
      type: Boolean,
      default: () => false,
    },
    room: {
      type: Object as PropType<ExternalVideoBarChatMatchingResult>,
      default: () => ({})
    }
  },
  emits: ['rematch'],
  setup(props) {
    const { t } = useI18n();
    const { room } = toRefs(props);
    const minMiliseconds = 45000;
    const participantLeftEarly = computed(() => room.value.startTime && (new Date(room.value.startTime)).getTime() + minMiliseconds > new Date().getTime());

    // until v-app is fixed, make sure we always scroll to the top
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    const feedbackComponent = computed(() => {
      if (props.variant === 'bar-chat') {
        // return BarChatFeedback.name;
        return BarChatFeedbackComponentName;
      }

      // return TableChatFeedback.name;
      return TableChatFeedbackComponentName;
    });

    return {
      t,
      feedbackComponent,
      participantLeftEarly,
    };
  }
});
