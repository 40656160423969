
import Vue from 'vue';
import { mdiClose, mdiChevronLeft } from '@mdi/js';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';

interface StringMap {
  [key: string]: undefined | string;
}

export default Vue.extend({
  components: {
    BCXAvatar
  },
  props: {
    recId: { type: String, default: '' },
  },
  data: () => ({
    dialog: false,
    step: 1,
    radioGroup: 'Yes',
    posMessage: '',
    negMessage: '',
    likeOptions: ['Yes', 'Somewhat', 'No'],
    reasons: {
      skills: undefined,
      preferences: undefined,
      other: undefined,
    } as StringMap,
    explanations: {
      preferences: undefined,
      other: undefined,
    } as StringMap,
    block: undefined,
    preferenceOptions: ['Too much effort', 'Commute Time', 'Hate wearing ties'],
    mdiClose,
    mdiChevronLeft,
  }),
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.width <= 767;
    },

    isNice(): boolean {
      return this.radioGroup === 'Yes';
    },

    canSend(): boolean {
      if (!this.isNice) {
        return !!Object.values(this.reasons).some(Boolean);
      }

      return true;
    },
    hasReasonsProvided(): boolean {
      if (!this.isNice) {
        return !!Object.keys(this.explanations).every((key: string) => (this.reasons[key] ? this.explanations[key] : true));
      }
      return true;
    },
  },
  watch: {
    radioGroup(newSelection: string) {
      this.step = newSelection === 'Yes' ? 1 : 2;
    },
  },
  methods: {
    closePanel(): void {
      return undefined;
    },
  },
});
