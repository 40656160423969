import { checkRoutePermissions } from '@/ability/helpers';
import useI18n from '@/mixins/useI18n';
import { InfoBoxState } from '@/models/InfoBox';
import router from '@/router/index';
import useInfoBoxState from '@/state/infoBox';
import usePageState from '@/state/pageState';
import useTokenState from '@/state/tokenState';
import store from '@/store';
import asyncWait from '@/utils/asyncWait';
import { isString } from 'lodash';

import { INTERVAL_RESET, INTERVAL_STOP, intervalRefresherEventBus } from '@/mixins/useIntervalRefresher';
import useConfigState from '@/state/configState';

const setNavigationGuards = () => {
  const {
    hasCustomSidebar, metaBoxState
  } = useInfoBoxState();
  // const { stop: stopRefreshInterval, reset: resetRefreshInterval } = useIntervalRefresher();

  const { title, pageNotFound } = usePageState();

  router.beforeEach(async (to, from, next) => {
    // get registration and platform config
    await useConfigState().getConfig();
    // reset page load specific caches
    await store.dispatch('voting/resetVotingReporting');

    const requiresAuth = to.matched.some((record) => record.meta.auth);
    const canBeAccessed = to.meta?.permissions ? checkRoutePermissions(to.meta.permissions) : true;

    const { isLoggedIn } = useTokenState();
    // const isLoggedIn = false;

    // /* handle undefined routes */
    // const target = router.resolve({ name: to.name as string });
    // if (target.route.path === '/' && target.route.name !== 'index') {
    //   next({ name: 'index' });
    // }

    // await asyncWait(100);
    // console.log(' - guard - to is:', to);

    // set meta info
    const descEl = document.querySelector('head meta[name="description"]');

    title.value = to?.meta?.title ?? 'ByteCookie';

    if (descEl) {
      if (to?.meta?.description) {
        descEl.setAttribute('content', to.meta.description);
      } else {
        descEl.setAttribute('content', '');
      }
    }

    /* default route => index */
    if (!to?.name) {
      pageNotFound.value = true;
      next({ name: 'index' });
    }

    if (isLoggedIn.value && !canBeAccessed) {
      const {
        registrationStep,
        registrationStepPlainName
      } = store.getters;

      if (registrationStep) {
        next({ name: registrationStep });
      } else if (registrationStepPlainName) {
        next({ name: registrationStepPlainName });
      } else {
        next({ name: 'register-status' });
      }
    }
    if (requiresAuth && !isLoggedIn.value && !['index'].includes(to.name as string)) {
      store.commit('removeRegistrationData');
      store.commit('setRedirectUrl', to);
      next({ name: 'login' });
    } else if (isLoggedIn.value && ['login', 'index'].includes(isString(to.name) ? to.name : 'index')) {
      next({ name: 'home', hash: to.hash, query: to.query });
    } else {
      next();
    }

    // delete room ticket when leaving video settings
    if (to.name !== 'chat-room') {
      const ticket = store.getters.roomTicket;

      if (ticket) {
        // TODO: Prüfen ob das hier noch benötig wird
        // const room = store.getters.selectedRoom;
        // VideoChatService.deleteFromRoom(room)
        //   .then(() => {
        //     store.commit('roomAccessToken', { roomAccessToken: null });
        //     store.commit('roomTicket', { roomTicket: null });
        //     store.commit('selectedRoom', { selectedRoom: null });
        //   });
      }
    }

    hasCustomSidebar.value = !!to?.meta?.sidebarComponent;
    metaBoxState.value = to?.meta?.infoBox ?? null as InfoBoxState | null;
  });

  router.afterEach(async (to, from) => {
    window.scrollTo(0, 0);

    // update notification status faster for some routes:
    const updatingRoutes = [
      'subscriptions',
      'manageSubscriptions',
      'projectForumThread',
      'thread',
      'recommendations'
    ];

    if (updatingRoutes.includes(to?.name ?? '')) {
      await asyncWait(200);
      intervalRefresherEventBus.emit(INTERVAL_RESET, `route change by updating routes (${updatingRoutes.join(',')})`);
    }

    // leaving logged in state:
    if (from.meta?.auth && !to.meta?.auth) {
      intervalRefresherEventBus.emit(INTERVAL_STOP);
    }

    // update route when coming from logged in state
    if (!from.meta?.auth && to.meta?.auth) {
      intervalRefresherEventBus.emit(INTERVAL_RESET, 'route change: coming from logged-out to logged-in state');
    }
  });
};

export default setNavigationGuards;
