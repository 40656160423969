
import Vue from 'vue';
import ExternalLink from '@/components/svg/external-link.svg?inline';

export default Vue.extend({
  name: 'CertificateEducationItem',
  components: {
    ExternalLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    origin: {
      type: String,
      default: '',
    },
    year: {
      type: Number,
      default: 2020,
    },
    link: {
      type: String,
      default: '',
    },
  },
});
