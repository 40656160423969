import isStageOrLocalEnvironment from '@/utils/isStageOrLocalEnvironment';

const LOG_LEVEL_STORAGE_KEY = 'logLevel';

export enum LogLevel {
   'NO_LOGGING' = '0',
   'ERROR_LOGGING' = '1',
   'DEBUG_LOGGING' = '2',
}

let logLevel:LogLevel = LogLevel.NO_LOGGING;

const getInitialLogLevel = () => {
  const logLevelStorage = localStorage.getItem(LOG_LEVEL_STORAGE_KEY) as LogLevel;
  logLevel = logLevelStorage;
  if (!logLevelStorage) logLevel = isStageOrLocalEnvironment ? LogLevel.DEBUG_LOGGING : LogLevel.ERROR_LOGGING;
};

const getLogLevelFromInput = (input: string | number) => {
  switch (input) {
    case 1:
    case '1':
    case 'error':
    case 'errors':
      return LogLevel.ERROR_LOGGING;

    case 2:
    case '2':
    case 'debug':
    case 'debugging':
      return LogLevel.DEBUG_LOGGING;

    default:
      return LogLevel.NO_LOGGING;
  }
};

const getLogLevelDescription = (level: LogLevel) => {
  switch (level) {
    case LogLevel.ERROR_LOGGING: return 'Only Errors';
    case LogLevel.DEBUG_LOGGING: return 'Errors & Debugging';
    default: return 'Nothing';
  }
};

window.setLogLevel = (input: string | number) => {
  logLevel = getLogLevelFromInput(input);
  localStorage.setItem('logLevel', logLevel);
  console.log(`Set log level to ${logLevel}(${getLogLevelDescription(logLevel)})`);
};

window.getLogLevel = () => {
  console.log(`Log level is ${logLevel} (${getLogLevelDescription(logLevel)})`);
};

export const isDebugLogLevel = () => logLevel === LogLevel.DEBUG_LOGGING;

export const isErrorLogLevel = () => logLevel === LogLevel.ERROR_LOGGING || isDebugLogLevel();

export const logDebug = (...params:any[]) => {
  if (!isDebugLogLevel()) return;
  console.log(...params);
};

export const logError = (...params:any[]) => {
  if (!isErrorLogLevel()) return;
  console.error(...params);
};

export const logWarning = (...params:any[]) => {
  if (!isDebugLogLevel()) return;
  console.warn(...params);
};

getInitialLogLevel();
