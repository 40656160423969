import computeScrollIntoView from 'compute-scroll-into-view';

/**
 * ScrollIntoView function that can prevent the body from scrolling also (for browsers other than chrome)
 * @param target target element to be scrolled into view
 * @param boundary don't scroll any parents of this element
 */
const scrollIntoView = (target:HTMLElement, boundary?:HTMLElement) => {
  const actions = computeScrollIntoView(target, {
    scrollMode: 'if-needed',
    block: 'start',
    inline: 'nearest',
    skipOverflowHiddenElements: true,
    boundary,
  });
  actions.forEach(({ el, top, left }) => {
    el.scrollTop = top;
    el.scrollLeft = left;
  });
};

export default scrollIntoView;
