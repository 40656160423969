import { computed, Ref, ref } from 'vue';
import { throttle } from 'lodash';
import { useRouter } from 'vue2-helpers/vue-router';
import asyncWait from '@/utils/asyncWait';
import useResponsiveness from '@/mixins/useResponsiveness';
import { MaybeElementRef, useEventListener, useIntersectionObserver } from '@vueuse/core';

const DISTANCE_THRESHOLD = 50;
const VISIBLE_MIN_SCROLL = 45;

let lastPosition = -1;
const { isMobileLayout } = useResponsiveness();
const shouldBeHidden:Ref<boolean> = ref(false);
const forceVisible:Ref<boolean> = ref(false);
const observer:Ref<IntersectionObserver | null> = ref(null);

const init = (contentElement:Ref<MaybeElementRef>) => {
  const startObserver = () => {
    const { stop } = useIntersectionObserver(contentElement.value,
      ([{ intersectionRatio }]) => {
        shouldBeHidden.value = false;
        forceVisible.value = intersectionRatio === 1;
      }, {
        rootMargin: '0px',
        threshold: 1.0,
      });
    return stop;
  };

  const stop = startObserver();

  const onTouchStart = () => {
    lastPosition = window.scrollY;
  };

  const onScroll = throttle(() => {
    const { scrollY } = window;
    if (scrollY < VISIBLE_MIN_SCROLL) shouldBeHidden.value = false;
    else {
      const scrollDistance = scrollY - lastPosition;
      if (scrollDistance > DISTANCE_THRESHOLD) shouldBeHidden.value = true;
      else if (scrollDistance < -DISTANCE_THRESHOLD) shouldBeHidden.value = false;
    }
  }, 50);

  useEventListener(document, 'touchstart', onTouchStart);
  useEventListener(window, 'scroll', onScroll);

  const router = useRouter();
  (async () => {
    await router.isReady();

    router.afterEach(async () => {
      if (observer.value && contentElement.value) {
        stop();
        await asyncWait(100);
        startObserver();
      }
    });
  })();
};

const isHidden = computed(() => (isMobileLayout.value ? (!forceVisible.value && shouldBeHidden.value) : false));

const useMobileNavigationVisibility = () => ({
  init,
  isHidden,
});

export default useMobileNavigationVisibility;
