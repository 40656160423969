import axios from '@/plugins/axios';
import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import store, { RootState } from '..';

export interface AvatarCache {
  imageData: string;
  imageType: string;
  CACHE_TIME: number;
}

export interface AvatarCacheSizes {
  [key: string]: AvatarCache;
}

interface Users {
  [key: string]: AvatarCacheSizes;
}

interface State {
  avatarUser: Users;
}

export const LOADING = 'LOADING';

export const namespaced = true;

export const state: State = {
  avatarUser: {},
};

export const getters: GetterTree<State, RootState> = {
  getUserAvatar: (state) => (userId: string, size: number, ratio: number): AvatarCache => {
    // Limit the ratio to a fixed number
    ratio = Math.round(ratio);
    if (state.avatarUser[userId]?.[`${size}_${ratio}`]) {
      // We have cached data
      // console.debug(`[avatarCache]: Returned cached image: size:${size} ratio:${ratio} User:${userId}`);
      // Just dispatch a check, if the data needs an update
      store.dispatch('avatarCache/checkCacheTime', { userId, size, ratio });
    } else {
      // Create cached data
      // console.debug(`[avatarCache]: Requesting avatar and creating cached data for user: ${userId} size: ${size} ratio: ${ratio}`);
      // Init empty state, so we can return already
      store.commit('avatarCache/initUserAvatarCache', { userId, size, ratio });
      // Load user data async, the store will updated later
      // We are reactive because this is a computed value
      store.dispatch('avatarCache/requestUserAvatar', { userId, size, ratio });
    }
    return state.avatarUser[userId][`${size}_${ratio}`];
  },
};

export const mutations: MutationTree<State> = {
  initUserAvatarCache(state, { userId, size, ratio }) {
    const imageKey = `${size}_${ratio}`;
    const emptyImage = {
      imageData: LOADING,
      imageType: '',
    };
    if (state.avatarUser[userId]) {
      if (!state.avatarUser[userId][imageKey]) {
        // Create cache for the requested image size
        Vue.set(state.avatarUser[userId], `${size}_${ratio}`, emptyImage);
      }
    } else {
      // Create cache for the user and the requested image size
      Vue.set(state.avatarUser, userId, {
        [imageKey]: emptyImage
      });
    }
    // console.log('[avatarCache] Created cache place', size, ratio, userId);
  },
  setUserState(state, {
    userId, size, ratio, imageResponseData
  }) {
    const image = {
      imageData: imageResponseData.imageData ?? '',
      imageType: imageResponseData.imageType ?? '',
    };
    Object.assign(state.avatarUser[userId][`${size}_${ratio}`], { ...image, CACHE_TIME: new Date().getTime() });
    // Vue.set(state.avatarUser[userId], `${size}_${ratio}`, { ...image, CACHE_TIME: new Date().getTime() });
    // console.debug('[avatarCache] Avatar image loaded & cached for ', size, ratio, userId);
  },
  resetUserState(state, userId) {
    Vue.delete(state.avatarUser, userId);
    // console.debug('[avatarCache] Deleted cache for user ', userId);
  },
};

export const actions: ActionTree<State, RootState> = {
  requestUserAvatar({ commit }, { userId, size, ratio }) {
    // Load user avatar
    axios.get(`/v1/users/${userId}/avatar?size=${size}&devicePixelRatio=${ratio}`).then((response) => {
      commit('setUserState', {
        userId, size, ratio, imageResponseData: response.data
      });
    }).catch(() => {
      commit('setUserState', {
        userId, size, ratio, imageResponseData: {}
      });
    });
  },
  checkCacheTime({ state, dispatch }, { userId, size, ratio }) {
    const REFRESH_AFTER_1_DAY = 1000 * 60 * 60 * 24;
    const REFRESH_AFTER_1_HOUR = 1000 * 60 * 60;
    // If there is no cache time yet, just take now, as it is just getting created
    const imageExists = !!state.avatarUser[userId][`${size}_${ratio}`].imageData;
    const imageCacheTime = state.avatarUser[userId][`${size}_${ratio}`].CACHE_TIME ?? new Date().getTime();
    const diff = new Date().getTime() - imageCacheTime;
    let reloadAvatar = false;
    if (imageExists && diff > REFRESH_AFTER_1_DAY) {
      // console.debug(`[avatarCache]: CACHETIME DIFF: ${diff} - LIMIT: ${REFRESH_AFTER_1_DAY}`);
      if (diff > REFRESH_AFTER_1_DAY) {
        reloadAvatar = true;
      }
    } else if (!imageExists) {
      // console.debug(`[avatarCache]: CACHETIME DIFF: ${diff} - LIMIT: ${REFRESH_AFTER_1_HOUR} - (no image yet)`);
      if (diff > REFRESH_AFTER_1_HOUR) {
        reloadAvatar = true;
      }
    }
    if (reloadAvatar) {
      dispatch('requestUserAvatar', { userId, size, ratio });
    }
  }
};
