import { onBeforeUnmount, onMounted, Ref } from 'vue';

const usePreventScroll = (element: Ref<HTMLElement | SVGElement | null>) => {
  const scrollHandler = (evt:Event) => {
    const elm = evt.target as HTMLElement;
    const elmScrollbars = elm.closest('[data-body-scroll-lock-ignore], .toastui-editor-contents');
    const canScroll = elmScrollbars && (
      elmScrollbars.scrollHeight > elmScrollbars.clientHeight || elmScrollbars?.nodeName === 'BODY'
    );

    if (!canScroll) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  onMounted(() => {
    if (element.value) {
      element.value.addEventListener('wheel', scrollHandler);
    }
  });
  onBeforeUnmount(() => {
    if (element.value) element.value.removeEventListener('wheel', scrollHandler);
  });
};

export default usePreventScroll;
