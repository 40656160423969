
import Vue from 'vue';

const orientations:{
  [key:string]:number;
} = {
  up: 0,
  down: 180,
  right: 90,
  left: -90,
};

export default Vue.extend({
  name: 'Triangle',

  props: {
    orientation: {
      type: String,
      default: 'up',
    },
    size: {
      type: Number,
      default: 15,
    },
  },

  computed: {
    rotation():number {
      return orientations[this.orientation];
    },
  },
});
