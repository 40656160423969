

import IndustryTag from '@/views/profile/components/IndustryTag.vue';
import { defineComponent, PropType } from 'vue';
import { Industry } from '@/models/Tags';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'IndustryTags',
  components: { IndustryTag },
  props: {
    industries: {
      type: Array as PropType<Industry[]>,
      default: () => []
    }
  },
  setup(props) {
    const industries = useVModel(props, 'industries');

    const removeIndustry = (id: string) => {
      const idx = industries.value.findIndex((item) => item.externalId === id);
      if (idx >= 0) {
        industries.value.splice(idx, 1);
      }
    };

    return { removeIndustry };
  }
});
