
import BCXCard from '@/components/molecules/BCXCard.vue';
import useI18n from '@/mixins/useI18n';
import LatestBlogs from '@/views/home/components/DashboardLatestBlogs.vue';
import NewMembers from '@/views/home/components/DashboardNewMembers.vue';
import SubscriptionsFeed from '@/views/home/components/DashboardSubscriptionsFeed.vue';
import UserProfileStatus from '@/views/home/components/DashboardUserProfileStatus.vue';
import { LatestUserDashboard } from '@/views/home/models/Dashboard';
import {
  computed, ComputedRef, defineComponent, ref
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'HomeDashboard',
  components: {
    BCXCard,
    SubscriptionsFeed,
    LatestBlogs,
    NewMembers,
    UserProfileStatus
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const subscriptionFeedNewMembers: ComputedRef<LatestUserDashboard[]> = computed(() => store.getters['dashboard/getDashboardUsersList'] || []);
    const showUserProfileStatusCard = computed(() => store.state.dashboard.userProfileStatus.missingSummary || store.state.dashboard.userProfileStatus.missingImage);

    const showArticlesCard = ref(true);
    const hideArticlesCard = () => {
      showArticlesCard.value = false;
    };
    const showInfoCard = ref(true);
    const hideInfoCard = () => {
      showInfoCard.value = false;
    };

    return {
      subscriptionFeedNewMembers,
      showUserProfileStatusCard,
      showArticlesCard,
      hideArticlesCard,
      showInfoCard,
      hideInfoCard,
      t
    };
  }
});
