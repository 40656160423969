import { computed, Ref } from 'vue';
import sortComp from '@/utils/sortComp';
import { Chat, Group } from '@/models/Messenger';
import { last } from 'lodash';

interface SorterOptions {
  sortBy:Ref<string>;
  sortDir:Ref<string>;
}

/**
 * Provides sorting for the contents of groups
 * @author nc
 *
 * @param groups
 * @param chats
 * @param sortBy
 * @param sortDir
 */
const useSorter = (groups:Ref<Group[]>, chats: Ref<Chat[]>, { sortBy, sortDir }:SorterOptions) => {
  const chatSorter = (chatA: Chat, chatB: Chat) => {
    let result = 0;
    const lastMessageA = last(chatA.messages);
    const lastMessageB = last(chatB.messages);
    if (lastMessageA && lastMessageB) {
      if (sortBy.value === 'user') {
        result = sortComp(
          `${chatB.privatePartner.firstname} ${chatB.privatePartner.lastname}`,
          `${chatA.privatePartner.firstname} ${chatA.privatePartner.lastname}`,
        );
      } else if (sortBy.value === 'time') {
        result = sortComp(
          lastMessageB.created,
          lastMessageA.created,
        );
      }
      if (sortDir.value === 'ascending') result = -result;
    }
    return result;
  };

  const chatSortMapper = (group: Group) => {
    group.chats = group.chats.sort(chatSorter);
    return { ...group };
  };

  const groupSorter = (groupA: Group, groupB: Group) => {
    const typeSort = -sortComp(groupA.type, groupB.type);
    const titleSort = sortComp(groupA.title, groupB.title);
    return typeSort * 2 + titleSort;
  };

  const groupsFilteredSorted = computed<Group[]>(() => {
    const sortedGroups = groups.value.sort(groupSorter);
    return sortedGroups.map(chatSortMapper);
  });

  const chatsSorted = computed<Chat[]>(() => [...chats.value.sort(chatSorter)]);

  return {
    groupsFilteredSorted,
    chatsSorted
  };
};

export default useSorter;
