import axios from '@/plugins/axios';
import {
  PostSubscription, SubscriptionFeedList, SubscriptionsList, Subscription
} from '@/views/subscriptions/models/Subscription';

export default {

  async getAllSubscriptions(): Promise<SubscriptionsList> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Subscriptions-Endpoint/get_api_v1_users_subscriptions
     */
    return axios.get('/v1/users/subscriptions').then(({ data }) => data);
  },

  async postSubscriptions(postSubscriptionData: PostSubscription): Promise<Subscription> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Subscriptions-Endpoint/post_api_v1_users_subscriptions
     */
    return axios.post('/v1/users/subscriptions', postSubscriptionData).then(({ data }) => data);
  },

  async getSubscriptionFeed(): Promise<SubscriptionFeedList> {
    /*
     * Open-API Description
     * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Subscriptions-Endpoint/get_api_v1_users_subscriptions_feed
    */
    return axios.get('/v1/users/subscriptions/feed').then(({ data }) => data);
  },

  async deleteSubscription(subscriptionId: string): Promise<void> {
    /*
     * Open-API Description
      * https://stage-api.bytecookie.net/api/openapi-ui/index.html#/Subscriptions-Endpoint/delete_api_v1_users_subscriptions_subscriptionId
      * subscriptionId: string
    */
    return axios.delete(`/v1/users/subscriptions/${subscriptionId}`).then(({ data }) => data);
  }

};
