import { getData } from '@/utils/apiTools';
import { City, Country } from '@/models/Geo';

export const getPlatformCountries = async (): Promise<Country[]> => getData('/basicdata/platform/countries');

export const getCountries = async (): Promise<Country[]> => getData('/basicdata/geodata/countries');

export const getCountryCitiesByPostCode = async (countryCode: string, filter: string): Promise<City[]> => getData(
  `/basicdata/geodata/country/${countryCode}/postalcodes?filter=${encodeURIComponent(filter)}`,
);

export const getCountryCities = async (countryCode: string, filter: string): Promise<City[]> => getData(
  `/basicdata/geodata/country/${countryCode}/cities?filter=${encodeURIComponent(filter)}`,
);
