import { ExternalVideoChatParticipant } from '@/__generated__/types';
import useResponsiveness from '@/mixins/useResponsiveness';
import useQuickInfo from '@/state/quickInfo';
import usePlaceholderImage from '@/views/virtual-pub/composable/video-chat/usePlaceholderImage';
import useVideoEvents from '@/views/virtual-pub/composable/video-chat/useVideoEvents';
import { computed, ref, watch } from 'vue';
import {
  createLocalAudioTrack,
  createLocalTracks,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalParticipant,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication
} from 'twilio-video';
import { useStore } from 'vue2-helpers/vuex';
import { logDebug, logError } from '@/utils/logger';

export default function useVideoChat() {
  const store = useStore();
  const videoEvents = useVideoEvents();

  const localAudioTrack = ref<LocalAudioTrack | null>(null);
  const localVideoTrack = ref<LocalVideoTrack | null>(null);

  const { setShowContactHistory, setQuickInfo } = useQuickInfo();
  const { insertPlaceholderImage } = usePlaceholderImage();

  async function createTracks(videoDeviceId: string, audioDeviceId: string) {
    const isPortrait = window.matchMedia('(orientation: portrait)').matches;
    const aspectRatio = 16 / 9;
    const videoConstraints = {
      deviceId: videoDeviceId,
      width: 1280,
      height: 720,
      aspectRatio,
    };
    if (isPortrait) {
      videoConstraints.height = 1280;
      videoConstraints.width = 720;
    }

    return createLocalTracks({
      audio: {
        deviceId: audioDeviceId,
        echoCancellation: true,
        noiseSuppression: true
      },
      video: videoConstraints
    });
  }

  function showQuickInfo(userId?: string) {
    if (userId) {
      const viewUser = computed(() => store.getters['userStates/getUserInfo'](userId));
      const stop = watch(() => viewUser.value?.userId, () => {
        if (viewUser.value?.userId) {
          setShowContactHistory(false);
          setQuickInfo(viewUser.value, 200, 50, '_blank');
          stop();
        }
      }, { immediate: true });
    }
  }

  function applyAudioOutputDeviceChange(speakerDeviceId: string) {
    const audioTracks = document.querySelectorAll('.remote-audio');

    audioTracks.forEach((track: any) => {
      track.setSinkId(speakerDeviceId);
    });
  }

  // Attach the Track to the DOM.
  function attachTrack(track: any, container: HTMLElement, speakerDeviceId?: string) {
    let element;
    if (track.kind === 'audio') {
      element = track.attach();
      element.className = 'remote-audio';
      if (speakerDeviceId && typeof element.setSinkId === 'function') {
        element.setSinkId(speakerDeviceId);
      }
      container.insertBefore(element, container.firstElementChild);
      videoEvents.emit('unmute', container.id);
    } else {
      element = track.attach();
      container.insertBefore(element, container.firstElementChild);
      videoEvents.emit('show', container.id);
    }
  }

  // Detach given track from the DOM
  function detachTrack(track: RemoteTrack | LocalVideoTrack | LocalAudioTrack | any) {
    const elements = track.detach();
    logDebug('Detach, elements:', elements);
    const container = elements?.[0]?.parentElement;
    videoEvents.emit(track.kind === 'video' ? 'hide' : 'mute', container?.id ?? '');
    elements.forEach((element: any) => {
      element.srcObject = null;
      element.remove();
    });
  }

  // A RemoteTrack was unpublished from the Room.
  function trackUnpublished(publication: RemoteTrackPublication) {
    logDebug(`${publication.kind} track was unpublished.`);
  }

  // A new RemoteTrack was published to the Room.
  function trackPublished(publication: RemoteTrackPublication, container: HTMLElement, speakerDeviceId: string) {
    logDebug(`Track was of kind ${publication.kind} was published:${publication.isSubscribed}`);
    if (publication.isSubscribed) {
      attachTrack(publication.track, container, speakerDeviceId);
    }
    publication.on('subscribed', (track) => {
      logDebug(`Subscribed to ${publication.kind} track`);
      attachTrack(track, container);
    });
    publication.on('unsubscribed', detachTrack);
  }

  // A new RemoteParticipant joined the Room
  function handleParticipantConnected(
    participant: RemoteParticipant,
    videoRoomParticipantList: ExternalVideoChatParticipant[],
    speakerDeviceId: string,
    htmlElement?: HTMLElement,
    isBarChat = true
  ) {
    logDebug(`Participant '${participant.identity}' joined the room`);

    let participantDiv = document.getElementById(participant.sid);
    if (!participantDiv && htmlElement) {
      participantDiv = document.createElement('div');
      participantDiv.id = participant.sid;
      participantDiv.className = 'participant-container';

      if (videoRoomParticipantList.length) {
        const videoRoomParticipant = videoRoomParticipantList.find((x) => x.participantSid === participant.sid);

        let participantName = document.querySelector(`#${participant.sid} .user-info`);

        if (!participantName) {
          participantName = document.createElement('div');
          participantName.className = 'user-info';
        }

        if (isBarChat) {
          participantName.innerHTML = videoRoomParticipant?.fullname ?? '';
        } else {
          participantName.innerHTML = videoRoomParticipant?.firstname ?? '';
        }

        participantName.addEventListener('click', () => {
          if (videoRoomParticipant?.userId) showQuickInfo(videoRoomParticipant.userId);
        });

        participantDiv.appendChild(participantName);
      }

      insertPlaceholderImage(participantDiv);

      htmlElement.appendChild(participantDiv);
    }
    participant.tracks.forEach((publication) => {
      trackPublished(publication, participantDiv!, speakerDeviceId);
    });
    participant.on('trackPublished', (publication) => {
      trackPublished(publication, participantDiv!, speakerDeviceId);
    });
    participant.on('trackUnpublished', trackUnpublished);
  }

  // Detach the Participant's Tracks from the DOM.
  function handleParticipantDisconnected(participant: RemoteParticipant) {
    logDebug(`Participant '${participant.identity}' leave the room`);
    const participantDiv = document.getElementById(participant.sid);
    if (participantDiv?.parentNode) {
      participantDiv.parentNode.removeChild(participantDiv);
    }
  }

  function getTracks(iterableIterator: IterableIterator<LocalAudioTrackPublication | LocalVideoTrackPublication>) {
    return Array.from(iterableIterator).map((publication) => publication.track);
  }

  // reads selected audio input, and updates preview and room to use the device.
  async function applyAudioInputDeviceChange(localParticipant: LocalParticipant, deviceId: string, htmlElement?: HTMLElement) {
    try {
      // const localAudioTrack = await createLocalAudioTrack({ deviceId });
      localAudioTrack.value = await createLocalAudioTrack({ deviceId });
      if (localAudioTrack.value) {
        const tracks = getTracks(localParticipant.audioTracks.values());
        localParticipant.unpublishTracks(tracks);
        tracks.forEach(detachTrack);

        await localParticipant.publishTrack(localAudioTrack.value);
        if (htmlElement) {
          htmlElement.appendChild(localAudioTrack.value.attach());
        }
      }
    } catch (error) {
      logError('applyAudioInputDeviceChange -> error', error);
    }
  }

  // reads selected video input, and updates preview and room to use the device.
  async function applyVideoInputDeviceChange(localParticipant: LocalParticipant, deviceId: string, username?: string, htmlElement?: HTMLElement) {
    try {
      const isPortrait = window.matchMedia('(orientation: portrait)').matches;
      const aspectRatio = 16 / 9;
      const videoConstraints = {
        deviceId,
        width: 1280,
        height: 720,
        aspectRatio,
      };
      const { isMobileLayout } = useResponsiveness();
      if (isPortrait && isMobileLayout.value) {
        videoConstraints.height = 1280;
        videoConstraints.width = 720;
      }

      localVideoTrack.value = await createLocalVideoTrack(videoConstraints);
      if (localVideoTrack.value) {
        const tracks = getTracks(localParticipant.videoTracks.values());
        localParticipant.unpublishTracks(tracks);
        tracks.forEach(detachTrack);
        await localParticipant.publishTrack(localVideoTrack.value);

        if (htmlElement) {
          const attachedVideo = localVideoTrack.value.attach();
          logDebug('videoConstraints', videoConstraints);
          logDebug('applyVideoInputDeviceChange -> htmlElement', htmlElement);
          logDebug('applyVideoInputDeviceChange -> localVideoTrack.value', { ...localVideoTrack.value });
          logDebug('applyVideoInputDeviceChange -> localVideoTrack.value.attach()', attachedVideo);
          const videoInputPreview = document.querySelector('#videoInputPreview') as HTMLElement;
          videoInputPreview?.insertBefore(attachedVideo, videoInputPreview?.firstElementChild);
          if (!videoInputPreview?.querySelector('img')) {
            insertPlaceholderImage(videoInputPreview);
          }
          videoEvents.emit('show', 'videoInputPreview');
        }
      } else {
        logError('applyVideoInputDeviceChange -> localVideoTrack.value is null');
        logError(localVideoTrack);
      }
    } catch (error) {
      logError('applyVideoInputDeviceChange -> error', error);
    }
  }

  function stopTrack(localParticipant: LocalParticipant, tracks: (LocalAudioTrack | LocalVideoTrack)[]) {
    tracks.forEach((track) => {
      track.disable();
      track.stop();
      detachTrack(track);
      if (track.kind === 'video') {
        localVideoTrack.value?.stop();
      } else if (track.kind === 'audio') {
        localAudioTrack.value?.stop();
      }
    });
    localParticipant.unpublishTracks(tracks);
  }

  function removeAudioInputDevice(localParticipant: LocalParticipant) {
    const tracks = getTracks(localParticipant.audioTracks.values());
    stopTrack(localParticipant, tracks);
  }

  function removeVideoInputDevice(localParticipant: LocalParticipant) {
    const tracks = getTracks(localParticipant.videoTracks.values());
    stopTrack(localParticipant, tracks);
  }

  function stopAllInputDevices(localParticipant: LocalParticipant) {
    const videoTracks = getTracks(localParticipant.videoTracks.values());
    const audioTracks = getTracks(localParticipant.audioTracks.values());
    localAudioTrack.value?.stop();
    localVideoTrack.value?.stop();

    stopTrack(localParticipant, [...videoTracks, ...audioTracks]);
  }

  return {
    handleParticipantConnected,
    handleParticipantDisconnected,
    applyAudioInputDeviceChange,
    applyVideoInputDeviceChange,
    stopAllInputDevices,
    removeAudioInputDevice,
    removeVideoInputDevice,
    applyAudioOutputDeviceChange,
    createTracks,
    localAudioTrack,
    localVideoTrack,
    insertPlaceholderImage
  };
}
