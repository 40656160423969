import { Ref, watch, ref } from 'vue';

const useAudioOutputLevel = (audio: Ref<HTMLAudioElement | undefined>) => {
  const level = ref(0);
  const ctx = ref(new AudioContext());
  const source = ref<MediaElementAudioSourceNode>();
  const analyser = ref<AnalyserNode>();
  const processor = ref<ScriptProcessorNode>();

  const release = () => {
    try {
      ctx.value.close();
      source.value?.disconnect();
      processor.value?.disconnect();
    } catch (_) {
      // ignore since context already closed
    }
  };

  watch(audio, () => {
    if (!audio.value) {
      return;
    }

    ctx.value = new AudioContext();
    audio.value.onplay = () => ctx.value.resume();
    source.value = ctx.value.createMediaElementSource(audio.value);
    analyser.value = ctx.value.createAnalyser();
    source.value.connect(analyser.value);
    processor.value = ctx.value.createScriptProcessor(2048, 1, 1);
    analyser.value.connect(processor.value);

    source.value.connect(ctx.value.destination);
    processor.value.connect(ctx.value.destination);

    processor.value.onaudioprocess = (event) => {
      const input = event.inputBuffer.getChannelData(0);
      let i;
      let sum = 0.0;
      for (i = 0; i < input.length; ++i) {
        sum += input[i] * input[i];
      }
      level.value = Math.sqrt(sum / input.length);
    };
  });

  return {
    level,
    release
  };
};

export default useAudioOutputLevel;
