export interface AvailabilityInterface{
  available: boolean;
  availableNow: boolean;
  availableFulltime: boolean;
  availableFrom?: string;
  availableTo?: string;
  availableHoursPerWeek?: number;
  overallStatusLabel?: string;
  overallStatus?: 'AVAILABLE' | 'PARTIALLY_AVAILABLE' | 'UNAVAILABLE';
}

export interface ProjectAvailabilityInterface {
  available: boolean;
}

export interface AvailabilityDatePickerConfigInterface{
  locale?: string;
  'first-day-of-week'?: string | number;
  min?: string;
  flat?: boolean;
  'locale-first-day-of-year'?: number;
}

export enum AvailabilityMode {
  AVAILABILITY_NOT_AVAILABLE = 0,
  AVAILABILITY_PARTLY_AVAILABLE = 2,
  AVAILABILITY_FULLY_AVAILABLE = 1
}
