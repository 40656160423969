import { replaceEmojis } from '@/utils/markdown/emojis';
import { LinkMdNode, HeadingMdNode, MdNode } from '@toast-ui/editor';

const BCXRendererRestricted: any = {
  heading(node: HeadingMdNode, context: any) {
    if (context.entering) {
      return {
        type: 'openTag',
        tagName: `h${node.level}`,
        classNames: [`md-heading md-heading--h${node.id}`],
      };
    } if (!context.entering) {
      return {
        type: 'closeTag',
        tagName: `h${node.level}`,
      };
    }
    context.skipChildren();
    return null;
  },
  strike(node: MdNode) {
    return {
      type: 'html',
      content: `<span class="bcx-highlighted-text">${node.firstChild?.literal}</span>`,
    };
  },
  codeBlock(node: MdNode) {
    return {
      type: 'html',
      content: `<pre class="md-code-block"><code class="md-code-block__code">${node.literal}</code></pre>`,
    };
  },

  link(linkNode: LinkMdNode, context: any) {
    const regexByteCookieURL = new RegExp(/^(\/.+|https?:\/\/(?:(?:www\.)?stage\.|www\.)?(bytecookie\.net)?(\/.+))$/i);
    if (linkNode.destination && context.entering) {
      if (regexByteCookieURL.test(linkNode.destination)) {
        const href = linkNode.destination;
        return {
          type: 'openTag',
          tagName: 'a',
          classNames: ['md-restricted-link'],
          attributes: {
            href,
            title: linkNode.title,
            'data-markdown-event': 'router-go',
            'data-markdown-event-detail': href,
          },
        };
      }
      const href = linkNode.destination;
      return {
        type: 'openTag',
        tagName: 'a',
        classNames: ['md-restricted-link'],
        attributes: {
          href,
          title: linkNode.title,
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      };
    } if (!context.entering) {
      return {
        type: 'closeTag',
        tagName: 'a',
      };
    }
    return null;
  },
  image(imgNode: LinkMdNode, context: any) {
    context.skipChildren();
    if (imgNode.destination) {
      return {
        type: 'openTag',
        tagName: 'img',
        attributes: {
          src: imgNode.destination,
          alt: imgNode.title,
          title: imgNode.title,
        },
        selfClose: true,
      };
    }
    return null;
  },

  hr() {
    return {
      type: 'text',
      content: '---',
    };
  },

  text(node: MdNode) {
    return {
      type: 'text',
      content: replaceEmojis(node.literal || ''),
    };
  }
};

export default BCXRendererRestricted;
