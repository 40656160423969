
import BCXMessengerUserSelect from '@/components/messenger/User/BCXMessengerUserSelect.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import { AvailabilityInterface } from '@/models/Availability';
import { Profile, ProfileStoreKey } from '@/models/Profile';
import { User } from '@/models/User';
import AvailabilityService from '@/services/AvailabilityService';
import { getProfile } from '@/services/Profile/Api';
import ProfileSidebarAvailability from '@/views/profile/components/sidebar/ProfileSidebarAvailability.vue';
import {
  computed, defineComponent, provide, ref, watch
} from 'vue';
import { asyncComputed } from '@vueuse/core';
import { useRoute } from 'vue2-helpers/vue-router';
import BCXSidebarHelpContainer from '@/components/molecules/BCXSidebarHelpContainer.vue';
import RecommendWarningErrors from '@/views/recommendations/components/RecommendWarningErrors.vue';
import RecommendNotMatchingReasons from '@/views/recommendations/components/RecommendNotMatchingReasons.vue';
import RecommendedMessage from '@/views/recommendations/components/recommend-project-to-users/RecommendedMessage.vue';
import useRecommendation from '@/views/recommendations/composables/useRecommendation';
import RecommendationMessageBox from '@/views/recommendations/components/RecommendationMessageBox.vue';

export default defineComponent({
  name: 'RecommendProjectToUsers',
  components: {
    RecommendationMessageBox,
    RecommendedMessage,
    RecommendNotMatchingReasons,
    RecommendWarningErrors,
    BCXSidebarHelpContainer,
    TagDisplay,
    BCXMarkdown,
    ProfileSidebarAvailability,
    BCXUserBanner,
    BCXMessengerUserSelect
  },
  emits: ['recommended', 'selected', 'loaded'],
  setup(_, { emit }) {
    const user = ref<User | null>(null);
    const route = useRoute();

    // dirty hack to get ProfileBasicView working
    provide('storeKey', ProfileStoreKey.PROFILE);

    const userId = computed(() => user.value?.userId ?? '');

    const availability = asyncComputed<AvailabilityInterface | null | false>(
      async () => {
        if (!userId.value) return null;

        const availability = await AvailabilityService.getAvailabiltyforUser(userId.value);
        if (!availability) return false;

        return availability;
      },
      null,
      { lazy: true }
    );

    const profile = asyncComputed<Profile | null>(
      async () => {
        if (!userId.value) return null;
        return getProfile(userId.value);
      },
      null,
      { lazy: true }
    );

    const projectId = computed(() => route.params.projectId ?? '');

    const {
      notMatchingReasons, recommendationKey, messageKey,
      recommend,
      hasBeenRecommended,
      message,
      cantRecommend,
      verifiedProject
    } = useRecommendation(projectId, userId);

    const resultMessageKey = computed(() => {
      if (availability.value === false) return 'recommendations.apiErrors.availability_fetch_error';
      return recommendationKey.value ? messageKey.value : null;
    });

    const onSelectUser = (newUser: User) => {
      user.value = newUser;
    };

    const isLoading = computed(() => userId.value && !availability.value && !profile.value && !verifiedProject.value);

    watch(user, (user) => {
      hasBeenRecommended.value = false;
      if (user) emit('selected');
    });

    return {
      onSelectUser,
      user,
      availability,
      profile,
      message,
      recommend,
      hasBeenRecommended,
      isLoading,
      cantRecommend,
      verifiedProject,
      resultMessageKey,
      notMatchingReasons,
    };
  }
});
