
import BadgeGoldCommunicationBright from '@/components/svg/badges/badge-communication-gold-brightbg.svg?inline';
import BadgeGoldCommunicationDark from '@/components/svg/badges/badge-communication-gold-darkbg.svg?inline';
import BadgeSilverCommunicationBright from '@/components/svg/badges/badge-communication-silver-brightbg.svg?inline';
import BadgeSilverCommunicationDark from '@/components/svg/badges/badge-communication-silver-darkbg.svg?inline';
import BadgeGoldExpertiseBright from '@/components/svg/badges/badge-expertise-gold-brightbg.svg?inline';
import BadgeGoldExpertiseDark from '@/components/svg/badges/badge-expertise-gold-darkbg.svg?inline';
import BadgeSilverExpertiseBright from '@/components/svg/badges/badge-expertise-silver-brightbg.svg?inline';
import BadgeSilverExpertiseDark from '@/components/svg/badges/badge-expertise-silver-darkbg.svg?inline';
import BadgeMemberBright from '@/components/svg/badges/badge-member-brightbg.svg?inline';
import BadgeMemberDark from '@/components/svg/badges/badge-member-darkbg.svg?inline';
import BadgeGoldNetworkBright from '@/components/svg/badges/badge-network-gold-brightbg.svg?inline';
import BadgeGoldNetworkDark from '@/components/svg/badges/badge-network-gold-darkbg.svg?inline';
import BadgeSilverNetworkBright from '@/components/svg/badges/badge-network-silver-brightbg.svg?inline';
import BadgeSilverNetworkDark from '@/components/svg/badges/badge-network-silver-darkbg.svg?inline';
import BadgeGoldRecommendationsBright from '@/components/svg/badges/badge-recommendations-gold-brightbg.svg?inline';
import BadgeGoldRecommendationsDark from '@/components/svg/badges/badge-recommendations-gold-darkbg.svg?inline';
import BadgeSilverRecommendationsBright from '@/components/svg/badges/badge-recommendations-silver-brightbg.svg?inline';
import BadgeSilverRecommendationsDark from '@/components/svg/badges/badge-recommendations-silver-darkbg.svg?inline';
import BadgeResidentBright from '@/components/svg/badges/badge-resident-brightbg.svg?inline';
import BadgeResidentDark from '@/components/svg/badges/badge-resident-darkbg.svg?inline';
import BadgeSeniorResidentBright from '@/components/svg/badges/badge-senior-resident-brightbg.svg?inline';
import BadgeSeniorResidentDark from '@/components/svg/badges/badge-senior-resident-darkbg.svg?inline';
import BadgeBcTeamBright from '@/components/svg/badges/badge-team-brightbg.svg?inline';
import BadgeBcTeamDark from '@/components/svg/badges/badge-team-darkbg.svg?inline';
import useResponsiveness from '@/mixins/useResponsiveness';
/* eslint-disable vue/no-unused-components */
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { camelCase, startCase } from 'lodash';

export default defineComponent({
  name: 'BCXUserBadge',
  components: {
    BadgeGoldCommunicationBright,
    BadgeGoldCommunicationDark,
    BadgeSilverCommunicationBright,
    BadgeSilverCommunicationDark,
    BadgeGoldExpertiseBright,
    BadgeGoldExpertiseDark,
    BadgeSilverExpertiseBright,
    BadgeSilverExpertiseDark,
    BadgeMemberBright,
    BadgeMemberDark,
    BadgeGoldNetworkBright,
    BadgeGoldNetworkDark,
    BadgeSilverNetworkBright,
    BadgeSilverNetworkDark,
    BadgeGoldRecommendationsBright,
    BadgeGoldRecommendationsDark,
    BadgeSilverRecommendationsBright,
    BadgeSilverRecommendationsDark,
    BadgeResidentBright,
    BadgeResidentDark,
    BadgeSeniorResidentBright,
    BadgeSeniorResidentDark,
    BadgeBcTeamBright,
    BadgeBcTeamDark,
  },
  props: {
    badge: {
      type: String,
      default: null
    },
    themeMode: {
      type: String as PropType<'Bright' | 'Dark'>,
      default: null
    }
  },
  emits: ['badge-clicked'],
  setup(props, { emit }) {
    const { badge, themeMode } = toRefs(props);
    const { isMobileLayout } = useResponsiveness();
    const mode = computed<'Bright' | 'Dark'>(() => {
      if (themeMode.value) {
        return themeMode.value;
      }
      return (isMobileLayout.value ? 'Dark' : 'Bright');
    });
    const badgeComponent = computed(() => `Badge${startCase(camelCase(badge.value)).replaceAll(/(\s|badge|key)/ig, '')}${mode.value}`);

    return {
      badgeComponent,
      emit,
    };
  }
});
