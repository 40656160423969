import { Ref, ref, watch } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { clamp } from 'lodash';

interface UseSuggestionBoxParameters {
  input: Ref<HTMLInputElement>;
  list: Ref<any[]>;
  select: (index:number) => void;
}

const useSuggestionBox = ({ input, list, select }:UseSuggestionBoxParameters) => {
  const cursorPosition = ref<number>(0);

  const addToCursorPosition = (amount:number) => {
    cursorPosition.value = clamp(cursorPosition.value + amount, 0, list.value.length - 1);
    // console.log('Cursor:', cursorPosition.value);
  };

  watch(list, () => addToCursorPosition(0));

  onKeyStroke(['ArrowUp', 'ArrowDown', 'Enter'], (evt) => {
    switch (evt.key) {
      case 'ArrowUp':
        addToCursorPosition(-1);
        break;
      case 'ArrowDown':
        addToCursorPosition(1);
        break;
      case 'Enter':
        select(cursorPosition.value);
        // selectUser(userListGrouped.value[cursorPosition.value]);
        break;
      default:
    }
    evt.preventDefault();
  }, {
    target: input
  });

  return { cursorPosition };
};

export default useSuggestionBox;
