
import { computed, defineComponent } from 'vue';
import ProjectListItem from '@/views/project-forum/components/ProjectListItem.vue';
import useProfileState from '@/state/profile/profileState';
import { ProfileStoreKey } from '@/models/Profile';
import BCXPaginatedContent from '@/components/molecules/BCXPaginatedContent.vue';
import ProfileCreateProjectLink from '@/views/profile/components/views/ProfileCreateProjectLink.vue';
import useResponsiveness from '@/mixins/useResponsiveness';

export default defineComponent({
  name: 'ProfilePublicProjectsView',
  components: { ProfileCreateProjectLink, BCXPaginatedContent, ProjectListItem },
  setup(props, { emit }) {
    const { profile, canEdit, isQuickInfo } = useProfileState(ProfileStoreKey.PROFILE);
    const projects = computed(() => profile.value?.company?.publicProjects?.externalProjectList ?? []);

    const { isSmartphoneWidth } = useResponsiveness();

    const onClickProject = () => {
      if (isSmartphoneWidth.value) emit('close');
    };

    return {
      projects, canEdit, isQuickInfo, onClickProject
    };
  }
});
