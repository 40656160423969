
import Vue from 'vue';
import QuickInfoLayer from '@/components/molecules/QuickInfoLayer.vue';

export default Vue.extend({
  name: 'Plain',

  components: {
    QuickInfoLayer,
  },

  computed: {
    appClasses() : string[] | undefined {
      return this.$route.meta?.mainClasses;
    },
  },
});

