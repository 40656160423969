import { RouteConfig } from 'vue-router';
import UserSearch from '@/views/user-search/pages/UserSearch.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/user-search',
    name: 'user-search',
    component: UserSearch,
    meta: {
      layout: 'full',
      auth: true,
    },
  }
];

export default routes;
