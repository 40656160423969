
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { Security2FaUsageType, Settings, SettingsVerificationType } from '@/models/Settings';
import { set2FAUsage } from '@/services/Settings/Api';
import useSettings from '@/state/settingsState';
import { i18nToItemList } from '@/utils/selectItemTools';
import ActionButtons from '@/views/settings/components/utils/ActionButtons.vue';
import useUpdateTools from '@/views/settings/utils/useUpdateTools';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'TwoFactorAuthSettings',
  components: {
    BackendErrors,
    ActionButtons,
    BCXSearchSelect,
  },
  setup() {
    const { settings } = useSettings();
    const twoFactorUsages = i18nToItemList('settings.security.2fa_usage_types');

    const twoFactorUsage = ref<Security2FaUsageType>(Security2FaUsageType.Important);

    const setBySettings = (settings: Settings | null) => {
      twoFactorUsage.value = settings?.securitySettings?.security2FaUsage ?? Security2FaUsageType.Important;
    };

    const dirty = computed(() => (
      settings.value?.securitySettings?.security2FaUsage !== twoFactorUsage.value
    ));

    const fnSave = async () => set2FAUsage({
      newSecurity2FaUsage: twoFactorUsage.value
    });

    const {
      isLoading,
      save,
      reset,
      backendErrors,
      isDirty
    } = useUpdateTools(null, fnSave, setBySettings, SettingsVerificationType.Security2FaUsage, dirty);

    return {
      isDirty,
      backendErrors,
      twoFactorUsage,
      twoFactorUsages,
      isLoading,
      reset,
      save
    };
  }
});
