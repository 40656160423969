
import Vue from 'vue';
import Chevron from '@/components/svg-components/Chevron.vue';

export default Vue.extend({
  name: 'BCXDropdown',
  components: { Chevron },
  props: {
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    items: { type: Array, default: () => [] },
  },
  computed: {
    selectedValue: {
      set(v:string) {
        this.$emit('input', v);
      },
      get():string {
        return this.value;
      },
    },
  },
});
