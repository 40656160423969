
import Vue from 'vue';
import BCXParticipate from '@/components/navigation/panels/BCXParticipate.vue';
import { createVuexHelpers } from 'vue2-helpers';
import useScrollLock from '@/mixins/useScrollLock';
import { templateRef } from '@vueuse/core';

export default Vue.extend({
  name: 'NavigationPanel',
  components: {
    BCXParticipate,
  },
  setup() {
    const { useState } = createVuexHelpers();
    const { isParticipateVisible } = useState('page', ['isParticipateVisible']);
    const panel = templateRef<HTMLElement>('panel');

    useScrollLock(panel, isParticipateVisible);

    return {
      isParticipateVisible,
    };
  },
});
