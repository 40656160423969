
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  props: {
    numberOfWaitingParticipants: {
      type: Number,
      default: () => 0
    }
  },

  setup() {
    // until v-app is fixed, make sure we always scroll to the top
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {};
  }
});
