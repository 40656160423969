
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  inheritAttrs: true,
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '18px'
    },
    borderRadius: {
      type: String,
      default: '5px'
    }
  },
  setup(props) {
    const { width, height, borderRadius } = toRefs(props);

    const styleString = `width:${width.value}; height:${height.value}; border-radius:${borderRadius.value};`;

    return {
      styleString
    };
  }
});
