
import { defineComponent } from 'vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';

export default defineComponent({
  setup() {
    const { isSimple } = useUserSearch();
    return { isSimple };
  }
});
