
import useResponsiveness from '@/mixins/useResponsiveness';
import {
  computed, defineComponent, nextTick, PropType, ref, watch
} from 'vue';
import {
  templateRef, toRefs, useDraggable, useMouse, useVModels
} from '@vueuse/core';
import useAdaptBounding from '@/mixins/useAdaptBounding';
import onSizeChange from '@/mixins/onSizeChange';
import useScrollLock from '@/mixins/useScrollLock';
// import usePreventScroll from '@/mixins/usePreventScroll';

export default defineComponent({
  name: 'BCXQuickLayer',
  components: {},
  props: {
    xPos: {
      type: Number,
      default: 0
    },
    yPos: {
      type: Number,
      default: 0
    },
    isOpen: {
      type: Boolean
    },
    adaptBoundingAfterMove: {
      type: Boolean
    },
    adaptBoundingOnSizeChange: {
      type: Boolean
    },
    width: {
      type: [Number, String] as PropType<number | 'auto'>,
      default: 'auto'
    },
    autoPosition: {
      type: Boolean
    },
    mobileFullscreen: {
      type: Boolean
    },
    scrollLock: {
      type: Boolean
    },
    addZIndex: {
      type: Number,
      default: 0
    }
  },
  emits: ['dragend', 'has-dissapeared'],
  setup(props, { emit }) {
    const {
      adaptBoundingAfterMove, isOpen, autoPosition, adaptBoundingOnSizeChange, mobileFullscreen
    } = toRefs(props);
    const { xPos, yPos } = useVModels(props);
    const { isMobileWidth } = useResponsiveness();
    const layer = templateRef<HTMLElement>('layer');
    const el = templateRef<HTMLElement>('el');
    const isTransitioning = ref(false);

    const { x: xMouse, y: yMouse } = useMouse({ type: 'client' });

    const { x, y } = useDraggable(layer, {
      initialValue: {
        x: xPos.value,
        y: yPos.value
      },
      // eslint-disable-next-line consistent-return
      onStart(_, event) {
        // is it inside THIS quicklayer? If not, return false and don't drag.
        // This allows nested quicklayers to function properly ;-)
        const target = event.target as HTMLElement;
        const closestQuickLayer = target.closest('.bc-quick-layer-holder');
        const isOnSame = closestQuickLayer === el.value;
        if (!isOnSame) return false;

        // is this on a textarea (to resize)? or Input (to select)
        if (['TEXTAREA', 'INPUT'].includes(target.nodeName)) return false;
      },
      async onEnd() {
        if (adaptBoundingAfterMove.value) {
          await nextTick();
          // eslint-disable-next-line no-use-before-define
          adaptBounding(0.75);
        }
        xPos.value = x.value;
        yPos.value = y.value;
      }
    });

    const { adaptBounding: adapt } = useAdaptBounding(layer, x, y, 4);

    watch([xPos, yPos], ([xPos, yPos]) => {
      x.value = xPos;
      y.value = yPos;
    });

    const style = computed(() => (isMobileWidth.value
      ? null
      : {
        left: `${x.value}px`,
        top: `${y.value}px`
      }));

    function adaptBounding(outsideRatio = 0) {
      if (!isMobileWidth.value) {
        adapt(outsideRatio);
      }
      if (isTransitioning.value) requestAnimationFrame(() => adaptBounding(outsideRatio));
    }

    watch(isOpen, (is) => {
      if (is && autoPosition.value) {
        x.value = xMouse.value + xPos.value;
        y.value = yMouse.value + yPos.value;
      }
    });

    const onTransition = (is: boolean, isAppearing: boolean) => {
      isTransitioning.value = is;
      if (!isMobileWidth.value) {
        if (is) {
          adaptBounding(autoPosition.value ? 0 : 0.75);
        }
      }
      if (!is && !isAppearing) {
        emit('has-dissapeared');
      }
    };

    if (adaptBoundingOnSizeChange.value) {
      onSizeChange(layer, ({ height, oldHeight }) => {
        if (height > oldHeight) adaptBounding(0);
      });
    }

    if (mobileFullscreen.value) {
      useScrollLock(el, isOpen);
      // usePreventScroll(el);
    }

    return {
      isTransitioning,
      onTransition,
      isMobileWidth,
      style,
      adaptBounding
    };
  }
});
