

import {
  computed, defineComponent, PropType, ref
} from 'vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { Industry } from '@/models/Tags';
import { asyncComputed, useVModel } from '@vueuse/core';
import { convertToItemList, getItemByValue } from '@/utils/selectItemTools';
import { getIndustries } from '@/services/Tags/Api';
import { PROFILE_MAX_INDUSTRIES } from '@/models/Profile';

export default defineComponent({
  name: 'IndustriesEditor',
  components: { BCXSearchSelect },
  props: {
    industries: {
      type: Array as PropType<Industry[]>,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const industries = useVModel(props, 'industries');
    const industryOptions = asyncComputed(async () => convertToItemList(await getIndustries(), 'externalId', 'label'), []);
    const availableIndustryOptions = computed(
      () => industryOptions.value.filter(
        (industryItem) => !industries.value.some((industry) => industry.externalId === industryItem.value),
      ),
    );
    const industryToAdd = ref<string>('');

    const addIndustry = () => {
      const industryItem = getItemByValue(availableIndustryOptions.value, industryToAdd.value);
      if (industryItem) {
        const newIndustry: Industry = {
          externalId: industryItem.value,
          label: industryItem.text,
        };
        industries.value = [...industries.value, newIndustry];
        industryToAdd.value = '';
      }
    };

    const canAddIndustries = computed(() => industries.value.length < PROFILE_MAX_INDUSTRIES);

    return {
      availableIndustryOptions, industryToAdd, addIndustry, canAddIndustries
    };
  }
});

