
import useResponsiveness from '@/mixins/useResponsiveness';
import useMessengerState from '@/state/messenger/messengerState';
import {
  computed, defineComponent, nextTick, ref, unref, watch
} from 'vue';
import { useInterval } from '@vueuse/core';

export default defineComponent({
  props: {
    isAudioInputDisabled: {
      type: Boolean,
      default: false
    },

    isVideoInputDisabled: {
      type: Boolean,
      default: false
    },

    isBarChat: {
      type: Boolean
    },

    isVideoButtonDisabled: {
      type: Boolean
    },

    isAudioButtonDisabled: {
      type: Boolean
    }
  },

  emits: ['leaveRoom', 'toggleAudioOrVideoInput', 'openDeviceSettings', 'toggleMessenger'],

  setup(_, { emit }) {
    const { isSmartphoneWidth } = useResponsiveness();

    function toggleAudioInput() {
      emit('toggleAudioOrVideoInput', 'audio');
    }

    function toggleVideoInput() {
      emit('toggleAudioOrVideoInput', 'video');
    }

    function toggleSettings() {
      emit('openDeviceSettings');
    }

    function leaveRoom() {
      emit('leaveRoom');
    }

    const toggleMessenger = () => {
      emit('toggleMessenger');
    };

    const { unreadMessages } = useMessengerState();
    const initialUnreadMessages = unref(unreadMessages);

    const hasUnreadMessages = computed(() => unreadMessages.value);

    const { counter, resume } = useInterval(500, { controls: true, immediate: false });

    watch(unreadMessages, (unreadMessages: number) => {
      if (unreadMessages > initialUnreadMessages) {
        counter.value = 0;
        resume();
      }
    });
    // eslint-disable-next-line no-bitwise
    const isBlinking = computed(() => counter.value & 1 && counter.value < 7);

    return {
      isBlinking,
      hasUnreadMessages,
      toggleMessenger,
      toggleAudioInput,
      toggleVideoInput,
      toggleSettings,
      leaveRoom,
      isSmartphoneWidth
    };
  }
});
