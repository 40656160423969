
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXItemsWithPoints from '@/components/molecules/BCXItemsWithPoints.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXPoints from '@/components/molecules/BCXPoints.vue';
import BCXUserBadges from '@/components/molecules/BCXUserBadges.vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import EditIcon from '@/components/molecules/EditIcon.vue';
import IconAvatarEdit from '@/components/svg/avatar-circle.svg?inline';
import MessageIcon from '@/components/svg/icon-message.svg?inline';
import ShareIcon from '@/components/svg/share_cropped.svg?inline';
import UserHistoryIcon from '@/components/svg/user-history.svg?inline';
import UserIcon from '@/components/svg/user.svg?inline';
import FlagIcon from '@/components/svg/flag_outline.svg?inline';
import Reporting from '@/components/voting/Reporting.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useResponsiveness from '@/mixins/useResponsiveness';
import { HtmlTarget } from '@/models/HtmlTarget';
import { BOT_NAMES } from '@/models/Messenger';
import useMessengerState from '@/state/messenger/messengerState';
import useProfileState from '@/state/profile/profileState';
import useQuickInfo from '@/state/quickInfo';
import store from '@/store';
import AvatarUpload from '@/views/profile/components/AvatarUpload.vue';
import RecommendUserForProjectsMobileLayer from '@/views/recommendations/components/recommend-user-for-projects/RecommendUserForProjectsMobileLayer.vue';
import RecommendUserForProjectsQuickInfoLayer from '@/views/recommendations/components/recommend-user-for-projects/RecommendUserForProjectsQuickInfoLayer.vue';
import useRecommendations from '@/views/profile/composables/useRecommendations';
import {
  computed, defineComponent, PropType, ref, toRefs
} from 'vue';
import { templateRef } from '@vueuse/core';
import { not, or } from '@vueuse/math';
import { useRouter } from 'vue2-helpers/vue-router';
import useUser from '@/mixins/useUser';

export default defineComponent({
  name: 'ProfileBasicView',
  components: {
    RecommendUserForProjectsQuickInfoLayer,
    AvatarUpload,
    IconAvatarEdit,
    RecommendUserForProjectsMobileLayer,
    BCXItemsWithPoints,
    EditIcon,
    BCXUserBanner,
    BCXMarkdown,
    BCXAvatar,
    MessageIcon,
    UserHistoryIcon,
    UserIcon,
    FlagIcon,
    ShareIcon,
    Reporting,
    BCXUserBadges,
    BCXPoints
  },
  props: {
    showContactHistory: {
      type: Boolean,
      default: false
    },
    target: {
      type: String as PropType<HtmlTarget>,
      default: '_self'
    },
    showBadgesLegend: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { showContactHistory, showBadgesLegend } = toRefs(props);
    const {
      setQuickInfoUser, setQuickInfo, setShowContactHistory, setShowBadgesLegend, quickInfoX, quickInfoY
    } = useQuickInfo();
    const isRecommendationsOpen = ref(false);
    const { availability } = useRecommendations();
    const { getFormattedDate } = useFormattedDateTime();
    const isAvailableForRecommendation = computed(() => availability.value?.available);

    const showProfileInfos = computed(() => !showContactHistory.value && !showBadgesLegend.value);

    const {
      user, profile, isSelf, toggleEditSection, isQuickInfo, canEdit
    } = useProfileState();
    const { isCustomer } = useUser(user);

    const { isMobileWidth, isSidebarVisible } = useResponsiveness();
    const { createNewChat, isOpened } = useMessengerState();

    const router = useRouter();

    const userInfo = computed(() => store.getters['userStates/getUserInfo'](user.value?.userId ?? 'user'));

    const memberSince = computed(() => getFormattedDate(profile.value?.basic.memberSince));

    const avatarSize = computed(() => (isMobileWidth.value ? 60 : 120));
    const avatarDialog = ref<boolean>(false);
    const avatarRerenderKey = ref<number>(0);

    const { tUser: t, displayName } = useUser(user);

    const writeMessage = async () => {
      if (user.value) {
        isOpened.value = true;
        await createNewChat(user.value);
        if (isMobileWidth.value) {
          await setQuickInfoUser(null);
        }
      }
    };

    const edit = () => toggleEditSection('basic');

    const gotoProfile = async () => {
      await setQuickInfoUser(null);
      if (isMobileWidth.value) {
        isOpened.value = false;
      }

      const path = isSelf.value ? '/profile' : `/profile/${user.value?.userId}`;

      if (props.target === '_blank') {
        const routeData = router.resolve({ path });
        window.open(routeData.href, '_blank');
      } else {
        await router.push({ path });
      }
    };

    const isBot = computed(() => BOT_NAMES.includes(user.value?.fullname ?? ''));
    const canWrite = not(or(isSelf, isBot));

    const el = templateRef<HTMLElement>('el');
    const showQuickInfo = () => {
      // if (insideQuickinfo.value) return;
      if (el.value?.parentElement) {
        const { left, top } = el.value.parentElement.getBoundingClientRect();
        setShowContactHistory(true);
        setQuickInfo(user.value, left, top + 50);
      }
    };

    const badgesTheme = computed(() => (isQuickInfo && isMobileWidth.value ? 'dark' : 'bright'));

    const badgeClicked = () => {
      if (el.value?.parentElement) {
        const { left, top } = el.value.parentElement.getBoundingClientRect();
        setShowBadgesLegend(true);
        setQuickInfo(user.value, left, top + 50);
      }
    };

    const recommendProject = () => {
      isRecommendationsOpen.value = true;
    };

    const canRecommend = computed(() => (!isSidebarVisible.value || isQuickInfo) && !isSelf.value && !isCustomer.value);
    const canEditAvatar = computed(() => canEdit.value && !isMobileWidth.value);

    return {
      isQuickInfo,
      isMobileWidth,
      canEditAvatar,
      canWrite,
      user,
      userInfo,
      avatarSize,
      profile,
      writeMessage,
      edit,
      gotoProfile,
      memberSince,
      emit,
      showQuickInfo,
      showProfileInfos,
      badgesTheme,
      badgeClicked,
      canRecommend,
      recommendProject,
      isRecommendationsOpen,
      isAvailableForRecommendation,
      avatarDialog,
      avatarRerenderKey,
      quickInfoX,
      quickInfoY,
      canEdit,
      isSelf,
      displayName,
      t,
    };
  }
});
