
import Vue from 'vue';
import ForumListItem from '@/views/forum/components/ForumListItem.vue';
import { RawLocation } from 'vue-router';
import Chevron from '@/components/svg-components/Chevron.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';

export default Vue.extend({
  components: {
    // 'bc-expansion-panel': BCExpansionPanel
    ForumListItem,
    'bc-chevron': Chevron,
    BCXMarkdown,
  },
  props: {
    forum: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      expanded: true,
    };
  },
  computed: {
    forumGroupIcon(): string {
      if (this.forum.title) {
        return `forum-${this.forum.title.toLowerCase()}.svg`;
      }
      return 'forum-bytecookie.svg';
    },
    forumLink(): RawLocation {
      return { name: 'threads', params: { forumId: this.forum.forumId } };
    },
    forumDescription(): string {
      return this.$t(`forum.descriptions.forums_overview.${this.forum.title.toLowerCase()}`) as string;
    },
    forumTitle(): string {
      return this.$t(`forum.headlines.forums_overview.groups.${this.forum.title}`) as string;
    }
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
});
