
/* eslint-disable vue/no-unused-components */

import {
  computed, defineComponent, PropType, toRefs, ComputedRef
} from 'vue';
import { sortBy, upperFirst } from 'lodash';
import BCXUserBadge from './BCXUserBadge.vue';

export default defineComponent({
  name: 'BCXUserBadges',
  components: {
    BCXUserBadge,
  },
  props: {
    badges: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    theme: {
      type: String as PropType<'bright' | 'dark'>,
      default: 'bright'
    }
  },
  emits: ['badge-clicked'],
  setup(props, { emit }) {
    const { badges, theme } = toRefs(props);

    const badgeTheme: ComputedRef<'Bright'|'Dark'> = computed(() => (upperFirst(theme.value) || 'Bright') as 'Bright'|'Dark');

    const bannerBadges = computed(() => {
      const shownBadges: {badge: string; position: number}[] = [];
      badges.value.forEach((badge) => {
        if (badge.includes('STRANGER_KEY')) {
          // do nothing
        } else if ((badge.includes('MEMBER') || badge.includes('RESIDENT'))
            && badges.value.length <= 4) {
          shownBadges.push({ badge, position: 2 });
        } else if (badge.includes('TEAM')) {
          shownBadges.push({ badge, position: 1 });
        } else if (badge.includes('EXPERTISE')
          || badge.includes('COMMUNICATION')
          || badge.includes('RECOMMENDATION')) {
          if (badge.includes('GOLD')) {
            shownBadges.push({ badge, position: 3 });
          } else {
            shownBadges.push({ badge, position: 4 });
          }
        } else if (badge.includes('NETWORKING')) {
          shownBadges.push({ badge, position: 5 });
        } else if (!(badge.includes('MEMBER') || badge.includes('RESIDENT'))) {
          shownBadges.push({ badge, position: 6 });
        }
      });
      return sortBy(shownBadges, 'position');
    });

    return {
      bannerBadges,
      badgeTheme,
      emit,
    };
  }
});
