import { Chat, ChatMessage, Group } from '@/models/Messenger';
import { computed, Ref } from 'vue';

export const getLatestUnreadChat = (chats: Chat[]):Chat | null => {
  let latestUnreadChat: Chat | null = null;
  let lastNewestMessage: ChatMessage | null = null;
  chats.forEach((chat) => {
    const unreadMessages = chat.messages.filter((message) => !message.read);
    if (unreadMessages?.length) {
      const newestMessage = unreadMessages.reduce((previousValue, currentValue) => (currentValue.created > previousValue.created ? currentValue : previousValue));
      if (!latestUnreadChat || newestMessage.created > (lastNewestMessage?.created ?? '')) {
        lastNewestMessage = newestMessage;
        latestUnreadChat = chat;
      }
    }
  });
  return latestUnreadChat;
};

export const getChatUnreadMessageCount = (chat:Chat) => chat.messages.reduce((prev, cur) => prev + (cur.read ? 0 : 1), 0);

export const getGroupUnreadMessageCount = (group:Group) => group.chats.reduce(
  (prev: number, cur: Chat) => prev + getChatUnreadMessageCount(cur), 0,
);

export const getGroupsUnreadMessageCount = (groups:Group[]) => groups.reduce(
  (prev:number, cur:Group) => prev + getGroupUnreadMessageCount(cur),
  0,
);

/**
 * Gets number of unread Messages for all groups
 * @author nc
 *
 * @param groups
 */
export const useGroupsUnreadMessages = (groups:Ref<Group[]>) => {
  const unreadMessages = computed(
    () => getGroupsUnreadMessageCount(groups.value),
  );
  return {
    unreadMessages,
  };
};
