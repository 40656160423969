
import { defineComponent } from 'vue';
import LPButton from '@/views/landingpage/components/LPButton.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useFreelancerLandingPage from '@/views/landingpage/components/freelancer/useFreelancerLandingPage';

export default defineComponent({
  name: 'FreelancerInvitation',
  components: { BCXMarkdown, LPButton },
  setup() {
    const { tl, toggleInvitePopup } = useFreelancerLandingPage();
    return { tl, toggleInvitePopup };
  }
});
