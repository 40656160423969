
import { SaveReportingSourceType } from '@/models/Reporting';
import { businessObjectType } from '@/models/Voting';
import { VotingState } from '@/store/voting';
import { defineComponent, toRefs } from 'vue';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  props: {
    reportedUser: {
      type: Object,
      required: true,
    },
    businessObjectType: {
      type: String as () => businessObjectType,
      default: 'MESSAGE'
    },
    businessObjectId: {
      type: String,
      required: true,
      default: undefined,
    },
    sourceType: {
      type: String as () => SaveReportingSourceType,
      default: 'MESSAGE'
    }
  },
  setup(props) {
    const {
      reportedUser, businessObjectType, businessObjectId, sourceType
    } = toRefs(props);

    const store = useStore();

    function openReporting(ev: Event) {
      const payload: VotingState = {
        activeType: 'report',
        user: reportedUser.value,
        businessObjectId: businessObjectId.value,
        businessObjectType: businessObjectType.value,
        updatedVoting: null,
        sourceType: sourceType.value
      };

      store.dispatch('voting/setVotingReporting', payload);
    }

    return {
      openReporting,
    };
  },
});
