import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// SVG: Icons
import svgIcons from './svg-icons';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      ...svgIcons,
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        bc_dark: '#444444',
        bc_blue: '#44A6E4',
        bc_yellow: '#F9B621',
        bc_dark_blue: '#3092D0',
        bc_red: '#B22222',
        bc_grey: '#696969',
        bc_light_grey: '#E3E3E3',
        bc_text_on_dark: '#ffffff',
        bc_text_on_light: '#363636',
        bc_bg_availability: '#1b2330',
        bc_availability_text_title: '#E5E5E5',
        bc_availability_text_content: '#D0D0D0',
        bc_availability_grey: '#676767',
        /* https://www.figma.com/file/XNnbfoZSKsQriSktER9iwb/Bytecookie_Figma?node-id=3487%3A11592 */
        black: '#494949',
        pitchblack: '#363636',
        blackest: '#262626',
        blue: '#3084B9',
        biscuit: '#F2DEC7',
        brown: '#A05700',
        cookie: '#E39724',
        danger: '#DB2121',
        gingerbread: '#D07D01',
        'dark-gray': '#979797',
        'light-gray': '#F2F3F5',
        gray: '#DADADA',
        green: '#66D077',
        gravel: '#BFB8AF',
        'mid-night': '#212936',
        'mobile-night': '#171b20',
        night: '#2A313E',
        bc_night: '#1D2430',
        'topbar-item': '#313a4b',
        'topbar-text': '#dbdbdb',
        orange: '#ee8f00',
        success: '#00B01C',
        sky: '#D6E6F1',
        'dark-stone': '#8790A1',
        stone: '#C7C9D9',
        warning: '#F1C013',
        white: '#FFFFFF',
        yellow: '#F9B621',
      },
    },
  },
});
