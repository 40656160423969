import { createGlobalState, useAsyncState } from '@vueuse/core';
import { getUserProjectCount } from '@/services/UserProjectCount';
import { computed } from 'vue';

const useUserProjectCount = createGlobalState(() => {
  // const remainingProjectsCount = asyncComputed<number | null | 'loading'>(async () => {
  //   const response = await getUserProjectCount();
  //   return response?.remainingProjectsCount ?? null;
  // }, 'loading');

  const { state: remainingProjectsCount, execute: loadRemainingProjectsCount, isReady: isRemainingProjectsLoaded } = useAsyncState<number | null>(async () => {
    const response = await getUserProjectCount();
    return response?.remainingProjectsCount ?? null;
  }, null, { immediate: false });

  const hasProjectCount = computed(() => typeof remainingProjectsCount.value === 'number');
  const noRemainingProjectsLeft = computed(() => typeof remainingProjectsCount.value === 'number' && remainingProjectsCount.value <= 0);

  return {
    remainingProjectsCount,
    hasProjectCount,
    noRemainingProjectsLeft,
    isRemainingProjectsLoaded,
    loadRemainingProjectsCount
  };
});

export default useUserProjectCount;
