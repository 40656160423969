

import { defineComponent } from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import AddGuest from '@/views/guests-employees/components/AddGuest.vue';
import GroupIcon from '@/components/svg/group.svg?inline';
import MemberList from '@/views/guests-employees/components/MemberList.vue';
import { useProvideGuestsEmployees } from '@/views/guests-employees/composables/useGuestsEmployees';

export default defineComponent({
  name: 'GuestsEmployees',
  components: {
    MemberList,
    AddGuest,
    BCXBreadcrumbs,
    BCXContentArea,
    GroupIcon
  },
  setup() {
    useProvideGuestsEmployees();
  }
});

