
import BCXDropdown from '@/components/molecules/forms/BCXDropdown.vue';
import isStageOrLocalEnvironment from '@/utils/isStageOrLocalEnvironment';
import { useBarOpeningHours } from '@/views/virtual-pub/composable/useBarOpeningHours';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import { useRouter } from 'vue2-helpers/vue-router';
import useI18n from '../../../../mixins/useI18n';
import useCreateRoom from '../../composable/rooms/useCreateRoom';
import VirtualPubSectionPanel from '../molecules/VirtualPubSectionPanel.vue';
import useRooms from '../../composable/rooms/useRooms';

export default defineComponent({
  components: {
    VirtualPubSectionPanel,
    BCXDropdown
  },

  emits: ['roomCreated'],

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { isBarOpen, canJoinRoom } = useBarOpeningHours();

    const userIsAdmin = computed(() => store.getters.user.roles.includes('ADMIN'));
    const AVAILABLE_SEATS_THRESHOLD = 50;

    const topic = ref('');

    const minSize = ref(4);
    const maxSize = ref(9);
    const size = ref(maxSize.value.toString());
    const items = ref([...Array(maxSize.value - minSize.value + 1)].map((_, index) => ({
      value: `${index + minSize.value}`,
      text: `${index + minSize.value}`,
    })).map((item) => ({ ...item, value: item.value.toString() })));

    const {
      createRoom, error, data, isLoading
    } = useCreateRoom();
    const { rooms, newRoomsAllowed } = useRooms();

    async function onCreateClicked() {
      router.push({ name: 'chat-room', query: { 'new-room': 'true', roomTopic: topic.value, maxParticipants: size.value } });
    }

    const availableSeats = computed(() => rooms.value.map((room) => ((room.maxParticipants ?? 0) - (room.numberOfActiveUsers ?? 0))).reduce((a, b) => a + b, 0));

    const canCreateRoom = computed(() => newRoomsAllowed.value && (rooms.value.length === 0 || availableSeats.value < AVAILABLE_SEATS_THRESHOLD) && canJoinRoom.value);

    const cannotCreateRoomText = computed(() => {
      if (!newRoomsAllowed.value && availableSeats.value >= 2) {
        return t('video_chat.new_room.cannot_create_room.new_rooms_not_allowed_seats_available').toString();
      } if (!newRoomsAllowed.value && availableSeats.value < 2) {
        return t('video_chat.new_room.cannot_create_room.new_rooms_not_allowed').toString();
      } if (availableSeats.value > AVAILABLE_SEATS_THRESHOLD) {
        return t('video_chat.new_room.cannot_create_room.enough_seats_available').toString();
      } if (!canJoinRoom.value && isBarOpen.value) {
        return t('video_chat.new_room.cannot_create_room.bar_closing_soon').toString();
      }
      return '';
    });

    const tableChatSubtitle = computed(() => {
      if (!canCreateRoom.value && isBarOpen.value) {
        return cannotCreateRoomText.value;
      }
      return t('video_chat.new_room.sub_title').toString();
    });

    return {
      canCreateRoom,
      tableChatSubtitle,
      t,
      topic,
      size,
      items,
      onCreateClicked,
      userIsAdmin,
    };
  }
});
