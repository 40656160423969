import _axios from '@/plugins/axios';
import { ExternalVideoChatParticipants } from '@/__generated__/types';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/rooms/uniqueRoomName/participants';

const useRoomParticipants = () => {
  function getEndpoint(uniqueRoomName: string) {
    return ENDPOINT.replace('uniqueRoomName', uniqueRoomName);
  }

  const roomParticipantsAxios = useAxios<ExternalVideoChatParticipants>(ENDPOINT, { method: 'GET' }, _axios, { immediate: false });

  async function getRoomParticipants(uniqueRoomName: string) {
    await roomParticipantsAxios.execute(getEndpoint(uniqueRoomName));
  }

  return {
    getRoomParticipants,
    ...roomParticipantsAxios,
  };
};

export default useRoomParticipants;
