import useDevice from './useDevice';

const useVideoInput = () => {
  const { current: currentCamera, devices: cameras } = useDevice('videoinput');

  return {
    cameras,
    currentCamera
  };
};

export default useVideoInput;
