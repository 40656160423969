import { RequestVideoJoinRoom, ResponseVideoJoinRoom } from '@/__generated__/types';
import _axios from '@/plugins/axios';
import { useAxios } from '@vueuse/integrations/useAxios';

const ENDPOINT = '/v1/community/videochat/rooms/uniqueRoomName/join';

const useJoinRoom = () => {
  function getEndpoint(uniqueRoomName: string) {
    return ENDPOINT.replace('uniqueRoomName', uniqueRoomName);
  }

  const joinRoomAxios = useAxios<ResponseVideoJoinRoom>(ENDPOINT, { method: 'POST' }, _axios, { immediate: false });

  async function createJoinRoom(request: RequestVideoJoinRoom) {
    const { roomSid, uniqueRoomName } = request;
    if (roomSid && uniqueRoomName) {
      await joinRoomAxios.execute(getEndpoint(request.uniqueRoomName),
        {
          method: 'POST',
          data: { uniqueRoomName: request.uniqueRoomName, roomSid: request.roomSid }
        });
    }
  }

  return {
    createJoinRoom,
    ...joinRoomAxios,
  };
};

export default useJoinRoom;
