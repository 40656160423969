import { MESSAGE_EVENT_REFRESH } from '@/models/Messenger';
import useMessengerState from '@/state/messenger/messengerState';
import { watch } from 'vue';
import { createGlobalState, useRefHistory } from '@vueuse/core';
import { INTERVAL_RESET, intervalRefresherEventBus } from '@/mixins/useIntervalRefresher';

const useMessengerRefresher = createGlobalState(() => {
  const {
    eventBus, loadChats, isOpened
  } = useMessengerState();
  const { history } = useRefHistory(isOpened, { capacity: 3 });

  watch(isOpened, (is) => {
    if (is) {
      if (history.value.length <= 2) {
        loadChats();
      }
    }
  });

  eventBus.on((action) => {
    if (action === MESSAGE_EVENT_REFRESH) {
      loadChats();
      intervalRefresherEventBus.emit(INTERVAL_RESET, 'MESSAGE_EVENT_REFRESH');
    }
  });
});

export default useMessengerRefresher;
