
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useResponsiveness from '@/mixins/useResponsiveness';
import { BlogEntry } from '@/views/blog/models/Blog';
import {
  computed, defineComponent, nextTick, onMounted, ref
} from 'vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import { RouterEntry } from '@/models/Router';
import TagList from '@/components/tagging/TagList.vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import Voting from '@/components/voting/Voting.vue';
import useI18n from '@/mixins/useI18n';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue2-helpers/vue-router';
import usePageState from '@/state/pageState';
import { getBlogEntry, putUpdateBlogEntryView } from '../../../services/Blog/Api';

export default defineComponent({
  components: {
    BCXContentArea,
    BCXBreadcrumbs,
    TagList,
    BCXUserBanner,
    BCXIcon,
    Voting,
    BCXMarkdown,
  },
  props: ['articleId'],
  setup(props) {
    const { t } = useI18n();
    const article = ref<BlogEntry>({} as BlogEntry);
    const { isMobileLayout } = useResponsiveness();
    const { getFormattedTimeByAge } = useFormattedDateTime();
    const { pageNotFound } = usePageState();
    const router = useRouter();
    const route = useRoute();

    const articleRoute = computed(() => {
      switch (article.value?.type) {
        case 'BC_NEWS':
          return { text: t('blog.news') as string, to: { name: 'blog news' } };
        case 'HELP_ARTICLE':
          return { text: t('blog.wiki') as string, to: { name: 'wiki help' } };
        default:
          return { text: t('blog.articles') as string, to: { name: 'blog articles' } };
      }
    });
    const entryBreadcrumbItems = computed(
      (): Array<RouterEntry> => {
        const breadcrumbItems: Array<RouterEntry> = [articleRoute.value, { text: article.value?.title }];
        if (isMobileLayout) {
          breadcrumbItems.unshift({ text: 'ByteCookie', to: { name: 'home' } });
        }
        return breadcrumbItems;
      }
    );

    const loadBlogEntry = async () => {
      const data = await getBlogEntry(props.articleId);
      if (!data) {
        const isWiki = route.path.includes('wiki');
        pageNotFound.value = true;
        router.push(isWiki ? '/wiki/help' : '/blog');
        return;
      }
      article.value = data;
      putUpdateBlogEntryView(props.articleId);
    };
    const lastUpdated = computed(() => getFormattedTimeByAge(article.value.lastUpdated));
    const markdown = computed(() => {
      if (article?.value?.content) {
        return article.value.content;
      }
      return '';
    });
    onMounted(loadBlogEntry);

    onBeforeRouteUpdate(async (to, from, next) => {
      next();
      await nextTick();
      loadBlogEntry();
    });

    return {
      t,
      article,
      entryBreadcrumbItems,
      isMobileLayout,
      markdown,
      lastUpdated,
    };
  }
});
