
import {
  computed, defineComponent, PropType, ref, watch
} from 'vue';
import ProjectMinimalListItem from '@/views/project-forum/components/ProjectMinimalListItem.vue';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import EditIcon from '@/components/molecules/EditIcon.vue';
import ProjectSelector from '@/views/project-forum/components/sidebar-actions/ProjectSelector.vue';
import { useVModel } from '@vueuse/core';
import { useGuestsEmployees } from '../composables/useGuestsEmployees';

export default defineComponent({
  name: 'MemberProjectList',
  components: { ProjectSelector, EditIcon, ProjectMinimalListItem },
  props: {
    projects: {
      type: Array as PropType<ProjectListProject[]>,
      required: true,
    }
  },
  setup(props) {
    const projects = useVModel(props, 'projects');
    const { projectList, projectName } = useGuestsEmployees();
    const selectedProject = ref<ProjectListProject | null>(null);

    watch(projects, (p) => {
      console.log('Projects changed!', projects);
    });

    const onSelectProject = (project: ProjectListProject) => {
      projectName.value = project.title;
      selectedProject.value = project;
    };

    const canAdd = computed(() => !!selectedProject.value);

    return {
      projectList,
      projectName,
      onSelectProject,
      selectedProject,
      canAdd
    };
  }
});
