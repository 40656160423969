import MostAppreciatedContainer from '@/components/most-appreciated/MostAppreciatedContainer.vue';
import { RouteConfig } from 'vue-router';
import store from '@/store';
import ThreadOverview from '@/views/forum/pages/ThreadOverview.vue';
import ForumsOverview from '@/views/forum/pages/ForumsOverview.vue';
import Thread from '@/views/forum/pages/Thread.vue';
import NewThread from '@/views/forum/pages/NewThread.vue';
import ForumService from '@/services/ForumService';
import router from '@/router';
import { GROUP_ID_ARTICLES } from '../models/Forum';

const routes: Array<RouteConfig> = [
  {
    name: 'forums',
    path: '/talks',
    component: ForumsOverview,
    beforeEnter(_to, _from, next) {
      store.dispatch('forum/fetchForumList').then(() => {
        next();
      });
    },
    meta: {
      sidebarComponent: MostAppreciatedContainer,
      layout: 'full',
      auth: true,
    },
  },

  {
    name: 'createThread',
    path: '/talks/:forumId/groups/:groupId/new-thread',
    component: NewThread,
    beforeEnter(to, from, next) {
      const { forumId, groupId } = to.params;
      if (groupId !== GROUP_ID_ARTICLES) {
        store.commit('forum/updateThread', { forumId, groupId });
        store.dispatch('forum/fetchThreadList', { forumId: to.params.forumId, groupId: to.params.groupId, previousRouteName: from.name });
        next();
      } else {
        router.push({ name: 'forums' });
      }
    },
    meta: {
      sidebarComponent: MostAppreciatedContainer,
      layout: 'full',
      auth: true,
    },
  },

  {
    name: 'threads',
    path: '/talks/:forumId/groups/:groupId/threads',
    component: ThreadOverview,
    beforeEnter(to, from, next) {
      store.dispatch('forum/clearThreadList');
      store.commit('forum/setGroupId', to.params.groupId);
      store.dispatch('subscriptions/fetchSubscriptions');
      store.dispatch('forum/fetchThreadList', { forumId: to.params.forumId, groupId: to.params.groupId, previousRouteName: from.name }).then(() => {
        next();
      });
    },
    meta: {
      sidebarComponent: MostAppreciatedContainer,
      layout: 'full',
      auth: true,
    },
  },

  {
    name: 'thread',
    path: '/talks/:forumId/groups/:groupId/threads/:threadId',
    component: Thread,
    beforeEnter(to, _from, next) {
      store.dispatch('forum/clearThreadList');
      store.dispatch('forum/clearThread');
      store.dispatch('subscriptions/fetchSubscriptions');
      const threadsViewed = JSON.parse(window.localStorage.getItem('threadsViewed') || '{}');
      const viewTimestamp = threadsViewed[to.params.threadId];
      const currentTimestamp: number = (new Date()).getTime();
      if (viewTimestamp === undefined || viewTimestamp + 30000 < currentTimestamp) { // Thread-View is counted only every 30 minutes
        ForumService.trackPageViewThread(to.params.forumId, to.params.threadId, to.params.groupId);
        threadsViewed[to.params.threadId] = currentTimestamp;
        window.localStorage.setItem('threadsViewed', JSON.stringify(threadsViewed));
      }
      next();
    },
    meta: {
      sidebarComponent: MostAppreciatedContainer,
      layout: 'full',
      auth: true,
    },
  },
];
export default routes;
