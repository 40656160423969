import useI18n from '@/mixins/useI18n';
import { computed } from 'vue';

type HalfShortWeekdays = Record<string, string[]>;

export enum TimeFormatting {
  ChatMessageOverview = 'chatMessageOverview'
}

const DAY_MSECS = 86400000;
const WEEK_MSECS = DAY_MSECS * 7;

const HALFSHORT_WEEKDAYS: HalfShortWeekdays = {
  de: [
    'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'
  ],
  en: [
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
  ]
};

const useFormattedDateTime = () => {
  const { locale: i18nLocale } = useI18n();

  const locale = computed(() => {
    if (i18nLocale.value === 'en') return 'en-GB'; // GB hat das "vernünftige" dd/mm/yy Format.
    return i18nLocale.value;
  });

  const toDate = (time: Date | string) => (typeof time === 'string' ? new Date(time) : time);

  const formatSimpleTime = (time: Date): string => new Intl.DateTimeFormat(locale.value, { timeStyle: 'short' }).format(time);

  const formatDayTime = (time: Date): string => `${HALFSHORT_WEEKDAYS[i18nLocale.value][time.getDay()]} ${formatSimpleTime(time)}`;

  const formatDate = (time:Date, short = false):string => {
    const dateFormatted = time.toLocaleString(locale.value, {
      year: short ? '2-digit' : 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return dateFormatted.replaceAll(',', '');
  };

  const formatDateTime = (time:Date): string => {
    const dateFormatted = time.toLocaleString(locale.value, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
    return dateFormatted.replaceAll(',', '').replaceAll(' ', '\xa0');
  };

  const timeFormatters = {
    [TimeFormatting.ChatMessageOverview]: {
      formatFullTime: (time: Date): string => {
        const monthName = time.toLocaleDateString(locale.value, { month: 'short' });
        const dateStr = `${time.getDate()}`.padStart(2, '0');
        const yearStr = `${time.getFullYear()}`.substring(2);
        const monthNr = `${time.getMonth() + 1}`.padStart(2, '0');
        return i18nLocale.value === 'en'
          ? `${dateStr}/${monthNr}/${yearStr}`
          : `${dateStr}. ${monthName} ${yearStr}`;
      },
      formatDayMonthTime: (time: Date): string => {
        const monthName = time.toLocaleDateString(locale.value, { month: 'long' });
        const dateStr = `${time.getDate()}`.padStart(2, '0');
        return `${dateStr}. ${monthName}`;
      }
    },
    default: {
      formatFullTime: (time: Date): string => {
        const monthName = time.toLocaleDateString(locale.value, { month: 'short' });
        const dateStr = `${time.getDate()}`.padStart(2, '0');
        const timeStr = formatSimpleTime(time);
        const yearStr = `${time.getFullYear()}`.substring(2);
        const monthNr = `${time.getMonth() + 1}`.padStart(2, '0');
        return i18nLocale.value === 'en'
          ? `${dateStr}/${monthNr}/${yearStr}\xa0\xa0${timeStr}`
          : `${dateStr}. ${monthName} ${yearStr}\xa0\xa0${timeStr}`;
      },
      formatDayMonthTime: (time: Date): string => {
        const monthName = time.toLocaleDateString(locale.value, { month: 'short' });
        const dateStr = `${time.getDate()}`.padStart(2, '0');
        const timeStr = formatSimpleTime(time);
        return i18nLocale.value === 'en'
          ? `${dateStr}. ${monthName}\xa0\xa0${timeStr}`
          : `${dateStr}. ${monthName}\xa0\xa0${timeStr}`;
      }
    }
  };

  const getRelativeTime = (time: Date | string, otherTime: Date | string, sonderLocke?: TimeFormatting):string => {
    time = toDate(time);
    otherTime = toDate(otherTime);
    const distance = otherTime.getTime() - time.getTime();

    const { formatDayMonthTime, formatFullTime } = timeFormatters[sonderLocke ?? 'default'];

    if (distance >= 0) {
      if (distance < DAY_MSECS && time.getDate() === otherTime.getDate()) return formatSimpleTime(time);
      if (distance < WEEK_MSECS) return formatDayTime(time);
      if (time.getFullYear() === otherTime.getFullYear()) return formatDayMonthTime(time);
    }
    return formatFullTime(time);
  };

  const getFormattedTimeByAge = (time?: Date | string, sonderLocke?: TimeFormatting): string => (time ? getRelativeTime(time, new Date(), sonderLocke) : '');

  const getFormattedDate = (time?: Date | string, short = false): string => {
    if (!time) return '';
    time = toDate(time);
    return formatDate(time, short);
  };

  const getFormattedDateTime = (time?: Date | string): string => {
    if (!time) return '';
    time = toDate(time);
    return formatDateTime(time);
  };

  return {
    getFormattedTimeByAge,
    getFormattedDate,
    getFormattedDateTime
  };
};

export default useFormattedDateTime;
