
import {
  defineComponent, computed, ref, ComputedRef
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import SubscriptionManageIcon from '@/components/svg/subscriptions-manage.svg?inline';
// import BackIcon from '@/components/svg/chevron_back.svg?inline';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import SubscriptionList from '@/views/subscriptions/components/SubscriptionList.vue';
import Chevron from '@/components/svg-components/Chevron.vue';
import BCXInfoCollapsible from '@/components/molecules/BCXInfoCollapsible.vue';
import EditIcon from '@/components/molecules/EditIcon.vue';
import BCXHelpTextTriggerIcon from '@/components/molecules/BCXHelpTextTriggerIcon.vue';

export default defineComponent({
  name: 'SubscriptionManager',
  components: {
    BCXHelpTextTriggerIcon,
    BCXBreadcrumbs,
    Chevron,
    BCXInfoCollapsible,
    // BackIcon,
    SubscriptionList,
    SubscriptionManageIcon,
  },
  setup() {
    const isInlineHelpOpened = ref(false);
    const store = useStore();
    const { t } = useI18n();

    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const breadcrumbItems = computed(() => {
      const breadcrumbArray: any = [
        {
          text: 'Home',
          to: {
            name: 'home',
          },
        },
        {
          text: t('subscriptions.title'),
          to: {
            name: 'subscriptions',
          },
        },
        {
          text: t('subscriptions.manage'),
          to: {
            name: 'manageSubscriptions',
          },
        },
      ];
      if (isMobileLayout.value) {
        breadcrumbArray[0] = { text: 'ByteCookie' };
      }
      return breadcrumbArray;
    });

    const hasSubscriptions: ComputedRef<boolean> = computed(() => store.state.subscriptions.subscriptionList.length > 0);

    const feedLink = computed(() => ({
      name: 'subscriptions',
    }));

    return {
      isMobileLayout,
      breadcrumbItems,
      hasSubscriptions,
      isInlineHelpOpened,
      feedLink,
      t
    };
  },
});
