
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BCXVuelidateErrors',
  props: {
    errors: {
      type: Array as PropType<any[]>, default: () => []
    }
  }
});
