
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import Vue, { computed, inject, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import useMessengerState from '@/state/messenger/messengerState';

export default Vue.extend({
  name: 'BCXMessengerGroupCollapsible',
  components: { BCXCollapsible, BCXIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    isOpened: {
      type: Boolean,
    },
    isHighlighted: {
      type: Boolean,
    },
  },
  setup(props) {
    const { searchTerm } = useMessengerState();
    const isOpenedVM = useVModel(props, 'isOpened');
    const collapsibleRef = ref<HTMLElement | null>(null);

    const scrollBoundary = inject('scrollBoundary');

    const immediateCollapsible = computed(() => !!searchTerm.value.length);

    return {
      scrollBoundary,
      collapsibleRef,
      isOpenedVM,
      immediateCollapsible,
    };
  },
});
