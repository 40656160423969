
import BCXMessengerCollapsible from '@/components/messenger/BCXMessengerCollapsible.vue';
import BCXMessengerConversations from '@/components/messenger/BCXMessengerConversations.vue';
import BCXMessengerHeader from '@/components/messenger/BCXMessengerHeader.vue';
import BCXMessengerChat from '@/components/messenger/Chat/BCXMessengerChat.vue';
import BCXMessengerChatHeader from '@/components/messenger/Chat/BCXMessengerChatHeader.vue';
import BCXMessengerOptionsPanel from '@/components/messenger/Panels/BCXMessengerOptionsPanel.vue';
import BCXMessengerSortPanel from '@/components/messenger/Panels/BCXMessengerSortPanel.vue';
import BCXMessengerTools from '@/components/messenger/Tools/BCXMessengerTools.vue';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import usePreventScroll from '@/mixins/usePreventScroll';
import useResponsiveness from '@/mixins/useResponsiveness';
import useScrollLock from '@/mixins/useScrollLock';
import { CHAT_TYPE_DIRECT, Group } from '@/models/Messenger';
import useMessengerState from '@/state/messenger/messengerState';
import {
  computed, defineComponent, onMounted, toRefs, watch
} from 'vue';
import { templateRef } from '@vueuse/core';

export default defineComponent({
  name: 'BCXMessenger',
  components: {
    BCXMessengerOptionsPanel,
    BCXMessengerSortPanel,
    BCXMessengerChatHeader,
    BCXMessengerChat,
    BCXMessengerConversations,
    BCXMessengerTools,
    BCXCollapsible,
    BCXMessengerCollapsible,
    BCXMessengerHeader
  },
  props: {
    inVideoChat: Boolean
  },
  setup(props) {
    const {
      isOpened,
      isExpanded,
      isOpenedGroupSelectedOnStartup,
      isNewChat,
      isFlat,
      isAnythingInsideSidebarBox,
      selectedChat,
      selectedChatId,
      watchIsExpandedToSelectNewestChat,
      isInputMaximized,
      groupsFiltered,
      openedGroupIDs,
      showSortPanel,
      showOptionsPanel,
      isSoundTemporarilyMuted
    } = useMessengerState();
    const {
      isDesktopWidth, isTabletWidth, isMobileWidth, isTooFlatForMessenger
    } = useResponsiveness();
    const { inVideoChat } = toRefs(props);

    const messenger = templateRef<HTMLElement>('messenger');

    useScrollLock(messenger);
    usePreventScroll(messenger);

    onMounted(() => {
      if (isDesktopWidth.value) {
        isOpened.value = !isTooFlatForMessenger.value;
      }
    });

    watch(groupsFiltered, (groups: Group[]) => {
      if (!isOpenedGroupSelectedOnStartup.value) {
        const directGroup: Group = groups.find(({ type }) => type === CHAT_TYPE_DIRECT) as Group;
        if (directGroup) {
          openedGroupIDs.openedIds.push(directGroup.id);
          isOpenedGroupSelectedOnStartup.value = true;
        }
      }
    });

    // rather for developing: When switching to mobile, close messenger:
    watch(isMobileWidth, (is) => {
      if (is) {
        isExpanded.value = false;
        isOpened.value = false;
      }
    });

    watch(isOpened, (is) => {
      if (!is && isExpanded.value) {
        isExpanded.value = false;
        isInputMaximized.value = false;
      }
    });

    watch(selectedChatId, () => {
      isInputMaximized.value = false;
    });

    watchIsExpandedToSelectNewestChat();

    const messengerClasses = computed(() => ({
      'bc-messenger--expanded': isExpanded.value,
      'no-sidebar-box': !isAnythingInsideSidebarBox.value,
      'bc-slight-transparency': inVideoChat.value
    }));

    const messengerContentClasses = computed(() => ({
      'bc-messenger-content--is-opened': isOpened.value,
      'bc-messenger-content--expanded-no-chats': isExpanded.value && !isNewChat.value && !selectedChat.value,
      'bcx-secondary bcx-base': isMobileWidth.value
    }));

    const mainContentComponent = computed(() => (isTabletWidth.value || isMobileWidth.value ? 'div' : 'BCXMessengerCollapsible'));

    const isChatVisible = computed(() => !!(selectedChat.value || isNewChat.value));

    return {
      isOpened,
      isChatVisible,
      isNewChat,
      isExpanded,
      isFlat,
      mainContentComponent,
      selectedChat,
      isMobileWidth,
      messengerClasses,
      messengerContentClasses,
      showSortPanel,
      showOptionsPanel
    };
  }
});
