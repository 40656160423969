
import { defineComponent, ref, PropType } from 'vue';
import { TranslatedErrorCode } from '@/models/BackendError';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  name: 'BackendErrors',
  components: {
    BCXMarkdown
  },
  props: {
    backendErrors: {
      type: Array as PropType<(TranslatedErrorCode | string)[]>,
      default: () => []
    }
  },
  setup() {
    const { t } = useI18n();
    const unknownErrorCodes = ref<string[]>([]);
    const markdownErrorText = (code: string) => {
      if (/\+\+#!#\+\+/.test(code)) {
        const errors = code.split('++#!#++');
        return t(`validation.backend_errors.${errors[0]}`, { errorCode: errors[1] }) as string;
      }
      return t(`validation.backend_errors.${code}`) as string;
    };
    return {
      unknownErrorCodes,
      markdownErrorText
    };
  }
});
