import { render, staticRenderFns } from "./RecommendationFeedback.vue?vue&type=template&id=6d8a2f34&scoped=true"
import script from "./RecommendationFeedback.vue?vue&type=script&lang=ts"
export * from "./RecommendationFeedback.vue?vue&type=script&lang=ts"
import style0 from "./RecommendationFeedback.vue?vue&type=style&index=0&id=6d8a2f34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d8a2f34",
  null
  
)

export default component.exports