
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import NotificationBarItem from '@/components/toolbar/notification/NotificationBarItem.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import useNotificationState from '@/state/notificationState';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationBar',
  components: {
    BCXChevronSwitch,
    NotificationBarItem
  },

  props: {
    active: {
      type: String,
      default: ''
    },
  },
  setup() {
    const { isMobileWidth } = useResponsiveness();
    const { showPanel, barItems, totalCount } = useNotificationState();

    const getPosForIndex = (index:number):number => {
      const { length } = Object.keys(barItems.value);
      if (length <= 1) return 0.5;
      return index / (length - 1);
    };

    return {
      barItems,
      isMobileWidth,
      totalCount,
      showPanel,
      getPosForIndex
    };
  },

});
