import useDevice from './useDevice';

const useAudioInput = () => {
  const { current: currentMicrophone, devices: microphones } = useDevice('audioinput');

  return {
    microphones,
    currentMicrophone
  };
};

export default useAudioInput;
