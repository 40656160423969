
import { defineComponent, ref } from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'MobileBurger',
  props: {
    value: {
      type: Boolean
    }
  },
  setup(props) {
    const isOpen = useVModel(props);

    const toggle = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      toggle,
      isOpen
    };
  }
});
